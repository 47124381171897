import { OnlineStoresList } from "./online-stores-list.model";

export class OnlineStoresModel {
  business_id: number;
  online_stores: any = [];

  constructor() {
    this.business_id = 0;
    this.online_stores = new Array<OnlineStoresList>();
    // this.online_stores.push({
    //   "id": 0,
    //   "url": ""
    // });
  }
}
