import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../environments/environment';
import { PostJsonBusinessList } from 'src/app/shared/class/data.model';
import { CommonService } from '../../services/common.service';
import { HomeService } from '../../services/home.service';


@Component({
  selector: 'ryec-group-post-liked-shared-tagged-users-list-modal',
  templateUrl: './group-post-liked-shared-tagged-users-list-modal.component.html',
  styleUrls: ['./group-post-liked-shared-tagged-users-list-modal.component.css']
})
export class GroupPostLikedSharedTaggedUsersListModalComponent implements OnInit {
  @Input() usersList: any[] = [];
  @Input() loadType: string = "";
  @Input() postId: number = 0;
  currentPage: number = 0;
  userListModel: any[] = [];
  perPageList = environment.USERS_LIST_LIMIT;
  totalsUserCount: number = 0;
  noData: boolean = false;
  collectionSize: number = 0;
  first: number = 1;
  last: number = 0;
  maxSize: number = 5;

  constructor(
    public activeModal: NgbActiveModal,
    private hS: HomeService,
    private cS: CommonService,
  ) {
    this.currentPage = 1;
  }

  ngOnInit() {
    this.currentPage = 1;
    if (this.postId && this.loadType == 'loadPostLikedUsers' || this.loadType == 'loadPostSharedUsers') {
      this.getLikedUsersList(this.postId);
    }
    else if (this.loadType == 'taggedUsers') {
      this.userListModel = this.usersList;
    }
  }

  getLikedUsersList(postId: number) {
    let postJson: PostJsonBusinessList;
    if (this.loadType == 'loadPostSharedUsers') {
      postJson = {
        post_id: postId,
        page: this.currentPage,
        limit: this.perPageList,
        share: true
      }
    }
    else{
      postJson = {
        post_id: postId,
        page: this.currentPage,
        limit: this.perPageList
      }
    }
    // const postJson: PostJsonBusinessList = {
    //   post_id: postId,
    //   page: this.currentPage,
    //   limit: this.perPageList
    // };
    this.hS.getPostLikedUsersList(postJson).subscribe((res: any) => {
      if (res.status == 1) {
        this.userListModel = res.data;
        this.totalsUserCount = res.total;
        this.countCollectionSize();
      }
      else {
        this.userListModel = [];
      }
    });
  }

  /**
  *Calculate pagination display on screen and count of total businesses
  */
  countCollectionSize() {
    if (this.totalsUserCount > this.perPageList) {
      this.collectionSize = Math.ceil(this.totalsUserCount / this.perPageList);
      this.collectionSize = this.collectionSize * 10;
      if (this.currentPage === 1) {
        this.first = 1;
        this.last = this.perPageList;
      } else {
        this.first = (this.currentPage - 1) * this.perPageList + 1;
        if (this.userListModel.length < this.perPageList) {
          this.last = (this.currentPage - 1) * this.perPageList + this.userListModel.length;
        } else {
          this.last = (this.currentPage - 1) * this.perPageList + this.perPageList;
        }
      }
    } else {
      this.last = this.totalsUserCount;
      this.collectionSize = this.perPageList;
    }

    if (this.totalsUserCount == 0 || this.totalsUserCount == undefined) {
      this.noData = true;
    } else {
      this.noData = false;
    }
    this.cS.scrollTop();
    // this.showBusinessOnMap();
  }

  getNextPageBusinessList(): void {
    this.getLikedUsersList(this.postId);
  }

}
