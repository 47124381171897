import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trimstr'
})
export class TrimstrPipe implements PipeTransform {

    transform(value: any, args: any) : any 
  	{
    	let limit = args ? parseInt(args, 10) : 10;
    	let trail = '...';
    	return value.length > limit ? value.substring(0, limit) + trail : value;
  	}

}
