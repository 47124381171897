import { Component, OnInit, OnDestroy, HostListener, ElementRef, Input } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';

import { TranslateService } from '@ngx-translate/core';
import { SignUpLoginSubRes, BusinessFlag } from 'src/app/shared/class/data.model';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BecomeAgentComponent } from './modal/become-agent/become-agent.component';
import { AddMemberAgentComponent } from './modal/add-member-agent/add-member-agent.component';
import { DeviceDetectorService } from 'ngx-device-detector';


import swal from 'sweetalert2';
import { MessageService } from 'src/app/shared/services/message.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { HomeService } from 'src/app/shared/services/home.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { environment } from 'src/environments/environment';

declare var $: any;
@Component({
  selector: 'ryec-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit, OnDestroy {
validTitle: any;
  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    if (this.eRef.nativeElement.contains(event.target)) {
      if (event.srcElement.className == "fa fa-list-ul side_menu_icon" || event.srcElement.className == "premium_membership_plans" || event.srcElement.className == "ng-untouched ng-pristine ng-valid" || event.srcElement.className == "business_name_align" || event.srcElement.className == "fa fa-envelope") {
        this.profileDropDown = false;
        this.isShow = false;
        if (this.appName == 'MRC') {
          $(".auto_complete_list_mrc").css("display", "none");
        }
        else if (this.appName == 'RYC') {
          $(".auto_complete_list_ryc").css("display", "none");
        }
      } else if (event.srcElement.className == 'container' || event.srcElement.className == 'ng-pristine ng-valid ng-touched' || event.srcElement.className == '' || event.srcElement.className == 'ng-star-inserted') {
        this.profileDropDown = false;
        this.showMenuList = false;
        if (this.appName == 'MRC') {
          $(".auto_complete_list_mrc").css("display", "none");
        }
        else if (this.appName == 'RYC') {
          $(".auto_complete_list_ryc").css("display", "none");
        }
        this.isShow = false;
      }
      else {
        if (event.srcElement.className == 'profile_menu_icon') {
          this.isShow = false;
        } else if (event.srcElement.className == 'dropbtn') {
          this.profileDropDown = false;
          if (this.appName == 'MRC') {
            $(".auto_complete_list_mrc").css("display", "none");
          }
          else if (this.appName == 'RYC') {
            $(".auto_complete_list_ryc").css("display", "none");
          }
        }
        this.showMenuList = false;
      }
      if (event.srcElement.className == 'dropbtn langauge') {
        this.showMenuList = true;
      } else {
        this.isLangaugeShow = false;
      }

      if (event.srcElement.className == '') {
        if (this.appName == 'MRC') {
          $(".auto_complete_list_mrc").css("display", "none");
        }
        else if (this.appName == 'RYC') {
          $(".auto_complete_list_ryc").css("display", "none");
        }
        this.searchText = '';
        this.search_location = 'All Locations';
      }
      if (event.srcElement.className == 'search_input') {
        if (this.appName == 'MRC') {
          $(".auto_complete_list_mrc").css("display", "none");
        }
        else if (this.appName == 'RYC') {
          $(".auto_complete_list_ryc").css("display", "none");
        }
      }
    } else {
      this.profileDropDown = false;
      this.showMenuList = false;
      if (this.appName == 'MRC') {
        $(".auto_complete_list_mrc").css("display", "none");
      }
      else if (this.appName == 'RYC') {
        $(".auto_complete_list_ryc").css("display", "none");
      }

      let path = window.location.pathname;
      if (path.indexOf('/business-search') === -1) {
        this.searchText = '';
        //this.search_location = 'All Locations';
      }
      this.isShow = false;

      if (event.srcElement.className != 'dropbtn') {
        this.isLangaugeShow = false;
      }
    }
    console.log("Test:- " + event.srcElement.className);
  }

  userProfile: SignUpLoginSubRes | null;
  userLoggedIn = false;
  userSubscription: Subscription;
  isVendorSubscription: Subscription;
  business_flag: boolean;
  agentStatus: boolean;
  languageProvider: any;
  lang: string;
  profileSubscription: Subscription;
  businessDetailsSub: Subscription;
  agentSub: Subscription;
  business_name = '';
  business_id = 0;
  isDesktopDevice: boolean=false;
  business_approved = 0;
  business_slug = '';
  showMenuList: boolean;
  total_unmessage = 0;
  userName: any;
  items: any;
  locations :any;
  allLocations :any;
  search_location = 'All Locations';
  searchText = '';
  isShow = false;
  location = '';
  isLangaugeShow = false;
  language = 'English';
  search_text = false;
  isSearchPage = false;
  isRajput = false;
  membership_type = 0;
  membership_type_image = '';
  profileDropDown: boolean;
  modalReference: any;
  validBusinessTitle = false;
  validName = false;
  validPhoneNo = false;
  validCity = false;
  validForm = false;
  businessType = "";
  businessForm = {
    business_name: '',
    name: '',
    phoneno: '',
    city: ''
  }
  isMobileAppUrl: boolean = false;
  hover: boolean = false;
  appDownloadUrl: string = "";
  appName = environment.APP_NAME;
  userDetail: any;
  //isLanding = true;

  private deviceInfo: any;
  isMobile:any;
  

  @Input() isHomePage: boolean = false;

  constructor(private authService: AuthService,
    private mS: MessageService,
    private cS: CommonService,
    public tS: TranslateService,
    public router: Router,
    public hS: HomeService,
    private modalService: NgbModal,
    private deviceService: DeviceDetectorService,
  //  private deviceService: Ng2DeviceService,
    private eRef: ElementRef,
    private httpS: HttpService) {

    //if (window.location.href.indexOf('landing') < 0) {
    //	this.isLanding = false;
    //}

    // if (window.location.href.indexOf('mobileApp') > 0) {
    //   console.log('found');
    //   this.isMobileAppUrl = true;
    // }

    //console.log(this.isLanding);

    this.business_flag = false;

    // agent status
    this.agentStatus = true;

    // init profileDropDown
    this.profileDropDown = false;

    // set lang
    this.lang = 'en';

    // set hide
    this.showMenuList = false;

    this.userSubscription = this.mS.getLoggedIn().subscribe(data => {
      this.userLoggedIn = data;
      if (this.userLoggedIn) {
        // this.getAddressDetail();
      }
    });

    // profile subscription
    this.profileSubscription = this.mS.getProfile().subscribe((data: SignUpLoginSubRes) => {
      this.userProfile = data;
      // show register business if not vendor
      if (data.isVendor === 0) {
        this.membership_type = 0;
        this.business_flag = false;
      }

      if (data.isRajput === 0) {
        this.isRajput = false
      } else {
        this.isRajput = true;
      }

      if (data.isVendor == 1) {
        if (data.membership_type != null) {
          this.membership_type = <any>data.membership_type;
          this.membership_type_image = <any>data.membership_type_icon;
        }
      }
      if (data.name !== 'null') {
        const temp = data.name.split(' ');
        if (temp.length > 1) {
          const userName = temp[0].substr(0, 1).toUpperCase() + ' ' + temp[1].substr(0, 1).toUpperCase();
          const matches = userName.match(/\b(\w)/g);
          if (matches) {
            this.userName = matches.join(' ');
          }
        } else {
          const matches = data.name.match(/\b(\w)/g);
          if (matches) {
            this.userName = matches.join(' ');
          }
        }
      }
      if (data) {
        const profile_pic_thumb = data.profile_pic_thumbnail;
        if (profile_pic_thumb === '') {
          // console.log('profile pic missing');
          if (this.appName == "RYC") {
            this.userProfile.profile_pic_thumbnail = '../assets/images/default-logo-ryc.png';
          }
          else {
            this.userProfile.profile_pic_thumbnail = '../assets/images/default_image.png';
          }
        }
      }
    });

    // business subscription
    this.businessDetailsSub = this.mS.getBusinessDetailUpdate().subscribe((dataRes: BusinessFlag) => {
      console.log(dataRes);
      this.updateUserBusinessData();
    });

    //message Count update
    const messageCount = this.mS.getMessageCount().subscribe((messageRes: any) => {
      console.log(messageRes);
      this.total_unmessage = 0;
    })
    console.log(messageCount);

    // agent Status Subscription
    this.agentSub = this.mS.getAgentStatus().subscribe(res => {
      if (res.isAgent === 'P') {
        this.agentStatus = true;
      } else if (res.isAgent === 'A') {
        this.agentStatus = false;
      } else {
        this.agentStatus = true;
      }
    });

    this.isVendorSubscription = this.mS.getRegisterbusiness().subscribe(data => {
      this.business_flag = data;
    });

    //Get business search page when serach business page change data
    this.mS.getBusinessSearchText().subscribe(data => {
      // TODO - change city here
      this.search_location = data.city;
      this.searchText = data.text;
      this.isSearchPage = true;
    });

    this.userDetail = this.cS.getUserDetails();

    //this.mS.getIsLandingPage().subscribe(data => {
    //	this.isLanding = data;
    //});

    // this.languageProvider = [{
    //   key: 'en',
    //   val: 'English'
    // },
    // {
    //   key: 'hi',
    //   val: 'हिंदी'
    // },
    // {
    //   key: 'gu',
    //   val: 'राजस्थानी'
    // }
    // ];
    this.getLanguage();

    this.mS.getBusinessName().subscribe(businessRes => {
      this.business_name = businessRes;
    })

    // set user profile
    this.userProfile = null;
  }

  getLanguage() {
    if (this.appName == 'RYC') {
      this.languageProvider = [
        { key: 'en', val: 'English' },
        { key: 'hi', val: 'हिंदी' },
        { key: 'gu', val: 'ગુજરાતી' }
      ];
    }
    else {
      this.languageProvider = [
        { key: 'en', val: 'English' },
        { key: 'hi', val: 'हिंदी' },
        { key: 'gu', val: 'राजस्थानी' }
      ];
    }

  }

  ngOnInit() {
    this.lang = this.cS.getLang();
    if (this.lang == 'en') {
      this.language = 'English';
    } else if (this.lang == 'hi') {
      this.language = 'हिंदी';
    } else {
      if (this.appName == 'RYC') {
        this.language = 'ગુજરાતી';
      }
      else {
        this.language = 'राजस्थानी';
      }
    }
    this.isDesktopDevice = this.deviceService.isDesktop();
    this.authService.isLoggedIn() ? this.userLoggedIn = true : this.userLoggedIn = false;
    if (this.userLoggedIn) {
      const isRegister = this.cS.isBusinessRegister();
      const isSkipped = this.cS.isBusinessRegisterSkipped();
      this.business_flag = isRegister || !isSkipped;
      const isAgentVal = this.cS.getData('isAgent');
      if (isAgentVal && isAgentVal === 'A') {
        this.agentStatus = false;
        this.business_flag = isRegister;
      } else if (isAgentVal && isAgentVal === 'P') {
        this.agentStatus = true;
      } else {
        this.business_flag = isRegister;
      }

      this.updateUserBusinessData();
      if (this.userLoggedIn) {
        this.userProfile = this.cS.getUserDetails();
        if (this.userProfile) {
          if (this.userProfile.isRajput === 0) {
            this.isRajput = false
          } else {
            this.isRajput = true;
          }
          if (this.userProfile.isVendor == 1) {
            if (this.userProfile.membership_type != null) {
              this.membership_type = <any>this.userProfile.membership_type;
              this.membership_type_image = <any>this.userProfile.membership_type_icon;
            }
            this.getBusinessApproved();
          }
          const profile_pic_thumb = this.userProfile.profile_pic_thumbnail;
          if (profile_pic_thumb === '') {
            this.userProfile['profile_pic_thumbnail'] = '../assets/images/default_image.png';
          }

          if (this.userProfile.name !== 'null') {
            const temp = this.userProfile.name.split(' ');
            if (temp.length > 1) {
              const userName = temp[0].substr(0, 1).toUpperCase() + ' ' + temp[1].substr(0, 1).toUpperCase();
              const matches = userName.match(/\b(\w)/g);
              if (matches) {
                this.userName = matches.join(' ');
              }
            } else {
              const matches = this.userProfile.name.match(/\b(\w)/g);
              if (matches) {
                this.userName = matches.join(' ');
              }
            }
          }
        }
      }
      if(this.appName=='MRC')
      {
      this.getUserUnreadThreadCount();
      }
      // this.getAddressDetail();

      // this.routeUrl = this.router.url;
    }
   
    // for detect mobile
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    if (this.isMobile) {
      this.redirectToAppDownload();
        let element:HTMLElement = document.getElementById('app-download-btn') as HTMLElement;
        element.click();
    }
  }

  // checkIsIOSAppInstalled() {
  //   setTimeout(() => { window.location.href = "https://apps.apple.com/in/app/myrajasthan-club/id1545374835"; }, 200);
  //   window.location.href = "myrajasthan-club://";
  // }

  // checkIsAndroidAppInstalled() {
  //   setTimeout(() => { window.location.href = "https://play.google.com/store/apps/details?id=club.myrajasthan"; }, 200);
  //   // window.location.href = "Myrajasthan Club://";
  // }

  showAppDownloadModal(content: any) {
    this.modalService.open(content, { windowClass: 'mobile_popup download-app' });
  }

  //Update business detail like business name, business approved or not
  updateUserBusinessData() {
    const businessData = this.cS.getBusinessDetail();
    if (businessData) {
      this.business_id = businessData.business_id;
      this.business_name = businessData.business_name;
      this.business_approved = businessData.business_approved;
      this.business_slug = businessData.business_slug;
    }
  }

  /**
   * redirect to app store dependent on device os type
   * @Yuvraj 01/12/2021
   */
  redirectToAppDownload() {
    if (this.deviceInfo.device == 'iPhone') {
      if (this.appName == 'RYC') {
        this.appDownloadUrl = "https://apps.apple.com/in/app/rajputs-club/id1660935382";
      }
      else {
        this.appDownloadUrl = "https://apps.apple.com/in/app/myrajasthan-club/id1545374835";
      }
      // this.checkIsIOSAppInstalled();
    }
    if (this.deviceInfo.device == 'Android') {
      if (this.appName == 'RYC') {
        this.appDownloadUrl = "https://play.google.com/store/apps/details?id=com.rajputsclub";
      }
      else {
        this.appDownloadUrl = "https://play.google.com/store/apps/details?id=club.myrajasthan";
      }
    }
  }

  ngOnDestroy(): void {
    // prevent memory leckage
    this.userSubscription.unsubscribe();
    this.agentSub.unsubscribe();
    this.businessDetailsSub.unsubscribe();
    this.isVendorSubscription.unsubscribe();
  }

  // logout trigger
  logout(): void {
    this.menuHide();
    // this.authService.logout();
    // this.cS.businessModel.is_Register = false;
    // this.cS.businessModel.business_id = 0;
    // this.cS.businessModel.business_name = '';
    // this.cS.businessModel.business_approved = 0;
    // this.cS.businessModel.business_slug = '';
    // this.cS.setBusinessRegiFlag();
    // this.tS.use('en');

    swal.fire({
      title: 'Are you sure you want to log out?',
      // text: name,
       
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      showCancelButton: true,
      showCloseButton: true,
      icon: 'warning',
      reverseButtons: true,
      cancelButtonColor: "#d33",
      confirmButtonColor: "#3085d6",
      
    }).then((result) => {
      if (result.value) {
        this.authService.logout();
        this.cS.businessModel.is_Register = false;
        this.cS.businessModel.business_id = 0;
        this.cS.businessModel.business_name = '';
        this.cS.businessModel.business_approved = 0;
        this.cS.businessModel.business_slug = '';
        this.cS.setBusinessRegiFlag();
        this.tS.use('en');
      }
    });
  }

  //Change language and set in default language for translate
  langSelection() {
    this.cS.setLang(this.lang);
    this.tS.setDefaultLang(this.lang);
    this.menuHide();
  }

  toggleMenu() {
    this.showMenuList = !this.showMenuList;
  }

  menuHide() {
    this.showMenuList = false;
    this.profileDropDown = false;
  }

  toggleProfile() {
    this.profileDropDown = !this.profileDropDown;
  }

  ckeckBusinessApproved() {
    this.menuHide();
    if (this.business_approved === 1) {
      this.router.navigateByUrl('home/my-business-detail');
    } else {
      if (this.appName == 'RYC') {
        this.cS.showInfo('Your business is under review.  Feel free to contact support at info@rajputsclub.com or call us at +91-9680419568 for any further details.');
      }
      if(this.appName == 'BD')
      {
        this.cS.showInfo('Your business is under review.  Feel free to contact support at info@bharatdarshan.org or call us at +91-7852812997 for any further details.'); 
      }
      else {
        this.cS.showInfo('Your business is under review.  Feel free to contact support at info@myrajasthanclub.com or call us at +91-9166319568 for any further details.');
      }
    }
  }

  /**
  * become an agnet model trigger
  */
  becomeAgent(): void {
    // hide menu if open
    this.showMenuList = false;
    // open become agent modal
    this.modalService.open(BecomeAgentComponent, { size: 'lg', windowClass: 'mobile_popup' });

  }

  /**
  * Open Mobile num verification model (Register Member By Agent)
  * @returns void
  */
  registerMember() {
    this.menuHide();
    this.modalService.open(AddMemberAgentComponent, { windowClass: 'mobile_popup' });
  }

  /**
   * Get unread message count from server
   */
  getThreadMessage() {
    this.total_unmessage = 0;
    this.menuHide();
  }

  /**
  * Get unread thread count
  */
  getUserUnreadThreadCount() {
 
    const apiUrl = environment.RYEC_API_URL + 'getUnreadThreadsCount';

    this.httpS.get(apiUrl).subscribe((res: any) => {
      if (res.status == 1) {
        this.total_unmessage = res.data.unread_count;
      }
    }, err => {
      if (err.error) {
        console.log(err.error);
      }
    })
  }


  /**
   * Search by category with autocomplete
   * @param event
   */
  search(event: any) {
    const val = event.target.value;
    if (val === "" || val.length < 3) {
      if (this.appName == 'MRC') {
        $(".auto_complete_list_mrc").css("display", "none");
      }
      else if (this.appName == 'RYC') {
        $(".auto_complete_list_ryc").css("display", "none");
      }
    }
    if (val.length >= 3) {
      const postJson = { searchText: val };

      this.hS.getSearchAutocomplete(postJson).subscribe((res: any) => {
        if (res.data) {
          console.log(res.data);
          if (res.data.length > 0) {
            if (this.appName == 'MRC') {
              $(".auto_complete_list_mrc").css("display", "block");
            }
            else if (this.appName == 'RYC') {
              $(".auto_complete_list_ryc").css("display", "block");
            }
            this.items = res.data;
          } else {
            if (this.appName == 'MRC') {
              $(".auto_complete_list_mrc").css("display", "none");
            }
            else if (this.appName == 'RYC') {
              $(".auto_complete_list_ryc").css("display", "none");
            }
          }
        }
      }, err => {
        console.log(err);
      });
    }
  }

  /**
  * Get Address Detail from entered details
  *@returns void
  */
  getAddressDetail() {
    this.hS.getAddressMaster().subscribe((res: any) => {
      // to be changed from cities to locations later
      this.locations = res.data.cities;
      if (this.locations.length > 0) {
        if (this.search_location == '') {
          this.search_location = this.locations[0]['name'];
        }
      }
      this.allLocations = this.locations;
    }, err => {
      console.log(err);
    });
  }

  getChildLocationSearchValue(val: string) {
    this.search_location = val;
  }

  checkEnableDisableView(val: any) {
    console.log('val', val);
  }

  searchBusinessByCategory(category_name: string) {
    this.searchText = category_name;
    this.searchBusiness();
    if (this.appName == 'MRC') {
      $(".auto_complete_list_mrc").css("display", "none");
      
    }
    else if (this.appName == 'RYC') {
      $(".auto_complete_list_ryc").css("display", "none");
    }
    this.isLangaugeShow = false;
  }

  searchBusiness() {
    if (this.appName == 'MRC') {
      $(".auto_complete_list_mrc").css("display", "none");
    }
    else if (this.appName == 'RYC') {
      $(".auto_complete_list_ryc").css("display", "none");
    }
    this.isLangaugeShow = false;
    if (this.searchText == "") {
      // this needs to be changed to city
      this.router.navigateByUrl('business-search?city=' + this.search_location + '&sort=relevance');
    } else {
      // this needs to be changed to city
      this.router.navigateByUrl('business-search?q=' + this.searchText + '&city=' + this.search_location + '&sort=relevance');
    }

  }

  showLocationDropDown() {
    if (this.isShow) {
      this.isShow = false;
    } else {
      this.isShow = true;
    }
  }

  //Filter location from location list
  filterLocation() {
    this.locations = [];
    if (this.location != '') {
      this.allLocations.filter((item:any) => {
        if (item['name'].toLowerCase().indexOf(this.location.toLowerCase()) > -1) {
          this.locations.push(item);
        }
      })
    } else {
      this.locations = this.allLocations;
    }
  }

  selectLocation(location: string) {
    this.searchText = this.searchText;
    // let path = window.location.pathname;
    this.search_text = true;
    this.search_location = location;
    this.isShow = false;
    // console.log(this.search_location);
    // if (path.indexOf('/business-search') > -1) {
    // 	if (this.search_location == 'All Locations') {
    // 		this.location = '';
    // 		this.locations = this.allLocations;
    // 	}
    // 	this.searchBusiness();
    // }
  }

  /**
   * Chech user business approved or not
   */
  getBusinessApproved() {
    const postJson = { business_id: this.business_id };

    this.hS.getBusinessApproved(postJson).subscribe((res: any) => {
      if (res.data) {
        this.business_approved = res.data.isApproved;
        this.membership_type = res.data.membership_type;
        this.membership_type_image = res.data.membership_type_icon;
      }
    }, (err:any) => {
      console.log(err);
    });
  }

  showLanguageDropDown() {
    if (this.isLangaugeShow) {
      this.isLangaugeShow = false;
    } else {
      this.isLangaugeShow = true;
    }
  }

  selectLanguage(lang: string, language: string) {
    // if (lang == 'gu') {
    //   lang = 'hi'; //due to rajasthani lang file is not present
    // }
    this.cS.setLang(lang);
    this.tS.setDefaultLang(lang);
    this.tS.use(lang);
    this.language = language;
    this.isLangaugeShow = false;
    this.mS.setLang(lang);
  }

  /**
  *add reviews and rating for business list
  */
  submitBusinessDetail() {
    const postJson = {
      business_name: this.businessForm.business_name,
      name: this.businessForm.name,
      mobile_number: this.businessForm.phoneno,
      city: this.businessForm.city,
      type: this.businessType,
    };

    const apiUrl = environment.RYEC_API_URL + 'addBusinesBrandingInquiry';

    this.httpS.post(apiUrl, postJson).subscribe((res: any) => {
      console.log(res);
      if (res.status === 1) {
        this.cS.showSuccess(res.message);
      }
      else {
        this.cS.showError(res.message);
      }
      this.modalReference.close();
    }, (err:any)=> {
      if (err.error) {
        console.log(err.error);
      }
    });
  }

  /**
  *open business list add enquiry modal
  */
  addContentForBusiness(content: any, type: any) {
    this.businessForm.business_name = '';
    this.businessForm.name = '';
    this.businessForm.phoneno = '';
    this.businessForm.city = '';
    this.businessType = type;
    this.modalReference = this.modalService.open(content, { windowClass: 'enquiry_popup' });
    this.modalReference.result.then((result: any) => {
      console.log(`Closed with: ${result}`);
    }, (reason: any) => {
      console.log(reason)
    });
  }

  changeTitleEvent(event?: any) {
    console.log(event);
    if (this.businessForm.business_name === '' && this.businessForm.business_name.trim() === '') {
      this.validBusinessTitle = true;
    } else {
      this.validBusinessTitle = false;
    }

    if (this.businessForm.name === '' && this.businessForm.name.trim() === '') {
      this.validName = true;
    } else {
      this.validName = false;
    }

    if (this.businessForm.phoneno === '' && this.businessForm.phoneno.trim() === '') {
      this.validPhoneNo = true;
    } else {
      this.validPhoneNo = false;
    }
    if (this.businessForm.city === '' && this.businessForm.city.trim() === '') {
      this.validCity = true;
    } else {
      this.validCity = false;
    }

    if (this.businessForm.business_name === '' || this.businessForm.business_name.trim() === '' ||
      this.businessForm.name === '' || this.businessForm.name.trim() === '' ||
      this.businessForm.phoneno === '' || this.businessForm.phoneno.trim() === '' ||
      this.businessForm.city === '' || this.businessForm.city.trim() === ''
    ) {
      this.validForm = false;
    } else {
      this.validForm = true;
    }
  }


  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
