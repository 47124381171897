export class GroupManagementModel {
    searchText: string;
    groupMemberId: number
    groupDesignationId: number;
    groupMemberRoleId: number;
    groupDesignationName: string;
    constructor() {
        this.searchText = "";
        this.groupMemberId = null as any;
        this.groupDesignationId = null as any;
        this.groupMemberRoleId = null as any;
        this.groupDesignationName = "";
    }
}