import { Component, ElementRef, OnInit, ViewChild, HostListener } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { OwlCarousel } from 'ngx-owl-carousel';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, Meta } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';

import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'src/app/shared/services/message.service';
import { HomeService } from 'src/app/shared/services/home.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { environment } from 'src/environments/environment';
import { ReportBusinessModalComponent } from 'src/app/shared/component/report-business-modal/report-business-modal.component';
// import { Meta } from '@angular/platform-browser';
declare var $: any;
@Component({
  selector: 'ryec-place-details',
  templateUrl: './place-details.component.html',
  styleUrls: ['./place-details.component.css']
})
export class PlaceDetailsComponent implements OnInit {

  readonly STORAGE_USER_KEY: string = "UN_AUTH_USER";

  review = '';
  reviewValid = false;
  modalReference: any;
  currentRate = 0;
  collectionSize = 0;
  currentPage: number;
  business_slug: string = '';
  start_5: number = 0;
  start_4: number = 0;
  start_3: number = 0;
  start_2: number = 0;
  start_1: number = 0;
  totalRatingCount: number = 0;
  placeDetailsModel: any = {};
  allRatings = [];
  imgTags: any;
  entityImgMetaTags: any[] = [];
  defaultDesc: string = "";
  knowMoreSectionDetailsModel: any;
  assetTypeFieldsModel: any = {};
  customDetailsModel: any;
  nearBySectionDetailsModel: any;
  topVideosSectionDetailsModel: any;
  ratingReviewsModel: any;
  repoUrl = window.location.href;
  contentLang: string = 'english';
  isShow = false;
  isMobileAppView: boolean = false;
  topPosToStartShowing = 100;
  subscribe: any;
  defaultSelectedVal: any;
  isMobile: boolean = false;
  currentPosition: number = 0;
  isKnowMoreLoading: boolean = false;
  isVideoLoading: boolean = false;
  isCustomDetailsLoading: boolean = false;
  isNearByLoading: boolean = false;
  isRatingReviewsLoading: boolean = false;
  imageGallerys: any[] = [];
  isPageLoaded: boolean = false;
  businessImageUrl: string = "";
  entityType: string = "";
  returnRedirectUrl: string = "";
  perPageList = environment.BUSINESS_LIST_LIMIT;
  appName = environment.APP_NAME;
  @ViewChild('owlElement')
  owlElement!: OwlCarousel;
  @ViewChild('message')
  message!: ElementRef;
  userVisitCount = new BehaviorSubject(0);
  userVisitCount$ = this.userVisitCount.asObservable();
  fun() {
    this.owlElement.next([200]);
  }
  customOptions = {
    loop: true,
    dots: false,
    nav: true,
    items: 1,
    margin: 0,
    startPosition: 0,
    navText: ["<div class='nav-btn prev-slide'><img src='assets/images/left-arrow.svg'/></div>", "<div class='nav-btn next-slide'><img src='assets/images/right-arrow.svg'/></div>"],
  }
  nearbyOptions = {
    loop: false,
    dots: false,
    nav: true,
    items: 4,
    margin: 0,
    navText: ["<div class='nav-btn prev-slide'><img src='assets/images/left-arrow.svg'/></div>", "<div class='nav-btn next-slide'><img src='assets/images/right-arrow.svg'/></div>"],
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 2,
      },
      768: {
        items: 3,
      },
      1199: {
        items: 4,
      }
    }
  }
  schemaJson: any[] = [];
  constructor(
    private modalService: NgbModal,
    private cS: CommonService,
    private httpS: HttpService,
    private activeRoute: ActivatedRoute,
    private homeService: HomeService,
    public sanitizer: DomSanitizer,
    public el: ElementRef,
    public router: Router,
    private meta: Meta,
    private deviceService: DeviceDetectorService,
    public tS: TranslateService,
    private mS: MessageService,
    // private meta: Meta
  ) {
    this.currentPage = 1;
  }

  ngOnInit() {
    /**
     * get url slug
     */
    this.isMobile = this.deviceService.isMobile();

    this.activeRoute.params.subscribe(data => {
      this.business_slug = data.slug;
      this.getBusinessDetailBySlug();
    });

  }

  // Back to top
  @HostListener('window:scroll')
  checkScroll() {

    // window의 scroll top
    // Both window.pageYOffset and document.documentElement.scrollTop returns the same result in all the cases. window.pageYOffset is not supported below IE 9.

    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    // console.log('[scroll]', scrollPosition);

    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }

    this.lazyLoadAPIContent();
  }
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  /**
   * check if image not in response set default one image
   * @Yuvraj 10/12/2021
   */
  setDefaultImage() {
    let img: string = "";
    if (this.appName == 'RYC') {
      img = '../assets/images/default-logo-ryc.png';
    }
    else {
      img = '../assets/images/default-logo.png';
    }
    if (this.placeDetailsModel.business_images.length == 0) {
      this.placeDetailsModel.business_images.push({
        id: 1,
        image_name: img,
        // image_name: '../assets/images/default-logo.png',
      });
    }
  }

  /**
   * call api only once user scroll down at certain criteria
   * @Yuvraj 11/11/2021
   */
  lazyLoadAPIContent() {
    var winScroll = window.scrollY + window.innerHeight;
    var height = document.body.scrollHeight;
    var scrolled = (winScroll / height) * 100;

    if (winScroll > this.currentPosition && scrolled >= 40) {
      if (this.knowMoreSectionDetailsModel?.length == 0 && this.assetTypeFieldsModel.know_more == '1' && !this.isKnowMoreLoading) {
        this.isKnowMoreLoading = true;
        this.getKnowMoreSectionData(this.placeDetailsModel.id, this.contentLang);
      }
    }
    if (winScroll > this.currentPosition && scrolled >= 60) {
      if (this.topVideosSectionDetailsModel?.length == 0 && this.assetTypeFieldsModel.video == '1' && !this.isVideoLoading) {
        this.isVideoLoading = true;
        this.getTopVideosSectionData(this.placeDetailsModel.id);
      }
    }
    if (winScroll > this.currentPosition && scrolled >= 70) {
      if (!this.nearBySectionDetailsModel && this.assetTypeFieldsModel.near_by == '1' && !this.isNearByLoading) {
        this.isNearByLoading = true;
        this.getEntityNearBySectionData(this.placeDetailsModel.id);
      }
    }
    if (winScroll > this.currentPosition && scrolled >= 80) {
      if (!this.ratingReviewsModel && this.assetTypeFieldsModel.rating == '1' && !this.isRatingReviewsLoading) {
        this.isRatingReviewsLoading = true;
        this.getNextPageRatingList();
      }
    }
    if (winScroll > this.currentPosition && scrolled >= 90) {
      if (this.customDetailsModel?.length == 0 && this.assetTypeFieldsModel.custom_component == '1' && !this.isCustomDetailsLoading) {
        this.isCustomDetailsLoading = true;
        this.getCustomDetailsSectionData(this.placeDetailsModel.id, this.contentLang);
      }
    }
    this.currentPosition = winScroll;
  }
  /**
    * Get Business Detail by business slug
    *@returns voidtopVideosSectionDetailsModel
    */
  getBusinessDetailBySlug(): void {
    const postJson = { business_slug: this.business_slug };

    this.homeService.getBusinessDetailBySlug(postJson).subscribe((res: any) => {
      if (res.data) {
        this.addUpdateMetaTags(res); //update seo meta tag
        this.placeDetailsModel = res.data;
        if (this.placeDetailsModel.business_images.length > 0) {
          this.businessImageUrl = this.placeDetailsModel.business_images[0].image_name;
        }
        else {
          this.setDefaultImage();
        }
        /**
         * if schema is not empty then call generate fun and pass values
         * @Yuvraj 20/01/2022 
         */
        if (res.data.schema_json.length > 0) {
          this.generateEntitySchema(res.data.schema_json);
        }
        let categoryId: number = 0;
        if (this.placeDetailsModel.entity_type) {
          if (this.placeDetailsModel.entity_type == "Business") {
            this.entityType = 'home/business-detail/';
          }
          if (this.placeDetailsModel.entity_type == "Place") {
            this.entityType = 'place/';
          }
          if (this.placeDetailsModel.entity_type == "Person") {
            this.entityType = 'person/';
          }
        }
        if (this.placeDetailsModel.parent_categories.length > 0) {
          categoryId = this.placeDetailsModel.parent_categories[0].id;
        }
        this.cS.scrollTop();
        if (this.placeDetailsModel.latitude && this.placeDetailsModel.longitude) {
          this.placeDetailsModel.mapURL = this.loadMap(this.placeDetailsModel.latitude, this.placeDetailsModel.longitude);
        }
        this.returnRedirectUrl = this.entityType + this.placeDetailsModel.business_slug;
        this.generateQrCode(this.placeDetailsModel.id);
        this.getSubAssetTypeFields(categoryId, this.placeDetailsModel.asset_type_id);
        this.convertMetaTagToArray();
        this.setDefaultLangDropdown();
        this.checkUrlLang();
        this.getLocalLang();
        this.isPageLoaded = true;
        this.defaultDesc = this.placeDetailsModel.descriptions;
        this.checkUserDetails();
      }
    }, err => {
      console.log(err);
    });
  }

  addUpdateMetaTags(res: any) {
    this.meta.updateTag({ name: "title", content: res["data"]["name"], });
    this.meta.updateTag({ name: "description", content: res["data"]["seo_meta_description"], });
    this.meta.updateTag({ name: "keywords", content: res["data"]["seo_meta_tags"], });
    this.meta.updateTag({ name: "og:title", content: res["data"]["name"], });
    this.meta.updateTag({ name: 'og:url', content: this.repoUrl });
    this.meta.updateTag({ name: 'og:description', content: res['data']['seo_meta_description'] });
    this.meta.updateTag({ name: 'twitter:site', content: this.repoUrl });
    this.meta.updateTag({ name: 'twitter:title', content: res['data']['name'] });
    this.meta.updateTag({ name: 'twitter:description', content: res['data']['seo_meta_description'] });
    this.meta.updateTag({ name: 'og:type', content: "Business details" });
    if (res.data.business_images.length > 0) {
      this.meta.updateTag({ name: 'og:image', content: res['data']['business_images']['0']['image_name'] });
      this.meta.updateTag({ name: 'twitter:image', content: res['data']['business_images']['0']['image_name'] });
    }
    else {
      this.meta.updateTag({ name: 'og:image', content: '../assets/images/default-logo.png' });
      this.meta.updateTag({ name: 'twitter:image', content: '../assets/images/default-logo.png' });
    }

  }

  /**
   * get schema json from the api and assign to schemaJson.
   * this will generate shema json script code in html file 
   * @Yuvraj 20/01/2022 06:00PM
   */
  generateEntitySchema(resSchemaJson: any) {
    this.schemaJson = resSchemaJson;
  }

  getSubAssetTypeFields(categoryId: number, assetTypeId: number) {
    const postJson = {
      category_id: categoryId,
      asset_type_id: assetTypeId
    };

    this.homeService.getSubAssetTypeFields(postJson).subscribe((res: any) => {
      if (res.data) {
        this.assetTypeFieldsModel = res.data;
      }
    }, err => {
      console.log(err);
    });
  }

  loadMap(param1: any, param2: any) {  //call this function from html
    let url = this.sanitizer.bypassSecurityTrustResourceUrl(`https://maps.google.com/maps?q=${param1},${param2}&z=14&output=embed`) // notice carefully the backticks dont confuse it with quotes ('' or " ")
    return url
  }

  changeDescriptionLang(event: any) {
    if (event.value == 'english') {
      this.placeDetailsModel.descriptions = this.defaultDesc;
    }
    else {
      this.placeDetailsModel.descriptions = event.value;
    }
  }

  generateQrCode(businessId: number) {
    const postJson = { business_id: businessId };

    this.homeService.generateQrCodeByBusinessId(postJson).subscribe((res: any) => {
      if (res.data) {
        this.placeDetailsModel.QRCode = res.data.url;
      }
    }, err => {
      console.log(err);
    });
  }

  suggestContent(content: any) {
    this.review = '';
    this.modalReference = this.modalService.open(content, { windowClass: 'modal-md suggest-modal' });
    this.modalReference.result.then((result: any) => {
      console.log(`Closed with: ${result}`);
      // this.getBusinessDetailBySlug();
    }, (reason: any) => {
      console.log(reason);
    });
  }

  ImageModal(content: any, index: any) {
    this.customOptions.startPosition = index;
    this.modalReference = this.modalService.open(content, { windowClass: 'post-img-carousel', centered: true });
    this.modalReference.result.then((result: any) => {
      console.log(`Closed with: ${result}`);
      // this.getBusinessDetailBySlug();
    }, (reason: any) => {
      console.log(reason);
    });
  }

  addReviewAndRating(content: any) {
    this.review = '';
    this.modalReference = this.modalService.open(content, { windowClass: 'rating_popup' });
    this.modalReference.result.then((result: any) => {
      console.log(`Closed with: ${result}`);
      // this.getBusinessDetailBySlug();
    }, (reason: any) => {
      console.log(reason);
    });
  }

  submitDescriptionSuggest() {
    if (this.checkAuth()) {
      if (this.review == '' && this.review.trim() == '') {
        this.reviewValid = true;
      } else {
        const postJson = {
          entity_id: this.placeDetailsModel.id,
          entity_know_more_id: 0,
          description: this.review
        }
        this.submitSuggest(postJson);
      }
    }
    else {
      this.cS.navigateTo('/login');
      this.modalReference.close();
    }
  }

  submitKnowMoreSuggest() {
    if (this.checkAuth()) {
      if (this.review == '' && this.review.trim() == '') {
        this.reviewValid = true;
      } else {
        const postJson = {
          entity_id: this.placeDetailsModel.id,
          entity_know_more_id: 0,
          description: this.review
        }
        this.submitSuggest(postJson);
      }
    }
    else {
      this.cS.navigateTo('/login');
      this.modalReference.close();
    }
  }

  submitSuggest(postJson: any) {
    const apiUrl = environment.RYEC_API_URL + 'suggestDescription';

    this.httpS.post(apiUrl, postJson).subscribe((res: any) => {
      if (res.status === 1) {
        this.cS.showSuccess(res.message);
      } else {
        this.cS.showError(res.message);
      }
      this.modalReference.close();
    }, err => {
      if (err.error) {
        console.log(err.error);
      }
    });
  }

  submitRatingDetail() {
    if (this.review == '' && this.review.trim() == '') {
      this.reviewValid = true;
    } else {
      const userDetail = this.cS.getUserDetails();
      if (userDetail) {
        const postJson = {
          user_id: userDetail.id,
          business_id: this.placeDetailsModel.id,
          rating: this.currentRate,
          comment: this.review
        }

        const apiUrl = environment.RYEC_API_URL + 'addBusinessRating';

        this.httpS.post(apiUrl, postJson).subscribe((res: any) => {
          if (res.status === 1) {
            this.cS.showSuccess(res.message);
            this.getNextPageRatingList(); //for refresh data
          } else {
            this.cS.showError(res.message);
          }
          this.modalReference.close();
        }, err => {
          if (err.error) {
            console.log(err.error);
          }
        });
      }
    }
  }

  getKnowMoreSectionData(businessId: number, contentLang: string) {
    const postJson = {
      entity_id: businessId,
      language: contentLang
    };

    this.homeService.getEntityKnowMore(postJson).subscribe((res: any) => {
      if (res.data) {
        this.knowMoreSectionDetailsModel = res.data;
      }
      else {
        this.knowMoreSectionDetailsModel = [];
      }
    }, err => {
      console.log(err);
    });
  }

  getEntityOtherSectionData(businessId: number, contentLang: string) {
    const postJson = {
      entity_id: businessId,
      language: contentLang
    };

    this.homeService.getEntityInOtherLang(postJson).subscribe((res: any) => {
      if (res.data) {
        this.placeDetailsModel.short_description = res.data.short_description;
        this.placeDetailsModel.descriptions = res.data.description;
      }
    }, err => {
      console.log(err);
    });
  }

  getCustomDetailsSectionData(businessId: number, contentLang: string) {
    const postJson = {
      entity_id: businessId,
      language: contentLang
    };

    this.homeService.getEntityCustomDetails(postJson).subscribe((res: any) => {
      if (res.data) {
        this.customDetailsModel = res.data;
      }
    }, err => {
      console.log(err);
    });
  }

  getEntityNearBySectionData(businessId: number) {
    const postJson = {
      entity_id: businessId,
    };

    this.homeService.getEntityNearBy(postJson).subscribe((res: any) => {
      if (res.data) {
        if (res.data.length > 0) {
          res.data.forEach((element: any) => {
            element.entities.forEach((element: any) => {
              if (element.entity_type) {
                if (element.entity_type == "Business") {
                  element.entity_type = '/home/business-detail/';
                }
                if (element.entity_type == "Place") {
                  element.entity_type = '/place/';
                }
                if (element.entity_type == "Person") {
                  element.entity_type = '/person/';
                }
                if (element.entity_type == "News") {
                  element.entity_type = '/news/';
                }
              }
            });

          });
        }
        this.nearBySectionDetailsModel = res.data;
        // this.getEntityType();
        this.convertMetaTagToArrayEntity();
        this.converEntityDescToString();
      }
    }, err => {
      console.log(err);
    });
  }

  getTopVideosSectionData(businessId: number) {
    const postJson = {
      entity_id: businessId,
    };

    this.homeService.getVideoLists(postJson).subscribe((res: any) => {
      if (res.data) {
        if (res.data.length > 0) {
          res.data.forEach((element: any) => {
            if (element.description) {
              element.description = new DOMParser().parseFromString(element.description, "text/html").documentElement.textContent;
            }
            else {
              element.description = "";
            }
          });
          this.topVideosSectionDetailsModel = res.data;
        }
      }
    }, err => {
      console.log(err);
    });
  }

  onLangChange(event: any) {
    this.contentLang = event.value;
    if (this.contentLang) {
      this.setLocalLang(this.contentLang);
    }
    this.getEntityOtherSectionData(this.placeDetailsModel.id, this.contentLang);
    if (this.isKnowMoreLoading) {
      this.knowMoreSectionDetailsModel = [];
      this.getKnowMoreSectionData(this.placeDetailsModel.id, this.contentLang);
    }
    if (this.isCustomDetailsLoading) {
      this.getCustomDetailsSectionData(this.placeDetailsModel.id, this.contentLang);
    }
  }

  setLocalLang(contentLang: any) {
    let lang: string = '';
    if (contentLang == 'हिंदी' || contentLang == 'hi') {
      lang = 'hi';
    }
    if (contentLang == 'राजस्थानी' || contentLang == 'hi') {
      lang = 'hi'; //as it set in univarsal so keep it for rajasthani
    }
    if (contentLang == 'english' || contentLang == 'en') {
      lang = 'en'
    }
    this.cS.setLang(lang);
    this.tS.setDefaultLang(lang);
    this.tS.use(lang);
    this.mS.setLang(lang);
  }

  /**
   * check if lang parameter exisit in local storage than perform some task
   * @Yuvraj 09/12/2021
   */
  getLocalLang() {
    let lang = this.cS.getLang();
    let currentLang: string = "";
    if (lang) {
      if (lang == 'hi') {
        currentLang = "हिंदी";
      }
      if (lang == 'en') {
        currentLang = "english";
      }
      this.getEntityOtherSectionData(this.placeDetailsModel.id, currentLang);
      if (this.isKnowMoreLoading) {
        this.knowMoreSectionDetailsModel = [];
        this.getKnowMoreSectionData(this.placeDetailsModel.id, currentLang);
      }
      if (this.isCustomDetailsLoading) {
        this.getCustomDetailsSectionData(this.placeDetailsModel.id, currentLang);
      }
    }
  }

  // onTabClick(event: any, i: number) {
  //   let entityNearbyId = this.nearBySectionDetailsModel[i].id;
  //   let assetTypeId = event.tab.textLabel.id;

  //   const postJson = { entity_nearby_id: entityNearbyId, asset_type_id: assetTypeId };

  //   this.homeService.getFilterEntitiesForNearBy(postJson).subscribe((res: any) => {
  //     if (res.status === 1) {
  //       if (res.data.length > 0) {
  //         res.data.forEach((element: any) => {
  //           if (element.metatags) {
  //             element.metatags = element.metatags.split(',');
  //           }
  //           else {
  //             element.metatags = [];
  //           }
  //           if (element.description) {
  //             element.description = new DOMParser().parseFromString(element.description, "text/html").documentElement.textContent;
  //           }
  //           else {
  //             element.description = "";
  //           }
  //           if (element.entity_type) {
  //             if (element.entity_type == "Business") {
  //               element.entity_type = '/home/business-detail/';
  //             }
  //             if (element.entity_type == "Place") {
  //               element.entity_type = '/place/';
  //             }
  //             if (element.entity_type == "Person") {
  //               element.entity_type = '/person/';
  //             }
  //           }
  //         });
  //         this.nearBySectionDetailsModel[i].entities = [];
  //         this.nearBySectionDetailsModel[i].entities = res.data;
  //       }
  //     }
  //   }, err => {
  //     console.log(event);
  //     console.log(err);
  //   });
  // }

  /**
    *get business rating list
    */
  getNextPageRatingList(event?: number): void {
    const postJson = { business_id: this.placeDetailsModel.id, page: this.currentPage };

    this.homeService.getBusinessRating(postJson).subscribe((res: any) => {
      if (res.status === 1) {
        this.ratingReviewsModel = res.data;
        this.totalRatingCount = this.ratingReviewsModel.total;
        this.start_5 = Math.round((this.ratingReviewsModel.start_5_rating * 100) / this.totalRatingCount);
        this.start_4 = Math.round((this.ratingReviewsModel.start_4_rating * 100) / this.totalRatingCount);
        this.start_3 = Math.round((this.ratingReviewsModel.start_3_rating * 100) / this.totalRatingCount);
        this.start_2 = Math.round((this.ratingReviewsModel.start_2_rating * 100) / this.totalRatingCount);
        this.start_1 = Math.round((this.ratingReviewsModel.start_1_rating * 100) / this.totalRatingCount);
        this.countCollectionSize();
      }
    }, err => {
      console.log(event);
      console.log(err);
    });
  }

  countCollectionSize() {
    if (this.totalRatingCount > this.perPageList) {
      this.collectionSize = Math.ceil(this.totalRatingCount / this.perPageList);
      this.collectionSize = this.collectionSize * 10;
    } else {
      this.collectionSize = this.perPageList;
    }
  }

  convertMetaTagToArray() {
    if (this.placeDetailsModel.metatags != "") {
      this.imgTags = this.placeDetailsModel.metatags.split(',');
    }
    else {
      this.imgTags = [];
    }
  }

  converEntityDescToString() {
    if (this.nearBySectionDetailsModel.entities) {
      this.nearBySectionDetailsModel.entities.forEach((element: any) => {
        if (element.description != null) {
          element.description = new DOMParser().parseFromString(element.description, "text/html").documentElement.textContent;
        }
        else {
          element.description = "";
        }
      });
    }
  }

  convertMetaTagToArrayEntity() {
    if (this.nearBySectionDetailsModel.entities) {
      this.nearBySectionDetailsModel.entities.forEach((element: any) => {
        if (element.metatags) {
          element.metatags = element.metatags.split(',');
        }
        else {
          element.metatags = [];
        }
      });
    }
  }

  /**
    *open share popup
    */
  openShareOptions(content: any) {
    this.modalService.open(content, { windowClass: 'share_popup' }).result.then((result) => {
      console.log(`Closed with: ${result}`);
    }, (reason) => {
      console.log(reason);
    });
  }


  shareNow = async () => {
    const navigator = window.navigator as any;
    let urlLang: string = "";
    let lang = this.cS.getLang();
    if (lang) {
      if (lang == 'en') {
        urlLang = "en";
      }
      if (lang == 'hi') {
        urlLang = "hi";
      }
    }
    else {
      urlLang = "en";
    }

    if ('canShare' in navigator) {
      let currentFullUrl = window.location.href + '?lang=' + urlLang;
      let title = this.placeDetailsModel.name;
      let text = new DOMParser().parseFromString(this.placeDetailsModel.short_description, "text/html").documentElement.textContent;
      let imageUrl = this.businessImageUrl;
      const share = async function () {
        try {
          // const response = await fetch(imageUrl, {mode: 'no-cors'});
          const response = await fetch(imageUrl);
          const blob = await response.blob();
          const file = new File([blob], 'image.jpg', { type: blob.type });
          await navigator.share({
            url: currentFullUrl,
            title: title,
            text: text,
            files: [file],
          });
          console.log("shared successfully....");
        } catch (err: any) {
          console.log(err.name, err.message);
        }
      };
      share();
    }
  };

  shareOnFacebook() {
    let currentFullUrl = window.location.href;
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${currentFullUrl}`, 'sharer');
  }

  // fbShare() {
  //   let url = 'https://mrcstaging.azureedge.net/place/kumbhalgrah';
  //   let title = this.placeDetailsModel.name;
  //   let descr = this.placeDetailsModel.seo_meta_description;
  //   let image = this.placeDetailsModel.business_images[0].image_name;
  //   let winWidth = 520;
  //   let winHeight = 350;
  //   let winTop = (screen.height / 2) - (winHeight / 2);
  //   let winLeft = (screen.width / 2) - (winWidth / 2);

  //   window.open('http://www.facebook.com/sharer.php?s=100&p[title]=' + title + '&p[summary]=' + descr + '&p[url]=' + url + '&p[images][0]=' + image, 'sharer', 'top=' + winTop + ',left=' + winLeft + ',toolbar=0,status=0,width=' + winWidth + ',height=' + winHeight);

  // }

  shareOnTwitter() {
    let currentFullUrl = window.location.href;
    window.open(`https://twitter.com/intent/tweet?url=${currentFullUrl}`, 'sharer');
  }

  // // LinkedIn share won't work if your shareUrl is localhost:port/abc, it should be genuine deployed url
  shareOnLinkedIn() {
    let currentFullUrl = window.location.href;
    window.open(`https://www.linkedin.com/shareArticle?url=${currentFullUrl}`, 'sharer');
  }

  setDefaultLangDropdown() {
    // if (this.placeDetailsModel.languages.length > 0) {
    //   this.defaultSelectedVal = this.placeDetailsModel.languages[0];
    // }

    let lang = this.cS.getLang();
    if (lang == 'en') {
      this.defaultSelectedVal = "english";
    }
    if (lang == 'hi') {
      this.defaultSelectedVal = "हिंदी";
    }
    if (lang == 'राजस्थानी') {
      this.defaultSelectedVal = 'राजस्थानी';
    }
  }

  checkUrlLang() {
    this.activeRoute.queryParams.subscribe(params => {
      let langParam = params['lang'];
      if (langParam) {
        if (langParam == 'hi') {
          this.defaultSelectedVal = "हिंदी";
        }
        else if (langParam == 'राजस्थानी') {
          this.defaultSelectedVal = "राजस्थानी";
        }
        else if (langParam == 'en') {
          this.defaultSelectedVal = "english";
        }
        this.getEntityOtherSectionData(this.placeDetailsModel.id, this.defaultSelectedVal);
        if (this.isKnowMoreLoading) {
          this.knowMoreSectionDetailsModel = [];
          this.getKnowMoreSectionData(this.placeDetailsModel.id, this.contentLang);
        }
        if (this.isCustomDetailsLoading) {
          this.getCustomDetailsSectionData(this.placeDetailsModel.id, this.contentLang);
        }

        this.setLocalLang(this.defaultSelectedVal); //set local lang then
      }

    });
  }

  // onLogin(returnRedirectUrl: string) {
  //   console.log('returnRedirectUrl', returnRedirectUrl)
  //   this.router.navigate(['/login'], { queryParams: { returnUrl: returnRedirectUrl } });
  // }

  checkUserDetails() { //check user loged in or not
    if (!this.checkAuth()) { //if not loged in then set as fake user
      let visitCount: number = 0; //init value will be 0
      let sessionStorageData = this.get_sessionStorage_json(this.STORAGE_USER_KEY); //get data from session
      if (sessionStorageData) {
        visitCount = sessionStorageData.visitCount; //get value from data
        if (visitCount <= 5) { //check if 5 time visit or not if not continue count ++
          let session_data = this.sessionData(visitCount + 1);
          this.set_sessionStorage(this.STORAGE_USER_KEY, session_data);
          this.userVisitCount.next(visitCount + 1);
        }
        else if (visitCount >= 5) { //if visited more then conditional time then redirect to login
          // this.cS.navigateTo('/login');
          this.cS.onReturnRedirect(this.returnRedirectUrl);
        }
      }
      else { //if not found in session then set init value
        let session_data = this.sessionData(visitCount + 1);
        this.set_sessionStorage(this.STORAGE_USER_KEY, session_data);
      }
    }
    else { // if user loged in then remove session storage
      this.remove_sessionStorage(this.STORAGE_USER_KEY);
    }
  }

  // get json
  get_sessionStorage_json(key: any) {
    if (this.check_sessionStorage_Exists()) {
      if (window.sessionStorage.length > 0) {
        return JSON.parse(window.sessionStorage.getItem(key) || '{}');
      }
    }
    return false;
  }

  // set
  set_sessionStorage(key: any, value: any) {
    if (this.check_sessionStorage_Exists()) {
      if (value.constructor.name == "Array" || value.constructor.name == "Object") {
        this.set_sessionStorage_json(key, value);
      } else {
        window.sessionStorage.setItem(key, value);
      }
    }
  }

  // set json
  set_sessionStorage_json(key: any, value: any) {
    if (this.check_sessionStorage_Exists()) {
      window.sessionStorage.setItem(key, JSON.stringify(value));
    }
  }

  // check
  check_sessionStorage_Exists() {
    if (window.sessionStorage) {
      return true;
    }
    return false;
  }

  checkAuth() {
    if (this.cS.getUserDetails()) {
      return true;
    }
    return false;
  }

  private sessionData(visitCount: any) {
    let session_data: any;
    session_data['visitCount'] = visitCount;
    return session_data;
  }

  // remove item
  remove_sessionStorage(key: any) {
    if (this.check_sessionStorage_Exists()) {
      if (window.sessionStorage.length > 0) {
        window.sessionStorage.removeItem(key);
      }
    }
    return false;
  }

  onWhatsAppBtn(countryCode: any, mobileNo: number) {
    // var newWhatsAppNumber = "+91 99 16 489165";
    let value = countryCode + mobileNo;
    // Remove all spaces
    let newWhatsAppNumber = value.replace(/ /g, '');

    // If string starts with +, drop first 1 characters
    if (value.slice(0, 1) == '+') {
      newWhatsAppNumber = newWhatsAppNumber.substring(1)
    }

    // If string starts with 0, drop first 4 characters
    // if (value.slice(0, 1) == '0') {
    //   newWhatsAppNumber = newWhatsAppNumber.substring(4)
    // }
    if (this.isMobile) {
      window.open(`whatsapp://send?phone=${newWhatsAppNumber}`);
    }
    else {
      window.open(`https://api.whatsapp.com/send?phone=${newWhatsAppNumber}`, '_blank');
    }
  }

  loadReportBusinessComponent() {
    const modalReference = this.modalService.open(ReportBusinessModalComponent, { windowClass: 'mobile_popup' });
    modalReference.componentInstance.assetTypeId = this.placeDetailsModel.asset_type_id;
    modalReference.componentInstance.entityId = this.placeDetailsModel.id;
    modalReference.result.then((result: any) => {
      console.log(`Closed with: ${result}`);
    }, (reason: any) => {
      console.log(reason);
    });
  }

  /**
   * implement pending
   */
  findAndAddAttr() {
    // const $widget = $(this.el.nativeElement);
    const $widget = $(this.placeDetailsModel.descriptions);
    // let test =  $widget.find('a').attr("target", "_blank");
    let ltest = $widget.find('a');
    console.log(ltest);
  }
}
