<div class="container">
  <div class="member_bg">
    <div class="signup_view">
      <div class="col-md-12">
        <div class="thank_section">
          <div class="thank_you_img">
            <img
              alt="thank_you"
              src="../assets/images/thank_you.png"
              class="img-responsive"
            />
          </div>
          <h4>{{ "ang_thank_your_interest" | translate }}</h4>
          <h4 *ngIf="status === 1">Your payment is successful.</h4>
          <h4 *ngIf="status === 2">Your payment is failed.</h4>
          <h4 *ngIf="status === 4">Your payment is under process.</h4>
          <h4 *ngIf="status === 0">
            Your payment processing resulting in error.
          </h4>
          <h4>
            Please contact support at info@myrajasthanclub.com or +91-9166319568 for any
            further information.
          </h4>
        </div>
      </div>
    </div>
  </div>
</div>