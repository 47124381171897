<div class="modal-header">
  <h4 class="text-info" *ngIf="statusType == 'shortList'">Short List {{ applicantUserName }} </h4>
  <h4 class="text-danger" *ngIf="statusType == 'reject'">Reject {{ applicantUserName }} </h4>
  <h4 class="text-success" *ngIf="statusType == 'select'">Select {{ applicantUserName }} </h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')" >
    <span aria-hidden="true">&times;</span>
  </button>
</div>


<div class="modal-body">
  <div id="container" class="container">
    <div class="row">
      <div class="col-md-12 my-2">
        <div class="info-form">
          <form name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" autocomplete="off" novalidate>
            <div class="form-group">
              <label for="message">Message <span>*</span></label>
              <textarea class="form-control" id="message" name="message" rows="3" placeholder="Please leave message.." required [ngClass]="{ 'state-error': (f.submitted && message.invalid) || (message.touched && message.invalid)}" [(ngModel)]="model.message" #message="ngModel"></textarea>
              <div *ngIf="(f.submitted && message.invalid) || (message.touched && message.invalid)" class="note">
                <div *ngIf="message.errors?.required">Message is required</div>
              </div>
            </div>
            <div class="form-group">
              <p class="pull-right">
                <button [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" [disabled]="isSubmit" type="submit">{{ "ang_submit" | translate | uppercase }}</button>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>