import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/shared/guard/auth.guard';
import { AddOwnerComponent } from './add-owner/add-owner.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { UserComponent } from './user.component';

const routes: Routes = [{ path: '', 
component: UserComponent,
canActivate: [AuthGuard],
canLoad: [AuthGuard],
canActivateChild: [AuthGuard],
        children: [
             { path: '', redirectTo: '/user/profile', pathMatch: 'full' },
             { path: 'user/profile', component: UserProfileComponent, data: { title: 'MyRajasthanClub - User Profile' } },
             { path: 'user/change-password', component: ChangePasswordComponent, data: { title: 'MyRajasthanClub - Change Password' } },
             { path: 'user/add-owner', component: AddOwnerComponent, data: { title: 'MyRajasthanClub - Add Owner' } }
        ]
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule { }
