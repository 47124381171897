<div class="group_banner  d-flex align-items-center" style="background-image:url('assets/images/group_banner.jpg')">
  <div class="container">
    <div class="banner-content">
      <h2 class="groups_title text-white">Jobs</h2>
      <div class="search_here_view ">
        <i (click)="searchBusiness()" style="cursor: pointer;" class="fa fa-search" aria-hidden="true"></i>
        <input id="search" (keyup.enter)="searchBusiness()" [(ngModel)]="searchText" placeholder="{{ 'ang_searchhere' | translate}} " type="text" #homeSearchInput>
        <div class="auto_complete_listing" style="display: none;">
          <perfect-scrollbar>
              <ul>
                  <li class="search_input" *ngFor="let item of items" (click)="searchBusinessByCategory(item.title)">
                    {{item.title}}
                  </li>
              </ul>
          </perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Groups banner End -->
<div class="container">
  <div class="group_listing_view">
    <div *ngIf="totalJobsCount > 0" class="d-flex justify-content-between align-items-end mb-3 flex-sm-row flex-column-reverse flex-sm-nowrap flex-wrap">
      <div class="total_result">
        ({{'ang_showing' | translate}} {{first}}-{{last}} {{'ang_of' | translate}} {{ totalJobsCount }} {{'ang_jobs' | translate}})
      </div>
      <!-- total_result End -->
      <!-- sorting -->
    </div>
    <!-- create group -->
    <div *ngIf="isUserHasBusiness" class="d-flex justify-content-between align-items-end mb-3 flex-sm-row flex-column-reverse flex-sm-nowrap flex-wrap">
      <div class="total_result">
      </div>
      <div class="d-flex align-items-end flex-wrap justify-content-end mb-2">
        <a routerLink="/job/create/{{groupId}}" [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" class="ml-3 create-group_page_btn ">
          <i class="fa fa-plus mr-2" aria-hidden="true" size="15"></i>
          Create Job
        </a>
      </div>
    </div>
      <!-- sort_by_tab End -->
    <div class="group_listing_view_wrapper">
      <!-- All Group -->
      <div class="group_listing_view" *ngIf="jobsList.length > 0">
        <div class="clearfix group_listing_box" *ngFor="let item of jobsList; let i = index">
          <div class="d-block d-sm-flex align-items-center">
            <div class="group_listing_img">
              <img alt="essar-real-estate" [src]="item.image_fullurl" />
            </div>
           <div class="group_listing_content">
             <h2>
               <span class="text-overflow-hidden" placement="bottom">{{ item.title }}</span>
            </h2>
            <div class="group_content_left group_title_icon">
             <ul>
                <li *ngIf="item.company_name" class="text-overflow-hidden">
                  <i class="fa fa-building" aria-hidden="true"></i>
                  {{ item.company_name }}
                </li>
                <li *ngIf="item.location" class="text-overflow-hidden">
                  <i class="fa fa-map-marker" aria-hidden="true"></i>
                  {{ item.location.district }}, {{ item.location.state }}, {{ item.location.country }}
                </li>
                <li *ngIf="item.created_at_diff && item.user" class="text-overflow-hidden">
                  <i class="fa fa-clock-o" aria-hidden="true"></i>
                  <!-- {{ item.user | json}} -->
                  {{ item.created_at_diff }} ago by {{ item.user.name }}
                </li>
             </ul>
            </div>
            <div class="group_content_right">
              <button *ngIf="userId === jobCreatedById" type="button" (click)="onJobDelete(item.id, item.title)" [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'">
                Delete Job
              </button>
              <a routerLink="/job/details/{{ item.id }}" [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" class="mt-2">
                View More
                <i class="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </div>
           </div>
          </div>
        </div>
      </div>
    </div>
    <!-- group_listing_view_wrapper End -->
  </div>
  <!-- group_listing_view End -->
  <div [ngClass]="appName == 'RYC' ? 'ryc_pagination_view' : 'mrc_pagination_view'" *ngIf="totalJobsCount > 0">
    <ngb-pagination [collectionSize]="collectionSize" [(page)]="currentPage" [maxSize]="maxSize" size="sm" (pageChange)="getNextPageBusinessList()"></ngb-pagination>
  </div>
  <!-- pagination_view End -->

  <div *ngIf="noData == true" style=" text-align: center;padding: 20px;">
    <img src="../assets/images/norecordfound.png" style="height:250px;margin:20px;">
    <h3>{{'ang_nomatches' | translate}}</h3>
  </div>
</div>
<!--container end-->