<div class="own-post-content">
    <div class="container">
      <div class="home-post_wrapper">
        <div class="popup_post" *ngIf="cS.getUserDetails()?.id && isSelfBusiness">
          <div class="popup_post_one">
            <div class="share_post_img">
              <img *ngIf="!cS.getUserDetails() || (cS.getUserDetails() && cS.getUserDetails()?.profile_url == ''); else createPostProfilePhoto1" class="profile_icon" src="../assets/images/default_user_profile.png" />
              <ng-template #createPostProfilePhoto1>
                <img class="profile_icon" [src]="cS.getUserDetails()?.profile_url" />
              </ng-template>
            </div>
            <!---->
  
            <div class="popup_post_button">
              <button class="pointer" style="margin-left: 10px" (click)="openSm(content)">
                {{ "ang_home_tab_create_post" | translate }}
              </button>
            </div>
          </div>
          <br style="clear: both" />
          <div class="seprator"></div>
          <div class="video_streming">
            <div class="row">
              <div class="col-md-12">
                <p class="pointer" (click)="openSm(content)">
                  <i aria-hidden="true" class="fa fa-picture-o camera"></i>{{ "ang_photo" | translate }}
                </p>
              </div>
            </div>
          </div>
        </div>
  
        <div class="row tab-content">
          <!-- <div class="col-md-12">
          <div class="noPost_msg_wrapper">
            <div class="noPost_msg">
              <p>{{ 'ang_no_post' | translate }}</p>
              <a (click)="openSm(content)" class="d-inline-block">Share your first post</a>
            </div>
          </div>
          </div> -->
          <div *ngIf="selectedType === postType[0]" role="tabpanel" class="tab-pane active" id="one" style="width: 100%;">
            <div *ngIf="posts.length > 0" class="col-md-12">
              <div *ngFor="let post of posts" class="main_post_list">
                <div class="post_list">
                  <div class="share_post">
                    <div class="share_post_top">
                      <div class="share_post_img">
                        <a *ngIf="post.business_slug; else postUserPhoto" routerLink="/home/business-detail/{{ post.business_slug }}" target="_blank">
                          <img *ngIf="post.user.profile_url == ''; else profilePhoto" class="profile_icon" src="../assets/images/default_user_profile.png" />
                          <ng-template #profilePhoto>
                            <img class="profile_icon" [src]="post.user.profile_url" />
                          </ng-template>
                        </a>
                        <ng-template #postUserPhoto>
                          <img *ngIf="post.user.profile_url == ''; else profilePhoto" class="profile_icon" src="../assets/images/default_user_profile.png" />
                          <ng-template #profilePhoto>
                            <img class="profile_icon" [src]="post.user.profile_url" />
                          </ng-template>
                        </ng-template>
                      </div>
                      <div class="share_post_head">
                        <div class="post_title-wrapper">
                          <h4 [ngStyle]="{'color':appName == 'RYC' ? 'var(--theme-ryc-based-secondary-color)' : 'var(--theme-mrc-based-primary-color)' }">
                            <a routerLink="/home/business-detail/{{post.business_slug}}" *ngIf="post.business_slug; else postUserName" [ngStyle]="{'color':appName == 'RYC' ? 'var(--theme-ryc-based-secondary-color)' : 'var(--theme-mrc-based-primary-color)' }">
                              {{ post.user.name }}
                            </a>
                            <span *ngIf="post.business_slug"> &nbsp; | &nbsp;</span>
                            <a *ngIf="post.business_slug" routerLink="/home/business-detail/{{post.business_slug}}" target="_blank" [ngStyle]="{'color':appName == 'RYC' ? 'var(--theme-ryc-based-secondary-color)' : 'var(--theme-mrc-based-primary-color)' }">
                              {{ post.business_name }}
                            </a>
                            <ng-template #postUserName>
                              {{ post.user.name }}
                            </ng-template>
                          </h4>
                          <p>
                            {{ post.category }} |
                            <span class="post-time">{{
                              post.created_at_diff
                              }}</span>
                          </p>
                        </div>
                        <div ngbDropdown class="d-inline-block" *ngIf="checkAuth()">
                          <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle><i class="fa fa-ellipsis-v" aria-hidden="true"></i></button>
                          <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-menu-right">
                            <button class="dropdown-item" ngbDropdownItem *ngIf="post.user_id === user_id"  (click)="editPostByPostId(content, post.id)" >
                              <span>
                                <!-- <i class="fa fa-pencil pointer edit-icon" aria-hidden="true" title="Edit">
                                </i> -->
                                 Edit
                              </span>
                            </button>
                            <button class="dropdown-item" ngbDropdownItem *ngIf="post.user_id === user_id" (click)="deletePostByPostId(post.id)">
                              <span>
                                <!-- <i aria-hidden="true" class="fa fa-times pointer" title="Delete"></i>  -->
                                Delete
                              </span>
                            </button>
                            <button class="dropdown-item" ngbDropdownItem *ngIf="post.user_id !== user_id" (click)="loadReportPostComponent(post.id)">
                              <span>
                                Report Post
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                      
                      
                    </div>
                    <p class="post_comment">
                      <span style="white-space: pre-line;">
                        {{(readMore && readMorePost == post.id) ? post.content : (post.content | slice: 0:130)}}
                      </span>
                      <span *ngIf="readMorePost != post.id && post.content.length > 130">...</span>
                      <a href="javascript:;" *ngIf="readMorePost != post.id &&  post.content.length > 130" (click)="readMore = true;readMorePost = post.id">
                        See More
                      </a>
                      <a href="javascript:void(0);" *ngIf="readMore && readMorePost == post.id && post.content.length > 130" (click)="readMore = false;readMorePost = 0">
                        See less
                      </a>
                    </p>
                  </div>
  
                  <div *ngIf="post.images.length > 0" class="share_image add_padding">
                    <!-- if image length is 1 or 2 -->
                    <div class="row img-length-1-2" *ngIf="post.images.length < 3">
                      <div class="{{ post.images.length == 1 ? 'col-md-12' : 'col-md-6' }} " *ngFor="let item of post.images;let index = index">
                        <a class="btn btn-lg btn-outline-primary open-post-popup" (click)="openVerticallyCentered(postImage, post.id,index)">
                          <img [src]="item.full_url" />
                        </a>
                      </div>
                    </div>
  
                    <!-- If image length is 3 or more thann 3 -->
                    <div class="row" *ngIf="post.images.length > 2">
                      <div class="col-sm-12">
                        <div class="img-wrapper {{ post.images.length == 3 ? 'total-img-3' : '' }}">
                          <div class="img-auto-adjust-{{ index }}" *ngFor="let item of post.images; let index = index">
                            <a class="btn btn-lg btn-outline-primary open-post-popup" (click)="openVerticallyCentered(postImage, post.id,index)">
                              <img [src]="item.full_url" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
  
                  <div class="like_buttons">
                    <p *ngIf="post.likes_count > 0; else viewCount">
                      <i class="fa fa-thumbs-o-up" aria-hidden="true"></i>
                      <span>{{ post.likes_count }}</span>
  
                      <span style="float: right" *ngIf="post.share_count != ''">
                        <span>{{ post.share_count }}</span>
                        {{ post.share_count == 1 ? ("ang_share" | translate) : ("ang_shares" | translate) }}
                      </span>
                      <span style="float: right; margin-right: 10px" *ngIf="post.views_count != ''">
                        <span>{{ post.views_count }}</span>
                        {{ post.views_count == 1 ? ("ang_view" | translate) : ("ang_views" | translate) }}
                      </span>
                    </p>
                    <ng-template #viewCount>
                      <p style="text-align: right">
                        <span *ngIf="post.views_count != ''">
                          <!-- <i class="fa fa fa-eye" aria-hidden="true"></i> -->
                          <span>{{ post.views_count }}</span>
                          {{ post.views_count == 1 ? ("ang_view" | translate) : ("ang_views" | translate) }}
                        </span>
                        <span *ngIf="post.share_count != ''">
                          <span>{{ post.share_count }}</span>
                          {{ post.share_count == 1 ? ("ang_share" | translate) : ("ang_shares" | translate) }}
                        </span>
                      </p>
                    </ng-template>
  
                    <div class="like_share_buttons">
                      <div class="row">
                        <div class="col-md-4">
                          <a href="javascript:void(0)" (click)="handleLikePost(post.id,$event)" [ngClass]="{ liked: post.is_like_by_user === 1 }">
                            <i class="fa fa-thumbs-o-up" aria-hidden="true"></i>
                            {{ "ang_like" | translate }}
                          </a>
                        </div>
                        <div class="col-md-4">
                          <a *ngIf="post.external_link && post.external_link != 'null'" target="_blank" [href]="post.external_link">
                            <i class="fa fa-globe" aria-hidden="true"></i>
                            {{ "ang_visit_link" | translate }}
                          </a>
                        </div>
                        <div class="col-md-4">
                          <a href="javascript:void(0)" (click)="openShareOptions(contentSocialShare, post.id, post.content)">
                            <i class="fa fa-share" aria-hidden="true"></i>
                            {{ "ang_share" | translate }}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="noPost_msg_wrapper" *ngIf="isPostNotExist == true && isSelfBusiness == true" >
                <div class="noPost_msg">
                  <p>{{ 'ang_no_post' | translate }}</p>
                  <a [ngStyle]="{'color':appName == 'RYC' ? 'var(--theme-ryc-based-secondary-color)' : 'var(--theme-mrc-based-primary-color)' }" (click)="openSm(content)" class="d-inline-block">Share your first post</a>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="noPost_msg_wrapper"  *ngIf="isPostNotExist == true && isSelfBusiness == false">
                <div class="noPost_msg">
                  <p>{{ 'ang_no_post_available' | translate }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <!-- Image Modal -->
  <ng-template #postImage let-c="close" let-d="dismiss">
    <div class="modal-header">
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <owl-carousel class="post-img-popup-carousel" #owlElement [options]="customOptions" [items]="postImages"
        [carouselClasses]="['owl-theme', 'row', 'sliding']">
        <div class="item post-img" *ngFor="let image of postImages; let i = index">
          <div class="">
            <img _ngcontent-c6="" src="{{ image.full_url }}" />
          </div>
        </div>
      </owl-carousel>
    </div>
  </ng-template>
  
  <!--- Share business view-->
  <ng-template #contentSocialShare let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h5 class="modal-title">{{ "ang_share" | translate }}</h5>
      <button id="closeBtn" type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="social_profile">
        <ul>
          <li>
            <a href="javascript:void(0)" (click)="shareOnFacebook()">
              <img alt="facebook" src="../assets/images/facebook.png" />
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" (click)="shareOnTwitter()">
              <img alt="twitter" src="../assets/images/twitter.png" />
            </a>
          </li>
          <li>
            <a href="" (click)="shareOnLinkedIn()">
              <img alt="linkedin" src="../assets/images/linkedin.png" />
            </a>
          </li>
        </ul>
      </div>
      <!-- social_profile End -->
    </div>
  </ng-template>
  <!--- Share business view End-->
  
  <ng-template #content let-c="close" let-d="dismiss">
    <form [formGroup]="postForm" novalidate (ngSubmit)="submitpost(postId)">
      <div class="modal-header">
        <h4 class="modal-title">
          {{ postId > 0 ? ("ang_edit_post" | translate) : ("ang_new_post" | translate) }}
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row profile">
          <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 profile-img">
            <img *ngIf="!cS.getUserDetails() || (cS.getUserDetails() && cS.getUserDetails()?.profile_url == ''); else createPostProfilePhoto" class="profile_menu_icon" src="../assets/images/default_user_profile.png" />
            <ng-template #createPostProfilePhoto>
              <img class="profile_menu_icon" [src]="cS.getUserDetails()?.profile_url" />
            </ng-template>
          </div>
          <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5">
            <label class="control-label" for="category">
              {{ "ang_category" | translate }}<span class="required_field" style="font-size: 17px">*</span>
            </label>
            <select name="category" formControlName="category" class="form-control pointer" [(ngModel)]="categoryName">
              <option value="" selected disabled hidden>
                Select Category
              </option>
              <option *ngFor="let category of categories" [ngValue]="category">
                {{ category }}
              </option>
            </select>
            <p class="help-block" *ngIf="
                postForm.controls.category.touched &&
                postForm.controls.category.errors?.required
              ">
              {{ "ang_category_error" | translate }}
            </p>
          </div>
          <!-- <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5">
            <label class="control-label" for="city">
              {{ "ang_city" | translate }}
            </label>
            <div class="location_dropdown">
              <div class="dropdown">
                <a href="javascript:;" name="cityLocationDropdown" (click)="cityShowLocationDropDown()"
                  class="dropbtn form-control control-label">{{ city_search_location }}</a>
                <div class="dropdown-content" *ngIf="cityIsShow">
                  <div class="location_search_input">
                    <i _ngcontent-c1="" aria-hidden="true" class="fa fa-search"></i>
                    <input id="myInput" type="text" placeholder="Search City" name="cityLocation"
                      formControlName="city" class="location-search" [(ngModel)]="cityName"
                      (input)="cityFilterLocation()" />
                  </div>
                  <perfect-scrollbar style="height: 170px">
                    <p *ngFor="let city of cityLocations" class="home-location city_search_input"
                      (click)="citySelectLocation(city.name)">
                      {{ city.name }}
                    </p>
                  </perfect-scrollbar>
                </div>
              </div>
            </div>
          </div> -->
        </div>
        <div class="form-group file-area">
          <!--  <input type="text" formControlName="message" id="message" placeholder="Write your message..."
            class="input-xlarge" /> -->
          <textarea formControlName="message" id="message" placeholder='{{ "ang_write_message" | translate }}...'
            class="input-xlarge" class="form-control" [(ngModel)]="messageValue"></textarea>
          <p class="help-block" *ngIf="postForm.controls.message.touched && postForm.controls.message.errors?.required">
            {{ "ang_message_error" | translate }}
          </p>
        </div>
  
        <div class="form-group file-area">
          <div *ngFor="let image of postImages; let ind = index" class="file-img-wrapper">
            <a href="javascript:;" class="file-delete-icon" (click)="deletePostImages(ind, image.id)"><i
                aria-hidden="true" class="fa fa-remove"></i></a>
            <img [src]="image.full_url" height="150" width="200" style="margin: 3px" />
            <br />
          </div>
          <div *ngFor="let url of images; let ind = index" class="file-img-wrapper">
            <a *ngIf="images.length > 0" href="javascript:;" class="file-delete-icon"
              (click)="deletePostImages(ind)"><i aria-hidden="true" class="fa fa-remove"></i></a>
            <img [src]="url" height="150" width="200" style="margin: 3px" />
            <br />
          </div>
          <div class="file-dummy">
            <div class="success">{{ "ang_add_images" | translate }}</div>
            <div class="file_images">
              <i class="fa fa-image" aria-hidden="true"></i>
              <input type="file" (change)="onSelectFile($event)" multiple="" id="file" class="input-xlarge" />
            </div>
          </div>
        </div>
        <div class="form-group file-area">
          <input type="text" formControlName="externallink" id="externallink" placeholder='{{ "ang_add_external_link" | translate }}' class="form-control" [(ngModel)]="externalLink" />
          <p class="help-block" *ngIf="postForm.controls.externallink.touched && postForm.controls.externallink.errors?.pattern">
            {{ "ang_external_pattern_error" | translate }}
          </p>
        </div>
      </div>
  
      <div class="modal-footer">
        <button class="btn btn-primary mb-2" type="submit" [disabled]="!postForm.valid">
          {{ postId > 0 ? ("ang_save" | translate) : ("ang_post" | translate) }}
        </button>
      </div>
    </form>
  </ng-template>
  