<div class="modal-header">
  <!-- <h4 class="text-primary">{{ "ang_writeyourenquiry" | translate }}</h4> -->
  <h4 class="text-primary" *ngIf="groupName">Join {{ groupName }} </h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')" >
    <span aria-hidden="true">&times;</span>
  </button>
</div>


<div class="modal-body">
  <div id="container" class="container">
    <div class="row">
      <div class="col-md-12 my-2">
        <div class="info-form">
          <form name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" autocomplete="off" novalidate>
            <div class="form-group">
              <label for="security_code">Code <span>*</span></label>
              <input type="text" class="form-control" id="security_code" name="security_code" rows="3" placeholder="Please enter code.." required [ngClass]="{ 'state-error': (f.submitted && security_code.invalid) || (security_code.touched && security_code.invalid)}" [(ngModel)]="verificationModel.security_code" #security_code="ngModel" />
              <div *ngIf="(f.submitted && security_code.invalid) || (security_code.touched && security_code.invalid)" class="note">
                <div *ngIf="security_code.errors?.required">Code is required</div>
              </div>
            </div>
            <div class="form-group" *ngIf="dynamicExtraFieldLabel != ''">
              <label for="security_code">{{ dynamicExtraFieldLabel }}</label>
              <input type="text" class="form-control" id="dynamic_field_value" name="dynamic_field_value" rows="3" placeholder="Please enter {{ dynamicExtraFieldLabel }}" [ngClass]="{ 'state-error': (f.submitted && dynamic_field_value.invalid) || (dynamic_field_value.touched && dynamic_field_value.invalid)}" [(ngModel)]="verificationModel.dynamic_field_value" #dynamic_field_value="ngModel" />
              <div *ngIf="(f.submitted && dynamic_field_value.invalid) || (dynamic_field_value.touched && dynamic_field_value.invalid)" class="note">
                <div *ngIf="dynamic_field_value.errors?.required">{{ dynamicExtraFieldLabel }} is required</div>
              </div>
            </div>
            <div class="form-group">
              <p class="pull-right">
                <button [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" [disabled]="isSubmit" type="submit">{{ "ang_submit" | translate | uppercase }}</button>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>