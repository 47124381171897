import { HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";

import { environment } from "../../../../environments/environment";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
// import { CustomValidators } from 'ng2-validation';
// import { StorepostConfig } from "./../../../class/data.model";
import { Component, OnInit, HostListener, Input, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import swal from 'sweetalert2';
import { OwlCarousel } from 'ngx-owl-carousel';
 
import { GroupPostLikedSharedTaggedUsersListModalComponent } from "../../../shared/component/group-post-liked-shared-tagged-users-list-modal/group-post-liked-shared-tagged-users-list-modal.component";
import { Observable, Observer } from "rxjs";
import { HttpService } from "src/app/shared/services/http.service";
import { HomeService } from "src/app/shared/services/home.service";
import { CommonService } from "src/app/shared/services/common.service";
import { ReportBusinessModalComponent } from "src/app/shared/component/report-business-modal/report-business-modal.component";
import Swal from "sweetalert2";
import { OwlOptions } from 'ngx-owl-carousel-o';
@Component({
  selector: "ryec-home-post",
  templateUrl: "./home-post.component.html",
  styleUrls: ["./home-post.component.css"],
})


export class HomePostComponent implements OnInit {
  @ViewChild('owlElement')
  // owlElement!: OwlCarousel;
  // fun() {
  //   this.owlElement.next([200]);
  // }
  closeResult = false;
  postForm: FormGroup;
  toggled: boolean = false;
  message: string = "";
  postType: Array<any> = ["Community Posts", "MyRajasthan Club Posts"];
  selectedType = this.postType[0];
  modalReference: any;
  user_id = 0;
  url: any;
  images: any[] = [];
  postFormData: FormData = new FormData();
  filePostImages: any = [];
  categories = [];
  cities = [];
  keywords = [];
  posts: Array<any> = [];
  collectionSize = 0;
  totalPostCount = 0;
  highlights:any[]=[];
  currentPage: number;
  perPageList = environment.BUSINESS_LIST_LIMIT;
  readMore: boolean = false;
  readMorePost = 0;
  repoUrl = window.location.href;
  sharingUrl: string = "";
  sharingTitle: string = "";
  categoryName: string = "";
  cityName: string = "";
  externalLink: string = "";
  messageValue: string = "";
  currentPosition: number = 0;
  isPostLoading: boolean = false;
  deleteImages: boolean = false;
  deleteImageIds: Array<any> = [];
  postId = 0;
  city_search_location = 'Select City';
  cityLocations :any;
  selectedCity = '';
  cityIsShow = false;
  citySearchText = '';
  city_search_text = false;
  pageSize = 20;
  postImages :any;
  base64Image: any;
  appName = environment.APP_NAME;

  lat = localStorage.getItem("latitude");
  lng = localStorage.getItem("longitude");

  @Input('isPostDetailPage') postDetailId = 0;

  customOptions: OwlOptions = {
    autoplay: true,
    nav: true,
     margin: 2,
     loop: false,
     responsive: {
        0: {
           items:1
        },
        200: {
           items:3
        },
        500: {
           items:4
        }}
  }

  HttpHeaderOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  constructor(
    private fb: FormBuilder,
    public router: Router,
    private hS: HomeService,
    public cS: CommonService,
    private httpS: HttpService,
    private modalService: NgbModal
  ) {
    this.url = "";
    this.currentPage = 1;
    const reg = '((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)';
    this.postForm = this.fb.group({
      category: ["", Validators.required],
      // city: [""],
      message: ["", Validators.required],
      externallink: ["", Validators.pattern(reg)],
      title: [""]
    });

    const userDetail = this.cS.getUserDetails();
    if (userDetail) {
      this.user_id = userDetail.id;
    }
  }
  @HostListener('document:click', ['$event'])
  clickout(event: any) {

    if (event.srcElement.name != "cityLocationDropdown" && event.srcElement.name != "cityLocation") {
      this.cityIsShow = false;
    } else {
      this.cityIsShow = true;
    }
  }
  ngOnInit() {
    this.cS.scrollTop();
    this.getAllPost();
    this.getCategoryDetail();
    this.gethighlights();
    // this.getAddressDetail();
    // console.log(this.cS.getUserDetails());
  }
  checkAuth() {
    if (this.cS.getUserDetails()) {
      return true;
    }
    return false;
  }
  getCategoryDetail() {
    const apiUrl = environment.RYEC_API_URL + "getCategoryAndModKeywords";
    this.httpS.get(apiUrl).subscribe(
      (res: any) => {
        this.categories = res.data.categories;
        this.keywords = res.data.keywords;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  handleChangeTab(type: string) {
    if (this.selectedType !== type) {
      this.selectedType = type;
      this.getAllPost();
    }
  }

  /**
   * Get city Detail from entered details
   *@returns void
   */
  getAddressDetail() {
    if(this.appName=='MRC')
    {
    this.hS.getAddressMaster().subscribe(
      (res: any) => {
        this.cities = res.data.cities;
        this.cityLocations = res.data.cities;
      },
      (err) => {
        console.log(err);
      }
    );
  }
}

  @HostListener('window:scroll', [])
  scrollHandler() {
    // for get height and postion of div scrolling
    // var winScroll = event.currentTarget.scrollTop;
    // var height = event.currentTarget.scrollHeight - event.currentTarget.clientHeight;

    // for get height and postion of window scrolling
    var winScroll = window.scrollY + window.innerHeight;
    var height = document.body.scrollHeight;
    var scrolled = (winScroll / height) * 100;

    var lastPage = Math.ceil(this.totalPostCount / this.pageSize);
    // console.log('height:- '+height);
    // console.log('last page:- '+lastPage);
    if (winScroll > this.currentPosition && scrolled > 80 && lastPage > this.currentPage && !this.isPostLoading) {
      this.isPostLoading = true;
      this.currentPage++;

      // if user is not logged in then show only 20 post only
      if (this.checkAuth()) {
        this.getAllPost(true);
      }
    }
    // console.log("Scroll %", scrolled);
    // console.log("current:- ", this.currentPosition);
    // console.log('page: ' + this.currentPage);
    this.currentPosition = winScroll;
  }

  getAllPost(isScroll: boolean = false): void {
    const apiUrl = environment.RYEC_API_URL + "getAllPosts";
    const paramConfig = {
      type: this.selectedType === this.postType[0] ? "business_user" : "admin",
      page: (isScroll) ? this.currentPage : 1,
      latitude: this.lat,
      longitude: this.lng,
      near_by: (this.lat && this.lng && false) ? 'true' : 'false',
      limit: this.pageSize,
      post_id: (this.postDetailId > 0) ? this.postDetailId : ''
    };
    this.httpS.post(apiUrl, paramConfig).subscribe(
      (res: any) => {
        if (res.status === 1) {
          if (isScroll) {
            this.posts = [...this.posts, ...res.data];
          } else {
            this.posts = res.data;
            this.currentPage = 1;
          }
          this.totalPostCount = res.total;
          this.countCollectionSize();
          this.isPostLoading = false;
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  editPostByPostId(content: any, id: number): void {
    this.images = [];
    this.deleteImageIds = [];
    this.filePostImages = [];
    this.postFormData = new FormData();
    const apiUrl = environment.RYEC_API_URL + "getPostDetail";
    const paramConfig = {
      post_id: id,
    };
    this.httpS.post(apiUrl, paramConfig).subscribe(
      (res: any) => {
        if (res.status === 1) {
          this.postId = res.data.id;
          this.categoryName = res.data.category;
          // this.selectedCity = res.data.city;
          // this.city_search_location = (res.data.city != '') ? res.data.city : 'Select City';
          this.messageValue = res.data.content;
          this.externalLink = res.data.external_link;
          this.postImages = res.data.images;
          this.cityIsShow = false;
          this.deleteImages = false;
          //for open modal box
          this.modalReference = this.modalService.open(content, { size: "lg" });
          this.modalReference.result.then(
            (result: any) => {
              console.log(`Closed with: ${result}`);
            },
            (reason: any) => {
              console.log(reason);
            }
          );
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  /**
   * report post of other user and load common component for report
   * @Yuvraj 23/12/2021
   */
  loadReportPostComponent(id: number) {
    const modalReference = this.modalService.open(ReportBusinessModalComponent, { windowClass: 'mobile_popup' });
    modalReference.componentInstance.type = 'Post';
    modalReference.componentInstance.postId = id;
    modalReference.result.then((result: any) => {
      console.log(`Closed with: ${result}`);
    }, (reason: any) => {
      console.log(reason);
    });
  }

  deletePostByPostId(id: number): void {
    Swal.fire
    ({
      title: 'Do you really want to delete this post?',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      showCancelButton: true,
      showCloseButton: true,
      reverseButtons: true,
      confirmButtonColor: '#3085d6',
      
    }).then((result) => {
      if (result.value) {
        const apiUrl = environment.RYEC_API_URL + "deletePost";
        const paramConfig = {
          post_id: id,
        };
        this.httpS.post(apiUrl, paramConfig).subscribe(
          (res: any) => {
            if (res.status === 1) {
              this.cS.showSuccess(res.message);
              this.getAllPost();
            }
          },
          (err) => {
            console.log(err);
          }
        );
    }
      }
  )}

  handleLikePost(id: number, event: any): void {
    if (this.checkAuth()) {
      const hasClass = event.target.classList.contains('liked');
      if (hasClass) {
        event.target.classList.remove("liked");
      } else {
        event.target.classList.add("liked");
      }

      const apiUrl = environment.RYEC_API_URL + "likePost";
      const paramConfig = {
        post_id: id,
      };
      this.httpS.post(apiUrl, paramConfig).subscribe(
        (res: any) => {
          if (res.status === 1) {
            this.getAllPost();
          }
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
      this.cS.navigateTo('/login');
    }
  }

  handleSharePost(): void {
    const apiUrl = environment.RYEC_API_URL + "likePost";
    const paramConfig = {
      post_id: this.postId,
      share: true
    };
    this.httpS.post(apiUrl, paramConfig).subscribe(
      (res: any) => {
        if (res.status === 1) {
          this.cS.showSuccess(res.message);
          this.getAllPost();
          setTimeout(() => {
            let element: HTMLElement = document.getElementById('closeBtn') as HTMLElement;
            element.click();
          }, 200);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  countCollectionSize() {
    if (this.totalPostCount > this.perPageList) {
      this.collectionSize = Math.ceil(this.totalPostCount / this.perPageList);
      this.collectionSize = this.collectionSize * this.pageSize;
    } else {
      this.collectionSize = this.perPageList;
    }
  }

  openSm(content: any) {
    if (this.checkAuth()) {
      this.modalReference = this.modalService.open(content, { windowClass: "modal-md" });
      this.modalReference.result.then(
        (result: any) => {
          console.log(`Closed with: ${result}`);
        },
        (reason: any) => {
          console.log(reason);
        }
      );
      this.postId = 0;
      this.postForm.reset();
      this.filePostImages = [];
      this.postImages = [];
      this.images = [];
      this.deleteImageIds = [];
      this.categoryName = "";
      this.cityName = "";
      this.selectedCity = "";
      this.city_search_location = 'Select City';
      this.messageValue = "";
      this.externalLink = "";
      this.postFormData = new FormData();
      this.cityIsShow = false;
      this.closeResult = true;
      this.deleteImages = false;
    } else {
      this.cS.navigateTo('/login');
    }


  }
  gethighlights() {
     if (this.checkAuth())
      {
    if (this.appName == 'RYC') {
      this.hS.highlightservice().subscribe((res: any) => {
        this.highlights = res.data;
      }, (err: any) => {
        console.log(err);
      });
      this.cS.scrollTop();
    }
  }
}

  close() {
    this.closeResult = false;
  }
  submitpost(editPost: any) {
    if (this.postForm.valid) {
      if (editPost > 0) {
        let deleteImages = (this.deleteImages) ? 'true' : 'false'
        this.postFormData.append("post_id", editPost);
        this.postFormData.append("delete_images", deleteImages);
        this.postFormData.append("delete_image_ids", JSON.stringify(this.deleteImageIds));
        var apiUrl = environment.RYEC_API_URL + "updatePost";
      } else {
        var apiUrl = environment.RYEC_API_URL + "storePost";
      }
      for (const x in Object.keys(this.filePostImages)) {
        this.postFormData.append("images[" + x + "]", this.filePostImages[x]);
      }
      this.postFormData.append(
        "category",
        this.postForm.controls.category.value
      );
      // this.postFormData.append("city", this.selectedCity);
      this.postFormData.append("title", this.postForm.controls.title.value);
      this.postFormData.append("content", this.postForm.controls.message.value);
      this.postFormData.append(
        "external_link",
        this.postForm.controls.externallink.value
      );
      this.postFormData.append("source", "myself");
      this.postFormData.append("status", "active");

      this.httpS.postUpload(apiUrl, this.postFormData).subscribe((res:any) => {
        if (res.status === 1) {
          this.cS.showSuccess(res.message);
          this.getAllPost();
        } else {
          this.cS.showError(res.message);
        }
        this.modalReference.close();
      });
    }
  }

  onSelectFile(event: any) {
    const srcEle = event.target;
    var filesAmount = event.target.files.length;
    if ((this.images.length + this.postImages.length + filesAmount) <= 4) {
      // this.images = [];
      // this.filePostImages = [];
      for (let i = 0; i < filesAmount; i++) {
        if (srcEle.files && srcEle.files[i]) {
          if (this.cS.checkValidImage(srcEle.files[i])) {
            this.readURL(srcEle.files[i]);
          } else {
            this.cS.showError("Please select file less than 5MB.");
          }
        }
      }
    } else {
      this.cS.showError("You can only upload 4 images.");
    }
  }

  readURL(input: any) {
    const self = this;
    if (input) {
      const reader = new FileReader();
      reader.onload = function (event: Event) {
        if (event.target) {
          self.images.push(reader.result);
          self.filePostImages.push(input);
        }
      };
      reader.readAsDataURL(input);
    }
  }

  deletePostImages(index: number, id?: number): void {
    if (id !== undefined) {
      this.postImages.splice(index, 1);
      this.deleteImageIds.push(id);
    } else {
      this.images.splice(index, 1);
      this.filePostImages.splice(index, 1);
    }
    // this.deleteImages = true;
    // this.images = [];
  }

  openShareOptions(content: any, slug: any, title: any) {
    if (this.checkAuth()) {
      this.postId = slug;
      this.sharingUrl = this.repoUrl + "?post_id=" + slug;
      this.sharingTitle = encodeURIComponent(title);

      this.modalService.open(content, { centered: true, windowClass: "share_popup" }).result.then(
        (result) => {
          console.log(`Closed with: ${result}`);
        },
        (reason) => {
          console.log(reason);
        }
      );
    } else {
      this.cS.navigateTo('/login');
    }
  }

  shareOnFacebook() {
    this.handleSharePost();
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${this.sharingUrl}`, 'sharer');
  }

  shareOnTwitter() {
    this.handleSharePost();
    window.open(`https://twitter.com/intent/tweet?url=${this.sharingUrl}&text=${this.sharingTitle}`, 'sharer');
  }

  // // LinkedIn share won't work if your shareUrl is localhost:port/abc, it should be genuine deployed url
  shareOnLinkedIn() {
    this.handleSharePost();
    window.open(`https://www.linkedin.com/shareArticle?url=${this.sharingUrl}&title=${this.sharingTitle}`, 'sharer');
  }
  openVerticallyCentered(postImage: any, postId: number, slideIndex: number) {
    var post = this.posts.find(function (post) {
      return post.id == postId;
    });
    this.postImages = post.images;
    this.customOptions.startPosition = slideIndex;
    this.modalService.open(postImage, { windowClass: 'post-img-carousel', centered: true });
  }

  cityShowLocationDropDown() {
    // e.preventDefault();
    if (this.cityIsShow) {
      this.cityIsShow = false;
    } else {
      this.cityIsShow = true;
    }
  }
  citySelectLocation(cityLocation: any) {
    this.city_search_text = true;
    this.city_search_location = this.selectedCity = cityLocation;
    this.cityIsShow = false;
  }

  cityFilterLocation() {
    this.cityLocations = [];
    if (this.cityName != '') {
      this.cities.filter((item:any) => {
        if (item['name'].toLowerCase().indexOf(this.cityName.toLowerCase()) > -1) {
          this.cityLocations.push(item);
        }
      })
    } else {
      this.cityLocations = this.cities;
    }
  }

  viewAllPost() {
    this.postDetailId = 0;
    this.getAllPost();
    //this.cS.navigateTo('/home');
  }

  /**
   * on click load common component for liked users
   * @Yuvraj 21/03/2022
   */
  loadGroupPostLikedSharedTaggedUsersListModalComponentForLiked(id: number) {
    if (this.checkAuth()) {
      const modalReference = this.modalService.open(GroupPostLikedSharedTaggedUsersListModalComponent, { windowClass: 'mobile_popup' });
      modalReference.componentInstance.postId = id;
      modalReference.componentInstance.loadType = 'loadPostLikedUsers'; //type of load
      modalReference.result.then((result: any) => {
        console.log(`Closed with: ${result}`);
      }, (reason: any) => {
        console.log('reason', reason);
      });
    }
    else {
      this.cS.navigateTo('/login');
    }
  }
  /**
   * on click load common component for shared users
   * @Yuvraj 21/03/2022
   */
  loadGroupPostLikedSharedTaggedUsersListModalComponentForShared(id: number) {
    if (this.checkAuth()) {
      const modalReference = this.modalService.open(GroupPostLikedSharedTaggedUsersListModalComponent, { windowClass: 'mobile_popup' });
      modalReference.componentInstance.postId = id; //post id
      modalReference.componentInstance.loadType = 'loadPostSharedUsers'; //type of load
      modalReference.result.then((result: any) => {
        console.log(`Closed with: ${result}`);
      }, (reason: any) => {
        console.log('reason', reason);
      });
    }
    else {
      this.cS.navigateTo('/login');
    }

  }


  generateImage(url: any) {
    let imageUrl = url;

    this.getBase64ImageFromURL(imageUrl).subscribe((base64data: any) => {
      this.base64Image = "data:image/jpg;base64," + base64data;
      // save image to disk
      var link = document.createElement("a");

      document.body.appendChild(link); // for Firefox

      link.setAttribute("href", this.base64Image);
      link.setAttribute("download", "image.jpg");
      link.click();
    });
  }

  getBase64ImageFromURL(url: string) {
    return Observable.create((observer: Observer<string>) => {
      const img: HTMLImageElement = new Image();
      img.crossOrigin = "Anonymous";
      img.src = url;
      if (!img.complete) {
        img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = err => {
          observer.error(err);
        };
      } else {
        observer.next(this.getBase64Image(img));
        observer.complete();
      }
    });
  }

  getBase64Image(img: HTMLImageElement) {
    const canvas: HTMLCanvasElement = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx: CanvasRenderingContext2D = canvas.getContext("2d")!;
    ctx.drawImage(img, 0, 0);
    const dataURL: string = canvas.toDataURL("image/png");

    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }
}
