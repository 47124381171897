import { Component, OnInit, OnDestroy } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { HttpService } from 'src/app/shared/services/http.service';

@Component({
    selector: 'ryec-membership-plan',
    templateUrl: './membership-plan.component.html',
    styleUrls: ['./membership-plan.component.css']
})
export class MembershipPlanComponent implements OnInit, OnDestroy {

    showPlan = false;
    membershipPlansList: any;
    isApply = 0;
    isPlanActive = 0;
    modalReference: any;
    selectedPlanDetail: any;
    lang = localStorage.getItem('lang');
    langRef: any;
    currentMembershipPlan: any;
    appName = environment.APP_NAME;

    constructor(
        private httpS: HttpService,
        private cS: CommonService,
        private mS: MessageService,
        private aS: AuthService,
        private router: Router,
        private modalService: NgbModal) {
            this.langRef = this.mS.getLang().subscribe(res => {
                this.lang = res;
            });
    }

    ngOnInit() {
        this.cS.scrollTop();
        if(this.aS.isLoggedIn()) {
            this.getCurrentMembershipPlan();
        } else {
            this.isPlanActive = 0;
        }
        this.getMembershipPlans();
        if (this.lang === undefined || this.lang === '' || this.lang === null) {
            this.lang = 'en';
        }
        console.log('isPlanActive', this.isPlanActive);
    }

    ngOnDestroy() {
        if (!!this.langRef) {
            this.langRef.unsubscribe();
        }
    }

    getCurrentMembershipPlan() {

    const apiUrl = environment.RYEC_API_URL + 'getCurrentSubscriptionPlan';


        this.httpS.get(apiUrl).subscribe((res: any) => {
            if (res.status === 1) {
                console.log('Member');
                this.isPlanActive    = res.isCurrentPlan;
              if(res.isCurrentPlan === 1) { 
                this.currentMembershipPlan = res.data;
              }

            }
        }, err => {
            if (err.error) {
                console.log(err.error);
            }
        })
    }


    getMembershipPlans() {
        const apiUrl = environment.RYEC_API_URL + 'getSubscriptionPlanListNoAuth';

        this.httpS.get(apiUrl).subscribe((res: any) => {
            if (res.status === 1) {
                this.membershipPlansList = res.data;
                this.isApply = res.isPendingRequest;
            }
        }, err => {
            if (err.error) {
                console.log(err.error);
            }
        })
    }

    showPlans() {
        if (this.showPlan) {
            this.showPlan = false;
        } else {
            this.showPlan = true;
        }
    }

    selectMembershipPlan(plan: number, content: any) {
        this.selectedPlanDetail = plan;
        this.modalReference = this.modalService.open(content, { windowClass: 'mobile_popup memberplan-popup' });
        this.modalReference.result.then((result: any) => {
            console.log(`Closed with: ${result}`);
        }, (reason: any) => {
            console.log(reason);
        });
    }

    submitSubscribePlan() {
        localStorage.setItem('selectedPlan', JSON.stringify(this.selectedPlanDetail));
        this.modalReference.close();
        this.router.navigate(['/home/my-cart']);
        // const postJson = {
        //     subscription_plans_id: this.selectedPlanDetail.id
        // };

        // const apiUrl = environment.RYEC_API_URL + 'sendMembershipRequest';

        // this.httpS.post(apiUrl, postJson).subscribe((res: any) => {
        //     if (res.status === 1) {
        //         this.modalReference.close();
        //         this.isApply = 1;
        //         // this.cS.showSuccess(res.message);
        //         this.modalReference = this.modalService.open(content, { windowClass: 'mobile_popup memberplan-popup' });
        //         this.modalReference.result.then((result: any) => {
        //             console.log(`Closed with: ${result}`);
        //         }, (reason: any) => {
        //             console.log(reason);
        //         });
        //     } else {
        //         this.modalReference.close();
        //         this.isApply = 0;
        //         this.cS.showError(res.message);
        //     }
        // }, err => {
        //     if (err.error) {
        //         this.isApply = 0;
        //         console.log(err.error);
        //     }
        //     this.modalReference.close();
        // });
    }

    closeSuccesssPopup() {
        this.modalReference.close();
    }
}