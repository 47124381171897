<div class="container">
	<div class="coming_text marketplace_text">
		<h4>{{'ang_marketplace_available_on_android_ios' | translate}}</h4>
		<div class="row">
			<div class="col-md-12">
				<div class="stor_link" *ngIf="appName == 'MRC'">
					<a href="https://apps.apple.com/in/app/myrajasthan-club/id1545374835" target="_blank">
					<img src="assets/images/app_store_landing.png" alt="" class="img-responsive">
					</a>
					<a href="https://play.google.com/store/apps/details?id=club.myrajasthan" target="_blank">
					<img src="assets/images/play_store_landing.png" alt="" class="img-responsive">
					</a>
				</div>
				<div class="stor_link" *ngIf="appName == 'RYC'">
					<a href="https://apps.apple.com/in/app/rajput-club/id1660935382" target="_blank">
					<img src="assets/images/app_store_landing.png" alt="" class="img-responsive">
					</a>
					<a href="https://play.google.com/store/apps/details?id=com.rajputsclub" target="_blank">
					<img src="assets/images/play_store_landing.png" alt="" class="img-responsive">
					</a>
				</div>
				<div class="stor_link" *ngIf="appName == 'BD'">
					<a href="#" target="_blank">
					<img src="assets/images/app_store_landing.png" alt="" class="img-responsive">
					</a>
					<a href="#" target="_blank">
					<img src="assets/images/play_store_landing.png" alt="" class="img-responsive">
					</a>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- <div class="market-wrapper">
	<div class="investment_banner">
		<h2>{{'ang_marketplace_title_text' | translate}}</h2>
		<img src="assets/images/investment_banner.jpg" alt="">
		<div class="myinterest_tab myinterest_tab_member">
			<ul>
				<li>
					<a href="javascript:void(0);" (click)="checkSpecificTab()" [ngClass]="{'is-tab-active': routerLink !== 'my-ads' && routerLink !== 'my-interest'}"  routerLinkActive="active">
            {{'ang_all' | translate}}
					</a>
				</li>
				<li>
					<a href="javascript:void(0);" routerLink="/home/market-place/my-ads" routerLinkActive="active">
						{{'ang_myads' | translate}}
					</a>
				</li>
				<li>
					<a href="javascript:void(0);" routerLink="/home/market-place/my-interest" routerLinkActive="active">
						{{'ang_myinterest' | translate}}
					</a>
				</li>
			</ul>
		</div>
	</div>
</div>

<div class="container" *ngIf="sort_slug !== ''">
	<div class="row">
		<div class="col-sm-12">
			<div class="sort_by_tab">
				<span>{{'ang_sortby' | translate}}</span>
				<ul>
					<li>
						<a href="javascript:void(0);" routerLink="/home/market-place/relevance"
							routerLinkActive="active">
							{{'ang_relevance' | translate}}
						</a>
					</li>
					<li>
						<a href="javascript:void(0);" routerLink="/home/market-place/popular"
							routerLinkActive="active">
							{{'ang_popular' | translate}}
						</a>
					</li>
					<li>
						<a href="javascript:void(0);" routerLink="/home/market-place/near-by"
							routerLinkActive="active">{{'ang_nearme' | translate}}</a>
					</li>
					<li>
						<a href="javascript:void(0);" routerLink="/home/market-place/atoz" routerLinkActive="active">
							{{'ang_a_to_z' | translate}}
						</a>
					</li>
					<li>
						<a href="javascript:void(0);" routerLink="/home/market-place/ztoa" routerLinkActive="active">
							{{'ang_z_to_a' | translate}}
						</a>
					</li>
				</ul>
				<div class="filter_btn">
					<a href="javascript:void(0);" (click)="filter(contentFilter)">
						<img src="assets/images/filter.png" alt="filter">{{'ang_Filters' | translate}}</a>
				</div>
			</div>
		</div>
	</div>
</div> -->
<!-- sort_by_tab End -->

<!-- <div class="container">
	<div>
		<div class="business_listing_view">
      <div class="top-bar">
        <div class="create_oppo_btn">
          <a href="javascript::void(0)" class="ryec_btn" (click)="market(contentcreatemarket)">
            <i class="fa fa-plus-circle" aria-hidden="true"></i>
            {{'ang_create_marketplace_btn_text' | translate}}
          </a>
        </div>
        <div *ngIf="MarketPlaceList.length > 0" class="total_result">
            {{MarketPlaceList.length}} {{'ang_results' | translate}}
        </div>
      </div>
			<div class="clearfix business_listing_box" [ngClass]="{'isPending': item?.approved === 0, 'isClosed': item?.is_closed === 1 && item?.approved === 1}"  *ngFor="let item of MarketPlaceList">
				<div *ngIf="item?.advertisement_images"  class="business_listing_img">
					<span class="ad-category">{{item?.ads_type_text}}</span>
					<img alt="essar-real-estate"
						src="{{item?.advertisement_images[0]?.original_url || '../assets/images/logo RYEC.png'}}">
				</div>
				<div class="business_listing_content">
					<div class="row">
						<div class="col-md-7 col-sm-7 col-xs-12">
							<div class="business_content_left">
								<h2><i *ngIf="item?.is_interest"  class="fa fa-star" aria-hidden="true"></i>{{item?.name}}</h2>
								<ul>
									<li *ngIf="item?.user_full_name">
										<i class="fa fa-user" aria-hidden="true"></i>{{item?.user_full_name}}
									</li>
									<li *ngIf="item?.address" class="text-overflow-hidden">
										<i class="fa fa-map-marker" aria-hidden="true"></i>{{item?.address}}
									</li>
									<li *ngIf="item?.parent_category_names" class="text-overflow-hidden">
										<i class="fa fa-angle-right text-overflow-hidden" aria-hidden="true"></i>
										<span>{{item?.parent_category_names}}</span>
									</li>
									<li *ngIf="item?.created_at">
										<i class="fa fa-calendar" aria-hidden="true"></i>
										<span>{{item?.created_at | date:'dd LLL yyyy'}}</span>
									</li>
								</ul>
							</div>
						</div>
						<div class="col-md-5 col-sm-5 col-xs-12">
							<div class="business_content_right">
								<a href="javascript:void(0);" (click)="gotoMarketDetail(item.id)" class="ryec_btn">
                  {{'ang_viewmore' | translate}}
									<i class="fa fa-angle-right" aria-hidden="true"></i>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div *ngIf="MarketPlaceList.length > 0" class="pagination_view">
			<ngb-pagination [collectionSize]="collectionSize" [(page)]="currentPage" [maxSize]="maxSize" size="sm"
				(pageChange)="getNextPageMarketPlaceList($event)"></ngb-pagination>
		</div>
	</div> -->
  <!-- pagination_view End -->
  <!-- <div *ngIf="noData" style=" text-align: center;padding: 20px;">
    <img src="../assets/images/norecordfound.png" style="height:250px;margin:20px;">
    <h3>{{'ang_nomatches' | translate}}</h3>
  </div>
</div> -->
<!--container end-->


<!-- filter popup -->
<!-- <ng-template #contentFilter let-c="close" let-d="dismiss">
	<div class="modal-header">
		<h4 class="modal-title">{{'ang_filter' | translate}}</h4>
		<button type="button" class="close" aria-label="Close" (click)="d('close')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="input-group input-group-lg"> -->
			<!-- <label>select category</label> -->
			<!-- <input type="text" [(ngModel)]="searchText" class="form-control" placeholder="{{'ang_searchhere' | translate}}" aria-describedby="sizing-addon1">
		</div>
		<div class="category_select top">
			<label>{{'ang_city' | translate}}</label> -->
      <!-- <input type="text" [(ngModel)]="city" class="form-control" placeholder="all cities" aria-describedby="sizing-addon1"> -->
      <!-- <ngx-select-dropdown [config]="config" [options]="allCities" [(ngModel)]="city" [multiple]="false" >
      </ngx-select-dropdown>
		</div>
		<div class="category_select top">
				<label>{{'ang_type' | translate}}{{adsType}}</label>
				<select [(ngModel)]="adsType">
					<option value="">All</option>
					<option value="0">Buy</option>
					<option value="1">Sell</option>
					<option value="2">Service</option>
				</select>
		</div>
		<div class="category_select top">
			<label>{{'ang_category' | translate}}</label> -->
      <!-- <input type="text" class="form-control" placeholder="all categories" aria-describedby="sizing-addon1"> -->
      <!-- <ngx-select-dropdown [config]="config" [options]="allCategoryArr" [(ngModel)]="category" [multiple]="false">
      </ngx-select-dropdown>
		</div>
	</div>
	<div class="modal-footer ">
		<div class="apply_btn">
			<button type="button" (click)="d('apply')" class="btn btn-light ryec_btn">{{'ang_apply' | translate}}</button>
		</div>
	</div>
</ng-template> -->


<!-- create market place popup -->
<!-- <ng-template #contentcreatemarket let-c="close" let-d="dismiss">
	<div class="modal-header">
		<h4 class="modal-title">{{'ang_submit_free_ads_msg' | translate}}</h4>
		<button type="button" class="close" aria-label="Close" (click)="d('click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="market-tab">
				<h3>{{'ang_select_ads_msg' | translate}}</h3>

			<form>
				<div class="cc-selector">
					<input id="buy" type="radio" name="market" [(ngModel)]="adType" value="0" />
					<label class="drinkcard-cc buy" for="buy"></label>

					<input id="sell" type="radio" name="market" [(ngModel)]="adType" value="1" />
					<label class="drinkcard-cc sell" for="sell"></label>

					<input id="service" type="radio" name="market" [(ngModel)]="adType" value="2" />
					<label class="drinkcard-cc service" for="service"></label>
				</div>
			</form>
      <h6>{{'ang_example_text' | translate}}</h6>
			<p *ngIf="adType === '0'">
        {{'ang_buy_info_msg' | translate}}
      </p>
			<p *ngIf="adType === '1'">
        {{'ang_sell_info_msg' | translate}}
      </p>
			<p *ngIf="adType === '2'">
        {{'ang_service_info_msg' | translate}}
      </p>
		</div>
	</div>
	<div class="modal-footer">
		<div class="apply_btn">
			<button [disabled]="adType === ''" type="button" (click)="d('createNewAd')" class="btn btn-light ryec_btn">{{'ang_next_btn' | translate}}</button>
		</div>
	</div>
</ng-template> -->
