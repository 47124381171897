// // The file contents for the current environment will overwrite these during build.
// // The build system defaults to the dev environment which uses `environment.ts`, but if you do
// // `ng build --env=prod` then `environment.prod.ts` will be used instead.
// // The list of which env maps to which file can be found in `.angular-cli.json`.

// export const environment = {
//   production: false,
//   //RYEC_API_URL: 'https://stageadmin.ryuva.club/api/',
//   RYEC_API_URL: 'https://stagingadmin.myrajasthanclub.com/api/',
//   RYEC_SHARE_URL: 'http://stagingadmin.myrajasthanclub.com/share',
//   //RYEC_API_URL: 'https://mrc-staging.azurewebsites.net/api/',
//   //RYEC_API_URL: 'http://127.0.0.1:8000/api/',

//   NDA: 'No Data Available',
//   //GOOGLE_MAP_API: 'https://maps.google.com/maps/api/js?key=AIzaSyC0GOPX5KCBWMktECRDFUXODd2WSNXFae4&libraries=places',
//   GOOGLE_MAP_API: 'https://maps.google.com/maps/api/js?key=AIzaSyCTdAmyxsAISAcV_jnGdndkmaWGWI1J6to&libraries=places',
//   BUSINESS_LIST_LIMIT: 10,
//   USERS_LIST_LIMIT: 5,
//   LOCATION_RADIUS: 25,
//   DESCRIPTIO_TRIM_LENGTH: 1000,
//   API_SECURITY_KEY: 'basic MTpNWVJBSkFTVEhBTkNMVUI6TVJDLTIwMjI6TVJDOkVORC1VU0VS',
//   RESEND_OTP_TIME: 120,
//   APPLICATION_ID: '1',
//   APP_NAME: 'MRC',
//   TITLE: 'My Rajasthan Club',
//   gTagManagerId: 'GTM-K4FCK9S'
// };

// The file contents for the current environment will overwrite these during build.

// The build system defaults to the dev environment which uses `environment.ts`, but if you do

// `ng build --env=prod` then `environment.prod.ts` will be used instead.

// The list of which env maps to which file can be found in `.angular-cli.json`.



export const environment = {

  production: false,

  //RYEC_API_URL: 'https://stageadmin.ryuva.club/api/',

  RYEC_API_URL:  'https://admin.bharatdarshan.org/api/',

  RYEC_SHARE_URL: 'https://stagingadmin.rajputsclub.com/share',

  //RYEC_API_URL: 'https://mrc-staging.azurewebsites.net/api/',

  //RYEC_API_URL: 'http://127.0.0.1:8000/api/',



  NDA: 'No Data Available',

  //GOOGLE_MAP_API: 'https://maps.google.com/maps/api/js?key=AIzaSyC0GOPX5KCBWMktECRDFUXODd2WSNXFae4&libraries=places',

  GOOGLE_MAP_API: 'https://maps.google.com/maps/api/js?key=AIzaSyCTdAmyxsAISAcV_jnGdndkmaWGWI1J6to&libraries=places',

  BUSINESS_LIST_LIMIT: 10,

  USERS_LIST_LIMIT: 5,

  LOCATION_RADIUS: 25,

  DESCRIPTIO_TRIM_LENGTH: 1000,

  API_SECURITY_KEY: 'basic MTpNWVJBSkFTVEhBTkNMVUI6TVJDLTIwMjI6TVJDOkVORC1VU0VS',

  RESEND_OTP_TIME: 120,

  APPLICATION_ID: '1',

  APP_NAME: 'MRC',

  TITLE: 'My Rajasthan Club',

  gTagManagerId: 'GTM-K4FCK9S'

};