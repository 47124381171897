import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { BusinessSearchComponent } from './business-search/business-search.component';
import { BusinessListComponent } from './business-list/business-list.component';
import { HomeDashboardComponent } from './home-dashboard/home-dashboard.component';
import { MembershipPlanComponent } from './membership-plan/membership-plan.component';
import { BusinessDetailComponent } from './business-detail/business-detail.component';
import { BusinessProfileComponent } from './business-profile/business-profile.component';

import { AddBusinessComponent } from './add-business/add-business.component';
import { BusinessOwnerDetailComponent } from './business-owner-detail/business-owner-detail.component';
import { MyBusinessDetailComponent } from './my-business-detail/my-business-detail.component';
import { AgentBusinessListComponent } from './agent-business-list/agent-business-list.component';
import { InvestmentOpportunityComponent } from './investment-opportunity/investment-opportunity.component';
import { CreateInvestmentOpportunityComponent } from './create-investment-opportunity/create-investment-opportunity.component';
import { InvestmentOpportunityDetailComponent } from './investment-opportunity-detail/investment-opportunity-detail.component';
import { MyInvestmentOpportunityDetailComponent } from './my-investment-opportunity-detail/my-investment-opportunity-detail.component';
import { PaymentSuccessfulComponent } from './payment-successful/payment-successful.component';
import { MyCartComponent } from './my-cart/my-cart.component';
import { MarketPlaceComponent } from './market-place/market-place.component';
import { MarketPlaceDetailComponent } from './market-place-detail/market-place-detail.component';
import { CreateMarketplaceComponent } from './create-marketplace/create-marketplace.component';
import { HomePostComponent } from './home-post/home-post.component';
import { HomePostDetailComponent } from './home-post-detail/home-post-detail.component';
import {  NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { SharedModule } from 'src/app/shared/module/shared/shared.module';
import { HeaderComponent } from '../auth/header/header.component';
import {  OwlModule } from 'ngx-owl-carousel';
import { TrimstrPipe } from 'src/app/pipes/trimstr/trimstr.pipe';
import { PostComponent } from './post/post.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxJsonLdModule } from '@ngx-lite/json-ld';
import { NewsDetailsComponent } from '../auth/news-details/news-details.component';

@NgModule({
  declarations: [
    HomeComponent,
    BusinessSearchComponent,
    BusinessListComponent,
    NewsDetailsComponent,
    HomeDashboardComponent,
    MembershipPlanComponent,
    BusinessDetailComponent,
    BusinessProfileComponent,
    PostComponent,
    AddBusinessComponent,
    BusinessOwnerDetailComponent,
    MyBusinessDetailComponent,
    AgentBusinessListComponent,
    InvestmentOpportunityComponent,
    CreateInvestmentOpportunityComponent,
    InvestmentOpportunityDetailComponent,
    MyInvestmentOpportunityDetailComponent,
    PaymentSuccessfulComponent,
    MyCartComponent,
    MarketPlaceComponent,
    MarketPlaceDetailComponent,
    CreateMarketplaceComponent,
    HomePostComponent,
    HomePostDetailComponent,
    TrimstrPipe
  ],
 
  imports: [
    ImageCropperModule,
    OwlModule,
    SharedModule,
    PerfectScrollbarModule,
    CommonModule,
    CarouselModule,
    HomeRoutingModule,
    NgbModule,
    NgxJsonLdModule
  ],
  
})
export class HomeModule { }
