<div class="container">
    <div class="contact_title">
      <h2>{{ "ang_donateplasma" | translate }}</h2>
    </div>
    <!-- contact_title End -->
    <form class="form-horizontal" [formGroup]="plasmaDonorForm" novalidate>
      <div class="contact_view">
        <!-- donor-list table start -->
        <div class="donor-search-main">
          <div class="donor-form-group">
            <div class="donor_search_here_view">
              <i
                (click)="getPlasmaDonorList()"
                style="cursor: pointer"
                class="fa fa-search"
                aria-hidden="true"
              ></i>
              <input
                id="donorSearch"
                (keyup.enter)="getPlasmaDonorList()"
                [(ngModel)]="searchText"
                (input)="donorSearch($event)"
                placeholder="Search for City, Blood group"
                type="text "
              />
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-xs-12 text-center">
          <div class="cart-table">
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col" (click)="sort('name')">
                      {{ "ang_name" | translate }}
                    </th>
                    <th scope="col" (click)="sort('mobile_umber')">
                      {{ "ang_mobile" | translate }}
                    </th>
                    <th scope="col" (click)="sort('blood_group')">
                      {{ "ang_bloodgroup" | translate }}
                    </th>
                    <th scope="col" (click)="sort('covid_start_date')">
                      {{ "ang_covidStartDate" | translate }}
                    </th>
                    <th scope="col">{{ "ang_city" | translate }}</th>
                    <!-- <th scope="col"></th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let x of donorData">
                    <td>{{ x.name }}</td>
                    <td>{{ x.mobile_number }}</td>
                    <td>{{ x.blood_group }}</td>
                    <td>{{ x.covid_start_date }}</td>
                    <td>{{ x.city }}</td>
                    <!-- <td>
                      <a href="javascript::void(0);" (click)="handleEditClick(x)"
                        >Edit</a
                      >
                      &nbsp;|&nbsp;
                      <a
                        href="javascript::void(0);"
                        (click)="handleDeleteClick(x)"
                        >Delete</a
                      >
                    </td> -->
                  </tr>
                </tbody>
              </table>
              <div
                class="pagination_view"
                *ngIf="donorData && donorData.length > 0"
              >
                <ngb-pagination
                  [collectionSize]="collectionSize"
                  [(page)]="currentPage"
                  [maxSize]="2"
                  [rotate]="true"
                  size="sm"
                  [boundaryLinks]="false"
                  (pageChange)="getPlasmaDonorList()"
                >
                </ngb-pagination>
              </div>
            </div>
          </div>
        </div>
        <!-- donor-list end -->
        <div class="row donor-table">
          <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="control-group">
              <label class="control-label" for="name">{{
                "ang_name" | translate
              }}</label>
              <div class="controls">
                <input
                  type="text"
                  formControlName="name"
                  id="name"
                  name="name"
                  placeholder="{{ 'ang_name' | translate }}"
                  class="input-xlarge"
                />
  
                <p
                  class="help-block"
                  *ngIf="
                    plasmaDonorForm.controls.name.touched &&
                    plasmaDonorForm.controls.name.errors?.required
                  "
                >
                  {{ "ang_contactusnamerequired" | translate }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="control-group">
              <label class="control-label" for="name">{{
                "ang_mobile" | translate
              }}</label>
              <div class="controls">
                <input
                  type="tel"
                  formControlName="mobile_number"
                  id="mobile"
                  name="mobile"
                  placeholder="{{ 'ang_mobile' | translate }}"
                  class="input-xlarge"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 gutter_right country_outer">
            <div class="control-group">
              <label class="control-label" for="name">{{
                "ang_bloodgroup" | translate
              }}</label>
              <div class="flag_dropdown controls">
                <button class="dropbtn" type="text" (click)="selectBloodGroup()">
                  {{ blood_group }}
                </button>
                <div class="flag_dropdown_list" *ngIf="showDropDown">
                  <perfect-scrollbar>
                    <a
                      href="javascript:void(0);"
                      (click)="selectGroup(group)"
                      *ngFor="let group of bloodGroups"
                    >
                      {{ group }}</a
                    >
                  </perfect-scrollbar>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="control-group">
              <label class="control-label" for="name">{{
                "ang_city" | translate
              }}</label>
              <div class="controls">
                <input
                  type="text"
                  formControlName="city"
                  id="city"
                  name="city"
                  placeholder="{{ 'ang_city' | translate }}"
                  class="input-xlarge"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="control-group">
              <label class="control-label" for="name">{{
                "ang_covidStartDate" | translate
              }}</label>
              <div class="controls date">
                <i
                  class="fa fa-calendar"
                  (click)="d.toggle()"
                  aria-hidden="true"
                ></i>
                <input
                  formControlName="covid_start_date"
                  ngbDatepicker
                  #d="ngbDatepicker"
                  type="text"
                  id="dob"
                  name="covid_start_date"
                  placeholder="{{ 'ang_covidStartDate' | translate }}"
                  class="input-xlarge"
                />
              </div>
            </div>
          </div>
  
          <div class="col-sm-12 col-xs-12">
            <div class="contact_btn">
              <button
                (ngClass)="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'"
                (click)="onplasmaDonorFormSubmit()"
                [disabled]="!plasmaDonorForm.valid"
              >
                {{ "ang_submit" | translate }}
              </button>
            </div>
            <!-- contact_btn End -->
          </div>
          <!-- col End -->
  
          <div class="col-sm-12 col-xs-12 text-center">
            <div class="line-text">
              <h6>or connect with us</h6>
            </div>
            <div class="bottom-contact">
              <div class="foo-logo">
                <a href="javascript:void(0)" title="RYEC"
                  >Rajput Yuva Entrepreneur Club</a
                >
              </div>
              <ul>
                <li>
                  <span class="contact-icon"
                    ><i class="fa fa-map-marker"></i></span
                  >{{ "ang_branchoffice1" | translate }} <br />
                  {{ "ang_branchoffice2" | translate }}
                </li>
                <li>
                  <span class="contact-icon"><i class="fa fa-phone"></i></span>
                  <a href="tel:919909115555" title="Call Us"> +91 9909115555 , </a
                  ><a href="tel:919099937890" title="Call Us">+91 9099937890</a>
                </li>
                <li>
                  <span class="contact-icon"><i class="fa fa-envelope"></i></span>
                  <a href="mailto:info@myrajasthanclub.com" title="Mail Us"
                    >info@myrajasthanclub.com</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  