import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/shared/guard/auth.guard';
import { environment } from 'src/environments/environment';
import { NewsDetailsComponent } from '../auth/news-details/news-details.component';
import { AddBusinessComponent } from './add-business/add-business.component';
import { AgentBusinessListComponent } from './agent-business-list/agent-business-list.component';
import { BusinessDetailComponent } from './business-detail/business-detail.component';
import { BusinessListComponent } from './business-list/business-list.component';
import { BusinessOwnerDetailComponent } from './business-owner-detail/business-owner-detail.component';
import { BusinessProfileComponent } from './business-profile/business-profile.component';
import { BusinessSearchComponent } from './business-search/business-search.component';
import { CreateInvestmentOpportunityComponent } from './create-investment-opportunity/create-investment-opportunity.component';
import { CreateMarketplaceComponent } from './create-marketplace/create-marketplace.component';
import { HomeDashboardComponent } from './home-dashboard/home-dashboard.component';
import { HomePostDetailComponent } from './home-post-detail/home-post-detail.component';
import { HomePostComponent } from './home-post/home-post.component';
import { HomeComponent } from './home.component';
import { InvestmentOpportunityDetailComponent } from './investment-opportunity-detail/investment-opportunity-detail.component';
import { InvestmentOpportunityComponent } from './investment-opportunity/investment-opportunity.component';
import { MarketPlaceDetailComponent } from './market-place-detail/market-place-detail.component';
import { MarketPlaceComponent } from './market-place/market-place.component';
import { MembershipPlanComponent } from './membership-plan/membership-plan.component';
import { MyBusinessDetailComponent } from './my-business-detail/my-business-detail.component';
import { MyCartComponent } from './my-cart/my-cart.component';
import { MyInvestmentOpportunityDetailComponent } from './my-investment-opportunity-detail/my-investment-opportunity-detail.component';
import { PaymentSuccessfulComponent } from './payment-successful/payment-successful.component';

const routes: Routes = [{ path: '', component: HomeDashboardComponent },
{
  path: '',
  component: HomeComponent,
  children: [
    {
      path: 'business-search', component: BusinessSearchComponent, data: { title: environment.TITLE + ' - Business Search Results' }
    },
    { path: 'home/business-list/:slug/:slug', component: BusinessListComponent, data: { title: environment.TITLE + ' - Business List' } },

    { path: '', component: HomeDashboardComponent, data: { title: environment.TITLE + ' - Dashboard' } },

    { path: 'home/membership-plan', component: MembershipPlanComponent, data: { title: environment.TITLE + ' - Membership Plans' } }
  ]
},
{ path: 'home/business-detail/:slug', component: BusinessDetailComponent, data: { title: environment.TITLE + ' - Business Profile' } },
{
  path: '',
  component: HomeComponent,
  canActivate: [AuthGuard],
  canLoad: [AuthGuard],
  canActivateChild: [AuthGuard],
  children: [

    { path: 'home/business-detail/:slug', component: BusinessDetailComponent, data: { title: environment.TITLE + ' - Business Profile' } },
    { path: 'home/business-profile/:slug', component: BusinessProfileComponent, data: { title: environment.TITLE + ' - Business Profile' } },
    {
      path: 'home/business-owner', component: BusinessOwnerDetailComponent,
      data: { title: 'Business Owner Profile - ' }
    },
    { path: 'home/add-business', component: AddBusinessComponent, data: { title: environment.TITLE + ' - Business Register' } },
    { path: 'home/my-business-detail', component: MyBusinessDetailComponent, data: { title: environment.TITLE + ' - My Business Profile' } },
    { path: 'home/agent-business-list/:slug', component: AgentBusinessListComponent, data: { title: environment.TITLE + ' - Business List' } },
    { path: 'home/investment-opportunity/:slug', component: InvestmentOpportunityComponent, data: { title: environment.TITLE + ' - Investment Opportunity List' } },
    { path: 'home/investment-opportunity-detail/:slug', component: InvestmentOpportunityDetailComponent, data: { title: environment.TITLE + ' - Investment Opportunity Detail' } },

    { path: 'home/create-investment-opportunity/:slug', component: CreateInvestmentOpportunityComponent, data: { title: environment.TITLE + ' - Create Investment Opportunity' } },
    { path: 'home/my-investment-opportunity-detail/:slug', component: MyInvestmentOpportunityDetailComponent, data: { title: environment.TITLE + ' - Investment Opportunity Detail' } },

    { path: 'home/market-place/:slug', component: MarketPlaceComponent, data: { title: environment.TITLE + ' - Market Place' } },
    { path: 'home/create-market-place', component: CreateMarketplaceComponent, data: { title: environment.TITLE + ' - Create Market Place' } },
    { path: 'home/edit-market-place', component: CreateMarketplaceComponent, data: { title: environment.TITLE + ' Edit Market Place - ' } },
    { path: 'home/market-place-detail/:slug', component: MarketPlaceDetailComponent, data: { title: environment.TITLE + ' - Market Place Detail' } },
    { path: 'home/market-place', component: MarketPlaceComponent, data: { title: environment.TITLE + ' - Market Place' } },
    { path: 'home/my-cart', component: MyCartComponent, data: { title: environment.TITLE + ' - My Cart' } },
    { path: 'home/payment-successful/:slug', component: PaymentSuccessfulComponent, data: { title: environment.TITLE + ' - Payment' } },
    
  {
    path: 'news/:slug',
    component: NewsDetailsComponent,
    data: { title: 'Event details' },
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
    canActivateChild: [AuthGuard],
  },

  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule { }
