import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../environments/environment';
import { CommonService } from '../../services/common.service';
import { HttpService } from '../../services/http.service';


@Component({
  selector: 'ryec-comment-modal',
  templateUrl: './comment-modal.component.html',
  styleUrls: ['./comment-modal.component.css']
})
export class CommentModalComponent implements OnInit {

  @Input() newsId: number = 0;
  @Input() replyCommentId: number = 0;
  commentModel: any = {};
  isSubmit: boolean = false;
  appName = environment.APP_NAME;
  constructor(
    public activeModal: NgbActiveModal,
    private httpS: HttpService,
    private cS: CommonService,
  ) { }

  ngOnInit() {
    // this.commentModel.news_id = this.newsId;
    this.commentModel.post_id = this.newsId;
    this.commentModel.comment = "";
    this.commentModel.public_post_comment_id = this.replyCommentId;
    this.commentModel.type = "news";
  }

  onSubmit() {
    this.isSubmit = true;
    const apiUrl = environment.RYEC_API_URL + 'commentPost';
    this.httpS.post(apiUrl, this.commentModel).subscribe((res: any) => {
      if (res.status === 1) {
        this.cS.showSuccess(res.message);
        this.activeModal.dismiss('postDone');
      }
      else {
        this.cS.showError(res.message);
      }
    });
  }

}
