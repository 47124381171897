export class GroupModel {
    name: string;
    visibility: boolean;
    images: any = [];
    logo: string;
    description: string;
    asset_type_id: number;
    is_enable_request: boolean;
    is_security_code: boolean;
    is_post_moderate: boolean;
    dynamic_field_label: string;
    is_agree: boolean;
    constructor() {
        this.name = "";
        this.visibility = false;
        this.images = [];
        this.logo = "";
        this.description = "";
        this.is_enable_request = false;
        this.is_security_code = false;
        this.is_post_moderate = false;
        this.asset_type_id = null as any;
        this.is_agree = false;
        this.dynamic_field_label = "";
    }
}