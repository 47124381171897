<div class="business_detail_banner" *ngIf="(marketPlaceDetailData | json) != '{}'">
  <span *ngIf="marketPlaceDetailData?.advertisement_images.length === 0" class="ad-category">{{marketPlaceDetailData?.ads_type_text}}</span>
  <!-- <img alt="eddar-real" *ngIf="marketPlaceDetailData?.advertisement_images.length === 0"  src="assets/images/default-logo.png"> -->
  <img class="background_image" alt="eddar-real" src="assets/images/default-logo.png" *ngIf="marketPlaceDetailData?.advertisement_images.length == 0 && appName == 'MRC'" style="object-fit: cover" />
  <img class="background_image" alt="eddar-real" src="assets/images/default-logo-ryc.png" *ngIf="marketPlaceDetailData?.advertisement_images.length == 0 && appName == 'RYC'" style="object-fit: cover" />
  <ngb-carousel *ngIf="marketPlaceDetailData?.advertisement_images.length > 0">
    <ng-template ngbSlide *ngFor="let image of marketPlaceDetailData?.advertisement_images;let index = index;" (id)="index">
      <div id="parent_div" style="position:relative;height:100%;width:100%;">
        <div [ngStyle]="{'background' : 'url(' + image.original_url + ') no-repeat'}" id="background"
          class="background_image"></div>
        <div class="bus_slider_img">
          <span class="ad-category">{{marketPlaceDetailData?.ads_type_text}}</span>
          <img style="object-fit: contain;height: 400px;" [src]="image.original_url" alt="Random first slide">
        </div>
      </div>
    </ng-template>
  </ngb-carousel>
</div>
<div class="container" *ngIf="(marketPlaceDetailData | json) != '{}'">
  <div class="row shadow_card desc_content">
    <div class="col-sm-8 col-lg-9 col-md-9">
      <h4 class="title">{{marketPlaceDetailData?.name}}</h4>
      <h6 class="title" *ngIf="marketPlaceDetailData?.address">
        <i class="fa fa-map-marker" aria-hidden="true"></i>
        {{marketPlaceDetailData?.address}}
      </h6>
    </div>
    <!--col-sm-9 end-->
    <div *ngIf="marketPlaceDetailData?.is_interest && marketPlaceDetailData?.user_id !== userData?.id" class="col-sm-4 col-lg-3 col-md-3">
      <div class="interest_btn">
        <a href="javascript:void(0);" (click)="gotomessageDetail(marketPlaceDetailData?.thread_id)" [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'">
          {{'ang_showdiscussion' | translate}}
        </a>
      </div>
    </div>
    <div *ngIf="!marketPlaceDetailData?.is_interest && marketPlaceDetailData?.user_id !== userData?.id" class="col-sm-4 col-lg-3 col-md-3">
      <div class="interest_btn">
        <a href="javascript:void(0);" [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" (click)="openShowInterest(contentInterest)">
          {{'ang_showinterest' | translate}}
        </a>
      </div>
    </div>
    <div *ngIf="marketPlaceDetailData?.user_id === userData?.id && !marketPlaceDetailData?.is_closed" class="col-sm-4 col-lg-3 col-md-3">
      <div class="interest_btn">
        <a href="javascript:void(0);" (click)="editads()"  class="edit-icon">
          <i class="fa fa-pencil" aria-hidden="true"></i>
        </a>
      </div>
    </div>
    <!--col-sm-3 end-->
    <div class="col-sm-12">
      <div class="project_info_outer">
        <div *ngIf="marketPlaceDetailData?.price" class="project_info">
          <span>{{'ang_price' | translate}}</span>
          <h6>
            <i class="fa fa-inr" aria-hidden="true"></i>
            {{marketPlaceDetailData?.price}}
          </h6>
        </div>
        <div *ngIf="marketPlaceDetailData?.interest_count !== 0" class="project_info">
          <span><i class="fa fa-star" aria-hidden="true"></i></span>
          <h6>{{marketPlaceDetailData?.interest_count}} {{'ang_interests' | translate}}</h6>
        </div>
      </div>
    </div>
  </div>
  <!--row end-->
  <div class="row shadow_card">
    <div class="col-sm-12">
      <h4 class="title">{{'ang_description' | translate}}</h4>
      <div class="project_desc">
        <p>{{marketPlaceDetailData?.descriptions}}</p>
      </div>
    </div>
  </div>
  <!--row end-->
  <div class="row shadow_card" *ngIf="categoryArr.length > 0" >
    <div class="col-sm-12">
      <h4 class="title">{{'ang_category' | translate}}</h4>
      <div class="category-list">
          <ul>
            <li *ngFor="let list of categoryArr; let i = index;">
              <span>
                {{list?.name}}
              </span>
              <ul *ngFor="let item of list?.subCategoryArr; let j = index;">
                <li>{{item.name}}</li>
              </ul>
            </li>
          </ul>
      </div>
    </div>
  </div>
  <!--row end-->
  <!-- owner_information End -->
  <div class="row shadow_card" *ngIf="marketPlaceDetailData?.advertisement_videos.length > 0">
    <div class="col-sm-12">
      <div class="video_gallery">
        <h4 class="title">{{'ang_videos' | translate}}</h4>
        <ul>
          <li *ngFor="let item of marketPlaceDetailData?.advertisement_videos; let index = index;" (click)="openVideoViwer(index,contentVideo)">
              <img alt="video" [src]="item?.thumbnail">
          </li>
        </ul>
      </div>
      <!-- photo_gallery End -->
    </div>
  </div>
  <div class="row shadow_card owner_information">
    <div class="col-sm-12">
      <h4 class="title">{{'ang_ownerinformation' | translate}}</h4>
    </div>
    <!--  <div class="row"> -->
    <div class="col-sm-3 col-md-3 col-lg-3 gutter_right">
      <div class="owner_profile">
        <img src="{{marketPlaceDetailData?.owner_profile_image}}">
      </div>
      <!-- owner_profile End -->
    </div>
    <div class="col-sm-5 col-md-6 col-lg-6">
      <div class="owner_info_content">
        <h2>{{marketPlaceDetailData?.owner_name}}</h2>
        <p *ngIf="marketPlaceDetailData?.business_name">
          <i class="fa fa-user" aria-hidden="true"></i>
          {{marketPlaceDetailData?.business_name}}
        </p>
        <p *ngIf="marketPlaceDetailData?.owner_email">
          <i class="fa fa-envelope" aria-hidden="true"></i>
          {{marketPlaceDetailData?.owner_email}}
        </p>
        <p *ngIf="marketPlaceDetailData?.owner_contact_number">
          <i class="fa fa-mobile" aria-hidden="true"></i>
          {{marketPlaceDetailData?.owner_contact_number}}
        </p>
      </div>
      <!-- owner_info_content End -->
    </div>
    <div class="col-sm-4 col-md-3 col-lg-3" *ngIf="marketPlaceDetailData?.business_slug">
      <div class="owner_more">
        <a href="javascript:void()" routerLink="/home/business-detail/{{marketPlaceDetailData?.business_slug}}" [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'">
          {{'ang_viewprofile' | translate}}
          <i aria-hidden="true" class="fa fa-angle-right"></i>
        </a>
      </div>
      <!-- owner_more End -->
    </div>
    <!-- </div> -->
  </div>
  <!--row end-->
  <div class="row shadow_card owner_information response" *ngIf="allIntrestData.length > 0">
    <div class="col-sm-12">
      <h4 class="title">{{'ang_responses' | translate}}</h4>
    </div>
    <div class="col-sm-12 row-cst" *ngFor="let item of allIntrestData">
      <div class="row">
        <div class="col-sm-2 col-md-3 col-lg-3 gutter_right">
          <div class="owner_profile">
            <img src="{{item?.image_url}}">
          </div>
          <!-- owner_profile End -->
        </div>
        <div class="col-sm-8 col-md-7 col-lg-6">
          <div class="owner_info_content">
            <h5 class="">{{item?.name}} - {{dateDiff(item?.timestamp)}}</h5>
            <p>{{item?.comment}}</p>
            <a href="javascript:void()" *ngIf="item?.user_business_slug" routerLink="/home/business-detail/{{item?.user_business_slug}}" [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'">
              {{'ang_viewbusiness' | translate}}
              <i aria-hidden="true" class="fa fa-angle-right"></i>
            </a>
            <a href="javascript:void()" (click)="gotomessageDetail(item?.thread_id)" [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'">
              {{'ang_message' | translate}}
              <i aria-hidden="true" class="fa fa-angle-right"></i>
            </a>
          </div>
          <!-- owner_info_content End -->
        </div>
        <div class="col-sm-2">
          <div class="more-btn">
            <a href="javascript:void()" (click)="gotomessageDetail(item?.thread_id)">
              <i aria-hidden="true" class="fa fa-angle-right"></i>
            </a>
            <a href="javascript:void()" class="trash" (click)="deleteResponses(closeAdsPopup,item.id)">
              <i class="fa fa-trash-o" aria-hidden="true"></i>
            </a>
          </div><!-- owner_more End -->
        </div>
      </div>
    </div>
    <!-- <div class="col-sm-12 text-center">
      <a href="javascript:void()" [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'">{{'ang_viewall' | translate}}</a>
    </div> -->
    <div [ngClass]="appName == 'RYC' ? 'ryc_pagination_view' : 'mrc_pagination_view'">
      <ngb-pagination [collectionSize]="collectionSize" [(page)]="currentPage" [maxSize]="maxSize" size="sm"
        (pageChange)="getNextPageIntrestList($event)"></ngb-pagination>
    </div>
  </div><!-- owner_information End -->

</div>
<!--container end-->


<!-- interest popup -->

<ng-template #contentInterest let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h3>{{'ang_showinterest' | translate}}</h3>
    <button type="button" class="close" aria-label="Close" (click)="d('cancel')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="form-horizontal" novalidate>
      <div class="control-group">
        <div class="controls">
          <textarea placeholder="{{'ang_typemessage' | translate}}" name="message" [(ngModel)]="msgText"></textarea>
        </div>
      </div>
      <div class="profile_btn">
        <button [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" [disabled]="msgText.length === 0" (click)="d('submit')">{{'ang_submit' | translate | uppercase}}</button>
      </div>
    </form>
  </div>
</ng-template>

<!--- Video viwer in model view-->

<ng-template #contentVideo let-c="close" let-d="dismiss" >
    <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ngb-carousel *ngIf="marketPlaceDetailData?.advertisement_videos.length > 0" (activeId)="videoActiveId">
            <ng-template ngbSlide *ngFor="let video of marketPlaceDetailData?.advertisement_videos;let index = index;" (id)="index">
                <!-- <youtube-player class="video_popup" style="width: 100%;"
                    [videoId]="video.video_id"
                ></youtube-player> -->
            </ng-template>
        </ngb-carousel>
    </div>
</ng-template>

<!--- Video viwer in model view End-->

<ng-template #closeAdsPopup let-c="close" let-d="dismiss">
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="d('close')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body delete-modal">
    <p>{{'ang_response_delete_text' | translate}}</p>
  </div>
  <div class="modal-footer delete-modal-foo">
    <button type="button"  class="btn btn-light ryec_btn" (click)="d('close')">{{'ang_cancel' | translate}}</button>
    <button type="button" class="btn btn-light ryec_btn" (click)="d('yes')">{{'ang_yes' | translate}}</button>
  </div>
</ng-template>
