import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';

import { PostJsonMarketPlaceList } from 'src/app/shared/class/data.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { HomeService } from 'src/app/shared/services/home.service';

@Component({
    selector: 'ryec-market-place',
    templateUrl: './market-place.component.html',
    styleUrls: ['./market-place.component.css']

})
export class MarketPlaceComponent implements OnInit {

    modalReference: any;
    currentPage: number;
    routerLink = '';
    activeUrl = 'relevance';
    MarketPlaceList = [];
    sort_slug = '';
    center_lat = localStorage.getItem('latitude');
    center_long = localStorage.getItem('longitude');
    perPageList = environment.BUSINESS_LIST_LIMIT;
    totalCount = 0;
    collectionSize = 0;
    first = 1;
    last = 0;
    noData = false;
    maxSize = 5;
    searchText = '';
    adsType = '';
    city: any = [];
    category: any = [];
    allCities: any;
    allCategoryArr: any;
    config = {
      displayKey: 'name',
      search: true
    };
    adType = '';
    appName = environment.APP_NAME;

    constructor(
      private modalService: NgbModal,
      private activeRoute: ActivatedRoute,
      private router: Router,
      private hS: HomeService,
      private cS: CommonService
      ) {

      this.currentPage = 1;
      //const activeUrlArray = this.activeRoute.snapshot.url;

      this.activeRoute.params.subscribe(data => {
        this.routerLink = data.slug;
        // if (activeUrlArray.length > 2) {
        //   this.activeUrl = activeUrlArray[2].path;
        // }

        if (data.slug !== 'my-ads' && data.slug !== 'my-interest') {
          this.activeUrl = data.slug;
          localStorage.setItem('setActiveTab', this.activeUrl);
        }
        this.currentPage = 1;
        /*
        *Check which type of sorting happend
        */
        if (this.routerLink === 'relevance') {
          this.sort_slug = 'relevance';
        } else if (this.routerLink === 'popular') {
          this.sort_slug = 'popular';
        } else if (this.routerLink === 'near-by') {
          this.sort_slug = 'nearMe';
        } else if (this.routerLink === 'atoz') {
          this.sort_slug = 'AtoZ';
        } else if (this.routerLink === 'ztoa') {
          this.sort_slug = 'ZtoA';
        } else if (this.routerLink === 'my-ads') {
          this.sort_slug = '';
        } else if (this.routerLink === 'my-interest') {
          this.sort_slug = '';
        } else {
          this.router.navigateByUrl('/home');
        }
        //console.log('isThere',localStorage.getItem('setFilters'));
        if (localStorage.getItem('setFilters')) {
          let filterData = JSON.parse(window.atob(<any>localStorage.getItem('setFilters')));
          this.searchText = filterData['searchText'];
          this.adsType = filterData['ads_type'];
          this.city = filterData['city'];
          this.category = filterData['category_id'];
        }
        // this.checkRouterUrlForCallingAPI();
      });
    }

    ngOnInit() {
      if (this.center_lat === '' || this.center_lat === null) {
        this.hS.getNetworkLatLong();
      }
      this.cS.scrollTop();
    }

   /**
	 * Get filter data from server
	 * @param content
	 */
	filter(content: string) {
    this.hS.getAddressMaster().subscribe((res: any) => {
      this.allCities = res.data.cities;
    });
    this.hS.getMenuCategoryListing().subscribe((res:any) => {
      this.allCategoryArr = res.data;
    });
    this.modalReference = this.modalService.open(content, { windowClass: 'filter_popup' });
    this.modalReference.result.then((result: any) => {
      console.log(`Closed with: ${result}`);
    }, (reason: any) => {
      if (reason === 'apply') {
        if (this.city === undefined) {
          this.city = [];
        }
        if (this.category === undefined) {
          this.category = [];
        }
        let filterData = {
          searchText: this.searchText.trim(),
          city: this.city,
          ads_type: this.adsType,
          category_id : this.category
        }
        localStorage.setItem('setFilters', window.btoa(JSON.stringify(filterData)));
        this.checkRouterUrlForCallingAPI();
      }
    });
  }

  /**
   * Create Market place popup model
   * @param contentcreatemarket
   */
  market(contentcreatemarket: string) {
		this.modalReference = this.modalService.open(contentcreatemarket, { windowClass: 'market_popup' });
		this.modalReference.result.then((result: any) => {
			console.log(`Closed with: ${result}`);
		}, (reason: any) => {
      if (reason === 'createNewAd') {
        //console.log('reason',reason);
        localStorage.setItem('setAdType', window.btoa(JSON.stringify(this.adType)));
        this.router.navigate(['home/create-market-place']);
      }
		});
	}

  /**
   * Check route url and Get Data according to routerLink
   */
  checkRouterUrlForCallingAPI() {
    let type = '';
    //console.log('routerLink', this.routerLink);
    switch (this.routerLink) {
      case 'relevance':
      case 'popular':
      case 'near-by':
      case 'atoz':
      case 'ztoa':
        type = 'getAllAdvertisement';
        break;
      case 'my-ads':
        type = 'getMyAdvertisement';
        this.searchText = '';
        this.city = [];
        this.adsType = '';
        this.category = [];
        break;
      case 'my-interest':
        type = 'getAllInterestedAdvertisement';
        this.searchText = '';
        this.city = [];
        this.adsType = '';
        this.category = [];
        break;
    }

		const postJson: PostJsonMarketPlaceList = {
			sortBy: this.sort_slug,
      page: this.currentPage,
      latitude: <any>this.center_lat,
      longitude: <any>this.center_long,
      searchText: this.searchText.trim(),
      city: this.city.hasOwnProperty('name') ? this.city.name : '',
      ads_type: this.adsType,
      category_id: this.category.hasOwnProperty('name') ? this.category.name : ''
    };

		if (this.sort_slug === 'nearMe') {
			postJson.radius = environment.LOCATION_RADIUS;
    }

    //console.log('postJson', postJson);
    //console.log('type', type);
    if (type !== '') {
      this.hS.getMarketPlaceList(postJson, type).subscribe((res: any) => {
        console.log('getMarketPlaceList', res);
        if (res['status'] === 1) {
          this.MarketPlaceList = res['data']['ads'];
          this.totalCount = res['totalCount'];
          this.countCollectionSize();
        } else {
          this.cS.showError(res['message']);
        }
      });
    }
  }

  /**
	*Calculate pagination display on screen and count of total businesses
	*/
	countCollectionSize() {
		if (this.totalCount > this.perPageList) {
			this.collectionSize = Math.ceil(this.totalCount / this.perPageList);
			this.collectionSize = this.collectionSize * 10;
			if (this.currentPage === 1) {
				this.first = 1;
				this.last = this.perPageList;
			} else {
				this.first = (this.currentPage - 1) * this.perPageList + 1;
				if (this.MarketPlaceList.length < this.perPageList) {
					this.last = (this.currentPage - 1) * this.perPageList + this.MarketPlaceList.length;
				} else {
					this.last = (this.currentPage - 1) * this.perPageList + this.perPageList;
				}
			}
		} else {
			this.last = this.totalCount;
			this.collectionSize = this.perPageList;
		}

		if (this.totalCount === 0 || this.totalCount === undefined) {
			this.noData = true;
		} else {
			this.noData = false;
		}
		this.cS.scrollTop();
  }

  /**
   * Set Listing pagination functionality
   * @param event
   */
  getNextPageMarketPlaceList(event: number): void {
    console.log(event);
		this.MarketPlaceList = [];
		this.checkRouterUrlForCallingAPI();
  }

  /**
   * Goto Market place detail screen
   * @param adId
   */
  gotoMarketDetail(adId?: any) {
    let id = window.btoa(adId);
    this.router.navigate(['home/market-place-detail/'+ id]);
  }

  /**
   * For check spacific link tabs
   */
  checkSpecificTab(){
    console.log('call',this.activeUrl);
    this.router.navigate(['home/market-place/' + this.activeUrl]);
  }

}
