<div class="group_banner  d-flex align-items-center" style="background-image:url('assets/images/group_banner.jpg')">
  <div class="container">
    <div class="banner-content">
      <h2 class="groups_title text-white">Applicants</h2>
      <div class="search_here_view ">
        <i (click)="searchBusiness()" style="cursor: pointer;" class="fa fa-search" aria-hidden="true"></i>
        <input id="search" (keyup.enter)="searchBusiness()" [(ngModel)]="searchText" placeholder="{{ 'ang_searchhere' | translate}} " type="text" #homeSearchInput>
        <div class="auto_complete_listing" style="display: none;">
          <perfect-scrollbar>
              <ul>
                  <li class="search_input" *ngFor="let item of items" (click)="searchBusinessByCategory(item.id, item.name)">
                    {{item.name}}
                  </li>
              </ul>
          </perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Groups banner End -->
<div class="container">
  <div class="group_listing_view">
    <div class="d-flex justify-content-between align-items-end mb-3 flex-sm-row flex-column-reverse flex-sm-nowrap flex-wrap">
      <div class="total_result">
        <!-- ({{'ang_showing' | translate}} {{first}}-{{last}} {{'ang_of' | translate}} {{ totalUsersCount }} {{'ang_groups' | translate}}) -->
        ({{'ang_showing' | translate}} {{first}}-{{last}} {{'ang_of' | translate}} {{ totalUsersCount }} {{'ang_applicants' | translate}})
      </div>
      <!-- total_result End -->
      <!-- sorting -->
      <div class="sortby-select d-flex align-items-end flex-wrap justify-content-end mb-2">
        <label class="control-label select-label text-capitalize" for="name" >Sort by</label >
        <select class="form-control" (change)="onSortTypeChange()" [(ngModel)]="sortingType">
          <option class="text-capitalize" value="all">All</option>
          <option class="text-capitalize" value="shortListed">Short Listed</option>
          <option class="text-capitalize" value="rejected">Rejected</option>
          <option class="text-capitalize" value="selected">Selected</option>
        </select>
      </div>
    </div>
      <!-- sort_by_tab End -->
    <div class="group_listing_view_wrapper" *ngIf="totalUsersCount > 0">
      <!-- All Group -->
      <div class="group_listing_view" *ngIf="usersList.length > 0">
        <div class="clearfix group_listing_box" *ngFor="let item of usersList; let i = index">
          <div class="d-block d-sm-flex align-items-center">
            <div class="group_listing_img">
              <img alt="essar-real-estate" [src]="item.applicant_image" />
            </div>
           <div class="group_listing_content">
             <h2>
               <span class="text-overflow-hidden" placement="bottom">{{ item.applicant_name }}</span>
            </h2>
            <div class="group_content_left group_title_icon">
             <ul>
                <li class="text-overflow-hidden" *ngIf="item.current_desination !== '' && item.current_desination != null">
                  <i class="fa fa-bullseye" aria-hidden="true"></i>
                  {{ item.current_desination }}
                </li>
                <li class="text-overflow-hidden" *ngIf="item.current_company_name !== '' && item.current_company_name != null">
                  <i class="fa fa-building" aria-hidden="true"></i>
                  {{ item.current_company_name }}
                </li>
                <li class="text-overflow-hidden" *ngIf="item.city !== ''">
                  <i class="fa fa-map-marker" aria-hidden="true"></i>
                  {{ item.city }}, {{ item.state }}, {{ item.country }}
                </li>
                <li class="text-overflow-hidden" *ngIf="item.document_fullurl !== ''">
                  <i class="fa fa-paperclip" aria-hidden="true"></i>
                  <a [href]="item.document_fullurl" target="_blank"> Resume </a>
                </li>
                <li class="text-overflow-hidden" *ngIf="item.applied_time !== ''">
                  <i class="fa fa-clock-o" aria-hidden="true"></i>
                  {{ item.applied_time }} ago
                </li>
             </ul>
            </div>
            <div class="group_content_right">
              <button *ngIf="item.is_short_listed == 0 && item.is_selected != 1" type="button" [disabled]="isSubmit" class="ryec_btn mt-2" (click)="loadJobApplicantUserStatusModalComponent(item, 'shortList');">
                Short List
              </button>
              <button *ngIf="item.is_selected == 0" type="button" [disabled]="isSubmit" class="ryec_btn mt-2" (click)="loadJobApplicantUserStatusModalComponent(item, 'select');">
                Select
              </button>
              <button *ngIf="item.is_rejected == 0" type="button" [disabled]="isSubmit" class="ryec_btn mt-2" (click)="loadJobApplicantUserStatusModalComponent(item, 'reject');">
                Reject
              </button>
            </div>
           </div>
          </div>
        </div>
      </div>
    </div>
    <!-- group_listing_view_wrapper End -->
  </div>
  <!-- group_listing_view End -->
  <div [ngClass]="appName == 'RYC' ? 'ryc_pagination_view' : 'mrc_pagination_view'" *ngIf="totalUsersCount > 0">
    <ngb-pagination [collectionSize]="collectionSize" [(page)]="currentPage" [maxSize]="maxSize" size="sm" (pageChange)="getNextPageBusinessList()"></ngb-pagination>
  </div>
  <!-- pagination_view End -->

  <div *ngIf="noData == true" style=" text-align: center;padding: 20px;">
    <img src="../assets/images/norecordfound.png" style="height:250px;margin:20px;">
    <h3>{{'ang_nomatches' | translate}}</h3>
  </div>
</div>
<!--container end-->