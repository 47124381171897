<div class="group-edit_page py-4">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h3 class="detail-title">Job Create</h3>
        <!-- <ngb-accordion #acc="ngbAccordion" [closeOthers]="true" activeIds="ngb-panel-0"> -->
        <ngb-accordion #acc="ngbAccordion" [closeOthers]="true" [activeIds]="activeIds">
            <ngb-panel>
              <div class="panel-title">
                <ng-template ngbPanelTitle>
                  <span [ngStyle]="{'color':appName == 'RYC' ? 'var(--theme-ryc-based-secondary-color)' : 'var(--theme-mrc-based-primary-color)' }">Create Job Post <i [ngStyle]="{'color':appName == 'RYC' ? 'var(--theme-ryc-based-secondary-color)' : 'var(--theme-mrc-based-primary-color)' }" class="fa fa-caret-down"></i></span>
                </ng-template>
              </div>
              <div class="panel-body">
                <ng-template ngbPanelContent>
                  <!-- <form [formGroup]="jobForm" novalidate (ngSubmit)="onSubmit()"> -->
                  <form name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" autocomplete="off" novalidate>
                    <div class="card-body">
                      <div class="edit_form edit-general_setting">
                          <div class="control-group">
                            <div class="controls">
                              <label class="control_label">Job Title <span class="required_feild">*</span></label>
                              <p>
                                <input type="text" id="title" name="title" placeholder="Enter job title" class="form-control" required [ngClass]="{ 'state-error': (f.submitted && title.invalid) || (title.touched && title.invalid)}" [(ngModel)]="jobForm.title" #title="ngModel" />
                              </p>
                              <div *ngIf="(f.submitted && title.invalid) || (title.touched && title.invalid)" class="note">
                                <div *ngIf="title.errors?.required">Job title is required !</div>
                              </div>
                            </div>
                          </div>
                          <div class="control-group">
                            <div class="controls">
                              <label class="control_label">Company Name <span class="required_feild">*</span></label>
                              <p>
                                <input type="text" id="company_name" name="company_name" placeholder="Enter company" class="form-control" [ngClass]="{ 'state-error': (f.submitted && company_name.invalid) || (company_name.touched && company_name.invalid)}" [(ngModel)]="jobForm.company_name" #company_name="ngModel" required/>
                              </p>
                              <div *ngIf="(f.submitted && company_name.invalid) || (company_name.touched && company_name.invalid)" class="note">
                                <div *ngIf="company_name.errors?.required">Company name is required !</div>
                              </div>
                              <!-- <p class="help-block" *ngIf="jobForm.controls.company_name.touched && jobForm.controls.company_name.errors?.required">
                                Company name is required !
                              </p> -->
                            </div>
                          </div>
                          <div class="control-group">
                            <div class="controls">
                              <label class="control_label">Workplace Type <span class="required_feild">*</span></label>
                              <p class="w-100">
                                <select class="form-control" name="workplace_type" [ngClass]="{ 'state-error': (f.submitted && workplace_type.invalid) || (workplace_type.touched && workplace_type.invalid)}" [(ngModel)]="jobForm.workplace_type" #workplace_type="ngModel" required>
                                  <option [ngValue]="null">--Select--</option>
                                  <option *ngFor="let item of workplaceTypesList; let i = index" [ngValue]="item.value">{{ item.value }}</option>
                                </select>
                              </p>
                              <div *ngIf="(f.submitted && workplace_type.invalid) || (workplace_type.touched && workplace_type.invalid)" class="note">
                                <div *ngIf="workplace_type.errors?.required">Workplace is required !</div>
                              </div>
                              <!-- <p class="help-block" *ngIf="jobForm.controls.workplace_type.touched && jobForm.controls.workplace_type.errors?.required">
                                Workplace is required !
                              </p> -->
                            </div>
                          </div>
                          <div class="control-group">
                            <div class="controls location-auto-search">
                              <label class="control_label">Job Location <span class="required_feild">*</span></label>
                              <p class="w-100">
                                <!-- <input type="text" [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}" placeholder="{{'ang_pincode' | translate}}" class="input-xlarge" (input)="locationSearch($event.target.value)" required /> -->
                                <input type="text" name="searchTextVal" placeholder="{{'ang_pincode' | translate}}" class="input-xlarge" (input)="locationSearch($event.target.value)" required [ngClass]="{ 'state-error': (f.submitted && searchTextVal.invalid) || (searchTextVal.touched && searchTextVal.invalid)}" [(ngModel)]="searchText" #searchTextVal="ngModel" />
                              </p>
                              <p class="help-block" *ngIf="isLocationNotFound">
                                {{ "ang_invalidPincodeValidation" | translate }}
                              </p>
                              <div *ngIf="(f.submitted && searchTextVal.invalid) || (searchTextVal.touched && searchTextVal.invalid)" class="note">
                                <div *ngIf="searchTextVal.errors?.required">Location required !</div>
                              </div>
                              <div class="member-list" style="display: none;">
                                <perfect-scrollbar style="height: 210px;">
                                  <ul>
                                    <li class="search_input" *ngFor="let item of locationsList; let i = index;" (click)="selectlocation(item.location_id, item.district, item.state, item.country_name)">
                                      {{ item.district }} , {{ item.state }} , {{ item.country_name }}
                                    </li>
                                  </ul>
                                </perfect-scrollbar>
                              </div>
                            </div>
                          </div>
                          <div class="control-group">
                            <div class="controls">
                              <label class="control_label">Employment Type <span class="required_feild">*</span></label>
                              <p class="w-100">
                                <select class="form-control" name="employment_type" [ngClass]="{ 'state-error': (f.submitted && employment_type.invalid) || (employment_type.touched && employment_type.invalid)}" [(ngModel)]="jobForm.employment_type" #employment_type="ngModel" required>
                                  <option [ngValue]="null">--Select--</option>
                                  <option *ngFor="let item of employmentTypesList; let i = index" [ngValue]="item.value">{{ item.value }}</option>
                                </select>
                              </p>
                              <div *ngIf="(f.submitted && employment_type.invalid) || (employment_type.touched && employment_type.invalid)" class="note">
                                <div *ngIf="employment_type.errors?.required">Employment type required !</div>
                              </div>
                              <!-- <p class="help-block" *ngIf="jobForm.controls.employment_type.touched && jobForm.controls.employment_type.errors?.required">
                                Workplace is required !
                              </p> -->
                            </div>
                          </div>
                          <div class="control-group">
                            <div class="controls">
                                <label class="control_label">Description <span class="required_feild">*</span></label>
                                <p>
                                  <textarea type="text" id="description" name="description" placeholder="Enter description" class="form-control" [ngClass]="{ 'state-error': (f.submitted && description.invalid) || (description.touched && description.invalid)}" [(ngModel)]="jobForm.description" #description="ngModel" required></textarea>
                                </p>
                                <div *ngIf="(f.submitted && description.invalid) || (description.touched && description.invalid)" class="note">
                                  <div *ngIf="description.errors?.required">Description required !</div>
                                </div>
                                <!-- <p class="help-block" *ngIf="jobForm.controls.description.touched && jobForm.controls.description.errors?.required">
                                  Description is required !
                                </p> -->
                            </div>
                          </div>
                          <div class="control-group">
                            <div class="controls">
                                <label class="control_label">Skills <span class="required_feild">*</span></label>
                                <input type="hidden" name="skills" placeholder="{{'ang_pincode' | translate}}" class="input-xlarge" required [ngClass]="{ 'state-error': (f.submitted && skills.invalid) || (skills.touched && skills.invalid)}" [(ngModel)]="jobForm.skills" #skills="ngModel" />
                                <a *ngIf="selectedSkillsList.length <= 9" [ngStyle]="{'background':appName == 'RYC' ? 'var(--theme-ryc-based-secondary-color)' : 'var(--theme-mrc-based-primary-color)' }" class="add-skill-link" href="javacript:void(0);" (click)="loadSkillModal(skillComponent);"> Add Skill</a>
                                <p *ngIf="selectedSkillsList?.length">
                                  <!-- <mat-chip-list>
                                    <mat-chip *ngFor="let item of selectedSkillsList; let i = index">
                                       {{ item.value }}
                                       <i (click)='deleteRow(i)' class="fa fa-times ml-2 cursor-pointer" aria-hidden="true"></i>
                                    </mat-chip>
                                 </mat-chip-list> -->
                                </p>
                                <div *ngIf="(f.submitted && skills.invalid) || (skills.touched && skills.invalid)" class="note">
                                  <div *ngIf="skills.errors?.required">Skills required !</div>
                                </div>
                            </div>
                          </div>
                          <div class="control-group">
                            <div class="controls">
                              <label class="control_label">Degree <span class="required_feild">*</span></label>
                              <p>
                                <!-- <mat-form-field appearance="fill">
                                  <mat-label>Choose Degree</mat-label>
                                  <mat-select disableOptionCentering (change)="onEducationChange($event)" [(value)]="defaultSelectedVal" multiple>
                                    <mat-option *ngFor="let item of qualificationsList" [value]="item.value" >
                                      {{ item.value }}
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field> -->
                                <input type="hidden" name="qualification" placeholder="{{'ang_pincode' | translate}}" class="input-xlarge" required [ngClass]="{ 'state-error': (f.submitted && qualification.invalid) || (qualification.touched && qualification.invalid)}" [(ngModel)]="jobForm.qualification" #qualification="ngModel" />
                              </p>
                              <div *ngIf="(f.submitted && qualification.invalid) || (qualification.touched && qualification.invalid)" class="note">
                                <div *ngIf="qualification.errors?.required">Degree required !</div>
                              </div>
                            </div>
                          </div>
                          <div class="control-group">
                            <div class="controls">
                              <label class="control_label">Experience Needed <span class="required_feild">*</span></label>
                              <p class="w-100">
                                <input type="number" id="experience" name="experience" placeholder="Enter minimum experience in years" class="form-control" [ngClass]="{ 'state-error': (f.submitted && experience.invalid) || (experience.touched && experience.invalid)}" [(ngModel)]="jobForm.experience" #experience="ngModel" required/>
                              </p>
                              <div *ngIf="(f.submitted && experience.invalid) || (experience.touched && experience.invalid)" class="note">
                                <div *ngIf="experience.errors?.required">Degree required !</div>
                              </div>
                              <!-- <p class="help-block" *ngIf="jobForm.controls.experience.touched && jobForm.controls.experience.errors?.required">
                                Experience is required !
                              </p> -->
                            </div>
                          </div>
                          <div class="control-group">
                            <div class="controls">
                              <label class="control_label">External Link </label>
                              <p class="w-100">
                                <input type="text" id="external_link" name="external_link" placeholder="Enter your website" class="form-control" [ngClass]="{ 'state-error': (f.submitted && external_link.invalid) || (external_link.touched && external_link.invalid)}" [(ngModel)]="jobForm.external_link" #external_link="ngModel" pattern="https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)" [ngModelOptions]="{ updateOn: 'change' }"/>
                              </p>
                              <div *ngIf="(f.submitted && external_link.invalid) || (external_link.touched && external_link.invalid)" class="note">
                                <div *ngIf="external_link.errors?.required">Degree required !</div>
                                <div class="error_message" *ngIf="external_link.errors?.pattern">
                                  Invalid Link !
                               </div>
                              </div>
                              <!-- <p class="help-block" *ngIf="jobForm.controls.external_link.touched && jobForm.controls.external_link.errors?.pattern">
                                {{ "ang_external_pattern_error" | translate }}
                              </p> -->
                            </div>
                          </div>
                          <div class="control-group">
                            <div class="controls">
                              <label class="control_label">Image <span class="required_feild">*</span></label>
                              <div id="dropZoon" class="upload-area__drop-zoon drop-zoon">
                                <button *ngIf="imagePost" type="button" class="btn edit_btn">
                                    <i class="fa fa-pencil" aria-hidden="true"></i>
                                    <input type="file" id="images" name="images" class="drop-zoon__file-input" (change)="onLogoImageChange($event)" accept="image/jpg, image/jpeg, image/png" (click)="$event.target.value=null">
                                </button>
                                <span class="drop-zoon__icon">
                                    <i *ngIf="!imagePost" class="fa fa-upload" aria-hidden="true"></i>
                                    <img *ngIf="imagePost" alt="Cover" [src]="imageShow" />
                                </span>
                                <input type="file" id="logo" name="logo" class="drop-zoon__file-input" (change)="onLogoImageChange($event)" accept="image/jpg, image/jpeg, image/png" (click)="$event.target.value=null" required/>
                              </div>
                              <input type="hidden" name="image_url" class="input-xlarge" required [ngClass]="{ 'state-error': (f.submitted && image_url.invalid) || (image_url.touched && image_url.invalid)}" [(ngModel)]="jobForm.image_url" #image_url="ngModel" />
                              <div *ngIf="(f.submitted && image_url.invalid) || (image_url.touched && image_url.invalid)" class="note">
                                <div *ngIf="image_url.errors?.required">Image required !</div>
                              </div>
                            </div>
                          </div>
                        <button type="submit" [disabled]="isSubmit" class="ryec_btn ml-auto mr-0 d-block">Save</button>
                      </div>
                    </div>
                  </form>
                </ng-template>
              </div>
            </ngb-panel>
        </ngb-accordion>
      </div>
    </div>
  </div>
</div>

<!-- tag post user -->
<ng-template #skillComponent let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="text-primary">
      Add Skill
    </h4>
    <button id="closeSkillComponent" type="button" class="close" aria-label="Close" (click)="d('Cross click');">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div id="container" class="container">
      <div class="row">
        <div class="col-md-12 my-2">
          <div class="info-form">
            <form name="form" (ngSubmit)="f.form.valid && onAddSkillSubmit()" #f="ngForm" autocomplete="off" novalidate>
              <div class="row">
                <div class="col-md-12 bottom_btn mb-4" *ngIf="selectedSkillsList.length <= 9">
                   <button type="button" class="btn btn-outline-success float-right my-2" (click)="addNewRow()">
                      Add more
                   </button>
                </div>
              </div>
              <div class="form-group form-wrap" *ngFor="let item of selectedSkillsList; let i = index">
                <label class="control-label" for="url">Skill <span>*</span></label>
                <input id="skill{{ i }}" type="skill" class="form-control" [value]="item.value" [(ngModel)]="item.value" name="skill{{ i }}" placeholder="e.g. Python" #skill="ngModel" [ngClass]="{ 'state-error': (f.submitted && skill.invalid) || (skill.touched && skill.invalid)}" required />
                <div *ngIf="(f.submitted && skill.invalid) || (skill.touched && skill.invalid)" class="note">
                  <div *ngIf="skill.errors?.required">Skill is required</div>
                </div>
                <div class="bottom_btn remove-btn">
                  <button class="text-danger float-right" type="button" (click)="deleteRow(i)">
                     <i class="fa fa-trash"></i>
                  </button>
                </div>
              </div>
              <div class="form-group">
                <p class="pull-right">
                  <button [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" [disabled]="isSubmit" type="submit">
                    {{ "ang_submit" | translate | uppercase }}
                  </button>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!-- tag post user -->
