import { Component, OnInit, EventEmitter, HostListener, Output, ViewChild, ElementRef } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from "@angular/common";
import { TrendingServiceRes, marker } from 'src/app/shared/class/data.model';
import { debounceTime, map, distinctUntilChanged, filter } from "rxjs/operators";
import { fromEvent } from 'rxjs/observable/fromEvent';
import { environment } from 'src/environments/environment';
import { HomeService } from 'src/app/shared/services/home.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AuthService } from 'src/app/shared/services/auth.service';

declare var $: any;
@Component({
  selector: 'ryec-home-dashboard',
  templateUrl: './home-dashboard.component.html',
  styleUrls: ['./home-dashboard.component.css']
})

export class HomeDashboardComponent implements OnInit {
  env = environment;
  validTitle: any;
  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    if (event.srcElement.className == '') {
      if (this.appName == 'MRC') {
        $(".home_auto_complete_list-mrc").css("display", "none");
      }
      else if (this.appName == 'RYC') {
        $(".home_auto_complete_list-ryc").css("display", "none");
      }
      this.homeSearchText = '';
      this.home_search_location = 'All Locations';
    }
    if (event.srcElement.className != 'home_search_input') {
      // $(".home_auto_complete_list").css("display", "none");
      if (this.appName == 'MRC') {
        $(".home_auto_complete_list-mrc").css("display", "none");
      }
      else if (this.appName == 'RYC') {
        $(".home_auto_complete_list-ryc").css("display", "none");
      }
    }
    if (event.srcElement.name != "homeLocationDropdown" && event.srcElement.name != "homeLocation") {
      this.homeIsShow = false;
    } else {
      this.homeIsShow = true;
    }
  }
  categoriesArr = [];
  schemaJson: any;
  trendServiceArr: any;
  trendCategorieArr: any;
  promotedBusinessArr = [];
  premiumBusinessArr: any;
  investmentOpportunityArr = [];
  recentlyAddedBusinessArr: any;
  popularBusinessArr = [];
  center_lat = localStorage.getItem('latitude');
  center_long = localStorage.getItem('longitude');
  businessMarkers: marker[] = [];
  business_title = '';
  business_address = '';
  business_slug = '';
  user_lat: any;
  user_long: any;
  homeIsShow = false;
  searchText = '';
  items: any;
  home_search_location = 'All Locations';
  homeLocations = [];
  homeLocation = '';
  homeAllLocations = [];
  public repoUrl: string;
  isPostDetailPage = 0;
  homeSearchText = '';
  home_search_text = false;
  isLangaugeShow = false;
  userDetail: any;
  isSuccessfullyRegister: boolean;
  agentStatus: boolean;
  modalReference: any;
  validBusinessTitle = false;
  validName = false;
  validPhoneNo = false;
  validCity = false;
  validForm = false;
  businessType = ""
  businessForm = {
    business_name: '',
    name: '',
    phoneno: '',
    city: ''
  }
  public screenWidth: any;
  appName = environment.APP_NAME;
  newslist: any[] = [];
  trendingCategoriesLists: any[] = [];
  businessAndTopPlacesLists: any = {};
  isMobile: boolean = false;
  isDesktopDevice: boolean = false;
  deviceInfo: any;
  appDownloadUrl: string = "";

  HttpHeaderOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  @Output() emit: EventEmitter<number> = new EventEmitter();
  @Output() isHomePage = new EventEmitter();

  // properties = {
  //   title: 'Business Name : Painter',
  //   description: 'my desc',
  //   image: 'https://ryec.s3.amazonaws.com/uploads/category/category_logo/thumbnail/catgory_logo_1522302770.png',
  //   via: 'RYEC'
  // }

  @ViewChild('homeSearchInput')
  homeSearchInput!: ElementRef;
  constructor(
    private hS: HomeService,
    private modalService: NgbModal,
    private cS: CommonService,
    private httpS: HttpService,
    public router: Router,
    public activateRouter: ActivatedRoute,
    private location: Location,
    private deviceService: DeviceDetectorService,
    private auth:AuthService
  ) {
    this.repoUrl = "http://apnsservice.com";
    this.isSuccessfullyRegister = false;
    this.agentStatus = false;

    router.events.subscribe((val: any) => {
      if (this.location.path() == '/home' || this.location.path() == '') {
        this.isPostDetailPage = 0;
        console.log(val);
      }
    });
  }


  ngOnInit() {
    this.screenWidth = window.innerWidth;
    this.getnewsLists();
    this.cS.scrollTop();
    this.userDetail = this.cS.getUserDetails();
    if (this.userDetail) {
      const isRegister = this.cS.isBusinessRegister();
      const isSkipped = this.cS.isBusinessRegisterSkipped();
      this.isSuccessfullyRegister = isRegister || !isSkipped;
      const isAgentVal = this.cS.getData('isAgent');
      if (isAgentVal && isAgentVal === 'A') {
        this.agentStatus = false;
        this.isSuccessfullyRegister = isRegister;
      } else if (isAgentVal && isAgentVal === 'P') {
        this.agentStatus = true;
      } else {
        this.isSuccessfullyRegister = isRegister;
      }
    }

    this.cS.currentMessage.subscribe((message: any) => {
      console.log(message);
      this.center_lat = <any>localStorage.getItem('latitude');
      this.center_long = <any>localStorage.getItem('longitude');
      this.user_lat = localStorage.getItem('latitude');
      this.user_long = localStorage.getItem('longitude');
      // this.getNearByBusinessesList();
    });

    if (this.center_lat === '' || this.center_lat === null) {
      this.hS.getNetworkLatLong();
    }
    this.getHomeAddressDetail();
    if(this.appName=='MRC')
    {
    this.getMenuCategoryListing();
    }
    if (!this.userDetail) {
      this.getTrendingServiceListing();
      this.getTrendingCategorieListing();
      this.getRecentlyAddedBusinessListing();
      this.getPremiumBusinessListing();
    }
    let postId = this.activateRouter.snapshot.queryParams["post_id"];
    this.isPostDetailPage = (postId > 0) ? postId : 0;
    this.showHomeSlider();
    this.hideSearchAndLocationFilters();
    this.isDesktopDevice = this.deviceService.isDesktop();
    this.isMobile = this.deviceService.isMobile();
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.generateMobileAppSchema();
  }

  showHomeSlider() {
    if ($('.nearby-carousel').data("owlCarousel") !== "undefined") {
      if (window.matchMedia('(max-width: 991px)').matches) {
        this.initialHomeSlider();
      } else {
        this.destroyHomeSlider();
      }
    }
  }

  initialHomeSlider() {
    $('.nearby-carousel').addClass("owl-carousel").owlCarousel({
      loop: false,
      dots: false,
      nav: true,
      items: 2,
      margin: 0,
      navText: ["<div class='nav-btn prev-slide'><img src='assets/images/left-arrow.svg'/></div>", "<div class='nav-btn next-slide'><img src='assets/images/right-arrow.svg'/></div>"],
      responsive: {
        0: {
          items: 1,
        },
        480: {
          items: 2,
        },
        768: {
          items: 3,
        },
        1199: {
          items: 2,
        }
      }
    });
  }

  destroyHomeSlider() {
    $('.nearby-carousel').trigger("destroy.owl.carousel");
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    console.log('event', event);
    this.screenWidth = window.innerWidth;
    this.showHomeSlider();
  }
  /**
  * Get Address Detail from entered details
  *@returns void
  */
  getHomeAddressDetail() {
    this.hS.getAddressMaster().subscribe((res: any) => {
      // to be changed from cities to locations later
      this.homeLocations = res.data.cities;
      if (this.homeLocations.length > 0) {
        if (this.home_search_location == '') {
          this.home_search_location = this.homeLocations[0]['name'];
        }
      }
      this.homeAllLocations = this.homeLocations;
    }, (err: any) => {
      console.log(err);
    });
  }

  homeShowLocationDropDown() {
    if (this.homeIsShow) {
      this.homeIsShow = false;
    } else {
      this.homeIsShow = true;
    }
  }

  changeTitleEvent(event?: any) {
    console.log(event);
    if (this.businessForm.business_name === '' && this.businessForm.business_name.trim() === '') {
      this.validBusinessTitle = true;
    } else {
      this.validBusinessTitle = false;
    }

    if (this.businessForm.name === '' && this.businessForm.name.trim() === '') {
      this.validName = true;
    } else {
      this.validName = false;
    }

    if (this.businessForm.phoneno === '' && this.businessForm.phoneno.trim() === '') {
      this.validPhoneNo = true;
    } else {
      this.validPhoneNo = false;
    }
    if (this.businessForm.city === '' && this.businessForm.city.trim() === '') {
      this.validCity = true;
    } else {
      this.validCity = false;
    }

    if (this.businessForm.business_name === '' || this.businessForm.business_name.trim() === '' ||
      this.businessForm.name === '' || this.businessForm.name.trim() === '' ||
      this.businessForm.phoneno === '' || this.businessForm.phoneno.trim() === '' ||
      this.businessForm.city === '' || this.businessForm.city.trim() === ''
    ) {
      this.validForm = false;
    } else {
      this.validForm = true;
    }
  }

  /**
    *open business list add enquiry modal
    */
  addContentForBusiness(content: string, type: string) {
    this.businessForm.business_name = '';
    this.businessForm.name = '';
    this.businessForm.phoneno = '';
    this.businessForm.city = '';
    this.businessType = type;
    this.modalReference = this.modalService.open(content, { windowClass: 'enquiry_popup' });
    this.modalReference.result.then((result: any) => {
      console.log(`Closed with: ${result}`);
    }, (reason: any) => {
      console.log(reason)
    });
  }

  /**
   *add reviews and rating for business list
   */
  submitBusinessDetail() {
    const postJson = {
      business_name: this.businessForm.business_name,
      name: this.businessForm.name,
      mobile_number: this.businessForm.phoneno,
      city: this.businessForm.city,
      type: this.businessType,
    };

    const apiUrl = environment.RYEC_API_URL + 'addBusinesBrandingInquiry';

    this.httpS.post(apiUrl, postJson).subscribe((res: any) => {
      console.log(res);
      if (res.status === 1) {
        this.cS.showSuccess(res.message);
      }
      else {
        this.cS.showError(res.message);
      }
      this.modalReference.close();
    }, (err: { error: any; }) => {
      if (err.error) {
        console.log(err.error);
      }
    });
  }

  //Filter location from location list
  homeFilterLocation() {
    // this.homeLocations = [];
    // if (this.homeLocation != '') {
    //   this.homeAllLocations.filter(item => {
    //     if (item['name'].toLowerCase().indexOf(this.homeLocation.toLowerCase()) > -1) {
    //       this.homeLocations.push(item);
    //     }
    //   })
    // } else {
    //   this.homeLocations = this.homeAllLocations;
    // }
  }

  getChildLocationSearchValue(val: string) {
    this.home_search_location = val;
  }

  homeSelectLocation(homeLocation: string) {
    //this.homeSearchText = this.homeSearchText;
    // let path = window.location.pathname;
    this.home_search_text = true;
    this.home_search_location = homeLocation;
    this.homeIsShow = false;
    //console.log(this.home_search_location);
    // if (path.indexOf('/business-search') > -1) {
    // 	if (this.search_location == 'All Locations') {
    // 		this.location = '';
    // 		this.locations = this.allLocations;
    // 	}
    // 	this.searchBusiness();
    // }
  }

  homeSearchBusinessByCategory(home_auto_select_value_name: string) {
    this.homeSearchText = home_auto_select_value_name;
    this.homeSearchBusiness();
    // $(".home_auto_complete_list").css("display", "none");
    if (this.appName == 'MRC') {
      $(".home_auto_complete_list-mrc").css("display", "none");
    }
    else if (this.appName == 'RYC') {
      $(".home_auto_complete_list-ryc").css("display", "none");
    }
    this.isLangaugeShow = false;
  }

  /**
 * Search by category with autocomplete - AJAX call
 * @param event
 */
  // homeSearch(event: any) {
  //   const val = event.target.value;
  //   if (val === "" || val.length < 3) {
  //     $(".home_auto_complete_list").css("display", "none");
  //   }
  //   if (val.length >= 3) {
  //     const postJson = { searchText: val };

  //     this.hS.getSearchAutocomplete(postJson).subscribe((res: any) => {
  //       if (res.data) {
  //         console.log(res.data);
  //         if (res.data.length > 0) {
  //           $(".home_auto_complete_list").css("display", "block");
  //           this.items = res.data;
  //         } else {
  //           $(".home_auto_complete_list").css("display", "none");
  //         }
  //       }
  //     }, err => {
  //       console.log(err);
  //     });
  //   }
  // }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.homeSearh();
    this.getCategoriesLists();
    this.getBusinessAndTopPlacesLists();
  }

  /**
   * deley api calling and called once user finish typed in search text box
   * @Yuvraj 15/11/2021
   */
  homeSearh() {
    fromEvent(this.homeSearchInput.nativeElement, 'keyup').pipe(
      // get value
      map((event: any) => {
        return event.target.value;
      }),
      // if character length greater then 3
      // , filter(res => res.length >= 3)
      filter((res: string | any[]) => {
        if (res && res.length >= 3) {
          return true;
        } else {
          if (this.appName == 'MRC') {
            $(".home_auto_complete_list-mrc").css("display", "none");
          }
          else if (this.appName == 'RYC') {
            $(".home_auto_complete_list-ryc").css("display", "none");
          }
          return false;
        }
      })

      // Time in milliseconds between key events
      , debounceTime(1000)

      // If previous query is diffent from current
      , distinctUntilChanged()

      // subscription for response
    ).subscribe((postJson: any) => {
      console.log('postJson', postJson);
      this.hS.getSearchAutocomplete({ searchText: postJson }).subscribe((res: any) => {
        if (res.data) {
          if (res.data.length > 0) {
            if (this.appName == 'MRC') {
              $(".home_auto_complete_list-mrc").css("display", "block");
            }
            else if (this.appName == 'RYC') {
              $(".home_auto_complete_list-ryc").css("display", "block");
            }
            this.items = res.data;
          } else {
            if (this.appName == 'MRC') {
              $(".home_auto_complete_list-mrc").css("display", "none");
            }
            else if (this.appName == 'RYC') {
              $(".home_auto_complete_list-ryc").css("display", "none");
            }
          }
        }
      }, (err: any) => {
        console.log(err);
      });
    });
  }


  homeSearchBusiness() {
    if (this.appName == 'MRC') {
      $(".home_auto_complete_list-mrc").css("display", "none");
    }
    else if (this.appName == 'RYC') {
      $(".home_auto_complete_list-ryc").css("display", "none");
    }

    if (this.homeSearchText === "") {
      // this needs to be changed to city
      this.router.navigateByUrl('business-search?city=' + this.home_search_location + '&sort=relevance');
    } else {
      // this needs to be changed to city
      this.router.navigateByUrl('business-search?q=' + this.homeSearchText + '&city=' + this.home_search_location + '&sort=relevance');
    }

  }

  generateMobileAppSchema() {
    this.getDevicePlatform();
    if (this.appName == 'RYC') { // if app is MRC
      this.schemaJson = {
        "@context": "https://schema.org",
        "@type": "SoftwareApplication",
        "name": "Rajputs Club",
        "url": this.appDownloadUrl,
        "description": "राजपूत समाज के शिक्षा, रोजगार,व्यपार एवं इतिहास को सुदृढ़ प्लेटफार्म राजपुत कल्ब",
        "operatingSystem": this.deviceInfo.device,
        "applicationCategory": "BUSINESS",
        "image": "https://play-lh.googleusercontent.com/194iOS9gyYd2ihqn7rFNBdtiPVTvT2odYyxlJOt6d7FQwxDyuoVR0A-NtfzMIn5mLg",
        "contentRating": "Rated for 12+",
        "author": {
          "@type": "Person",
          "name": "RC Club",
          "url": "https://www.rajputsclub.com"
        },
        "offers": [{
          "@type": "Offer",
          "price": "0",
          "priceCurrency": "INR",
          "availability": "https://schema.org/InStock"
        }]
      };
    }
    if (this.appName == 'MRC') { // if MyRajasthanClub
      this.schemaJson = {
        "@context": "https://schema.org",
        "@type": "SoftwareApplication",
        "name": "MyRajasthan Club",
        "url": this.appDownloadUrl,
        "description": "MyRajasthan Club is a business listing local search engine Rajasthani Community",
        "operatingSystem": this.deviceInfo.device,
        "applicationCategory": "TOOLS",
        "image": "https://play-lh.googleusercontent.com/46HVV_Bnl-EANZaMc71RfAOZxu38Ho5TiVSQehKOM_3DrIvAni9DK9ghrkjS1OStXlQ",
        "contentRating": "Rated for 3+",
        "author": {
          "@type": "Person",
          "name": "RC Club",
          "url": "https://www.myrajasthanclub.com"
        },
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.9259257316589355",
          "ratingCount": "56"
        },
        "offers": [
          {
            "@type": "Offer",
            "price": "0",
            "priceCurrency": "INR",
            "availability": "https://schema.org/InStock"
          }
        ]
      };
    }
  }

  getDevicePlatform() {
    if (this.isMobile) {
      if (this.deviceInfo.device == 'iPhone') {
        if (this.appName == 'RYC') { //if RajputsClub
          this.appDownloadUrl = "https://apps.apple.com/in/app/rajput-club/id1660935382";
        }
        else { //else MyRajasthanClub
          this.appDownloadUrl = "https://apps.apple.com/in/app/myrajasthan-club/id1545374835";
        }
      }
      if (this.deviceInfo.device == 'Android') {
        if (this.appName == 'RYC') { //if RajputsClub
          this.appDownloadUrl = "https://play.google.com/store/apps/details?id=com.rajputsclub";
        }
        else { // else MyRajasthanClub
          this.appDownloadUrl = "https://play.google.com/store/apps/details?id=club.myrajasthan";
        }
      }
    }
    if (this.isDesktopDevice) {
      if (this.appName == 'RYC') { //if RajputsClub
        this.appDownloadUrl = "https://play.google.com/store/apps/details?id=com.rajputsclub";
      }
      else { // else MyRajasthanClub
        this.appDownloadUrl = "https://play.google.com/store/apps/details?id=club.myrajasthan";
      }
    }
  }

  /**
  * Get ALl Categories Menu listing
  * @returns void
  */

  getMenuCategoryListing(): void {
    if (this.hS.menuCategories.data.length <= 0) {
      this.hS.getMenuCategoryListing().subscribe((res: { data: any; }) => {
        this.categoriesArr = <any>res.data;
      }, (err: any) => {
        console.log(err);
      });
    }
   else {
      this.categoriesArr = <any>this.hS.menuCategories.data;
    }
  }



  /**
  * Get Trending service listing
  * @returns void
  */
  getTrendingServiceListing(): void {
    if (this.hS.trendingServicesList.data.length <= 0) {
      const limit = 6;
      this.hS.getTrendingServiceListing(limit).subscribe((res: TrendingServiceRes) => {
        this.trendServiceArr = <any>res.data;
      }, (err: any) => {
        console.log(err);
      });
    } else {
      this.trendServiceArr = <any>this.hS.trendingServicesList.data;
    }
  }
  /**
  * Get Trending Category listing
  * @returns void
  */
  getTrendingCategorieListing(): void {
    if (this.hS.trendingCategoryList.data.length <= 0) {
      const limit = 6;
      this.hS.getTrendingCategorieListing(limit).subscribe((res: TrendingServiceRes) => {
        this.trendCategorieArr = <any>res.data;
      }, (err: any) => {
        console.log(err);
      });
    } else {
      this.trendCategorieArr = <any>this.hS.trendingCategoryList.data;
    }
  }

  getCategoriesLists() {
    this.hS.getCategoriesLists().subscribe((res: any) => {
      this.trendingCategoriesLists = res.data;
    }, (err: any) => {
      console.log(err);
    });
    this.cS.scrollTop();
  }

  getnewsLists() {
    if (this.checkAuth())
     {
    if (this.appName == 'RYC') {
      this.hS.getnewsLists().subscribe((res: any) => {
        this.newslist = res.data;
      }, (err: any) => {
        console.log(err);
      });
      this.cS.scrollTop();
    }
  }
}


checkAuth()
 {
   if (this.cS.getUserDetails()) 
   {
    return true;
   }
     return false;
 }

  getBusinessAndTopPlacesLists() {
    const postJson = { entity_types: ['business', 'place'] };
    this.hS.getBusinessAndTopPlacesLists(postJson).subscribe((res: any) => {
      if (res.data) {
        this.updateBusinessUrls(res.data);
        this.updateTopPlacesUrls(res.data);
        this.updateTopPlaceDescription(res.data);
        this.updateSponserBusinessDescription(res.data);
      }
      this.businessAndTopPlacesLists = res.data;
    }, (err: any) => {
      console.log(err);
    });
    this.cS.scrollTop();
  }

  updateBusinessUrls(responseData: any) {
    responseData.business.forEach((element: any) => {
      if (element.entity_type) {
        if (element.entity_type == "Business") {
          element.entity_type = '/home/business-detail/';
        }
        if (element.entity_type == "Place") {
          element.entity_type = '/place/';
        }
        if (element.entity_type == "Person") {
          element.entity_type = '/person/';
        }
        if (element.entity_type == "News") {
          element.entity_type = '/news/';
        }
      }
    });
  }

  updateTopPlacesUrls(responseData: any) {
    responseData.place.forEach((element: any) => {
      if (element.entity_type) {
        if (element.entity_type == "Business") {
          element.entity_type = '/home/business-detail/';
        }
        if (element.entity_type == "Place") {
          element.entity_type = '/place/';
        }
        if (element.entity_type == "Person") {
          element.entity_type = '/person/';
        }
      }
    });
  }

  updateSponserBusinessDescription(responseData: any) {
    responseData.business.forEach((element: any) => {
      if (element.description != null) {
        element.description = new DOMParser().parseFromString(element.description, "text/html").documentElement.textContent;
      }
      else {
        element.description = "";
      }
    });
  }
  updateTopPlaceDescription(responseData: any) {
    responseData.place.forEach((element: any) => {
      if (element.description != null) {
        element.description = new DOMParser().parseFromString(element.description, "text/html").documentElement.textContent;
      }
      else {
        element.description = "";
      }
    });
  }


  /**
  * Get Recently Added Business listing
  * @returns void
  */
  getRecentlyAddedBusinessListing(): void {
    if (this.hS.trendingCategoryList.data.length <= 0) {
      const postJson = {
        limit: 4
      };

      this.hS.getRecentlyAddedBusinessListing(postJson).subscribe((res: any) => {
        this.recentlyAddedBusinessArr = res.data;
      }, (err: any) => {
        console.log(err);
      });
    } else {
      this.recentlyAddedBusinessArr = <any>this.hS.recentlyAddedBusinessList.data;
    }
  }

  /**
  * Get Promoted Business listing
  * @returns void
  */
  getPromotedBusinessListing() {
    if (this.hS.promotedBusinessList.data.length <= 0) {

      const postJson = {
        limit: 4
      };

      this.hS.getPromotedBusinessListing(postJson).subscribe((res: any) => {
        this.promotedBusinessArr = res.data;
      }, (err: any) => {
        console.log(err);
      });
    } else {
      this.promotedBusinessArr = <any>this.hS.promotedBusinessList.data;
    }
  }

  /**
  * Get Investment Opportunities Listing
  * @returns void
  */
  getInvestmentOpportunityList() {
    if (this.hS.investmentOpportunityList.data.length <= 0) {
      const postJson = {
        limit: 4
      };

      this.hS.getInvestmentOpportunityList(postJson).subscribe((res: any) => {
        this.investmentOpportunityArr = res.data;
      }, (err: any) => {
        console.log(err);
      });
    } else {
      this.investmentOpportunityArr = <any>this.hS.investmentOpportunityList.data;
    }
  }

  /**
  * Get Popular Business Listing
  * @returns void
  */
  getPopularBusinessList(): void {
    if (this.hS.popularBusinessList.data.length <= 0) {
      const postJson = {
        limit: 4
      };

      this.hS.getPopularBusinessList(postJson).subscribe((res: any) => {
        this.popularBusinessArr = res.data;
      }, (err: any) => {
        console.log(err);
      });
    } else {
      this.popularBusinessArr = <any>this.hS.popularBusinessList.data;
    }
  }

  /**
  * Get Near by Business Listing
  * @returns void
  */
  getNearByBusinessesList(): void {
    const postJson = {
      radius: environment.LOCATION_RADIUS,
      latitude: this.center_lat,
      longitude: this.center_long,
      sortBy: 'nearMe'
    };

    this.hS.getNearByBusinessesList(postJson).subscribe((res: any) => {
      for (const x in res.data) {
        if (res.data.hasOwnProperty(x)) {
          this.businessMarkers.push({
            latitude: res.data[x]['latitude'],
            longitude: res.data[x]['longitude'],
            title: res.data[x]['name'],
            address: res.data[x]['address'],
            business_slug: res.data[x]['business_slug']
          });
        }
      }
    }, (err: any) => {
      console.log(err);
    });
  }

  /**
  * open map click
  */
  clicked(markerEvent: any, pos: any) {
    this.business_title = pos.title;
    this.business_address = pos.address;
    this.business_slug = pos.business_slug;
    let marker = markerEvent.target;
    marker.nguiMapComponent.openInfoWindow('ibn', marker);
  }

  saveParentCategory(item: any) {
    const obj = {
      category_slug: item['category_slug'],
      category_id: item['service_id'],
      name: item['category_name']
    };
    this.cS.parentCategoryList = [];
    this.cS.setParentCategory(obj);
  }

  saveParentCategories(item: any) {
    const obj = {
      category_slug: item['category_slug'],
      category_id: item['category_id'],

      name: item['name']
    };
    this.cS.parentCategoryList = [];
    this.cS.setParentCategory(obj);
  }

  showCategory(obj: any) {
    return (obj['isBusiness'] === 1) ? false : true;
  }

  /**
  * Get Premium  Business listing
  * @returns void
  */
  getPremiumBusinessListing() {
    if (this.hS.premiumBusinessList.data.length <= 0) {

      const postJson = {
        limit: 4
      };

      this.hS.getPremiumBusinessListing(postJson).subscribe((res: any) => {
        this.premiumBusinessArr = res.data;
      }, (err: any) => {
        console.log(err);
      });
    } else {
      this.premiumBusinessArr = <any>this.hS.premiumBusinessList.data;
    }
  }

  /** Landing Page Code **/


  memebersList = [
    {
      owner_name: 'Jadeja Dileepsinh Dolubha',
      bussiness_name: 'Deep Projects',
      description: 'Fabrication & Labour Supplier in Gujarat, India',
      image: '../../../assets/images/owner/Jadeja_Dileepsinh_Dolubha.jpeg'
    },
    {
      owner_name: 'Hemendrasinh Vaghela',
      bussiness_name: 'MPH Precision Castings LTD',
      description: 'Investment Castings in Indrad Gam, Ahmedabad',
      image: '../../../assets/images/owner/Hemendrasinh_Vaghela.jpg'
    },
    {
      owner_name: 'Jaiveersinh Chudasama',
      bussiness_name: 'Gallops Food Plaza',
      description: 'Highway Restaurant in Ahmedabad,India',
      image: '../../../assets/images/owner/Jayvirsinh_Zala.jpg'
    },
    {
      owner_name: 'Jaydipsinhji B Zala',
      bussiness_name: 'Rudra Tours & Travel',
      description: 'Tours & Travels in Surendranagar, India',
      image: '../../../assets/images/owner/Jaydipsinhji_B_Zala.jpg'
    },
    {
      owner_name: 'Jayvirsinh Zala',
      bussiness_name: 'Zala Construction',
      description: 'Civil Engineer & Contractor in Ankleshwar, India',
      image: '../../../assets/images/owner/Jayvirsinh_Zala.jpg'
    },
    {
      owner_name: 'Karandevsinh K Jadeja',
      bussiness_name: 'Mansi Sea Food',
      description: 'SeaFood Distributors in Kutch, India',
      image: '../../../assets/images/owner/Karandevsinh_K_Jadeja.jpg'
    },
    {
      owner_name: 'Kuljeetsinh B Jhala',
      bussiness_name: 'B G Jhala Consultants',
      description: 'Tax Consultant in Kutch, India',
      image: '../../../assets/images/owner/Kuljeetsinh_B_Jhala.jpg'
    },
    {
      owner_name: 'Manoharsinh Jadeja',
      bussiness_name: 'Riddhi Logistics',
      description: 'Body Dumper Truck Service Provider in Kutch, India',
      image: '../../../assets/images/owner/Manoharsinh_Jadeja.jpeg'
    },
    {
      owner_name: 'Rajdeepsinh M Jadeja (Raja)',
      bussiness_name: 'Subham Land Developers',
      description: 'Real Estate in Rajkot, India',
      image: '../../../assets/images/owner/Rajdeepsinh_M_Jadeja.jpeg'
    },
    {
      owner_name: 'Vaghela Narpatsinh',
      bussiness_name: 'Bhal Enterprises',
      description: 'Tours & Travel Services in Gandhidham, India',
      image: '../../../assets/images/owner/Vaghela_Narpatsinh.jpg'
    },
    {
      owner_name: 'Virendrasinh Kiransinh Rana',
      bussiness_name: 'Designer view',
      description: 'Architectural Design & Interior Design in Ankleshwar, India',
      image: '../../../assets/images/owner/Virendrasinh_Kiransinh_Rana.jpg'
    },
    {
      owner_name: 'Shripalsinh Narendrasinh Vaghela',
      bussiness_name: 'Hotel Heritage',
      description: 'Hotel for Business & Leisure Travellers in Ahmedabad, India',
      image: '../../../assets/images/owner/Shripalsinh_Narendrasinh_Vaghela.jpg'
    },
  ];

  getLifeTimeMemberList() {
    const apiUrl = environment.RYEC_API_URL + 'getLifetimeMembers';

    this.httpS.post(apiUrl, {}).subscribe((res: any) => {
      if (res.status === 1) {
        this.memebersList = res.data['businesses'];
      }
    }, (err: { error: any; }) => {
      if (err.error) {
        console.log(err.error);
      }
    });
  }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }


  hideSearchAndLocationFilters() {
    if ($(".right_header")) {
      // $(".right_header").css("display", "none");
    }
  }

}
