import { DatePipe, UpperCasePipe } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './features/auth/login/login.component';
import { RyecHttpInterceptor } from './shared/class/ryec-http-interceptor';
import { AuthGuard } from './shared/guard/auth.guard';
import { SharedModule } from './shared/module/shared/shared.module';
import { ApiResolver } from './shared/resolver/api-resolver.resolver';
import { AuthService } from './shared/services/auth.service';
import { CommonService } from './shared/services/common.service';
import { HttpService } from './shared/services/http.service';
import { MessageService } from './shared/services/message.service';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Router, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { UserModule } from './features/user/user.module';
import { AddMemberAgentComponent } from './features/auth/header/modal/add-member-agent/add-member-agent.component';
import { BecomeAgentComponent } from './features/auth/header/modal/become-agent/become-agent.component';
import { RegisterMemberComponent } from './features/auth/header/modal/register-member/register-member.component';
import { VerifyMemberComponent } from './features/auth/header/modal/verify-member/verify-member.component';
import { HeaderComponent } from './features/auth/header/header.component';
import { FooterComponent } from './features/auth/footer/footer.component';
import { HeaderSimpleComponent } from './features/auth/header-simple/header-simple.component';
import { HomeService } from './shared/services/home.service';
import { PlasmaDonorComponent } from './features/auth/plasma-donor/plasma-donor.component';
import { TemplateDetailComponent } from './features/auth/template-detail/template-detail.component';
import { NotFoundPageComponent } from './features/auth/not-found-page/not-found-page.component';
import { HomeModule } from './features/home/home.module';
import { SpinnerComponent } from './features/auth/spinner/spinner.component';
import { NotificationsListComponent } from './features/auth/notifications-list/notifications-list.component';
import { MessagesComponent } from './features/auth/messages/messages.component';
//import { NewsDetailsComponent } from './features/auth/news-details/news-details.component';
import { CommentModalComponent } from './shared/component/comment-modal/comment-modal.component';

import { ReportBusinessModalComponent } from './shared/component/report-business-modal/report-business-modal.component';
import { CityAutoCompleteSearchComponent } from './shared/component/city-auto-complete-search/city-auto-complete-search.component';
import { PlaceDetailsComponent } from './features/place-details/place-details.component';
import { OwlModule } from 'ngx-owl-carousel';
import { VideosDetailsComponent } from './features/videos-details/videos-details.component';
import { EntityListingModule } from './features/person/entity-listing.module';
import { SendEnquiryMessageModalComponent } from './shared/component/send-enquiry-message-modal/send-enquiry-message-modal.component';
import { JobApplicantUsersListingComponent } from './features/job/group-jobs-listing/job-applicant-users-listing/job-applicant-users-listing.component';
import { JobApplicantsStatusModalComponent } from './shared/component/job-applicants-status-modal/job-applicants-status-modal.component';
import { GroupPostTaggedUsersListComponent } from './shared/component/group-post-tagged-users-list/group-post-tagged-users-list.component';
import { GroupPostRejectModalComponent } from './shared/component/group-post-reject-modal/group-post-reject-modal.component';
import { GroupPostPendingRequestComponent } from './shared/component/group-post-pending-request/group-post-pending-request.component';
import { GroupPostLikedSharedTaggedUsersListModalComponent } from './shared/component/group-post-liked-shared-tagged-users-list-modal/group-post-liked-shared-tagged-users-list-modal.component';
import { GroupPendingRequestComponent } from './shared/component/group-pending-request/group-pending-request.component';
import { GroupMemberDesignationRolesModalComponent } from './shared/component/group-member-designation-roles-modal/group-member-designation-roles-modal.component';
import { GroupLatterListModalComponent } from './shared/component/group-latter-list-modal/group-latter-list-modal.component';
import { GroupJoinVerificationModalComponent } from './shared/component/group-join-verification-modal/group-join-verification-modal.component';
import { CategorieModule } from './features/categorie/categorie.module';
import { PaymentRoutingModule } from './features/auth/payment/payment-routing.module';
import { PaymentModule } from './features/auth/payment/payment.module';
import { EventAttendieUsersComponent } from './shared/component/event-attendie-users/event-attendie-users.component';
import { GroupDeleteConfirmationModalComponent } from './shared/component/group-delete-confirmation-modal/group-delete-confirmation-modal.component';
import { GroupMemberRemoveConfirmationModalComponent } from './shared/component/group-member-remove-confirmation-modal/group-member-remove-confirmation-modal.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ToastrModule } from 'ngx-toastr';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxJsonLdModule } from '@ngx-lite/json-ld';
import { GroupEditComponent } from './features/group-edit/group-edit.component';
import { NewslistComponent } from './features/auth/newslist/newslist.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    AddMemberAgentComponent,
    BecomeAgentComponent,
    RegisterMemberComponent,
    VerifyMemberComponent,
    HeaderSimpleComponent,
    GroupPendingRequestComponent,
    GroupPostLikedSharedTaggedUsersListModalComponent,
    EventAttendieUsersComponent,
    GroupDeleteConfirmationModalComponent,
    GroupMemberRemoveConfirmationModalComponent,
    PlasmaDonorComponent,
    TemplateDetailComponent,
    SpinnerComponent,
    NotFoundPageComponent,
    NotificationsListComponent,
    NewslistComponent,
    MessagesComponent,
 //   NewsDetailsComponent,
    CommentModalComponent,
    ReportBusinessModalComponent,
    CityAutoCompleteSearchComponent,
    PlaceDetailsComponent,
    VideosDetailsComponent,
    SendEnquiryMessageModalComponent,
    JobApplicantsStatusModalComponent,
    GroupPostTaggedUsersListComponent,
    GroupPostRejectModalComponent,
    GroupPostPendingRequestComponent,
    //GroupMemberIdCardComponent,
    GroupMemberDesignationRolesModalComponent,
    GroupLatterListModalComponent,
    GroupJoinVerificationModalComponent,
    GroupPostLikedSharedTaggedUsersListModalComponent,
    GroupEditComponent

    
  ],

  imports: [
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    HomeModule,
    
    SharedModule,
    PerfectScrollbarModule,
    UserModule,
    OwlModule,
    AppRoutingModule,
    FormsModule,
    RouterModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    NgbModule,
    CarouselModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgxJsonLdModule
  ],
  providers: [
    HttpService,
    AuthService,
    CommonService,
    AuthGuard,
    UpperCasePipe,
    ApiResolver,
    HomeService,
    MessageService,
    NgbActiveModal,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RyecHttpInterceptor,
      multi: true,
    },

  ],
  bootstrap: [AppComponent],
  exports: [RouterModule]
})
export class AppModule { }
