<div class="modal-header">
  <!-- <h4 class="text-primary">{{ "ang_writeyourenquiry" | translate }}</h4> -->
  <h4 class="text-primary" *ngIf="jobName">Apply for {{ jobName }} </h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')" >
    <span aria-hidden="true">&times;</span>
  </button>
</div>


<div class="modal-body">
  <div id="container" class="container">
    <div class="row">
      <div class="col-md-12 my-2">
        <div class="info-form">
          <form name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" autocomplete="off" novalidate>
            <div class="form-group">
              <label for="email">Email Address <span>*</span></label>
              <input type="email" class="form-control" id="email" name="email" placeholder="Please enter email.." required [ngClass]="{ 'state-error': (f.submitted && email.invalid) || (email.touched && email.invalid)}" [(ngModel)]="jobApplyModel.email" #email="ngModel" />
              <div *ngIf="(f.submitted && email.invalid) || (email.touched && email.invalid)" class="note">
                <div *ngIf="email.errors?.required">Email is required</div>
              </div>
            </div>
            <div class="form-group" >
              <label for="mobileNo">Mobile No <span>*</span></label>
              <input minlength="6" maxlength="13" type="tel" class="form-control" id="mobileNo" name="mobileNo" placeholder="Please enter mobile no" [ngClass]="{ 'state-error': (f.submitted && mobileNo.invalid) || (mobileNo.touched && mobileNo.invalid)}" [(ngModel)]="jobApplyModel.mobileNo" #mobileNo="ngModel" (keypress)="numberOnly($event)" />
              <div *ngIf="(f.submitted && mobileNo.invalid) || (mobileNo.touched && mobileNo.invalid)" class="note" required>
                <div *ngIf="mobileNo.errors?.required">Mobile is required</div>
                <div *ngIf="mobileNo.errors?.minlength">Please enter valid Mobile Number</div>
                <div *ngIf="mobileNo.errors?.maxlength">Please enter valid Mobile Number</div>
              </div>
            </div>
            <div class="form-group" >
              <label for="experience">Your Experience (In Year) <span>*</span></label>
              <input type="number" class="form-control" id="experience" name="experience" placeholder="Please enter experience" [ngClass]="{ 'state-error': (f.submitted && experience.invalid) || (experience.touched && experience.invalid)}" [(ngModel)]="jobApplyModel.experience" #experience="ngModel" required />
              <div *ngIf="(f.submitted && experience.invalid) || (experience.touched && experience.invalid)" class="note">
                <div *ngIf="experience.errors?.required">Experience is required</div>
              </div>
            </div>
            <div class="form-group" >
              <label for="mobileNo">Resume <span>*</span></label>
              <div>
                <span>Be sure to include an updated resume</span>
              </div>
              <div _ngcontent-c10="" class="">
                <h1 _ngcontent-c10="">
                  <i _ngcontent-c10="" class="fa fa-file-word-o text-primary"></i>
                  <i _ngcontent-c10="" class="fa fa-file-excel-o text-success"></i>
                  <i _ngcontent-c10="" class="fa fa-file-powerpoint-o text-danger"></i>
                  <i _ngcontent-c10="" class="fa fa-file-pdf-o text-danger"></i>
                </h1>
              </div>
              <div class="custom-file">
                <input type="file" class="custom-file-input" name="documentFile" id="documentFile" accept=".xlsx,.xls/*,.doc, .docx,.ppt, .pptx,.txt,.pdf" (change)="onFileChange($event)" required />
                <label class="custom-file-label" for="file" (change)="onFileChange($event)">
                  Choose file
                </label>
                <input type="hidden" class="custom-file-input" name="documentFile" id="documentFile" accept=".xlsx,.xls/*,.doc, .docx,.ppt, .pptx,.txt,.pdf" required [ngClass]="{ 'state-error': (f.submitted && documentFile.invalid) || (documentFile.touched && documentFile.invalid)}" [(ngModel)]="jobApplyModel.documentFile" #documentFile="ngModel" />
                <div *ngIf="attachedFileName != ''" class="text-secondary pull-left my-2">
                  {{ attachedFileName }}
                </div>
                <div *ngIf="(f.submitted && documentFile.invalid) || (documentFile.touched && documentFile.invalid)" class="note">
                  <div *ngIf="documentFile.errors?.required">Document is required</div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <p class="pull-right">
                <button [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" [disabled]="isSubmit" type="submit">{{ "ang_submit" | translate | uppercase }}</button>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>