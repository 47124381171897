import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MessagesComponent } from './features/auth/messages/messages.component';
import { NewsDetailsComponent } from './features/auth/news-details/news-details.component';
import { NewslistComponent } from './features/auth/newslist/newslist.component';
import { NotFoundPageComponent } from './features/auth/not-found-page/not-found-page.component';
import { NotificationsListComponent } from './features/auth/notifications-list/notifications-list.component';
import { PlasmaDonorComponent } from './features/auth/plasma-donor/plasma-donor.component';
import { TemplateDetailComponent } from './features/auth/template-detail/template-detail.component';
import { EventCreateComponent } from './features/event-listing/event-create/event-create.component';
import { EventDetailsComponent } from './features/event-listing/event-details/event-details.component';
import { EventEditComponent } from './features/event-listing/event-edit/event-edit.component';
import { EventListingComponent } from './features/event-listing/event-listing.component';
import { GroupEditComponent } from './features/group-edit/group-edit.component';
import { GroupJobDetailsComponent } from './features/job/group-jobs-listing/group-job-details/group-job-details.component';
import { GroupJobEditComponent } from './features/job/group-jobs-listing/group-job-edit/group-job-edit.component';
import { GroupJobsCreateComponent } from './features/job/group-jobs-listing/group-jobs-create/group-jobs-create.component';
import { GroupJobsListingComponent } from './features/job/group-jobs-listing/group-jobs-listing.component';
import { JobApplicantUsersListingComponent } from './features/job/group-jobs-listing/job-applicant-users-listing/job-applicant-users-listing.component';
import { EntityListingComponent } from './features/person/entity-listing.component';
import { PlaceDetailsComponent } from './features/place-details/place-details.component';
import { AuthGuard } from './shared/guard/auth.guard';


const routes: Routes = [
  // { path: 'login', loadChildren: () => import('../app/features/auth/login/login.module').then(m => m.LoginModule) },
  { path: 'login', loadChildren: () => import('./features/auth/login/login.module').then(m => m.LoginModule) },
  { path: 'signup', loadChildren: () => import('./features/auth/signup/signup.module').then(m => m.SignupModule) },
  { path: 'user', loadChildren: () => import('./features/user/user.module').then(m => m.UserModule) },
  { path: 'home', loadChildren: () => import('./features/home/home.module').then(m => m.HomeModule) },
  { path: 'Coomingsoon', loadChildren: () => import('./features/auth/coomingsoon/coomingsoon.module').then(m => m.CoomingsoonModule) },
  { path: 'landing', redirectTo: 'landing', pathMatch: 'full' },
  { path: 'landing', loadChildren: () => import('./features/auth/landing/landing.module').then(m => m.LandingModule) },
  { path: 'success', loadChildren: () => import('./features/auth/success/success.module').then(m => m.SuccessModule) },
  { path: 'about', loadChildren: () => import('./features/auth/about/about.module').then(m => m.AboutModule) },
  { path: 'faqs', loadChildren: () => import('./features/auth/faqs/faqs.module').then(m => m.FaqsModule) },
  { path: 'terms-conditions', loadChildren: () => import('./features/auth/term-conditions/term-conditions.module').then(m => m.TermConditionsModule) },
  { path: 'refund-policy', loadChildren: () => import('./features/auth/refund-policy/refund-policy.module').then(m => m.RefundPolicyModule) },
  { path: 'groups', loadChildren: () => import('./features/group/group.module').then(m => m.GroupModule) },
  { path: 'how-it-work', loadChildren: () => import('./features/auth/how-it-work/how-it-work.module').then(m => m.HowItWorkModule) },
  { path: 'MembershipPlan', loadChildren: () => import('./features/auth/membership-plan/membership-plan.module').then(m => m.MembershipPlanModule) },
  { path: 'plasma-donor', component: PlasmaDonorComponent, data: { title: environment.TITLE + ' - Plasma Donor' } },
  { path: 'contact', loadChildren: () => import('./features/auth/contact/contact.module').then(m => m.ContactModule) },
  { path: 'privacy-policy', loadChildren: () => import('./features/auth/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule) },
  { path: 'payment-detail', loadChildren: () => import('./features/auth/payment/payment.module').then(m => m.PaymentModule) },
  { path: 'template/detail/:slug', component: TemplateDetailComponent, data: { title: environment.TITLE + ' -  Template Detail' } },
  { path: 'atoz', loadChildren: () => import('./features/auth/azcategories/azcategories.module').then(m => m.AZcategoriesModule) },

  { path: '404', component: NotFoundPageComponent },
  { path: 'notifications-list', component: NotificationsListComponent, data: { title: environment.TITLE + ' -  Template Detail' } },
  { path: 'trending', loadChildren: () => import('./features/trending-categories/trending-categories.module').then(m => m.TrendingCategoriesModule) },

  { path: 'plasma-donor', component: PlasmaDonorComponent, data: { title: environment.TITLE + ' - Plasma Donor' } },
  { path: 'job', loadChildren: () => import('./features/job/group-jobs-listing/group-jobs-listing.module').then(m => m.GroupJobsListingModule) },

  {
    path: 'job/create',
    component: GroupJobsCreateComponent,
    data: { title: 'Job Create' },
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
  {
    path: 'jobs/:slug', component: GroupJobsListingComponent, data: { title: 'Jobs Listing' },
    canActivate: [AuthGuard], canLoad: [AuthGuard], canActivateChild: [AuthGuard]
  },
  {
    path: 'group/edit/:slug',
    component: GroupEditComponent,
    data: { title: 'Group Edit' },
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'job/create/:slug', component: GroupJobsCreateComponent, data: { title: 'Job Create' },
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'job/edit/:slug', component: GroupJobEditComponent, data: { title: 'Job Edit' },
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'job/details/:slug', component: GroupJobDetailsComponent, data: { title: 'Job Details' },
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'job/applicant/:slug', component: JobApplicantUsersListingComponent, data: { title: 'Job Applicants' },
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  { path: 'events/:slug', loadChildren: () => import('./features/event-listing/event-listing.module').then(m => m.EventListingModule) },

  {
    path: 'events/:slug',
    component: EventListingComponent,
    data: { title: 'Event List' },
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'event/create/:slug',
    component: EventCreateComponent,
    data: { title: 'Event Create' },
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'event/edit/:slug',
    component: EventEditComponent,
    data: { title: 'Event Edit' },
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'event/details/:slug',
    component: EventDetailsComponent,
    data: { title: 'Event details' },
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
    canActivateChild: [AuthGuard],
  },


  // {
  //   path: 'news/:slug',
  //   component: NewsDetailsComponent,
  //   data: { title: 'Event details' },
  //   canActivate: [AuthGuard],
  //   canLoad: [AuthGuard],
  //   canActivateChild: [AuthGuard],
  // },
  {
    path: 'place/:slug',
    component: PlaceDetailsComponent,
    data: { title: 'Person details' }
  },
  {
    path: 'person/:slug',
    component: PlaceDetailsComponent,
    data: { title: 'Person details' }
  },
  {
    path: 'business/:slug',
    component: PlaceDetailsComponent,
    data: { title: 'Business details' }
  },
  {
    path: 'history/:slug',
    component: PlaceDetailsComponent,
    data: { title: 'History details' }
  },
  {
    path: 'places',
    component: EntityListingComponent,
    data: { title: 'Place Listing' }
  },
  {
    path: 'persons',
    component: EntityListingComponent,
    data: { title: 'Person Listing' }
  },
  {
    path: 'historys',
    component: EntityListingComponent,
    data: { title: 'Historys Listing' }
  },
  {
    path: 'events/:slug',
    component: EventListingComponent,
    data: { title: 'Event List' },
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'event/create/:slug',
    component: EventCreateComponent,
    data: { title: 'Event Create' },
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'event/edit/:slug',
    component: EventEditComponent,
    data: { title: 'Event Edit' },
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'event/details/:slug',
    component: EventDetailsComponent,
    data: { title: 'Event details' },
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    canActivate: [AuthGuard], canLoad: [AuthGuard], canActivateChild: [AuthGuard],
    path: 'messages', component: MessagesComponent, data: { title: 'Job Applicants' }
  },
  { path: 'category', loadChildren: () => import('./features/categorie/categorie.module').then(m => m.CategorieModule) },
  {
    path: 'newslist',
    component: NewslistComponent,
    data: { title: 'News list' }
  },
  
  {
    path: 'service', loadChildren: () => import('./features/categorie/categorie.module').then(m => m.CategorieModule)
  },
  { path: 'website/:slug', loadChildren: () => import('./features/public-business-detail/public-business-detail.module').then(m => m.PublicBusinessDetailModule) },
  {
    path: 'group/create',
    loadChildren: () => import('./features/group-create/group-create.module').then(m => m.GroupCreateModule)
  },
  { path: 'group/details/:slug', loadChildren: () => import('./features/group-details/group-details.module').then(m => m.GroupDetailsModule) },

  { path: '**', redirectTo: '/404' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
