import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
 
import { environment } from './../../../../environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@angular/common';
import { HomeService } from 'src/app/shared/services/home.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { HttpService } from 'src/app/shared/services/http.service';

@Component({
  selector: 'ryec-create-marketplace',
  templateUrl: './create-marketplace.component.html',
  styleUrls: ['./create-marketplace.component.css']
})
export class CreateMarketplaceComponent implements OnInit {

  @ViewChild('adsImages') adsImagesRef: ElementRef | undefined;
  myform: FormGroup;
  formData: any = {
    'ads_type' : '',
    'name' : '',
    'price' : '',
    'descriptions' : '',
    'address' : '',
    'city' : '',
    'category_id' : [],
    'parent_category_id' : [],
    'video_link' : []
  };
  submitted = false;
  allCities: any;
  allCategoryArr: any;
  parentCategoryIdArr: any = [];
  categoryIdArr: any = [];
  categoryArr: any = [];
  selectedCategoryArr: any = [];
  config = {
    displayKey: 'name',
    search: false
  };
  adsImagesFormData: FormData = new FormData();
  base64AdsImages: Array<{}> = [];
  fileAdsImages: any = [];
  isEdit: boolean = false;
  uploaedImages: any = [];
  deletedImagesArr: any = [];
  modalReference: any;
  appName = environment.APP_NAME;

  constructor(
    private hS: HomeService,
    private cS: CommonService,
    private httpService: HttpService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private router: Router,
    private _location: Location
  ) {
    this.myform = new FormGroup(this.formBuilder.group({
      Name: ['', [Validators.required]],
      AdsType: [''],
      Description: ['', [Validators.required]],
      Price: [''],
      Category: [''],
      City: [''],
      Location: [''],
      VideoUrlArr: this.formBuilder.array([])
    }).controls, { updateOn: 'change' });
  }

  ngOnInit() {
    let editData: any = {};
    this.isEdit = localStorage.getItem('adDetailData') ? true : false;
    if (this.isEdit) {
      editData = JSON.parse(window.atob(<any>localStorage.getItem('adDetailData')));
      console.log('editData', editData);
      this.formData['id'] = editData['id'];
      this.formData['name'] = editData['name'];
      this.formData['ads_type'] = editData['ads_type'];
      this.formData['descriptions'] = editData['descriptions'];
      this.formData['price'] = editData['price'];
      this.formData['address'] = editData['address'];
      this.myform.controls['Location'].setValue(editData['address']);
      if (editData['advertisement_images'].length > 0) {
        this.uploaedImages = editData['advertisement_images'];
      }
      const categoryArr = editData['category_hierarchy'];
      if (categoryArr.length > 0) {
        for (const x in Object.keys(categoryArr)) {
          for (const y in Object.keys(categoryArr[x])) {
            if (y === '1') {
              categoryArr[x][y].id = parseInt(categoryArr[x][y].id);
              this.categoryIdArr.push(categoryArr[x][y].id);
              this.selectedCategoryArr.push({
                parent_name: categoryArr[x][0].name,
                category_id: categoryArr[x][y].id,
                name: categoryArr[x][y].name
              });
            }
          }
        }
      }
      const VideoUrlArr: any = this.myform.controls.VideoUrlArr;
      if (editData['advertisement_videos'].length > 0) {
        for (const x in Object.keys(editData['advertisement_videos'])) {
          VideoUrlArr.push(this.formBuilder.control(editData['advertisement_videos'][x]['url']));
        }
      }
    }
    this.hS.getMenuCategoryListing().subscribe((res:any) => {
      this.allCategoryArr = res.data;
      if (this.isEdit) {
        let categoryArr = editData['category_hierarchy'];
        let id = categoryArr[categoryArr.length - 1][0]['id'];
        this.allCategoryArr.map((x: any) => {
          if (x.category_id === id) {
            this.myform.controls['Category'].setValue(x);
            this.categoryArr = x.sub_category;
          }
        });
      }
    });
    this.hS.getAddressMaster().subscribe((res: any) => {
      this.allCities = res.data.cities;
      if (this.isEdit) {
        this.allCities.map((x: any) => {
          if (x.name === editData['city']) {
            this.myform.controls['City'].setValue(x);
          }
        });
      }
    });
    if (!this.isEdit) {
      this.formData['ads_type'] = JSON.parse(window.atob(<any>localStorage.getItem('setAdType')));
    }
  }

  ngOnDestroy() {
    localStorage.removeItem('adDetailData');
    localStorage.removeItem('setAdType');
  }

  get f() { return this.myform.controls; }

  get VideoUrlArr() {
    let arr: any = this.myform.get('VideoUrlArr');
    return arr.length > 0 ? arr : [];
  }

  /**
   * On Change category selection
   * @param event
   */
  changeSelection(event: any) {
    if (event.value) {
      this.categoryArr = event.value.sub_category;
    } else {
      this.categoryArr = [];
    }
  }

  /**
   * For Checked and Unchecked check box
   * @param item
   */
  onChangeCheckbox(item: any) {
    const i = this.selectedCategoryArr.findIndex((x: any) => x.category_id === item.category_id);
    if (i === -1) {
      console.log('Category',this.myform.value['Category']);
      item['parent_name'] = this.myform.value['Category']['name'];
      this.selectedCategoryArr.push(item);
      this.categoryIdArr.push(item.category_id);
    } else {
      this.selectedCategoryArr.splice(i, 1);
      this.categoryIdArr.splice(i, 1);
    }
  }

  /**
   * For Remove category selection
   * @param item
   */
  onRemoveSelection(item: any) {
    const y = this.selectedCategoryArr.findIndex((x: any) => x.category_id === item.category_id);
    if (y !== -1) {
      this.selectedCategoryArr.splice(y, 1);
      this.categoryIdArr.splice(y, 1);
    }
  }

  /**
   * For Check category selection
   * @param item
   */
  isSelectedChange(item: any) {
      return this.categoryIdArr.indexOf(item) >= 0;
  }

  /**
   * For Location selection
   * @param place
   */
  placeChanged(place: any) {
      //console.log('place',place);
      this.formData['latitude'] = place.geometry.location.lat();
      this.formData['longitude'] = place.geometry.location.lng();
      this.formData['address'] = place['formatted_address'];
  }

  /**
   * For Upload images API
   * @param id
   */
  uploadImage(id: any) {
    const apiUrl = environment.RYEC_API_URL + 'uploadAdsImage';
    for (const x in Object.keys(this.fileAdsImages)) {
      this.adsImagesFormData.append('image_name[' + x + ']', this.fileAdsImages[x]);
    }
    this.adsImagesFormData.append('advertisement_id', <any>id);
    this.httpService.postUpload(apiUrl, this.adsImagesFormData).subscribe((res: any) => {
        if (res['status'] === 1) {
            this.cS.showSuccess(res['message']);
        } else {
            this.cS.showError(res['message']);
        }
    });
  }

  /**
   * For Remove images API
   * @param id
   */
  removeImage() {
    const obj = {
      image_ids: this.deletedImagesArr,
      advertisement_id: this.formData['id']
    };

    const apiUrl = environment.RYEC_API_URL + 'deleteAdsImage';
    this.httpService.post(apiUrl, obj).subscribe((res: any) => {
        console.log('deleteAdsImage',res);
        // if (res['status'] === 1) {
        //   this.cS.showSuccess(res['message']);
        // } else {
        //   this.cS.showError(res['message']);
        // }
    });
  }

  /**
   * Click in triggered Images selection
   */
  triggerUpload() {
    if (this.base64AdsImages.length === 9) {
      this.cS.showError('You can only upload 9 images.');
    } else {
      if (this.adsImagesRef) {
        this.adsImagesRef.nativeElement.click();
      }
    }
  }

  /**
   * For read file type
   * @param input
   */
  readURL(input: any) {
    const self = this;
    if (input.files && input.files[0]) {
        const reader = new FileReader();
        reader.onload = function (e:any) {
          if (e['target']) {
            self.base64AdsImages.push(e['target']['result']);
            self.fileAdsImages.push(input.files[0]);
          }
        };
        reader.readAsDataURL(input.files[0]);
    }
  }

  /**
   * For Upload images functionality
   * @param event
   */
  uploadAdsimages(event: any) {
    const srcEle = event.srcElement;
    if (srcEle.files && srcEle.files[0]) {
        if (this.cS.checkValidImage(srcEle.files[0])) {
            //this.adsImagesFormData.append('image_name[0]', srcEle.files[0]);
            this.readURL(srcEle);
        } else {
            this.cS.showError('Please select file less than 5MB.');
        }
    }
  }

  /**
   * For remove image selection
   * @param index
   * @param id
   */
  deleteAdsImage(index: number, id?: number) {
    if (id === undefined) {
      this.base64AdsImages.splice(index, 1);
      this.fileAdsImages.splice(index, 1);
    } else {
      this.deletedImagesArr.push(id.toString());
      this.uploaedImages.splice(index, 1);
    }
  }

  /**
   * For add new blank Url text box
   */
  addNewVideoURL() {
    const VideoUrlArr: any = this.myform.controls.VideoUrlArr;
    VideoUrlArr.push(this.formBuilder.control(''));
  }

  /**
   * On Remove video URL
   * @param i
   */
  onRemoveVideoUrl(i: any) {
    const VideoUrlArr: any = this.myform.controls.VideoUrlArr;
    VideoUrlArr.removeAt(i);
  }

  /**
   * For cancel selection
   */
  onCancelClick() {
    this.myform.reset();
    if (this.isEdit) {
      this._location.back();
    } else if (localStorage.getItem('setActiveTab')) {
      let url = localStorage.getItem('setActiveTab');
      this.router.navigate(['home/market-place/' + url]);
    } else {
      this.router.navigate(['home/market-place/relevance']);
    }
  }

  /**
   * For save Ads
   */
  onCreateNewAds() {
    this.submitted = true;
    if (this.myform.invalid) {
      return;
    } else {
      let isBlank = true;
      this.formData['video_link'] = [];
      if (this.myform.value['VideoUrlArr'].length > 0) {
        this.formData['video_link'] = this.myform.value['VideoUrlArr'];
        for (const x in Object.keys(this.formData['video_link'])) {
          if (this.formData['video_link'][x].trim() === '') {
            isBlank = false;
          }
        }
      }
      if (isBlank) {
        this.parentCategoryIdArr = [];
        for (const x in Object.keys(this.allCategoryArr)) {
          if (this.allCategoryArr[x].hasOwnProperty('sub_category')) {
            for (const y in (this.allCategoryArr[x]['sub_category'])) {
              if (this.categoryIdArr.indexOf(this.allCategoryArr[x]['sub_category'][y]['category_id']) !== -1) {
                const i = this.parentCategoryIdArr.indexOf(this.allCategoryArr[x]['category_id']);
                if (i === -1) {
                  this.parentCategoryIdArr.push(this.allCategoryArr[x]['category_id']);
                }
              }
            }
          }
        }

        this.formData['category_id'] = [];
        this.formData['parent_category_id'] = [];
        this.formData['city'] = '';
        if (this.categoryIdArr.length > 0) {
          this.formData['category_id'] = this.categoryIdArr;
        }
        if (this.parentCategoryIdArr.length > 0) {
          this.formData['parent_category_id'] = this.parentCategoryIdArr;
        }
        if (this.myform.value['City'] !== undefined) {
          this.formData['city'] = this.myform.value['City']['name'];
        }
        console.log('formData',this.formData);
        const apiUrl = environment.RYEC_API_URL + 'addOrUpdateAds';
        this.httpService.post(apiUrl, this.formData).subscribe((res:any) => {
          //console.log('addOrUpdateAds',res);
          if (res['status'] === 1) {
            if (this.base64AdsImages.length > 0) {
              this.uploadImage(res['data']['id']);
            }
            if (this.isEdit && this.deletedImagesArr.length > 0) {
              this.removeImage();
            }
            this.cS.showSuccess(res['message']);
            if (this.isEdit) {
              this._location.back();
            } else {
              if (res['data'].hasOwnProperty('id')) {
                let id = window.btoa(res['data']['id']);
                this.router.navigate(['home/market-place-detail/' + id]);
              }
            }
          } else {
            this.cS.showError(res['message']);
          }
        });
      } else {
        this.cS.showError('Please add video url');
      }
    }
  }

  /**
   * For remove ads
   * @param content
   */
  onCloseAd(content: any) {
    this.modalReference = this.modalService.open(content, { windowClass: 'dark-modal' });
    this.modalReference.result.then((result: any) => {
      console.log(`Closed with: ${result}`);
    }, (reason: any) => {
      if (reason === 'yes') {
        console.log('reason', reason);
        const apiUrl = environment.RYEC_API_URL + 'removeAdvertisement';
        const postJson = {
            "advertisement_id" : this.formData['id']
        }
        this.httpService.post(apiUrl, postJson).subscribe((res:any) => {
          if (res['status'] === 1) {
            this.cS.showSuccess(res['message']);
            this._location.back();
          } else {
            this.cS.showError(res['message']);
          }
        });
      }
    });
  }

}
