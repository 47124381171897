<!-- <ryec-header *ngIf="isPrivate && !isMobileAppUrl && !isPrivacyPolicyPage" [isHomePage]="isHomePage"></ryec-header>
<ryec-header-simple *ngIf="isPrivacyPolicyPage"></ryec-header-simple>
 <ryec-spinner *ngIf="toggleSpinner"></ryec-spinner> 
<router-outlet></router-outlet>
<ryec-footer [isPrivate]="isPrivate" *ngIf="!isMobileAppUrl"></ryec-footer>
 -->

 <div *ngIf="appName=='BD'">
 <ryec-header *ngIf="isPrivate && !isMobileAppUrl && !isPrivacyPolicyPage" [isHomePage]="isHomePage"></ryec-header>
</div>
<div *ngIf="appName=='MRC'">
    <ryec-header *ngIf="isPrivate && !isMobileAppUrl && !isPrivacyPolicyPage" [isHomePage]="isHomePage"></ryec-header>
</div>
<div *ngIf="appName=='RYC'">
    <ryec-header [isHomePage]="isHomePage" *ngIf="isPrivate && !isMobileAppUrl && !isPrivacyPolicyPage   && router.url !== '/login'&& router.url !== '/signup'"></ryec-header>
</div>
    <ryec-header-simple *ngIf="isPrivacyPolicyPage"></ryec-header-simple>
    <ryec-spinner *ngIf="toggleSpinner"></ryec-spinner> 
 <router-outlet></router-outlet>
 <div *ngIf="appName=='BD'">
    <ryec-footer [isPrivate]="isPrivate" *ngIf="!isMobileAppUrl "></ryec-footer>
 </div>
<div *ngIf="appName=='MRC'">
    <ryec-footer [isPrivate]="isPrivate" *ngIf="!isMobileAppUrl "></ryec-footer>
</div>
 <div  *ngIf="appName=='RYC'">
     <ryec-footer [isPrivate]="isPrivate" *ngIf="!isMobileAppUrl && router.url !== '/login'&& router.url !== '/signup' "></ryec-footer>
</div>