import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../environments/environment';

import { GroupManagementModel } from '../../model/group-management.model';
import { CommonService } from '../../services/common.service';
import { HomeService } from '../../services/home.service';
import { HttpService } from '../../services/http.service';

declare var $: any;

@Component({
  selector: 'ryec-group-member-designation-roles-modal',
  templateUrl: './group-member-designation-roles-modal.component.html',
  styleUrls: ['./group-member-designation-roles-modal.component.css']
})
export class GroupMemberDesignationRolesModalComponent implements OnInit {
  @Input() groupId: number = 0;
  @Input() groupName: string = "";
  @Input() parentModel: any = {};
  @Input() isCustomDesignation: boolean = false;
  managementModel = new GroupManagementModel();
  suggestMembersList: any = [];
  isSubmit: boolean = false;
  groupDesignationList: any[] = [];
  groupRolesList: any[] = [];
  appName = environment.APP_NAME;

  constructor(
    public activeModal: NgbActiveModal,
    private httpS: HttpService,
    private cS: CommonService,
    private homeService: HomeService,
  ) { }

  ngOnInit() {
    if (this.groupId) {
      this.getGroupDesignationsListByGroupId(this.groupId);
      this.getGroupRoleList(this.groupId);
    }
    if(this.parentModel){
      this.managementModel = this.parentModel;
    }
  }

  getGroupDesignationsListByGroupId(id: number) {
    const postJson = {
      site_id: id,
    };
    this.homeService.getGroupDesignationsListByGroupId(postJson).subscribe((res: any) => {
      if (res.data) {
        if (res.data.length > 0) {
          this.groupDesignationList = res.data;
        } else {
          this.groupDesignationList = [];
        }
      }
    });
  }

  getGroupRoleList(id: number) {
    const postJson = {
      site_id: id,
    };
    this.homeService.getGroupMemberRoleLists(postJson).subscribe((res: any) => {
      if (res.data) {
        if (res.data.length > 0) {
          this.groupRolesList = res.data;
        } else {
          this.groupRolesList = [];
        }
      }
    });
  }

  onChangeDesignation() {
    if (this.managementModel.groupDesignationId == -1) {
      this.managementModel.groupDesignationName = "";
      this.isCustomDesignation = true;
    }
    else {
      this.managementModel.groupDesignationName = "";
      this.isCustomDesignation = false;
    }
  }

  searchMember(event: any) {
    const val = event.target.value;
    if (val === "" || val.length < 3) {
      $(".member-list").css("display", "none");
    }
    if (val.length >= 3) {
      const postJson = { site_id: this.groupId, searchText: val };

      this.homeService.getGroupMemberAutocomplete(postJson).subscribe((res: any) => {
        if (res.data) {
          if (res.data.length > 0) {
            $(".member-list").css("display", "block");
            this.suggestMembersList = res.data;
          } else {
            this.suggestMembersList = [];
            $(".member-list").css("display", "none");
          }
        }
      });
    }
  }

  /**
   * on select auto suggest member list get selected id and pass into group member id
   * @Yuvraj 13/01/22
   */
  selectMemeber(id: number, name: string) {
    this.managementModel.groupMemberId = id;
    this.managementModel.searchText = name;
    $(".member-list").css("display", "none");
  }

  onSubmit() {
    if (this.managementModel.groupMemberId != null) {
      // check if custom group name is exisit & designationid is -1 then rest designationId to null
      if (this.managementModel.groupDesignationName != '' && this.managementModel.groupDesignationId == -1) {
        this.managementModel.groupDesignationId = null as any;
      }
      // check if designationid is greater then 0 then empty custom designation name
      if (this.managementModel.groupDesignationId > 0) {
        this.managementModel.groupDesignationName = "";
      }
      const postJson = {
        site_id: this.groupId,
        user_id: this.managementModel.groupMemberId,
        role_id: this.managementModel.groupMemberRoleId,
        group_designation_id: this.managementModel.groupDesignationId,
        custom_designation_name: this.managementModel.groupDesignationName
      };
      const apiUrl = environment.RYEC_API_URL + 'assignRoleSiteMember';
      this.httpS.post(apiUrl, postJson).subscribe((res: any) => {
        if (res.status === 1) {
          this.cS.showSuccess(res.message);
          this.activeModal.dismiss('close');
        }
        else {
          this.cS.showError(res.message);
        }
      });
    }
    else {
      this.cS.showError('Please select valid details');
    }
  }

}
