<div class="location-auto-search" [ngStyle]="{ width: boxWidth + '%' }">
  <i style="cursor: pointer" class="fa fa-map-marker" aria-hidden="true" [ngClass]="appName == 'MRC' ? 'mrc-icon' :appName == 'BD' ? 'BD-icon': 'ryc-icon'"></i>
  <!-- {{searchText}} -->
  <input [ngStyle]="{ height: boxHeight + 'px' }" id="locationSearchInput" (input)="checkVal()" [(ngModel)]="searchText" placeholder="Search Location" type="text" #locationSearchInput placeholder="All Locations">
  <div style="display: none;" [ngClass]="appName == 'MRC' ? 'header-location-filter-mrc' : 'header-location-filter-ryc'">
    <perfect-scrollbar style="height: 210px;">
      <ul>
        <!-- <li class="search_input" (click)="selectLocation('All Locations')">
            All Locations
        </li> -->
        <li [ngClass]="appName == 'MRC' ? 'mrc-location-search-list':appName == 'BD' ? 'BD-location-search-list' :  'ryc-location-search-list'" class="search_input" *ngFor="let item of locationLists" (click)="selectLocation(item.name)">
            {{ item.name }}
        </li>
      </ul>
    </perfect-scrollbar>
  </div>
</div>
