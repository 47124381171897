<div class="group-edit_page py-4">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h3 class="detail-title">Edit {{ groupDetailsModel.name }}</h3>
          <ngb-accordion #acc="ngbAccordion" [closeOthers]="true" activeIds="ngb-panel-0">
            <!-- <form [formGroup]="postForm" novalidate (ngSubmit)="onSubmit()"> -->
              <!-- General Settings Start -->
              <ngb-panel>
                <div class="panel-title">
                  <ng-template ngbPanelTitle>
                    <span>General Settings <i class="fa fa-caret-down"></i></span>
                  </ng-template>
                </div>
                <div class="panel-body">
                  <ng-template ngbPanelContent>
                    <form [formGroup]="postForm" novalidate (ngSubmit)="onSubmit()">
                      <div class="card-body">
                        <div class="edit_form edit-general_setting">
                            <div class="control-group">
                              <div class="controls">
                                <label class="control_label">Group Name <span class="required_feild">*</span></label>
                                <div class="w-100">
                                  <input type="text" id="group_name" name="group_name" formControlName="name" placeholder="Enter group name" class="form-control" required/>
                                  <p class="help-block" *ngIf="postForm.controls.name.touched && postForm.controls.name.errors?.required">
                                    Group name is required !
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div class="control-group">
                              <div class="controls">
                                <label class="control_label">Category <span class="required_feild">*</span></label>
                                <div class="w-100">
                                  <select class="form-control" name="asset_type_id" formControlName="asset_type_id" required>
                                    <option [ngValue]="null">--Select Category--</option>
                                    <option *ngFor="let item of categoryList; let i = index" [ngValue]="item.id">{{ item.name }}</option>
                                  </select>
                                  <p class="help-block" *ngIf="postForm.controls.asset_type_id.touched && postForm.controls.asset_type_id.errors?.required">
                                    Category is required !
                                  </p>
                                </div>
                                <!-- <input type="text" id="category_name" name="category_name" placeholder="Enter your category" class="form-control" required/> -->
                                <!-- <button type="button" class="btn edit_btn" ><i class="fa fa-pencil" aria-hidden="true"></i></button> -->
                              </div>
                            </div>
                            <div class="control-group">
                              <div class="controls">
                                <label class="control_label">Description <span class="required_feild">*</span></label>
                                <div class="w-100">
                                  <input type="text" id="description" name="description" formControlName="description" placeholder="Enter your description" class="form-control" required/>
                                  <p class="help-block" *ngIf="postForm.controls.description.touched && postForm.controls.description.errors?.required">
                                    Description is required !
                                  </p>
                                </div>
                                <!-- <button type="button" class="btn edit_btn" ><i class="fa fa-pencil" aria-hidden="true"></i></button> -->
                              </div>
                            </div>
                            <div class="control-group">
                              <div class="controls">
                                <label class="control_label">Website </label>
                                <div class="w-100">
                                  <input type="text" id="link" name="link" formControlName="link" placeholder="Enter your website" class="form-control"/>
                                  <p class="help-block" *ngIf="postForm.controls.link.touched && postForm.controls.link.errors?.pattern">
                                    {{ "ang_external_pattern_error" | translate }}
                                  </p>
                                </div>
                                <!-- <button type="button" class="btn edit_btn" ><i class="fa fa-pencil" aria-hidden="true"></i></button> -->
                              </div>
                            </div>
                            <div class="control-group">
                              <div class="controls">
                                <label class="control_label">Group Logo </label>
                                <div id="dropZoon" class="upload-area__drop-zoon drop-zoon">
                                  <!-- <button type="button" *ngIf="logoImagePost" class="btn remove_btn" (click)="removeLogoImage();">
                                    <img src="assets/images/close.png" alt="Remove" />
                                  </button> -->
                                  <button type="button" class="btn edit_btn" ><i class="fa fa-pencil" aria-hidden="true"></i></button>
                                  <!-- <button *ngIf="logoImagePost" type="button" class="btn edit_btn">
                                    <i class="fa fa-pencil" aria-hidden="true"></i>
                                    <input type="file" id="images" name="images" class="drop-zoon__file-input" (change)="onLogoImageChange($event)" accept="image/jpg, image/jpeg, image/png" (click)="$event.target.value=null">
                                  </button> -->
                                  <span class="drop-zoon__icon">
                                      <!-- <i class="fa fa-upload" aria-hidden="true"></i> -->
                                      <img [src]="logImageShow" alt="uploaded file" />
                                  </span>
                                  <input type="file" id="images" name="images" class="drop-zoon__file-input" (change)="onLogoImageChange($event)" required accept="image/jpg, image/jpeg, image/png">
                                </div>
                              </div>
                            </div>
                            <div class="control-group">
                              <div class="controls">
                                <label class="control_label">Cover Image </label>
                                <div id="dropZoon" class="upload-area__drop-zoon drop-zoon">
                                  <!-- <button *ngIf="converImageShow.length" type="button" class="btn remove_btn" (click)="removeCoverImage();">
                                    <img src="assets/images/close.png" alt="Remove" />
                                  </button> -->
                                  <!-- <button *ngIf="postConverImage.length" type="button" class="btn edit_btn" ><i class="fa fa-pencil" aria-hidden="true"></i></button> -->
                                  <!-- <span class="drop-zoon__icon" *ngFor="let item of converImageShow; let i = index;">
                                    <i *ngIf="postConverImage.length == 0" class="fa fa-upload" aria-hidden="true"></i>
                                    <i class="fa fa-upload" aria-hidden="true"></i>
                                    <img [src]="item.url" alt="uploaded file" />
                                  </span>
                                  <input type="file" id="images" name="images" class="drop-zoon__file-input" (change)="onCoverImageChange($event)" accept="image/jpg, image/jpeg, image/png"> -->
                                  <!-- {{converImageShow.length}} -->
                                  <span class="drop-zoon__icon">
                                    <i class="fa fa-upload" aria-hidden="true"></i>
                                    <!-- <span *ngFor="let item of converImageShow; let i = index;">
                                      Below image will show as preview image 
                                      <div *ngIf="converImageShow.length" class="d-block h-100">
                                          <img *ngFor="let item of converImageShow; let i = index;" alt="Cover" [src]="item.url" />
                                      </div>
                                  </span> -->
                                </span>
                                <!-- (click)="$event.target.value=null" -->
                                <input type="file" id="images" name="images" class="drop-zoon__file-input" (change)="onCoverImageChange($event)" accept="image/jpg, image/jpeg, image/png" multiple="">
                                </div>
                              </div>
                            </div>
                            <div class="control-group">
                              <div class="controls">
                                  <div *ngFor="let item of converImageShow; let i = index" class="file-img-wrapper">
                                      <a *ngIf="converImageShow.length > 0" href="javascript:;" class="file-delete-icon" (click)="removeCoverImage(item.id, i)">
                                          <i aria-hidden="true" class="fa fa-remove"></i>
                                      </a>
                                      <img [src]="item.url" height="100" width="100" style="margin: 3px" />
                                      <!-- <br /> -->
                                  </div>
                              </div>
                            </div>
                            <button type="submit" [disabled]="isSubmit" [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" class="ml-auto mr-0 d-block">Save</button>
                        </div>
                      </div>
                    </form>
                  </ng-template>
                </div>
              </ngb-panel>
              <!-- General Settings End -->
              
              <!-- Social Profiles Start -->
              <ngb-panel>
                <div class="panel-title">
                  <ng-template ngbPanelTitle>
                    <span>Social Profiles <i class="fa fa-caret-down"></i></span>
                  </ng-template>
                </div>
                <div class="panel-body">
                  <ng-template ngbPanelContent>
                    <form [formGroup]="postForm" novalidate (ngSubmit)="onSubmit()">
                      <div class="card-body social_body">
                        <p>Provide complete link to your social profile.</p>
                        <p class="mb-3">
                          <a href="javascript:void(0);" title="https://www.facebook.com/inexture/">
                          Ex. https://www.facebook.com/inexture/
                          </a>
                        </p>
                        <div class="edit_form">
                            <div class="control-group">
                              <div class="controls">
                                <label class="control_label"><i class="fa fa-facebook-square" aria-hidden="true"></i> </label>
                                <input type="text" id="facebook" name="facebook" formControlName="facebook" placeholder="Enter facebook link" class="form-control"/>
                              </div>
                            </div>
                            <div class="control-group">
                              <div class="controls">
                                <label class="control_label"><i class="fa fa-twitter-square" aria-hidden="true"></i> </label>
                                <input type="text" id="twitter" name="twitter" formControlName="twitter" placeholder="Enter twitter link" class="form-control"/>
                              </div>
                            </div>
                            <div class="control-group">
                              <div class="controls">
                                <label class="control_label"><i class="fa fa-linkedin-square" aria-hidden="true"></i></label>
                                <input type="text" id="linkedin" name="linkedin" formControlName="linkedin" placeholder="Enter linkedin link" class="form-control"/>
                              </div>
                            </div>
                            <div class="control-group">
                              <div class="controls">
                                <label class="control_label"><i class="fa fa-instagram" aria-hidden="true"></i></label>
                              <input type="text" id="instagram" name="instagram" formControlName="instagram" placeholder="Enter instagram link" class="form-control"/>
                              </div>
                            </div>
                            <button type="submit" [disabled]="isSubmit" [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" class="ml-auto mr-0 d-block">Save</button>
                        </div>
                      </div>
                    </form>
                  </ng-template>
                </div>
              </ngb-panel>
              <!-- Social Profiles End -->
              
              <!-- Privacy Start -->
              <ngb-panel>
                <div class="panel-title">
                  <ng-template ngbPanelTitle>
                    <span>Privacy <i class="fa fa-caret-down"></i></span>
                  </ng-template>
                </div>
                <div class="panel-body">
                  <ng-template ngbPanelContent>
                    <form [formGroup]="postForm" novalidate (ngSubmit)="onSubmit()">
                      <div class="card-body group_body">
                        <div class="edit_form" >
                            <div class="control-group">
                              <div class="controls">
                                  <label class="control_label">Group Privacy </label>
                                  <div class="d-flex flex-wrap w-100">
                                    <p>
                                      <select class="form-control" name="visibility" id="visibility" (ngModelChange)="onPrivacyChange($event)" formControlName="visibility" required>
                                        <option [disabled]="isPrivate" [ngValue]="false"><i class="fa fa-globe" aria-hidden="true"></i> Public</option>
                                        <option [ngValue]="true"><i class="fa fa-lock" aria-hidden="true"></i> Private</option>
                                      </select>
                                    </p>
                                    <span class="privacy_note">
                                      <i class="fa fa-info-circle" aria-hidden="true"></i>
                                      If public, can change to private. But if Private, We can not change it to public.
                                    </span>
                                  </div>
                              </div>
                            </div>
                            <div class="control-group" *ngIf="showJoinSection === true">
                              <div class="controls">
                                <label class="control_label">Join Request</label>
                                <div class="d-flex flex-wrap w-100">
                                  <p class="desc_text mb-2">Users will see the "Join" button at the group detail page if you enable this. Otherwise only invited users by admin will be able to join this group.</p>
                                  <p class="request_toggle max-w-full">
                                    <span *ngIf="isJointRequest === false">No</span>
                                    <span *ngIf="isJointRequest === true">Yes</span>
                                    <!-- <span>Yes</span> -->
                                    <label class="switch">
                                      <input type="checkbox" name="is_enable_request" formControlName="is_enable_request" value="true" (ngModelChange)="joinRequest($event)">
                                      <span class="slider round"></span>
                                    </label>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div class="control-group" *ngIf="showJoinSection === true">
                              <div class="controls">
                                <label class="control_label">Group Post Moderate</label>
                                <div class="d-flex flex-wrap w-100">
                                  <p class="desc_text mb-2">If anyone posts in your group, then it will need to be reviewed by the group admin.</p>
                                  <p class="request_toggle max-w-full">
                                    <span *ngIf="isPostModerateRequest === false">No</span>
                                    <span *ngIf="isPostModerateRequest === true">Yes</span>
                                    <!-- <span>Yes</span> -->
                                    <label class="switch">
                                      <input type="checkbox" name="is_post_moderate" formControlName="is_post_moderate" value="true" (ngModelChange)="onGroupPostModerate($event)">
                                      <span class="slider round"></span>
                                    </label>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div class="control-group" *ngIf="showJoinSection === true">
                              <div class="controls">
                                <label class="control_label">Security Code</label>
                                <div class="d-flex flex-wrap w-100">
                                  <p class="desc_text mb-2">If you enable this, users will only need to enter this code when joining the group.</p>
                                  <p class="request_toggle max-w-full">
                                    <span *ngIf="isJoinSecurityCodeRequest === false">No</span>
                                    <span *ngIf="isJoinSecurityCodeRequest === true">Yes</span>
                                    <!-- <span>Yes</span> -->
                                    <label class="switch">
                                      <input type="checkbox" name="is_security_code" formControlName="is_security_code" value="true" (ngModelChange)="joinSecurityCode($event)">
                                      <span class="slider round"></span>
                                    </label>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div class="control-group" *ngIf="isShowExtraField == true">
                              <div class="controls">
                                <label class="control_label">Add Extra Field </label>
                                <span class="info_desc">If you want to get extra details from the new group joiners, you can enable this option. You can set that extra field's label whatever you want to. You can add labels Eg. 'Roll Number', 'Employee Id', 'Identification Number, Etc.</span>
                                <div class="w-100">
                                  <input type="text" id="dynamic_field_label" name="dynamic_field_label" formControlName="dynamic_field_label" placeholder="Enter label for the extra field" class="form-control"/>
                                  <p class="help-block" *ngIf="postForm.controls.dynamic_field_label.touched && postForm.controls.dynamic_field_label.errors?.required">
                                    extra field label name is required !
                                  </p>
                                </div>
                              </div>
                            </div>
                            <button type="submit" [disabled]="isSubmit" [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" class="ml-auto mr-0 d-block">Save</button>
                        </div>
                      </div>
                    </form>
                  </ng-template>
                </div>
              </ngb-panel>
              <!-- Privacy End -->
              
              <!-- Contact Details Start -->
              <ngb-panel>
                <div class="panel-title">
                  <ng-template ngbPanelTitle>
                    <span>Contact Details <i class="fa fa-caret-down"></i></span>
                  </ng-template>
                </div>
                <div class="panel-body">
                  <ng-template ngbPanelContent>
                    <form [formGroup]="postForm" novalidate (ngSubmit)="onSubmit()">
                      <div class="card-body">
                        <div class="edit_form" novalidate >
                            <div class="control-group">
                              <div class="controls">
                                  <label class="control_label">Contact Person </label>
                                  <p><input type="text" id="contactPersonName" name="contactPersonName" placeholder="Enter Contact Person" formControlName="contactPersonName" class="form-control"/></p>
                              </div>
                            </div>
                            <div class="control-group">
                              <div class="controls">
                                  <label class="control_label">Contact Number </label>
                                  <p>
                                    <input type="tel" id="contactNumber" name="contactNumber" placeholder="Enter Contact Number" formControlName="contactNumber" class="form-control" minlength="6" maxlength="13" (keypress)="numberOnly($event)"/>
                                  </p>
                                  <p class="help-block" *ngIf="postForm.controls.contactNumber.touched && postForm.controls.contactNumber.errors?.required ">
                                    {{'ang_mobilerequired'| translate}}
                                  </p>
                                  <p class="help-block" *ngIf="postForm.controls.contactNumber.touched && (postForm.controls.contactNumber.errors?.minlength || postForm.controls.contactNumber.errors?.maxlength || postForm.controls.contactNumber.errors?.pattern)">
                                    {{ 'ang_mobilevalidation' | translate}}
                                  </p>
                                  <p class="help-block" *ngIf="postForm.controls.contactNumber.touched && postForm.controls.contactNumber.errors?.pattern">
                                    {{ 'ang_numbervalidation' | translate}}
                                  </p>
                              </div>
                            </div>
                            <div class="control-group">
                              <div class="controls">
                                <label class="control_label">Pincode </label>
                                <p>
                                  <input type="text" id="contactPinCode" name="contactPinCode" placeholder="Enter your pincode" formControlName="contactPinCode" class="form-control" (input)="locationSearch($event.target.value)"/>
                                </p>
                              </div>
                            </div>
                            <div class="control-group" *ngIf="locationsList.length">
                              <div class="controls">
                                <label class="control_label">Country</label>
                                <p>
                                  <!-- <select class="form-control" name="contactCountryId" id="contactCountryId" formControlName="contactCountryId">
                                    <option [ngValue]="null">--Select Country--</option>
                                    <option *ngFor="let country of locationsList; let i = index" [ngValue]="country.location_id">{{ country.country_name }}</option>
                                  </select> -->
                                  <i class="fa fa-map-marker" aria-hidden="true"></i>
                                  <input type="text" id="countryName" name="countryName" readonly class="form-control" value="{{locationsList[0].country_name}}" />
                                </p>
                              </div>
                            </div>
                            <div class="control-group" *ngIf="locationsList.length">
                              <div class="controls">
                                  <label class="control_label">State</label>
                                  <p>
                                    <i class="fa fa-map-marker" aria-hidden="true"></i>
                                    <input type="text" id="stateName" name="stateName" readonly class="form-control" value="{{locationsList[0].state}}" />
                                  </p>
                              </div>
                            </div>
                            <div class="control-group" *ngIf="locationsList.length">
                              <div class="controls">
                                <label class="control_label">District</label>
                                <p>
                                  <i class="fa fa-map-marker" aria-hidden="true"></i>
                                  <input type="text" id="districtName" name="districtName" readonly class="form-control" value="{{locationsList[0].district}}" />
                                  <!-- <select class="form-control" name="contactCityId" id="contactCityId">
                                    <option [ngValue]="null">--Select City--</option>
                                    <option *ngFor="let item of locationsList; let i = index" [ngValue]="item.location_id">{{ item.district }}</option>
                                  </select> -->
                                </p>
                              </div>
                            </div>
                              <div class="control-group" *ngIf="locationsList.length">
                                <div class="controls">
                                <label class="control_label">City</label>
                                <p>
                                  <i class="fa fa-map-marker" aria-hidden="true"></i>
                                  <select class="form-control" name="locationId" id="locationId" formControlName="locationId" (required)="locationsList.length">
                                    <option value="" disabled>--Select City--</option>
                                    <option *ngFor="let item of locationsList; let i = index" [ngValue]="item.location_id">{{ item.city }}</option>
                                  </select>
                                  <span class="help-block" *ngIf="postForm.controls.locationId.touched && postForm.controls.locationId.errors?.required">
                                    City is required !
                                  </span>
                                </p>
                              </div>
                            </div>
                            <div class="control-group">
                              <div class="controls">
                                  <label class="control_label">Address</label>
                                  <p><textarea type="text" id="contactAddress" name="contactAddress" placeholder="Enter your address" formControlName="contactAddress" class="form-control"></textarea></p>
                              </div>
                            </div>
                            <button type="submit" [disabled]="isSubmit" [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" class="ml-auto mr-0 d-block">Save</button>
                        </div>
                      </div>
                    </form>
                  </ng-template>
                </div>
              </ngb-panel>
              <!-- Contact Details End -->
              
            <!-- </form> -->
              <!-- Group Management Start -->
              <ngb-panel>
                <div class="panel-title">
                  <ng-template ngbPanelTitle>
                    <span>Group Management <i class="fa fa-caret-down"></i></span>
                  </ng-template>
                </div>
                <div class="panel-body">
                  <ng-template ngbPanelContent>
                      <div class="card-body management_body">
                        <div class="edit_form">
                            <div class="row border-bottom pb-3 mb-3 group_wrapper">
                              <div class="control-group col-md-12">
                                <p class="info_text mb-3">You can assign designation & role to any of group member.
                                <!-- <button type="submit" [disabled]="isSubmit" class="ryec_btn ml-auto mr-0 d-block">Assign</button> -->
                                <button type="button" (click)="loadGroupMemberDesignationAndRolesModalComponent();" [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" class="ml-auto mr-0 d-block">
                                  Manage
                                </button>
                              </p>
                              </div>
                            </div>
                        </div>
                        <div *ngIf="managementsLists.length">
                          <div class="member_details" *ngFor="let item of managementsLists; let i = index">
                            <span class="member_img">
                              <img *ngIf="item.profile_pic" [src]="item.profile_url" [alt]="item.name"/>
                              <img *ngIf="!item.profile_pic" src="assets/images/default_user_profile.png" alt="user"/>
                              <span class="member_status" *ngIf="item.role_name">{{ item.role_name }}</span>
                            </span>
                            <div class="member_data">
                                <div class="d-flex justify-content-between align-items-start">
                                  <h5 *ngIf="item.singlebusiness">
                                    <a routerLink="/home/business-detail/{{ item.singlebusiness.business_slug }}">
                                      {{ item.name }} <span>|</span> <span>{{ item.singlebusiness.name }}</span>
                                    </a>
                                    <p>
                                      <span *ngIf="item.custom_designation_name || item.designation_name">Designation: </span>
                                      <span *ngIf="item.custom_designation_name" class="member_status">{{ item.custom_designation_name }}</span>
                                      <span *ngIf="item.designation_name" class="member_status">{{ item.designation_name }}</span>
                                    </p>
                                  </h5>
                                  <h5 *ngIf="!item.singlebusiness">
                                    <a href="javascript:void(0);">
                                      {{ item.name }}
                                    </a>
                                    <p>
                                      <span *ngIf="item.custom_designation_name || item.designation_name">Designation: </span>
                                      <span *ngIf="item.custom_designation_name" class="member_status">{{ item.custom_designation_name }}</span>
                                      <span *ngIf="item.designation_name" class="member_status">{{ item.designation_name }}</span>
                                    </p>
                                  </h5>
                                  <div class="d-flex justify-content-flex-end align-items-center">
                                    <button *ngIf="(userId == groupDetailsModel.created_by.id) && (item.role_name !== 'Creator')" type="button" (click)="loadGroupMemberRemoveConfirmationModalComponent(item.id, item.name)" [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" class="mr-1">
                                      <i class="fa fa-trash" aria-hidden="true"></i>
                                    </button>
                                    <button *ngIf="(userId !== item.id) && (item.role_name !== 'Creator')" type="button" (click)="onEditMemberRole(item)" [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'">
                                      <i class="fa fa-pencil" aria-hidden="true"></i>
                                    </button>
                                  </div>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                  </ng-template>
                </div>
              </ngb-panel>
              <!-- Group Management End -->
            </ngb-accordion>
        </div>
      </div>
    </div>
  </div>
  