<div class="container tc_bg place-details">
  <div class="row" *ngIf="placeDetailsModel.name">
    <!-- Main text section -->
    <div class="col-sm-12">
      <div class="page-title">
        <div class="d-flex justify-content-between position-relative flex-wrap">
          <h4 [ngStyle]="{'color':appName == 'RYC' ? 'var(--theme-ryc-based-secondary-color)' : 'var(--theme-mrc-based-primary-color)' }">{{ placeDetailsModel.name }}</h4>
          <!-- <mat-form-field appearance="fill">
            <mat-label [ngStyle]="{'color':appName == 'RYC' ? 'var(--theme-ryc-based-secondary-color)' : 'var(--theme-mrc-based-primary-color)' }">{{'ang_chooseLanguage' | translate}}</mat-label>
            <mat-select disableOptionCentering (change)="onLangChange($event)" [(value)]="defaultSelectedVal">
              <mat-option *ngFor="let item of placeDetailsModel.languages" [value]="item" >
                {{ item }}
              </mat-option>
            </mat-select>
          </mat-form-field> -->
        </div>
        <div class="d-flex justify-content-between align-items-center mt-3">
          <div class="place-rating mt-2" *ngIf="totalRatingCount > 0">
            <span class="badge badge-success">
              <span class="avg-rate">
                {{ ratingReviewsModel.avg_rating }}
              </span>/5
            </span>
            <span *ngIf="ratingReviewsModel.total == 1" class="text-muted">
              ({{ ratingReviewsModel.total }} {{ 'ang_review' | translate }})
            </span>
            <span *ngIf="ratingReviewsModel.total > 1" class="text-muted">
              ({{ ratingReviewsModel.total }} {{ 'ang_reviews' | translate }})
            </span>
          </div>
          <div *ngIf="placeDetailsModel.visits > 0" class="total-view">
            <i class="fa fa-eye" aria-hidden="true"></i>
            {{ 'ang_totalViews' | translate }}: <span>{{placeDetailsModel.visits}}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- Main Image Section -->
    <div class="col-sm-12">
      <div class="row">
        <div class="col-md-8 mb-4">
          <div class="carousel-wrapper place_card p-0 m-0">
            <div class="tag-wrapper" *ngIf="imgTags?.length > 0">
              <span *ngFor="let item of imgTags | slice: 0:3; let i = index" class="tag tag-warning" >
                {{ item }}
              </span>
            </div>
            <owl-carousel #owlElement [options]="customOptions" [carouselClasses]="['owl-theme', '', 'main-image-carousel']" [items]="placeDetailsModel.business_images" >
              <div class="item" *ngFor="let item of placeDetailsModel.business_images; let i = index" >
                <a (click)="ImageModal(ImageCarouselModal, i)" class="">
                  <img _ngcontent-c6="" src="{{ item.image_name }}" loading="lazy"/>
                </a>
              </div>
            </owl-carousel>
          </div>
        </div>
        <div class="col-md-4 mb-4">
          <div class="place_card h-100">
            <div id="shortDesc" #shortDesc [innerHtml]="placeDetailsModel.short_description" >
              {{ placeDetailsModel.short_description }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Location & QR Section -->
    <div class="col-sm-12" *ngIf="placeDetailsModel.mapURL">
      <div class="row">
        <!-- Location Section -->
        <div class="col-md-8 mb-4">
          <div class="place_card address_card m-0">
            <div class="tc_bg">
              <h4>{{ 'ang_location' | translate }}</h4>
            </div>
            <div class="inform_view mb-3">
              <span>
                <i aria-hidden="true" class="fa fa-map-marker"></i>
              </span>
              <h6 class="m-0">{{ placeDetailsModel.full_address }}</h6>
            </div>
            <div *ngIf="placeDetailsModel.mapURL">
              <div class="location-map">
                <iframe [src]="placeDetailsModel.mapURL" width="100%" height="100%" style="border: 0" allowfullscreen="" loading="lazy"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
        <!-- QR code Section -->
        <div class="col-md-4 mb-4" *ngIf="placeDetailsModel.QRCode">
          <div class="place_card address_card h-100 m-0">
            <div class="tc_bg">
              <h4>{{ 'ang_qrCode' | translate }}</h4>
              <div class="d-none align-items-center action-wrapper">
                <a [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" class="ml-2 p-1 px-2">
                  <i class="fa fa-share-square-o" aria-hidden="true"></i>
                </a>
                <a [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" class="ml-2 p-1 px-2">
                  <i class="fa fa-download" aria-hidden="true"></i>
                </a>
              </div>
            </div>
            <div class=" d-flex align-items-center justify-content-center qr-code_sec">
              <img src="{{ placeDetailsModel.QRCode }}" width="200px" loading="lazy"/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Description Content -->
    <div class="col-sm-12" *ngIf="placeDetailsModel.descriptions">
      <div class="place_card place-desc">
        <div class="tc_bg">
          <h4>{{ placeDetailsModel.name }}</h4>
          <div class="d-flex align-items-center action-wrapper">
            <!-- <mat-form-field appearance="fill">
                    <mat-label>Choose Language</mat-label>
                    <mat-select disableOptionCentering (change)="changeDescriptionLang($event)" >
                      <mat-option selected value="english">English</mat-option>
                       <mat-option *ngFor="let item of placeDetailsModel.descriptions_in_other_language" [value]="item.description">
                       {{ item.language }}
                       </mat-option>
                    </mat-select>
                 </mat-form-field> -->
            <a class="ryec_btn ml-2" *ngIf="checkAuth()" (click)="suggestContent(descriptionContentSuggest)">
              <i class="fa fa-lightbulb-o d-md-none"></i>
              {{ 'ang_suggestContent' | translate }}
            </a>
          </div>
        </div>
        <div class="description" #description id="description" [innerHtml]="placeDetailsModel.descriptions" >
          <!-- description content goes here -->
        </div>
      </div>
    </div>

    <!-- Top Video Section -->
    <ng-template [ngIf]="assetTypeFieldsModel.video == '1' && topVideosSectionDetailsModel?.length > 0">
      <div class="col-sm-12">
        <div class="place_card">
          <div class="tc_bg">
            <h4>{{ 'ang_videos' | translate }}</h4>
          </div>
          <div class="tab-wrapper">
            <owl-carousel #owlElement [options]="nearbyOptions" [carouselClasses]="['owl-theme', 'nearby-carousel', 'sliding']" [items]="topVideosSectionDetailsModel" >
              <div class="item thumbnail" *ngFor="let item of topVideosSectionDetailsModel; let i = index" >
                <div class="tab_card place_card position-relative">
                  <a routerLink="/video/{{ item.id }}" target="_blank" title="{{ item.title }}" >
                    <i class="tab_img-wrap">
                      <img class="tab_card-image" src="{{ item.thumbnail_url }}" alt="{{ item.title }}" loading="lazy"/>
                      <div class="play-icon">
                        <img src="assets/images/play-icon.svg" alt="Play" />
                      </div>
                    </i>
                    <div class="tab_card-content">
                      <h4 class="font-600">
                        <a routerLink="/video/{{ item.id }}" target="_blank" title="{{ item.title }}" >
                          {{ item.title }}
                        </a>
                      </h4>
                      <div class="d-flex justify-content-between place-category" >
                        <span class="text-gray mr-2">
                          <span>
                            {{ item.description.length > 1 ? (item.description | slice: 0:25) + ".." : item.description }}
                          </span>
                        </span>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </owl-carousel>
          </div>
        </div>
      </div>
    </ng-template>

    <!-- Know More section -->
    <ng-template [ngIf]="assetTypeFieldsModel.know_more == '1' && knowMoreSectionDetailsModel?.length > 0">
      <div class="col-sm-12">
        <div class="place_card place-desc">
          <div class="tc_bg">
            <h4>Know more about {{ placeDetailsModel.name }}</h4>
            <div class="d-flex align-items-center action-wrapper">
              <a class="ryec_btn ml-2" *ngIf="checkAuth()" (click)="suggestContent(knowMoreContentSuggest)">
                <i class="fa fa-lightbulb-o d-md-none"></i>
                {{ 'ang_suggestContent' | translate }}
              </a>
            </div>
          </div>
          <ngb-accordion #acc="ngbAccordion" [closeOthers]="true" activeIds="ngb-panel-0">
            <ngb-panel *ngFor="let item of knowMoreSectionDetailsModel; let i = index">
              <div class="panel-title">
                <ng-template ngbPanelTitle>
                  <span>{{ item.title }} <i class="fa fa-caret-down"></i></span>
                </ng-template>
              </div>
              <div class="panel-body">
                <ng-template ngbPanelContent>
                  <div [innerHtml]="item.description">
                    {{ item.description }}
                  </div>
                </ng-template>
              </div>
            </ngb-panel>
          </ngb-accordion>
        </div>
      </div>
    </ng-template>

    <!-- Near By Section Only Carousel-->
    <ng-template [ngIf]=" assetTypeFieldsModel.near_by == '1' && nearBySectionDetailsModel?.length > 0">
      <div class="col-sm-12" *ngFor="let item of nearBySectionDetailsModel; let i = index">
        <div class="place_card">
          <div class="tc_bg">
            <h4>{{ item.title }}</h4>
          </div>
          <div class="tab-wrapper">
            <owl-carousel #owlElement [options]="nearbyOptions" [carouselClasses]="['owl-theme', 'nearby-carousel', 'sliding']" [items]="item.entities">
              <div class="item thumbnail" *ngFor="let entitiesItem of item.entities">
                <div class="tab_card place_card position-relative">
                  <div class="tag-wrapper" *ngIf="entitiesItem.metatags?.length">
                    <span *ngFor="let metaTag of entitiesItem.metatags | slice: 0:1; let i = index" class="tag tag-warning">
                      {{ metaTag }}
                    </span>
                  </div>
                  <a routerLink="{{entitiesItem.entity_type + entitiesItem.business_slug}}" title="{{ entitiesItem.name }}">
                    <i class="tab_img-wrap">
                      <img class="tab_card-image" src="{{ entitiesItem.business_logo_url }}" alt="{{ entitiesItem.name }}" loading="lazy"/>
                    </i>
                    <div class="tab_card-content">
                      <h4 class="font-600">
                        <a routerLink="{{ entitiesItem.entity_type + entitiesItem.business_slug }}" title="{{ entitiesItem.name }}" >
                          {{ entitiesItem.name }}
                        </a>
                      </h4>
                      <div class="d-flex justify-content-between place-category" >
                        <span class="text-gray mr-2">
                          <span>
                            {{ entitiesItem.categories.length > 1 ? (entitiesItem.categories | slice: 0:25) + ".." : entitiesItem.categories }}
                          </span>
                        </span>
                      </div>
                      <div class="ratings_reviews" *ngIf="entitiesItem.total_review > 0" >
                        <div class="d-flex align-items-center rate_bar">
                          <ngb-rating [ngClass]="appName == 'RYC' ? 'rating-star-ryc' : 'rating-star-mrc'" [(rate)]="entitiesItem.avg_rating" [readonly]="true" (max)="5"></ngb-rating>
                          <em class="review ml-2" >({{ entitiesItem.total_review }} Reviews)</em>
                        </div>
                      </div>
                      <div class="ratings_reviews" *ngIf="entitiesItem.total_review == 0" >
                        <div class="d-flex align-items-center rate_bar">
                          <ngb-rating [ngClass]="appName == 'RYC' ? 'rating-star-ryc' : 'rating-star-mrc'" [(rate)]="entitiesItem.avg_rating" [readonly]="true" (max)="5"></ngb-rating>
                          <em class="review ml-2" >({{ entitiesItem.total_review }} Reviews)</em>
                        </div>
                      </div>
                      <p *ngIf="entitiesItem.description" [innerHtml]="entitiesItem.description.length > 1 ? (entitiesItem.description | slice: 0:50) + '..' : entitiesItem.description"
                      ></p>
                    </div>
                  </a>
                </div>
              </div>
            </owl-carousel>
          </div>
        </div>
      </div>
    </ng-template>

    <!-- Rating & Review Section -->
    <ng-template [ngIf]="assetTypeFieldsModel.rating == '1'" && ratingReviewsModel >
      <div class="col-sm-12" *ngIf="assetTypeFieldsModel.rating == '1'">
        <div class="place_card">
          <div class="tc_bg">
            <h4>{{ "ang_ratingsandreview" | translate }}</h4>
            <div class="all_rating_outer">
              <a href="javascript:void();" *ngIf="checkAuth()" [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" (click)="addReviewAndRating(contentRating)" >
                {{ "ang_rateus" | translate }}
              </a>
            </div>
          </div>
          <div class="ratings_reviews row" *ngIf="ratingReviewsModel?.total">
            <div class="star_section col-md-4">
              <p>{{ 'ang_averageRating' | translate}}</p>
              <div class="rate_bar">
                <span class="rate">
                  <span>
                    {{ ratingReviewsModel.avg_rating }}
                  </span>
                  <em>{{ 'ang_outOf' | translate }} 5</em>
                </span>
                <div class="d-flex align-items-center">
                  <ngb-rating [ngClass]="appName == 'RYC' ? 'rating-star-ryc' : 'rating-star-mrc'" [(rate)]="ratingReviewsModel.avg_rating" [readonly]="true" (max)="5" >
                  </ngb-rating>
                  <em class="review ml-2">
                    ({{ ratingReviewsModel.total }} {{ 'ang_reviews' | translate }})
                  </em>
                </div>
              </div>
            </div>
            <div [ngClass]="appName == 'RYC' ? 'progress_bar_cst_ryc' : 'progress_bar_cst_mrc'" class="col-md-8" *ngIf="placeDetailsModel?.rating?.total" >
              <span *ngIf="start_5">{{ "ang_5star" | translate }}</span>
              <p *ngIf="start_5">
                <ngb-progressbar [value]="start_5"></ngb-progressbar>
                <span
                  class="rate-count"
                  *ngIf="placeDetailsModel.rating.start_5_rating == 1"
                >
                  ({{ placeDetailsModel.rating.start_5_rating }} Rate)
                </span>
                <span
                  class="rate-count"
                  *ngIf="placeDetailsModel.rating.start_5_rating > 1"
                >
                  ({{ placeDetailsModel.rating.start_5_rating }} Rates)
                </span>
              </p>

              <span *ngIf="start_4">{{ "ang_4star" | translate }}</span>
              <p *ngIf="start_4">
                <ngb-progressbar [value]="start_4"></ngb-progressbar>
                <span
                  class="rate-count"
                  *ngIf="placeDetailsModel.rating.start_4_rating == 1"
                >
                  ({{ placeDetailsModel.rating.start_4_rating }} Rate)
                </span>
                <span
                  class="rate-count"
                  *ngIf="placeDetailsModel.rating.start_4_rating > 1"
                >
                  ({{ placeDetailsModel.rating.start_4_rating }} Rates)
                </span>
              </p>

              <span *ngIf="start_3">{{ "ang_3star" | translate }}</span>
              <p *ngIf="start_3">
                <ngb-progressbar [value]="start_3"></ngb-progressbar>
                <span
                  class="rate-count"
                  *ngIf="placeDetailsModel.rating.start_3_rating == 1"
                >
                  ({{ placeDetailsModel.rating.start_3_rating }} Rate)
                </span>
                <span
                  class="rate-count"
                  *ngIf="placeDetailsModel.rating.start_3_rating > 1"
                >
                  ({{ placeDetailsModel.rating.start_3_rating }} Rates)
                </span>
              </p>

              <span *ngIf="start_2">{{ "ang_2star" | translate }}</span>
              <p *ngIf="start_2">
                <ngb-progressbar [value]="start_2"></ngb-progressbar>
                <span
                  class="rate-count"
                  *ngIf="placeDetailsModel.rating.start_2_rating == 1"
                >
                  ({{ placeDetailsModel.rating.start_2_rating }} Rate)
                </span>
                <span
                  class="rate-count"
                  *ngIf="placeDetailsModel.rating.start_2_rating > 1"
                >
                  ({{ placeDetailsModel.rating.start_2_rating }} Rates)
                </span>
              </p>

              <span *ngIf="start_1">{{ "ang_1star" | translate }}</span>
              <p *ngIf="start_1">
                <ngb-progressbar [value]="start_1"></ngb-progressbar>
                <span
                  class="rate-count"
                  *ngIf="placeDetailsModel.rating.start_1_rating == 1"
                >
                  ({{ placeDetailsModel.rating.start_1_rating }} Rate)
                </span>
                <span
                  class="rate-count"
                  *ngIf="placeDetailsModel.rating.start_1_rating > 1"
                >
                  ({{ placeDetailsModel.rating.start_1_rating }} Rates)
                </span>
              </p>
            </div>
          </div>
          <p class="my-2" *ngIf="!ratingReviewsModel?.total">
            {{ "ang_noratingsandreviewfound" | translate }}
          </p>
          <!-- ratings_reviews End -->
          <div class="read_review" *ngIf="ratingReviewsModel?.total">
            <div
              class="all_ratings ratings_reviews clearfix"
              *ngIf="ratingReviewsModel.reviews?.length"
            >
              <div
                class="ratings_comment"
                *ngFor="let item of ratingReviewsModel.reviews"
              >
                <div class="rating-user">
                  <div class="comment_img">
                    <img alt="user_profile" src="{{ item.image_url }}" loading="lazy"/>
                  </div>
                  <div class="rate_details">
                    <div class="ratings_name w-100">
                      <div
                        class="
                          position-relative
                          d-flex
                          justify-content-between
                          flex-wrap
                        "
                      >
                        <h5>{{ item.name }}</h5>
                        <div class="rating_time">
                          <span>{{ item.timestamp | date: "medium" }}</span>
                        </div>
                      </div>
                      <div class="total_ratings">
                        <div class="rate_bar">
                          <ngb-rating
                            [ngClass]="appName == 'RYC' ? 'rating-star-ryc' : 'rating-star-mrc'"
                            [(rate)]="item.rating"
                            [readonly]="true"
                            (max)="5"
                          >
                          </ngb-rating>
                        </div>
                      </div>
                      <div class="comment_view">
                        <p>
                          {{ item.review }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div [ngClass]="appName == 'RYC' ? 'ryc_pagination_view' : 'mrc_pagination_view'" *ngIf="ratingReviewsModel?.total">
                <ngb-pagination
                  [collectionSize]="collectionSize"
                  [(page)]="currentPage"
                  size="sm"
                  (pageChange)="getNextPageRatingList($event)"
                ></ngb-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <!-- Timeline section -->
    <div
      class="col-sm-12"
      *ngIf="
        assetTypeFieldsModel.custom_component == '1' &&
        customDetailsModel?.length > 0
      "
    >
      <div
        class="place_card"
        *ngFor="let item of customDetailsModel; let i = index"
      >
        <div class="tc_bg">
          <h4>{{ item.title }}</h4>
        </div>
        <div [innerHtml]="item.description">
          {{ item.description }}
        </div>
        <!-- <ul class="status-timeline">
              <li>
                 <div class="status-update-outer">
                    <div class="date-wrapper">
                       <p>(1925)</p>
                    </div>
                    <div class="status-detail">
                       <div class="vertical-row pb-0"></div>
                       <div class="user-details w-100">
                          <p>Rana Pratap</p>
                       </div>
                    </div>
                 </div>
              </li>
           </ul> -->
      </div>
    </div>
  </div>
  <button [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" class="back-to-top" *ngIf="isShow" (click)="gotoTop()">
    <img src="assets/images/up-arrow.svg" alt="Back to top" />
  </button>
</div>
<!-- Fix action buttons -->
<div class="fix-action-btn">
  <button *ngIf="placeDetailsModel.country_code && placeDetailsModel.mobile" (click)="onWhatsAppBtn(placeDetailsModel.country_code, placeDetailsModel.mobile)" type="button" [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" title="{{ 'ang_whatsApp' | translate }}" >
    <i class="fa fa-whatsapp"></i>
  </button>
  <a *ngIf="placeDetailsModel.country_code && placeDetailsModel.mobile" href="{{ 'tel:' + placeDetailsModel.country_code + placeDetailsModel.mobile }}" [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" title="{{ 'ang_call' | translate }}" >
    <i class="fa fa-phone"></i>
  </a>
  <!-- <button [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'"> <i class="fa fa-share-alt"></i> </button> -->
  <button *ngIf="!isMobile && isPageLoaded" [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" (click)="openShareOptions(contentShare)" title="{{ 'ang_share' | translate }}" >
    <i class="fa fa-share-alt"></i>
  </button>
  <button *ngIf="isMobile && isPageLoaded" [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" (click)="shareNow()" title="{{ 'ang_share' | translate }}" >
    <i class="fa fa-share-alt"></i>
  </button>
  <button *ngIf="checkAuth()" [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" (click)="loadReportBusinessComponent()" title="{{ 'ang_reportBusinessTitle' | translate }}" >
    <i class="fa fa-exclamation-triangle"></i>
  </button>
</div>
<!--- Add Rating and Reviews-->
<ng-template #contentRating let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h5 class="modal-title">
      {{ "ang_ratingsandreview" | translate }}
    </h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="d('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="form-horizontal" novalidate (submit)="submitRatingDetail()">
      <div class="d-flex">
        <span class="rate">{{ "ang_giverating" | translate }}</span>
        <div class="rate_bar">
          <ngb-rating [(rate)]="currentRate" (max)="5"></ngb-rating>
        </div>
      </div>
      <div class="control-group">
        <div class="controls">
          <!-- <input type="text"  formControlName="review" id="review" placeholder="Add review"
                 class="input-xlarge"> -->
          <textarea
            [(ngModel)]="review"
            name="review"
            placeholder="{{ 'ang_addreview' | translate }}"
            required
          ></textarea>
          <p class="help-block" *ngIf="reviewValid != false">
            {{ "ang_reviewisrequired" | translate }}
          </p>
        </div>
      </div>
      <div class="profile_btn">
        <button [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" [disabled]="currentRate == 0" type="submit">
          {{ "ang_submit" | translate }}
        </button>
      </div>
    </form>
  </div>
</ng-template>
<!--- Add Rating and Reviews End-->

<!-- DescriptionContentSuggest Content Suggest Modal -->
<ng-template #descriptionContentSuggest let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h5 class="modal-title">{{ placeDetailsModel.name }}</h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="d('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form
      class="form-horizontal"
      novalidate
      (submit)="submitDescriptionSuggest()"
    >
      <div class="control-group">
        <div class="controls">
          <textarea
            [(ngModel)]="review"
            name="review"
            placeholder="{{ 'ang_addsuggest' | translate }}"
          ></textarea>
        </div>
      </div>
      <div class="profile_btn">
        <button [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" type="submit">
          {{ "ang_submit" | translate }}
        </button>
      </div>
    </form>
  </div>
</ng-template>

<!-- knowMoreContentSuggest Content Suggest Modal -->
<ng-template #knowMoreContentSuggest let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h5 class="modal-title">{{ placeDetailsModel.name }}</h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="d('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="form-horizontal" novalidate (submit)="submitKnowMoreSuggest()">
      <div class="control-group">
        <div class="controls">
          <textarea
            [(ngModel)]="review"
            name="review"
            placeholder="{{ 'ang_addsuggest' | translate }}"
          ></textarea>
        </div>
      </div>
      <div class="profile_btn">
        <button [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" type="submit">
          {{ "ang_submit" | translate }}
        </button>
      </div>
    </form>
  </div>
</ng-template>

<!-- Image carousel Modal -->
<ng-template #ImageCarouselModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="d('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="carousel-wrapper place_card p-0">
      <owl-carousel
        #owlElement
        [options]="customOptions"
        [carouselClasses]="['owl-theme', '', 'sliding']"
        [items]="placeDetailsModel.business_images"
      >
        <div
          class="item"
          *ngFor="let item of placeDetailsModel.business_images; let i = index"
        >
          <div class="">
            <img _ngcontent-c6="" src="{{ item.image_name }}" />
          </div>
        </div>
      </owl-carousel>
    </div>
  </div>
</ng-template>

<ng-template #contentShare let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h5 class="modal-title">{{ "ang_share" | translate }}</h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="d('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="social_profile">
      <ul>
        <li>
            <a href="javascript:void(0)" (click)="shareOnFacebook()">
              <img alt="facebook" src="../assets/images/facebook.png" />
            </a>
        </li>
        <li>
          <a href="javascript:void(0)" (click)="shareOnTwitter()">
            <img alt="twitter" src="../assets/images/twitter.png" />
          </a>
        </li>
        <li>
          <a href="javascript:void(0)" (click)="shareOnLinkedIn()">
            <img alt="linkedin" src="../assets/images/linkedin.png" />
          </a>
        </li>
      </ul>
    </div>
    <!-- social_profile End -->
  </div>
</ng-template>
<ngx-json-ld [json]="schemaJson"></ngx-json-ld>
<!-- <div [innerHTML]="jsonLD"></div> -->
