<div class="container">
  <div class="business_detail_view">
    <div class="business_title">
      <div class="row business_all_btn">
        <div class="col-md-6 col-xs-12">
          <h2 *ngIf="!isEdit" >{{'ang_submit_free_ads_msg' | translate}}</h2>
          <h2 *ngIf="isEdit" >{{'ang_editad' | translate}}</h2>
        </div>
      </div>
    </div><!-- business title end-->
    <form class="form-horizontal" [formGroup]="myform" #form="ngForm">
      <div class="row edit_business_form">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <div class="control-group">
            <label class="control-label" for="name">
              {{'ang_title' | translate}}
            </label>
            <div class="controls">
              <input type="text" formControlName="Name" [(ngModel)]="formData.name" id="name" name="name"  class="input-xlarge" required>
            </div>
            <p class="help-block" *ngIf="f.Name.errors && (f.Name.touched || submitted)">
              {{'ang_titleisrequired' | translate}}
            </p>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <div class="control-group">
            <div class="category_select top">
              <label>{{'ang_type' | translate}}</label>
              <select formControlName="AdsType" [(ngModel)]="formData.ads_type">
                <option value="0">Buy</option>
                <option value="1">Sell</option>
                <option value="2">Service</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="row edit_business_form">
        <div class="col-sm-12 col-xs-12">
          <div class="control-group">
            <label class="control-label" for="description">
              {{'ang_description' | translate}}
            </label>
            <div class="controls">
              <textarea formControlName="Description" [(ngModel)]="formData.descriptions" id="description" name="description"></textarea>
            </div>
            <p class="help-block" *ngIf="f.Description.errors && (f.Description.touched || submitted)">
              {{'ang_descriptionisrequired' | translate}}
            </p>
          </div>
        </div><!-- col End -->
      </div><!-- row End -->
      <div class="row edit_business_form">
        <div class="col-sm-12 col-xs-12">
          <div class="control-group">
            <div class="category_select top">
              <label class="control-label" for="price">
                {{'ang_price' | translate}}
                <i class="fa fa-info-circle" aria-hidden="true" title="{{'ang_productcosthelp' | translate}}"></i>
              </label>
              <div class="controls">
                <input type="number" min="0" id="price" name="price" formControlName="Price" [(ngModel)]="formData.price" class="input-xlarge">
              </div>
            </div>
          </div>
        </div><!-- col End -->
      </div>
      <div class="row edit_business_form">
        <div class="col-sm-12 col-xs-12">
          <div class="control-group">
            <div class="category_select top">
              <label class="control-label">
                {{'ang_select_parent_category' | translate}}
              </label>
              <ngx-select-dropdown
              (change)="changeSelection($event)"
              formControlName="Category"
              (config)="config"
              (options)="allCategoryArr"
              (multiple)="false">
              </ngx-select-dropdown>
            </div>
          </div>
          <div class="category_list" *ngIf="categoryArr.length > 0">
            <div class="control-group">
              <ul>
                <li *ngFor="let item of categoryArr; let i = index;" (click)="onChangeCheckbox(item)">
                  <span>{{item.name}}</span>
                  <div class="checkbox-cst">
                    <input type="checkbox" id="{{item.category_id}}" [checked]="isSelectedChange(item.category_id)" (change)="onChangeCheckbox(item)">
                    <label for="{{item.category_id}}"></label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <!--category list end-->
          <div class="category_selected">
            <!-- <mat-chip-list *ngIf="selectedCategoryArr.length > 0">
              <ng-container *ngFor="let item of selectedCategoryArr">
                  <mat-chip>
                      <span>{{item.parent_name}} / {{item.name}}</span>
                      <i (click)="onRemoveSelection(item)" class="fa fa-times" aria-hidden="true"></i>
                    </mat-chip>
              </ng-container>
            </mat-chip-list> -->
          </div>
        </div><!-- col End -->
      </div><!-- row End -->
      <div class="row edit_business_form">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <div class="control-group">
            <div class="category_select top">
              <label>{{'ang_city' | translate}}</label>
              <ngx-select-dropdown
              (config)="config"
              (options)="allCities"
              formControlName="City"
              (multiple)="false">
              </ngx-select-dropdown>
            </div>
          </div><!-- control-group End -->
        </div><!-- col End -->
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <div class="control-group">
            <div class="category_select top">
              <label for="location">
                {{'ang_location' | translate}}
                <i class="fa fa-info-circle" aria-hidden="true" title="{{'ang_location_info' | translate}}"></i>
              </label>
              <div class="controls">
                  <input type="text" places-auto-complete (place_changed)="placeChanged($event)" id="location" formControlName="Location" class="input-xlarge">
              </div>
            </div>
          </div>
        </div><!-- col End -->
      </div><!-- row End -->
      <div class="row edit_business_form">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <div class="categories_gallery clearfix">
            <input type="file" (change)="uploadAdsimages($event)" style="display: none;" accept="image/x-png,image/gif,image/jpeg" #adsImages>
            <label>
              {{'ang_uploadimage' | translate}}
              <i class="fa fa-info-circle" aria-hidden="true" title="{{'ang_images_validate_info' | translate}}"></i>
              <span class="browse_btn pull-right" (click)="triggerUpload()">{{'ang_browse' | translate}}</span>
            </label>
            <ul>
              <li *ngFor="let img of base64AdsImages; let i = index;">
                <span><i aria-hidden="true" class="fa fa-times" (click)="deleteAdsImage(i)"></i></span>
                <img [src]="img">
              </li>
            </ul>
            <ul *ngIf="uploaedImages.length !== 0" >
              <li *ngFor="let img of uploaedImages; let i = index;">
                <span><i aria-hidden="true" class="fa fa-times" (click)="deleteAdsImage(i,img?.id)"></i></span>
                <img [src]="img?.original_url">
              </li>
            </ul>
          </div><!-- categories_gallery End -->
        </div><!-- col End -->
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <div class="categories_gallery clearfix">
            <label>
              {{'ang_upload_video_text' | translate}}
              <i class="fa fa-info-circle" aria-hidden="true" title="{{'ang_video_validate_info' | translate}}"></i>
              <span class="browse_btn pull-right" (click)="addNewVideoURL()">{{'ang_addnew' | translate}}</span>
            </label>
            <ul formArrayName="VideoUrlArr" class="video-list">
              <li *ngFor="let item of VideoUrlArr.controls; let i = index">
                  <input type="text" [formControlName]="i" class="form-control">
                  <i class="fa fa-trash" (click)="onRemoveVideoUrl(i)" aria-hidden="true"></i>
              </li>
            </ul>
          </div>
        </div><!-- col End -->
      </div><!-- Row End -->
      <div class="row">
        <div class="col-md-6 col-xs-12">
          <div class="profile_btn">
            <button [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" type="submit" (click)="onCreateNewAds()">{{'ang_save' | translate}}</button>
          </div>
        </div>
        <div class="col-md-6 col-xs-12">
          <div class="profile_btn">
            <button [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" (click)="onCancelClick()">{{'ang_cancel' | translate}}</button>
          </div>
        </div>

      </div>
      <div class="row justify-content-center">
          <div class="col-xs-12 " *ngIf="isEdit" >
              <div class="profile_btn closed-ad">
                <button [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" (click)="onCloseAd(closeAdsPopup)">{{'ang_closead' | translate}}</button>
              </div>
            </div>
      </div>
    </form>
  </div>
</div>

<ng-template #closeAdsPopup let-c="close" let-d="dismiss">
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="d('close')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body cancel-modal">
    <p>{{'ang_closead_text' | translate}}</p>
  </div>
  <div class="modal-footer cancel-modal-foo">
    <button type="button"  class="btn btn-light ryec_btn" (click)="d('close')">{{'ang_cancel' | translate}}</button>
    <button type="button" class="btn btn-light ryec_btn" (click)="d('yes')">{{'ang_yes' | translate}}</button>
  </div>
</ng-template>
