import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OwlCarousel } from 'ngx-owl-carousel';
import { CommonService } from 'src/app/shared/services/common.service';
import { HomeService } from 'src/app/shared/services/home.service';
import { HttpService } from 'src/app/shared/services/http.service';
import Swal from 'sweetalert2';
import swal from 'sweetalert2';
import { environment } from '../../../../../environments/environment';

import { JobApplyModalComponent } from '../job-apply-modal/job-apply-modal.component';

@Component({
  selector: 'ryec-group-job-details',
  templateUrl: './group-job-details.component.html',
  styleUrls: ['./group-job-details.component.css']
})
export class GroupJobDetailsComponent implements OnInit {
  jobsDetailsModel: any ;
  userId: number = 0;
  jobId: number = 0;
  isMobile: boolean = false;
  isShow: boolean = false;
  topPosToStartShowing = 100;
  jobCreatedByUserId: number = 0;

  @ViewChild('owlElement')
  owlElement!: OwlCarousel;
  fun() {
    this.owlElement.next([200]);
  }
  customOptions = {
    loop: true,
    dots: false,
    nav: true,
    items: 1,
    margin: 0,
    startPosition: 0
  }
  appName = environment.APP_NAME;

  constructor(
    public cS: CommonService,
    private activeRoute: ActivatedRoute,
    private homeService: HomeService,
    private deviceService: DeviceDetectorService,
    private modalService: NgbModal,
    private router: Router,
    private httpS: HttpService,
  ) {
    const userDetail = this.cS.getUserDetails();
    if (userDetail) {
      this.userId = userDetail.id;
    }
  }

  ngOnInit() {
    this.activeRoute.params.subscribe(data => {
      this.jobId = data.slug;
      if (this.jobId) { //if id is available call api
        this.getJobDetailsById(this.jobId);
      }
    });

    this.isMobile = this.deviceService.isMobile();
  }

  /**
   * if get jobId from query params call api to get details of job
   * @Yuvraj 08/04/2022
   */
  getJobDetailsById(jobId: number) {
    const postJson = {
      job_vacancy_id: jobId,
    };
    this.homeService.getJobDetailsById(postJson).subscribe((res: any) => {
      if (res.data) {
        this.jobsDetailsModel = res.data;
        this.jobCreatedByUserId = this.jobsDetailsModel.user.id;
        this.convertQualificationToArray();
      }
    });
  }

  convertQualificationToArray() {
    if (this.jobsDetailsModel.qualification) {
      this.jobsDetailsModel.qualification = this.jobsDetailsModel.qualification.split(',');
    }
    else {
      this.jobsDetailsModel.qualification = [];
    }
  }

  loadJobApplyModalComponent(item: any) {
    const modalReference = this.modalService.open(JobApplyModalComponent, { windowClass: 'mobile_popup' });
    modalReference.componentInstance.jobId = item.id; //job id
    modalReference.componentInstance.jobName = item.title; //job name
    modalReference.result.then((result: any) => {
      console.log(`Closed with: ${result}`);
    }, (reason: any) => {
      if (reason == 'close') {
        // this.checkRouterUrlForCallingAPI();
        this.getJobDetailsById(this.jobId);
      }
    });
  }

  onWithdrawJobApply(item: any) {
    swal.fire({
      // title: 'Are you sure you want to withdraw' + item.title + ' ?',
      title: 'Are you sure you want to withdraw this job application?',
   
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      showCancelButton: true,
      showCloseButton: true,
      reverseButtons: true,
     
    }).then((result) => {
      if (result.value) {
        let tempModel: any = {};
        tempModel.job_vacancy_id = item.id;
        const apiUrl = environment.RYEC_API_URL + 'cancelJobApply';
        this.httpS.post(apiUrl, tempModel).subscribe((res: any) => {
          if (res.status === 1) {
            this.cS.showSuccess(res.message);
            this.getJobDetailsById(this.jobId);
          }
          else {
            this.cS.showError(res.message);
          }
        });
      }
    });
  }

  onEdit(id: any) {
    this.router.navigate(['/job/edit/' + id]);
  }

  onJobDelete(id: number, name: string) {
    swal.fire({
      title: 'Are you sure you want to delete ' + name + ' ?',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      showCancelButton: true,
      showCloseButton: true,
      reverseButtons: true,
      
    }).then((result) => {
      if (result.value) {
        this.delteAndCancelJob(id, 'delete');
      }
    });
  }

  onCloseJob(id: number, name: string) {
   
    Swal.fire({
      title: 'Are you sure you want to remove ?',
     
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      showCancelButton: true,
      showCloseButton: true,
      reverseButtons: true,
     
      
    }).then((result) => {
      
      if (result.value) {
        this.delteAndCancelJob(id, 'close');
      }
      })


}

  delteAndCancelJob(id: number, type: string) {
    let tempModel: any = {};
    tempModel.job_vacancy_id = id;
    let apiUrl: string = "";
    if (type == 'delete') {
      apiUrl = environment.RYEC_API_URL + 'deleteJob';
    }
    else if (type == 'close') {
      apiUrl = environment.RYEC_API_URL + 'JobClose';
    }
    this.httpS.post(apiUrl, tempModel).subscribe((res: any) => {
      if (res.status === 1) {
        this.cS.showSuccess(res.message);
        if (this.jobsDetailsModel.site_id) {
          this.router.navigate(['/jobs/' + this.jobsDetailsModel.site_id]);
        }
        else {
          this.router.navigate(['/jobs']);
        }
      }
      else {
        this.cS.showError(res.message);
      }
    });
  }

  loadApplicantComponent(id: number) {
    this.router.navigate(['/job/applicant/' + id]);
  }

  openShareOptions(content: any) {
    this.modalService.open(content, { windowClass: 'share_popup' }).result.then((result) => {
      console.log(`Closed with: ${result}`);
    }, (reason) => {
      console.log(reason);
    });
  }

  shareNow = async () => {
    const navigator = window.navigator as any;
    if ('canShare' in navigator) {
      // let currentFullUrl = window.location.href + '?lang=' + urlLang;
      let currentFullUrl = window.location.href;
      let title = this.jobsDetailsModel.title;
      let desc = this.jobsDetailsModel.description;
      let imageUrl = this.jobsDetailsModel.image_fullurl;
      const share = async function () {
        try {
          // const response = await fetch(imageUrl, {mode: 'no-cors'});
          const response = await fetch(imageUrl);
          const blob = await response.blob();
          const file = new File([blob], 'image.jpg', { type: blob.type });
          await navigator.share({
            url: currentFullUrl,
            title: title,
            text: desc,
            files: [file],
          });
          console.log("shared successfully....");
        } catch (err:any) {
          console.log(err.name, err.message);
        }
      };
      share();
    }
  };

  OpenCoverImageModal(content: any) {
    this.modalService.open(content, { windowClass: 'full-screen_img', centered: true }).result.then((result) => {
      console.log(`Closed with: ${result}`);
    }, (reason) => {
      console.log(reason);
    });
  }

  shareOnFacebook() {
    let currentFullUrl = window.location.href;
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${currentFullUrl}`, 'sharer');
  }

  shareOnTwitter() {
    let currentFullUrl = window.location.href;
    window.open(`https://twitter.com/intent/tweet?url=${currentFullUrl}`, 'sharer');
  }

  // // LinkedIn share won't work if your shareUrl is localhost:port/abc, it should be genuine deployed url
  shareOnLinkedIn() {
    let currentFullUrl = window.location.href;
    window.open(`https://www.linkedin.com/shareArticle?url=${currentFullUrl}`, 'sharer');
  }


  // Back to top button
  @HostListener('window:scroll')
  checkScroll() {
    // window의 scroll top
    // Both window.pageYOffset and document.documentElement.scrollTop returns the same result in all the cases. window.pageYOffset is not supported below IE 9.
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }

  }

  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

}
