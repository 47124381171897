import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserRoutingModule } from './user-routing.module';
import { UserComponent } from './user.component';
import { AddOwnerComponent } from './add-owner/add-owner.component';

import { editUserProfileComponent } from './user-profile/edit-user-profile/edit-user-profile.component';


import { groupsUserProfileComponent } from './user-profile/groups-user-profile/groups-user-profile.component';
import { FormsModule } from '@angular/forms';
import { ImageCropperModule } from "ngx-image-cropper";
import { SharedModule } from 'src/app/shared/module/shared/shared.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { aboutUserProfileComponent } from './user-profile/about-user-profile/about-user-profile.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { postUserProfileComponent } from './user-profile/post-user-profile/post-user-profile.component';
import { settingsUserProfileComponent } from './user-profile/settings-user-profile/settings-user-profile.component';






@NgModule({
  declarations: [
    UserComponent,
    UserProfileComponent,
    AddOwnerComponent,
    aboutUserProfileComponent,
    editUserProfileComponent,
    postUserProfileComponent,
    settingsUserProfileComponent,
    groupsUserProfileComponent,
    ChangePasswordComponent,

  ],
  imports: [
    PerfectScrollbarModule,
    NgbModule,
    FormsModule,
    SharedModule,
    CommonModule,
    UserRoutingModule,
    ImageCropperModule,
  
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: PERFECT_SCROLLBAR_CONFIG
    }

  ]

})

export class UserModule { }
