import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse, } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { timer } from 'rxjs/observable/timer';

// import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
 

import 'rxjs/add/operator/do';
import 'rxjs/add/operator/finally';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth.service';
import { MessageService } from '../services/message.service';
  



@Injectable()
export class RyecHttpInterceptor implements HttpInterceptor {

    timer: any;
    constructor(public auth: AuthService, private mS: MessageService) {
        this.timer = timer(1000);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let applicationId = environment.APPLICATION_ID;
        let securityKey = environment.API_SECURITY_KEY;
       

        //console.log('intercepted request ... ');

        // Clone the request to add the new header.
        const authReq = req.clone({
            setHeaders: {
                'Authorization': `Bearer ${this.auth.getToken()}`,
                'Platform': 'web',
                'application-id' : applicationId,  //For MRC
                'API-SECURITY-KEY' : securityKey, //For MRC
                'Content-Encoding':'gzip',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
                'Content-Transfer-Encoding':'gzip',
                'X-Frame-Options' :'SAMEORIGIN',
           
                "Content-Security-Policy": "default-src 'self'; script-src 'self' 'unsafe-inline' 'unsafe-eval'; connect-src 'self'; img-src 'self'; style-src 'self' 'unsafe-inline'; font-src 'self' data:;"
        
                

            }
        });
        if (req.url.indexOf('getSearchAutocomplete') > -1 || req.url.indexOf('likePost') > -1  || req.url.indexOf('getAllPosts') > -1  || req.url.indexOf('getThreadMessages') > -1 || req.url.indexOf('getEntityKnowMore') > -1 || req.url.indexOf('getEntityNearBy') > -1 || req.url.indexOf('getBusinessRatings') > -1 || req.url.indexOf('getEntityCustomDetails') > -1 || req.url.indexOf('cityList') > -1 || req.url.indexOf('getAddressMaster?searchText') > -1 || req.url.indexOf('siteAutoSuggestion') > -1 || req.url.indexOf('siteMembers') > -1 || req.url.indexOf('getLocationByPincode') > -1 || req.url.indexOf('suggestSiteMembers') > -1 || req.url.indexOf('getBusinessOnlineStores') > -1 || req.url.indexOf('getAllAdvertisement') > -1 || req.url.indexOf('getAllAdvertisement') > -1 || req.url.indexOf('getPublicWebsiteReviews') > -1) {
            this.mS.setSpinner(false);
        } else {
            this.mS.setSpinner(true);
        }
        // console.log('Sending request with Autorization now ...');
        // show spinner
        // send the newly created request
        return next.handle(authReq).do((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                // do stuff with response if you want
            }
        }, (err: any) => {
            if (err instanceof HttpErrorResponse) {
                if (err.status === 400 && this.auth.checkTypeAuth(err.error)) {
                    if (req.url.indexOf('getAddressMaster') === -1) {
                        this.auth.logout();
                    }
                    this.mS.setSpinner(false);
                }
            }
        }).finally(() => {
            this.timer.subscribe(() => {
                this.mS.setSpinner(false);
            });
        });
        // throw new Error("Method not implemented.");
    }

}

