export class GroupJobModel{
    application_id: any;
    title: string;
    type: string;
    company_name: string;
    workplace_type: any;
    location_id: any;
    employment_type: any;
    description: string;
    skills: string;
    qualification: string;
    experience: any;
    external_link: string;
    image_url: string;
    constructor(){
        this.application_id = 1;
        this.title = "";
        this.type = "Job Hiring";
        this.company_name = "";
        this.workplace_type = null as any;
        this.location_id = 0;
        this.employment_type = null as any;
        this.description = "";
        this.skills = "";
        this.qualification = "";
        this.experience = 0;
        this.external_link = "";
        this.image_url = "";
    }
}