import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
  BusinessListDetailsRes
} from 'src/app/shared/class/data.model';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { Meta } from '@angular/platform-browser';
import { DeviceDetectorService } from 'ngx-device-detector';

import { CommonService } from 'src/app/shared/services/common.service';
import { HomeService } from 'src/app/shared/services/home.service';
//import { AnyTxtRecord } from 'dns';
@Component({
  selector: 'ryec-my-business-detail',
  templateUrl: './my-business-detail.component.html',
  styleUrls: ["./my-business-detail.component.css", "../market-place/market-place.component.css"]
})
export class MyBusinessDetailComponent implements OnInit {

  currentPage: number;
  selectedItem: any;
  selectedIndex: number;
  selectedServiceItem: any;
  selectedServiceIndex: number;
  selectedImageItem: any;
  selectedImageItemIndex: number;
  business_slug = '';
  business_name = '';
  currentRate = 0;
  businessDetail: any;
  productDetail :any;
  serviceDetail :any;
  collectionSize = 0;
  business_id: any;
  modalReference: any;
  activeId = 0;
  totalRatingCount = 0;
  perPageList = environment.BUSINESS_LIST_LIMIT;
  allRatings :any;
  start_5 = 0;
  start_4 = 0;
  start_3 = 0;
  start_2 = 0;
  start_1 = 0;
  trimLength = environment.DESCRIPTIO_TRIM_LENGTH;
  review = '';
  reviewValid = false;
  enquiryForm = {
    title: '',
    description: ''
  };
  validTitle = false;
  validDescription = false;
  validForm = false;
  business_category: Array<string> = [];
  businessStoresList: any;
  isPostView: boolean = false;
  isMobile: boolean = false;
  isPageLoaded: boolean = false;
  userId: number = 0;
  businessImageUrl: string = "";
  repoUrl = window.location.href;
  businessImages: any[] = [];
  businessDescription: string = "";
  assetTypeFieldsModel: any = {};
  appName = environment.APP_NAME;

  constructor(
    private modalService: NgbModal,
    private hS: HomeService,
    private cS: CommonService,
    private meta: Meta,
    private deviceService: DeviceDetectorService,
   // private deviceService: Ng2DeviceService,
    private router: Router) {
    this.businessDetail = {
      status: 0,
      message: ''
    };
    this.selectedIndex = 1;
    this.selectedServiceIndex = 1;
    this.currentPage = 1;
    this.selectedImageItemIndex = 1;

    /*
    *get url slug
    */
    const businessData = this.cS.getBusinessDetail();
    if (businessData) {
      this.business_id = businessData.business_id;
      this.business_slug = businessData.business_slug;
      if (this.business_slug === '') {
        this.router.navigateByUrl('/home');
      } else {
        this.getBusinessDetailBySlug();
      }
    }

    localStorage.removeItem('business_name');
  }

  ngOnInit() {
    this.cS.scrollTop();
    this.isMobile = this.deviceService.isMobile();
  }

  /**
  * Get Business Detail by business slug
  *@returns void
  */
  getBusinessDetailBySlug(): void {
    const postJson = { business_slug: this.business_slug };
    let categoryId: number = 0;

    this.hS.getBusinessDetailBySlug(postJson).subscribe((res: any) => {
      if (res.data) {
        this.businessDetail = res;
        this.business_id = res.data.id;
        this.business_name = res.data.name;
        this.businessImages = res.data.business_images;
        this.businessDescription = res.data.descriptions;
        if (res.data.parent_categories.length > 0) {
          categoryId = res.data.parent_categories[0].category_id;
        }
        this.getSubAssetTypeFields(categoryId, res.data.asset_type_id);
        this.isPageLoaded = true;
        this.getAddedOnlineStores();
        if (res.data.rating) {
          this.totalRatingCount = res.data.rating.total;
        }
        let category = '';
        if (res.data.category_hierarchy.length > 0) {
          for (let x in res.data.category_hierarchy) {
            // for (let i in res.data.category_hierarchy[x]) {
            //     if (parseInt(i) != 0) {
            //         category += ' > ' + res.data.category_hierarchy[x][i].name;
            //     }
            //     else {
            //         category = res.data.category_hierarchy[x][i].name;
            //     }
            // }
            if (parseInt(x) != 0) {
              category += ' > ' + res.data.category_hierarchy[x].name;
            } else {
              category = res.data.category_hierarchy[x].name;
            }
            this.business_category.push(category);
          }
        }
        if(this.businessImages.length > 0){
          this.businessImageUrl = this.businessImages[0].image_name;
          this.meta.updateTag({ name: 'twitter:image', content: this.businessImageUrl });
          this.meta.updateTag({ name: 'og:image', content: this.businessImageUrl });
        }
        this.updateMetaTag(res);
        this.start_5 = Math.round((res.data.rating['start_5_rating'] * 100) / this.totalRatingCount);
        this.start_4 = Math.round((res.data.rating['start_4_rating'] * 100) / this.totalRatingCount);
        this.start_3 = Math.round((res.data.rating['start_3_rating'] * 100) / this.totalRatingCount);
        this.start_2 = Math.round((res.data.rating['start_2_rating'] * 100) / this.totalRatingCount);
        this.start_1 = Math.round((res.data.rating['start_1_rating'] * 100) / this.totalRatingCount);
        this.getNextPageRatingList();
      }
    }, err => {
      console.log(err);
    });
  }

  updateMetaTag(res : any){
    this.meta.updateTag({ name: "title", content: res["data"]["name"], });
    this.meta.updateTag({ name: "keywords", content: res["data"]["seo_meta_tags"], });
    this.meta.updateTag({ name: "description", content: res["data"]["seo_meta_description"], });
    this.meta.updateTag({ name: 'twitter:site', content: this.repoUrl });
    this.meta.updateTag({ name: 'twitter:title', content: res['data']['name'] });
    this.meta.updateTag({ name: 'twitter:description', content: res['data']['seo_meta_description'] });

    this.meta.updateTag({ name: 'og:url', content: this.repoUrl });
    this.meta.updateTag({ name: 'og:type', content: "Business details" });
    this.meta.updateTag({ name: 'og:title', content: res['data']['name'] });
    this.meta.updateTag({ name: 'og:description', content: res['data']['seo_meta_description'] });
  }


  getSubAssetTypeFields(categoryId: number, assetTypeId: number) {
    const postJson = {
      category_id: categoryId,
      asset_type_id: assetTypeId
    };

    this.hS.getSubAssetTypeFields(postJson).subscribe((res: any) => {
      if (res.data) {
        this.assetTypeFieldsModel = res.data;
      }
    }, err => {
      console.log(err);
    });
  }

  /**
  *open Image in modal view
  */
  openImageViwer(index: any, content: any) {
    this.activeId = index;
    this.modalService.open(content, { windowClass: 'image_gallery' }).result.then((result) => {
      console.log(`Closed with: ${result}`);
    }, (reason) => {
      console.log(reason);
    });
  }

  /**
  *open Product detail in modal view
  */
  openProductDetail(product_id: any, content: any) {
    const postJson = { product_id: product_id };
    this.hS.getProductDetailByProductId(postJson).subscribe((res: any) => {
      this.productDetail = res.data;
      this.modalService.open(content, { windowClass: 'product_gallery' }).result.then((result) => {
        console.log(`Closed with: ${result}`);
      }, (reason) => {
        console.log(reason);
      });
    }, err => {
      console.log(err);
    });
  }

  /**
  *open Service detail in modal view
  */
  openServiceDetail(service_id: any, content: any) {
    const postJson = { service_id: service_id };
    this.hS.getServiceDetailByServiceId(postJson).subscribe((res: any) => {
      this.serviceDetail = res.data;
      this.modalService.open(content, { windowClass: 'service_gallery' }).result.then((result) => {
        console.log(`Closed with: ${result}`);
      }, (reason) => {
        console.log(reason);
      });
    }, err => {
      console.log(err);
    });
  }


  countCollectionSize() {
    if (this.totalRatingCount > this.perPageList) {
      this.collectionSize = Math.ceil(this.totalRatingCount / this.perPageList);
      this.collectionSize = this.collectionSize * 10;
    } else {
      this.collectionSize = this.perPageList;
    }
  }

  /**
  *get business rating list
  */
  getNextPageRatingList(event?: number): void {

    const postJson = { business_id: this.business_id, page: this.currentPage };

    this.hS.getBusinessRating(postJson).subscribe((res: any) => {
      if (res.status === 1) {
        this.allRatings = res.data.reviews;
        console.log('revi');
        console.log(this.allRatings);
        this.countCollectionSize();
      }
    }, err => {
      console.log(err);
      console.log(event);
    });
  }

  /**
  *open business list detail view
  */
  openBusinessDescription(content: any) {
    this.modalService.open(content, { windowClass: 'desc_popup' }).result.then((result) => {
      console.log(`Closed with: ${result}`);
    }, (reason) => {
      console.log(reason);
    });
  }

  /**
  *open business list categories list
  */
  openAllCategories(content: string) {
    this.modalService.open(content, { windowClass: 'allcategory_popup' }).result.then((result) => {
      console.log(`Closed with: ${result}`);
    }, (reason) => {
      console.log(reason);
    });
  }

  /**
  * add or update owner data
  * @param ownerId
  */
  viewOwnerData(ownerId?: number) {
    localStorage.setItem('business_name', this.business_name);
    const owner_id = btoa(<any>ownerId);
    this.router.navigate(['/home/business-owner'], { queryParams: { owner_id: owner_id }, queryParamsHandling: 'merge' })
  }

  /**
  *open share popup
  */
  openShareOptions(content: any) {
    this.modalService.open(content, { windowClass: 'share_popup' }).result.then((result) => {
      console.log(`Closed with: ${result}`);
    }, (reason) => {
      console.log(reason);
    });
  }

  shareOnFacebook() {
    let currentFullUrl = window.location.href;
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${currentFullUrl}`, 'sharer');
  }

  shareOnTwitter() {
    let currentFullUrl = window.location.href;
    window.open(`https://twitter.com/intent/tweet?url=${currentFullUrl}`, 'sharer');
  }

  // // LinkedIn share won't work if your shareUrl is localhost:port/abc, it should be genuine deployed url
  shareOnLinkedIn() {
    let currentFullUrl = window.location.href;
    window.open(`https://www.linkedin.com/shareArticle?url=${currentFullUrl}`, 'sharer');
  }

  shareNow = async () => {
    const navigator = window.navigator as any;
    let urlLang: string = "";
    let lang = this.cS.getLang();
    if (lang) {
      if (lang == 'en') {
        urlLang = "en";
      }
      if (lang == 'hi') {
        urlLang = "hi";
      }
    }
    else {
      urlLang = "en";
    }

    if ('canShare' in navigator) {
      let currentFullUrl = window.location.href + '?lang=' + urlLang;
      let title = this.business_name;
      let text = new DOMParser().parseFromString(this.businessDescription, "text/html").documentElement.textContent;
      let imageUrl = this.businessImageUrl;
      const share = async function () {
        try {
          // const response = await fetch(imageUrl, {mode: 'no-cors'});
          const response = await fetch(imageUrl);
          const blob = await response.blob();
          const file = new File([blob], 'image.jpg', { type: blob.type });
          await navigator.share({
            url: currentFullUrl,
            title: title,
            text: text,
            files: [file],
          });
          console.log("shared successfully....");
        } catch (err:any) {
          console.log(err.name, err.message);
        }
      };
      share();
    }
  };

  /**
 * get online added stores
 * @Yuvraj 30/11/2021
 */
  getAddedOnlineStores() {
    const postJson = { business_id: this.business_id };
    this.hS.getBusinessOnlineStores(postJson).subscribe((res: any) => {
      if (res.status == 1) {
        this.businessStoresList = res.data;
      }
      else {
        this.businessStoresList = [];
      }
    });
  }

  /**
   * on click store redirect to store url
   * @Yuvraj 01/12/2021
   */
  onStoreClick(url: string) {
    window.open(url, '_blank');
  }

  onTabClick(event: any) {
    let tabName: string = "";
    tabName = event.tab.textLabel;
    if (tabName == 'Post') {
      const userDetail = this.cS.getUserDetails();
      if (userDetail) {
        this.userId = userDetail.id;
        this.isPostView = true;
      }
    }
    else {
      this.isPostView = false;
    }
  }
}
