import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
 
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { environment } from '../../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { GroupJobModel } from '../../../../shared/model/group-job.model';
import { HttpService } from 'src/app/shared/services/http.service';
import { HomeService } from 'src/app/shared/services/home.service';
import { CommonService } from 'src/app/shared/services/common.service';
declare var $: any;

@Component({
  selector: 'ryec-group-jobs-create',
  templateUrl: './group-jobs-create.component.html',
  styleUrls: ['./group-jobs-create.component.css']
})
export class GroupJobsCreateComponent implements OnInit {
  jobForm = new GroupJobModel();
  groupId: any;
  jobModelData: FormData = new FormData();
  list: any[] = [];
  workplaceTypesList: any[] = [];
  employmentTypesList: any[] = [];
  qualificationsList: any[] = [];
  locationsList: any[] = [];
  skillsList: any[] = [];
  selectedSkillsList: any[] = [];
  isSubmit: boolean = false;
  isLocationNotFound: boolean = false;
  searchText: string = "";
  imageShow: any = "assets/images/logo RYEC.png";
  imagePost: any;
  defaultSelectedVal: any;
  modalReference: any;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  appName = environment.APP_NAME;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  activeIds: string[] = [];
  panels = [0];
  constructor(
    // private fb: FormBuilder,
    private cS: CommonService,
    private hmS: HomeService,
    private modalService: NgbModal,
    private httpS: HttpService,
    private router: Router,
    private activeRoute: ActivatedRoute,
  ) {
    this.activeRoute.params.subscribe(data => {
      this.groupId = data.slug;
    });
  }

  ngOnInit() {
    this.openAccordionPanelAll();
    this.getWorkPlaceTypesList();
    this.getEmploymentTypesList();
    this.getQualificationsList();
    this.getSkillsList();
  }

  openAccordionPanelAll() {
    this.activeIds = this.panels.map(p => "ngb-panel-" + p);
    console.log(this.activeIds);
  }

  getWorkPlaceTypesList() {
    this.workplaceTypesList = [
      { id: 1, value: 'On Site' },
      { id: 2, value: 'Remote' },
      { id: 3, value: 'Hybrid' }
    ];
  }

  getEmploymentTypesList() {
    this.employmentTypesList = [
      { id: 1, value: 'Full Time' },
      { id: 2, value: 'Part Time' },
      { id: 3, value: 'Contract Basic' },
      { id: 4, value: 'Internship' }
    ];
  }

  getSkillsList() {
    this.skillsList = [
      { id: 1, value: 'HTML' },
      { id: 2, value: 'CSS' },
      { id: 3, value: 'Javascript' },
      { id: 4, value: 'Android' }
    ];
  }

  getQualificationsList() {
    this.qualificationsList = [
      { id: 1, value: 'Secondary School Certificate' },
      { id: 2, value: 'Higher Secondary Certificate' },
      { id: 3, value: 'Diploma of education' },
      { id: 4, value: 'Bachelor of Applied Science - BASC' },
      { id: 5, value: 'Bachelor of Architecture - BArch' },
      { id: 6, value: 'Bachelor of Arts - BA' },
      { id: 7, value: 'Bachelor of Business Administration - BBA' },
      { id: 8, value: 'Bachelor of Commerce - BCom' },
      { id: 9, value: 'Bachelor of Education - BEG' },
      { id: 10, value: 'Bachelor of Engineering - BE' },
      { id: 11, value: 'Bachelor of Fine Arts - BFA' },
      { id: 12, value: 'Master of Architecture MArch' },
      { id: 13, value: 'Master of Arts - MA' },
      { id: 14, value: 'Master of Business Administration - MBA' },
      { id: 15, value: 'Master of Computer Applications - MCA' },
      { id: 16, value: 'Master of Divinity - MDiv' },
      { id: 17, value: 'Master of Education - Med' },
      { id: 18, value: 'Master of Engineering - Ming' },
      { id: 19, value: 'Master of Fine Arts - MFA' },
      { id: 20, value: 'Master of Laws LLM' },
      { id: 21, value: 'Master of Library & Information Science MLIS' },
      { id: 22, value: 'Master of Philosophy - MPhil' },
      { id: 23, value: 'Master of Public Administration - MPA' },
      { id: 24, value: 'Master of Public Health - MPH' },
      { id: 25, value: 'Master of Science - MS' },
      { id: 26, value: 'Master of Social Work MSW' },
      { id: 27, value: 'Master of Technology - Mech' },
      { id: 28, value: 'Doctor of Education - ECD' },
      { id: 29, value: 'Doctor of Law' },
      { id: 30, value: 'Doctor of Medicine - MD' },
      { id: 31, value: 'Doctor of Pharmacy - PharmD' },
      { id: 32, value: 'Doctorate Philosophy - PHD' },
      { id: 33, value: 'Doctorate of Science' },
      { id: 34, value: 'Any' },
    ];
  }

  locationSearch(event: any) {
    // const val = event.target.value;
    const val = event;
    if (val === "" || val.length < 3) {
      $(".member-list").css("display", "none");
    }
    if (val.length >= 3) {
      const postJson = { pincode: val };

      this.hmS.getLocationByPinCode(postJson).subscribe((res: any) => {
        if (res.data) {
          if (res.data.length > 0) {
            $(".member-list").css("display", "block");
            this.locationsList = res.data;
            this.isLocationNotFound = false;
          } else {
            this.locationsList = [];
            this.isLocationNotFound = true;
            $(".member-list").css("display", "none");
          }
        }
      });
    }
  }

  selectlocation(id: number, district: string, state: string, countryName: string) {
    this.searchText = district + ' , ' + state + ' , ' + countryName;
    this.jobForm.location_id = id;
    $(".member-list").css("display", "none");
  }


  onLogoImageChange(event: any) {
    const srcEle = event.target;
    if (srcEle.files && srcEle.files[0]) {
      if (this.cS.checkValidImage(srcEle.files[0])) {
        this.readLogoImageURL(srcEle.files[0]);
      } else {
        this.cS.showError("Please select file less than 5MB.");
      }
    }
  }

  readLogoImageURL(input: any) {
    const self = this;
    if (input) {
      const reader = new FileReader();
      reader.onload = function (event: Event) {
        if (event.target) {
          self.imageShow = reader.result;
          self.jobForm.image_url = input;
          self.imagePost = input;
        }
      };
      reader.readAsDataURL(input);
    }
  }

  onEducationChange(event: any) {
    let selectedVal = event.value;
    let newNode: any[] = [];
    selectedVal.forEach((element: any) => {
      newNode.push(element);
    });
    this.jobForm.qualification = newNode.toString();
  }

  /**
   * Add row on click add more
   * @Yuvraj
   * 08/04/2021
   */
  addNewRow() {
    this.selectedSkillsList.push({
      id: + 1,
      value: ''
    });
  }

  onAddSkillSubmit() {
    let newNode: any[] = [];
    this.selectedSkillsList.forEach((element: any) => {
      newNode.push(element.value);
    });
    this.jobForm.skills = newNode.toString();
    setTimeout(() => {
      let element: HTMLElement = document.getElementById('closeSkillComponent') as HTMLElement;
      element.click();
    }, 200);
  }

  deleteRow(index: number) {
    this.selectedSkillsList.splice(index, 1);
    let tempData = JSON.parse(JSON.stringify(this.selectedSkillsList));
    this.selectedSkillsList = [];
    this.selectedSkillsList = tempData;
    this.cS.showSuccess("Skill deleted successfully");
  }

  loadSkillModal(content: any) {
    if (this.selectedSkillsList.length == 0) {
      this.addNewRow();
    }
    this.modalReference = this.modalService.open(content, { windowClass: "modal-md" });
    this.modalReference.result.then(
      (result: any) => {
        console.log(`Closed with: ${result}`);
      },
      (reason: any) => {
        console.log(reason);
      }
    );
  }

  setSubmitModelValue() {
    if (this.appName == 'RYC') {
      this.jobModelData.append("application_id", '2');
    }
    else {
      this.jobModelData.append("application_id", '1');
    }
    if (this.groupId) {
      this.jobModelData.append("site_id", this.groupId);
    }
    this.jobModelData.append("type", this.jobForm.type);
    this.jobModelData.append("title", this.jobForm.title);
    this.jobModelData.append("company_name", this.jobForm.company_name);
    this.jobModelData.append("workplace_type", this.jobForm.workplace_type);
    this.jobModelData.append("location_id", this.jobForm.location_id);
    this.jobModelData.append("employment_type", this.jobForm.employment_type);
    this.jobModelData.append("description", this.jobForm.description);
    this.jobModelData.append("skills", this.jobForm.skills);
    this.jobModelData.append("qualification", this.jobForm.qualification);
    this.jobModelData.append("experience", this.jobForm.experience);
    this.jobModelData.append("external_link", this.jobForm.external_link);
    if (this.imagePost) {
      this.jobModelData.append("image_url", this.imagePost);
    }
  }

  onSubmit() {
    this.setSubmitModelValue();
    this.isSubmit = true;
    const apiUrl = environment.RYEC_API_URL + 'createUpdateJob';
    this.httpS.postUpload(apiUrl, this.jobModelData).subscribe((res: any) => {
      if (res.status === 1) {
        this.cS.showSuccess(res.message);
        this.isSubmit = false;
        this.router.navigate(['/job/details/' + res.data.id]);
      }
      else {
        this.cS.showError(res.message);
        this.isSubmit = false;
      }
    });
  }

}
