
<div class="top_header_view" [ngStyle]="{'background':appName == 'MRC'? 'var(--theme-mrc-based-primary-color)': appName == 'BD' ?'var(--theme-bd-based-primary-color)' : 'var(--theme-ryc-based-primary-color)' }">
    <div class="container">
        <div class="row">
            <div class="col-md-5 col-sm-5 col-xs-12 col-6">
                <div class="logo_view">
                    <a href="javascript:void(0)" routerLink="/home" (click)="menuHide()">
                        <img *ngIf="appName == 'MRC'" src="../assets/images/logo_new.png" alt="logo">
                        <img *ngIf="appName == 'RYC'" src="../assets/images/raj_new_logo.png" alt="logo">
                        <img *ngIf="appName == 'BD'" src="../assets/images/bharat-darshan-logo.png" alt="logo">
                    </a>
                </div>
                <!-- logo_view End -->
            </div>
            <!-- col End -->
            <div class="col-md-7 col-sm-7 col-xs-12 col-6">
                 <div class="top_menu_section " [ngStyle]="{'color':appName == 'MRC'? 'var(--theme-mrc-based-primary-color)': appName == 'BD' ?'var(--theme-bd-based-primary-color)' : 'var(--theme-ryc-based-primary-color)' }">
                    <ul>
                        <!-- <a href="javascript:void(0)" class="register_business member ryec_btn donate-btn" routerLink="/plasma-donor" routerLinkActive="active" (click)="menuHide()">{{'ang_corona_helpline' | translate}}</a> -->
                        <li *ngIf="!userLoggedIn  then loginEleRef; else logoutEleRef">this is ignored</li>
                        <ng-template #loginEleRef>
                            <li *ngIf="!userLoggedIn">
                                <a href="javascript:void(0)" routerLink="/signup" routerLinkActive="active" [ngStyle]="{'color':appName == 'MRC'? '#ffffff': appName == 'BD' ?'#ffffff' : '#000000' }">{{'ang_signupMRC' | translate}}</a>
                            </li>
                            <li *ngIf="!userLoggedIn">
                                <a href="javascript:void(0)" routerLink="/login" routerLinkActive="active" [ngStyle]="{'color':appName == 'MRC'? '#ffffff': appName == 'BD' ?'#ffffff' : '#000000' }">{{'ang_logintitle' | translate}}</a>
                            </li>
                            
                        </ng-template>
                    </ul>
                    <ng-template #logoutEleRef>
                        <a *ngIf="!agentStatus" href="javascript:void(0);" class="register_business member" (click)="registerMember()">{{'ang_addnewmember' | translate}}</a>
                        <div *ngIf="business_flag && agentStatus && business_name != ''" class="business_title_icon business_name">
                            <a href="javascript:void(0);" (click)="ckeckBusinessApproved()" class="text-overflow-hidden" title="{{business_name}}">
                                <img *ngIf="business_approved == 0" src="assets/images/round-error-symbol.png" title="{{'ang_businessunderreview' | translate}}" />
                                <img *ngIf="business_approved == 1 && membership_type == 0 && membership_type_image != ''" [src]="membership_type_image" title="{{ 'ang_basicplantext' | translate }}" />
                                <img *ngIf="business_approved == 1 && membership_type == 1 && membership_type_image != ''" [src]="membership_type_image" title="{{ 'ang_premiumplantext' | translate }}" /> <span class="business_name_align">{{business_name}}</span>
                            </a>
                        </div>

                        <div class="user_profile">
                            <a href="javascript:void(0);" (click)="toggleProfile()">
                                <span [ngStyle]="{'background':appName == 'RYC' ? '#ffffff' : '' }">
									<img [src]="userProfile?.profile_pic_thumbnail" alt="" class="profile_menu_icon">
								</span>
                                <p class="profile_name">{{userName}}</p>
                            </a>
                            <div class="user_profile_dropdown" *ngIf="profileDropDown">
                                <ul>
                                    <li>{{'ang_hi' | translate}}, {{userProfile?.name}}</li>
                                    <li>
                                        <a href="javascript:void(0)" (click)="menuHide()" routerLink="/user" routerLinkActive="active">{{'ang_profile' | translate}}</a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)" (click)="menuHide()" routerLink="/user/change-password" routerLinkActive="active">{{'ang_changepassword' | translate}}</a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);" (click)="logout()">{{'ang_logout' | translate}}</a>
                                    </li>
                                </ul>
                            </div>
                            <!-- user_profile_dropdown End -->
                        </div>
                        <div class="alert_message">
                            <a href="javascript:void(0);" (click)="getThreadMessage()" routerLink="/messages" [ngStyle]="{'color':appName == 'MRC' ? 'var(-theme-bd-based-text-primary-color)' : appName == 'BD' ? 'var(  --theme-bd-based-text-primary-color)': 'var(--theme-ryc-based-secondary-color)' }">
                                <i class="fa fa-envelope" aria-hidden="true"></i>
                            </a>
                            <span class="total_message" *ngIf="total_unmessage != 0">{{total_unmessage}}</span>
                        </div>
                    </ng-template>
                    <!-- user_profile End -->
                    <!-- alert_message End -->
                </div>
                <!-- top_menu_section End -->
            </div>
            <!-- col End -->
        </div>
        <!-- Row End -->
    </div>
    <!-- container End -->
</div>
<div class="menu_bar_view" *ngIf="userLoggedIn" [ngStyle]="{'background':appName == 'MRC' ? 'var(--theme-mrc-based-secondary-color)' :  appName == 'BD' ? 'var( --theme-bd-based-text-primary-color)' :'var(--theme-ryc-based-secondary-color)' }">

    <div class="container">
        <a href="javascript:void(0);" (click)="toggleMenu()" class="menu_icon">
            <i class="fa fa-list-ul side_menu_icon" aria-hidden="true"></i>
        </a>

        <a *ngIf="membership_type == 0" href="javascript:void(0)" routerLink="/home/membership-plan" >
            <img style="width:30px;" title="Apply for Premium Membership and Create Publice Business Website" src="../assets/images/premium_white.png"/> <span class="premium_membership_plans"> {{'ang_goto_premium_membership_plan' | translate}}</span>
        </a>



        <div [ngClass]="appName == 'RYC' ? 'nav_bar_RYC' : appName == 'BD' ? 'nav_bar_bd' : 'nav_bar_MRC'" *ngIf="showMenuList">
            <div class="language" [ngClass]="appName == 'RYC' ? 'location_dropdown_RYC' : appName == 'BD' ? 'location_dropdown_BD': 'location_dropdown_MRC'">
                <div class="dropdown">
                    <button (click)="showLanguageDropDown()" class="dropbtn langauge">{{language}}</button>
                    <div id="myDropdown" class="dropdown-content" *ngIf="isLangaugeShow">
                        <a href="javascript:void(0)" (click)="selectLanguage(item.key, item.val)" *ngFor="let item of languageProvider">{{item.val}}</a>
                    </div>
                </div>
            </div>

            <ul>
                <li *ngIf="business_flag && business_name != ''" class="business_title_icon text-overflow-hidden business_name_agent">
                    <a href="javascript:void(0);" (click)="ckeckBusinessApproved()" title="{{business_name}}">
                        <img *ngIf="business_approved == 0" src="assets/images/round-error-symbol.png" title="{{'ang_businessunderreview' | translate}}" />
                        <img *ngIf="business_approved == 1 && membership_type == 0 && membership_type_image != ''" [src]="membership_type_image" title="{{ 'ang_basicplantext' | translate }}" />
                        <img *ngIf="business_approved == 1 && membership_type == 1 && membership_type_image != ''" [src]="membership_type_image" title="{{ 'ang_premiumplantext' | translate }}" />
                        <img *ngIf="business_approved == 1 && membership_type == 2 && membership_type_image != ''" [src]="membership_type_image" title="{{ 'ang_lifetimeplantext' | translate }}" /> {{business_name}}
                    </a>
                </li>
                <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <a href="javascript:void(0)" routerLink="/home" (click)="menuHide()">{{'ang_home' | translate}}</a>
                </li>
                <li routerLinkActive="active">
                    <a href="javascript:void(0)" routerLink="/groups" (click)="menuHide()">
                        {{'ang_groups' | translate}}
                    </a>
                </li>
                <li routerLinkActive="active">
                    <a href="javascript:void(0)" routerLink="/places" (click)="menuHide()">
                        {{'ang_places' | translate}}
                    </a>
                </li>
                <li routerLinkActive="active" *ngIf="appName == 'RYC'">
                    <a href="javascript:void(0)" routerLink="/newslist" (click)="menuHide()">
                        {{'ang_news' | translate}}
                    </a>
                </li>
                <li routerLinkActive="active">
                    <a href="javascript:void(0)" routerLink="/home/business-list/premium-business/relevance" (click)="menuHide()">
                        {{'ang_premiumbusinesses' | translate}}
                    </a>
                </li>
                <li routerLinkActive="active">
                    <a href="javascript:void(0)" routerLink="/atoz/categories" (click)="menuHide()">
                        {{'ang_atozcategories' | translate}}
                    </a>
                </li>
                <li routerLinkActive="active">
                    <a href="javascript:void(0)" routerLink="/trending/service" (click)="menuHide()">
                        {{'ang_trendingservices' | translate}}
                    </a>
                </li>
                <li routerLinkActive="active">
                    <a href="javascript:void(0)" routerLink="/messages" (click)="menuHide()">{{'ang_messages' | translate}}</a>
                </li>
                <li routerLinkActive="active">
                    <a href="javascript:void(0)" routerLink="/notifications-list" (click)="menuHide()">{{'ang_notifications' | translate}}</a>
                </li>
                <li routerLinkActive="active"  [routerLinkActiveOptions]="{exact: true}">
                    <a href="javascript:void(0)" routerLink="/home/market-place/relevance" (click)="menuHide()">{{'ang_marketplace' | translate}}</a>
                </li>
                <li *ngIf="!agentStatus">
                    <a href="javascript:void(0)" routerLink="/home/agent-business-list/relevance" routerLinkActive="active" (click)="menuHide()">{{'ang_mymembers' | translate}}</a>
                </li>
                <li routerLinkActive="active" class="register_business" *ngIf="!business_flag && agentStatus && isRajput">
                    <a href="javascript:void(0)" routerLink="/home/add-business" (click)="menuHide()">{{'ang_free_listing' | translate}}</a>
                </li>
                <li routerLinkActive="active" class="register_business" *ngIf="userDetail && appName == 'RYC'">
                    <a href="javascript:void(0)" (click)="menuHide(); addContentForBusiness(businesscontent, 'advertise')">
                        {{'ang_advertise' | translate}}
                    </a>
                </li>
                <li *ngIf="!business_flag && !agentStatus && isRajput" routerLinkActive="active" class="register_business">
                    <a href="javascript:void(0)" routerLink="/home/add-business" (click)="menuHide()">{{'ang_free_listing' | translate}}</a>
                </li>

                <li *ngIf="business_flag && isRajput" routerLinkActive="active" class="register_business">
                    <a href="javascript:void(0)" routerLink="/home/membership-plan" (click)="menuHide()">{{'ang_membership' | translate}}</a>
                </li>
                <li *ngIf="isRajput" class="register_business">
                    <a href="javascript:void(0)" (click)="addContentForBusiness(businesscontent, 'website')">{{'ang_createwebsite' | translate}}</a>
                </li>

                 <li *ngIf="agentStatus && isRajput" class="register_business">
                    <a href="javascript:void(0)" (click)="becomeAgent();">{{'ang_becomeagent' | translate}}</a>
                </li>

            </ul>
            <ul [ngClass]="appName == 'RYC' ? 'useful_links_RYC' : 'useful_links_MRC'" >
                <h5>{{'ang_usefullinks' | translate}}</h5>
                <li>
                    <a href="javascript:void(0)" routerLink="/about" routerLinkActive="active" (click)="menuHide()">{{'ang_aboutus' | translate}}</a>
                </li>
                <li routerLinkActive="active">
                    <a href="javascript:void(0)" routerLink="/contact" (click)="menuHide()">{{'ang_contactus' | translate}}</a>
                </li>
                <li *ngIf="appName == 'MRC'" routerLinkActive="active">
                    <a href="javascript:void(0);" routerLink="/privacy-policy" (click)="menuHide()">
                        {{'ang_privacyandpolicy' | translate}}
                    </a>
                </li>
                <li *ngIf="appName == 'BD'">
                    <a href="https://app.bharatdarshan.org/privacy-policy" (click)="menuHide()" target="_blank">
                        {{'ang_privacyandpolicy' | translate}}
                    </a>
                </li>
                <li *ngIf="appName == 'RYC'">
                    <a href="https://app.rajputsclub.com/privacy-policy" (click)="menuHide()" target="_blank">
                        {{'ang_privacyandpolicy' | translate}}
                    </a>
                </li>
            </ul>
        </div>
        <div class="right_header pull-right" *ngIf="isHomePage != true">
            <ryec-city-auto-complete-search [boxWidth]="48" [boxHeight]="36" (storeToParent)="getChildLocationSearchValue($event)">

            </ryec-city-auto-complete-search>
            <!-- <div class="location_dropdown ">
                <div class="dropdown">
                    <button (click)="showLocationDropDown()" class="dropbtn">{{search_location}}</button>
                    <div class="dropdown-content" *ngIf="isShow">
                        <div class="location_search_input">
                            <i _ngcontent-c1="" aria-hidden="true" class="fa fa-search"></i>
                            <input type="text" placeholder="{{'ang_searchbyall' | translate}}" id="myInput" name="location" [(ngModel)]="location" (input)="filterLocation()" class="location-search">
                        </div>
                        <perfect-scrollbar style="height: 210px;">
                            <a href="javascript:void(0)" (click)="selectLocation('All Locations')" class="location">All Locations</a>
                            <a href="javascript:void(0)" (click)="selectLocation(location.name)" *ngFor="let location of locations;" class="location">{{location.name}}</a>
                        </perfect-scrollbar>
                    </div>
                </div>
            </div> -->
            <!-- language_select End -->
            <!-- location_dropdown End -->
            <div class="search_here_view">
                <i (click)="searchBusiness()" [ngClass]="appName == 'MRC' ? 'mrc-icon' :appName == 'BD' ? 'bd-icon' : 'ryc-icon'" style="cursor: pointer;" class="fa fa-search" aria-hidden="true"></i>
                <input id="search" (keyup.enter)="searchBusiness()" [(ngModel)]="searchText" (input)="search($event)" placeholder="{{ 'ang_searchhere' | translate}} " type="text ">
                <div [ngClass]="appName == 'MRC' ? 'auto_complete_list_ryc' : 'auto_complete_list_ryc'" style="display: none;">
                    <perfect-scrollbar>
                        <ul>
                            <li *ngFor="let item of items" (click)="searchBusinessByCategory(item.value)" class="search_input">{{item.value}}</li>
                        </ul>
                    </perfect-scrollbar>
                </div>
            </div>
            <!-- search_here_view End -->
        </div>
        <!-- right_header End -->
        
    </div>
    <!-- container End -->
</div>
<!-- menu_bar_view End -->
<div class="market_place_div" *ngIf="appName == 'RYC'">
    <div class="container">
        <ul>
            <li>
                    <a><img class="ryc_menu_icn" src="assets/images/login_component/menu.svg" /></a> 
            </li>
            <li><a><img src="assets/images/login_component/rakthaveer.svg" /> Raktaveer </a></li>
            <li><a><img src="assets/images/login_component/yuvamithra.svg" /> Yuva Mitra </a></li>
            <li><a><img src="assets/images/login_component/nearby.svg" /> Nearby </a></li>
            <li><a><img src="assets/images/login_component/survey.svg" /> Survey </a></li>
            <li><a><img src="assets/images/login_component/village.svg" /> My Village </a></li>
            <li><a><img src="assets/images/login_component/market_place.svg" /> Market Place </a></li>
            <li><a><img src="assets/images/login_component/group.svg" /> Group </a></li>
        </ul>
    </div>
</div>
<ng-template #appInstall let-c="close" let-d="dismiss">
    <div class="modal-body mobile-download-alert">

        <div class="alert p-2 alert-dismissible fade mb-0 show" role="alert">
            <!-- <button aria-label="Close" class="close p-3" type="button"><span aria-hidden="true">×</span></button> -->
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="row mt-3">
                <div class="col-sm-12">
                    <div class="" *ngIf="appName == 'RYC'">
                        <h4 class="font-weignt-bold"> {{ 'ang_RYCMobileAppTitle' | translate }}</h4>
                        <p class="card-text">{{ 'ang_forBetterExperience' | translate }}
                            <a href="{{ appDownloadUrl }}" target="_blank">{{ 'ang_downloadOurApp' | translate }}</a>
                        </p>
                    </div>
                    <div class="" *ngIf="appName == 'MRC'">
                        <h4 class="font-weignt-bold"> {{ 'ang_MRCMobileAppTitle' | translate }}</h4>
                        <p class="card-text">{{ 'ang_forBetterExperience' | translate }}
                            <a href="{{ appDownloadUrl }}" target="_blank">{{ 'ang_downloadOurApp' | translate }}</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #businesscontent let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h5 *ngIf="businessType === 'advertise'" class="modal-title">ADVERTISE</h5>
      <h5 *ngIf="businessType === 'website'" class="modal-title">CREATE WEBSITE</h5>
      <button
        type="button"
        class="close"
        aria-label="Close" (click)="d('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <p *ngIf="businessType === 'advertise'" class="business-content">Advertise your business with My Rajasthan Club Network through various social media channels and highlight your business in My Rajasthan Club Platform. To Know more submit your inquiry.</p>
        <p *ngIf="businessType === 'website'" class="business-content">
             {{ appName == 'MRC' ? 'Create your business Website without need of domain and hosting on My Rajasthan Club Platform. To Know more submit your inquiry.'
             :appName == 'BD' ? 'Create your business Website without need of domain and hosting on Bharat Darshan Platform. To Know more submit your inquiry.'
             :'Create your business Website without need of domain and hosting on Rajput Club Platform. To Know more submit your inquiry.'}}
            </p>
      <form class="form-horizontal" novalidate (submit)="submitBusinessDetail()">
        <div class="control-group">
          <div class="controls">
            <input
              type="text"
              name="businessForm.business_name"
              [(ngModel)]="businessForm.business_name"
              id="business_name"
              placeholder="{{ 'ang_businessprofessionname' | translate }}"
              class="input-xlarge"
              (ngModelChange)="changeTitleEvent($event)"
            />
            <!-- <textarea id="review" formGroupName="review" placeholder="Add review"></textarea> -->
            <p class="help-block" *ngIf="validTitle == true">
              {{ "ang_businessprofessionnamevalidation" | translate }}
            </p>
          </div>
        </div>

        <div class="control-group">
          <div class="controls">
            <input
              type="text"
              name="businessForm.name"
              [(ngModel)]="businessForm.name"
              id="name"
              placeholder="{{ 'ang_businessprofessionusername' | translate }}"
              class="input-xlarge"
              (ngModelChange)="changeTitleEvent($event)"
            />
            <!-- <textarea id="review" formGroupName="review" placeholder="Add review"></textarea> -->
            <p class="help-block" *ngIf="validTitle == true">
              {{ "ang_businessprofessionusernamevalidation" | translate }}
            </p>
          </div>
        </div>

        <div class="control-group">
          <div class="controls">
            <input type="text"
              name="businessForm.phoneno"
              [(ngModel)]="businessForm.phoneno"
              id="phoneno" minlength="6" maxlength="13" (keypress)="numberOnly($event)"
              placeholder="{{ 'ang_businessprofessionuserphone' | translate }}"
              class="input-xlarge"
              (ngModelChange)="changeTitleEvent($event)"
            />
            <!-- <textarea id="review" formGroupName="review" placeholder="Add review"></textarea> -->
            <p class="help-block" *ngIf="validTitle == true">
              {{ "ang_businessprofessionphonevalidation" | translate }}
            </p>
          </div>
        </div>

        <div class="control-group">
          <div class="controls">
            <input
              type="text"
              name="businessForm.city"
              [(ngModel)]="businessForm.city"
              id="city"
              placeholder="{{ 'ang_businessprofessionusercity' | translate }}"
              class="input-xlarge"
              (ngModelChange)="changeTitleEvent($event)"
            />
            <!-- <textarea id="review" formGroupName="review" placeholder="Add review"></textarea> -->
            <p class="help-block" *ngIf="validTitle == true">
              {{ "ang_businessprofessioncityvalidation" | translate }}
            </p>
          </div>
        </div>

        <div class="profile_btn">
          <button
            [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'"
            [disabled]="validForm == false"
            type="submit"
          >
            {{ "ang_submit" | translate | uppercase }}
          </button>
        </div>
      </form>
    </div>
</ng-template>
<a style="display: none;" id="app-download-btn" href="javascript:void(0)" (click)="showAppDownloadModal(appInstall)">open</a>

<!-- top_header_view End -->
<script type="text/javascript ">
    $(document).ready(function() {
        $(".menu_icon ").click(function() {
            if(appName == 'RYC'){
                $(".nav_bar_RYC ").slideToggle("slow ");
            }
            else{
                $(".nav_bar_MRC ").slideToggle("slow ");
            }
        });
    });
</script>
