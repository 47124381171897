<div class="group_banner  d-flex align-items-center" style="background-image:url('assets/images/group_banner.jpg')">
    <div class="container">
      <div class="banner-content">
        <h2 class="groups_title text-white">News</h2>
        <div class="search_here_view " >
          <i (click)="searchBusiness()" style="cursor: pointer;" class="fa fa-search {{appName}}" aria-hidden="true"></i>
          <input id="search" (keyup.enter)="searchBusiness()" [(ngModel)]="searchText" placeholder="{{ 'ang_searchhere' | translate}} " type="text" #homeSearchInput>
          <div class="auto_complete_listing" style="display: none;">
            <perfect-scrollbar>
                <ul>
                    <li class="search_input" *ngFor="let item of items" (click)="searchBusinessByCategory(item.name)">
                      {{item.name}}
                    </li>
                </ul>
            </perfect-scrollbar>
          </div>
        </div>
      </div>
    </div>
  </div>
 
  <div class="container">
    <div class="group_listing_view" *ngIf="totalGroupsCount > 0">
      <div class="d-flex justify-content-between align-items-end mb-3 flex-sm-row flex-column-reverse flex-sm-nowrap flex-wrap">
        
        
      </div>
      <div class="d-flex justify-content-between align-items-end mb-3 flex-sm-row flex-column-reverse flex-sm-nowrap flex-wrap">
        <div class="total_result">
        </div>
        
      </div>
     
      <div class="d-flex justify-content-between align-items-end mb-3 flex-sm-row flex-column-reverse flex-sm-nowrap flex-wrap">
        <div class="total_result">
        </div>
        <div class="d-flex align-items-end flex-wrap justify-content-end mb-2">
          <a routerLink="/group/create" [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" class="ml-3 create-group_page_btn ">
          
          Breaking News
          </a>
        </div>
      </div>
    
      <div class="group_listing_view_wrapper">

        <div class="group_listing_view" *ngIf="newsList?.length > 0">
          <div class="clearfix group_listing_box" *ngFor="let item of newsList; let i = index">
            
            <div class="d-block d-sm-flex align-items-center"  >
              
              <div class="group_listing_img">
                <a routerLink="/news/{{ item.business_slug }}"> 
                  <img  alt="essar-real-estate" [src]="item.business_logo_url" />
              
            </a>
              </div>
             <div class="group_listing_content">
               <h2>
                 <a style="color: #FF7722" routerLink="/news/{{ item.business_slug }}" class="text-overflow-hidden" placement="bottom">{{ item.name }}</a>
              </h2>
              <h6>
                <span class="text-overflow-hidden" placement="bottom"> News created date :  {{ item.created_at | date }}</span><br>
             </h6>

            
             </div>
            
            </div>
          </div>
         
        </div>
      
        <div class="group_listing_view" *ngIf="groupType == 'yourGroup' && myGroupsList.yourGroup?.length">
          <h5>Groups you manage</h5>
          <div class="clearfix group_listing_box" *ngFor="let item of myGroupsList.yourGroup; let i = index">
            <div class="d-block d-sm-flex align-items-center">
              <div class="group_listing_img">
                <img alt="essar-real-estate" [src]="item.logo" />
              </div>
             <div class="group_listing_content">
               <h2>
                 <span class="text-overflow-hidden" placement="bottom">{{ item.name }}</span>
              </h2>
              <div class="group_content_left group_title_icon">
               <ul>
                  <li class="text-overflow-hidden">
                    <i class="fa fa-users" aria-hidden="true"></i>
                    {{ item.asset_type.name }}
                  </li>
                  <li class="text-overflow-hidden" *ngIf="item.visibility == 'Public' ">
                    <i class="fa fa-globe" aria-hidden="true"></i>
                    Public
                  </li>
                  <li class="text-overflow-hidden" *ngIf="item.visibility == 'Private' ">
                    <i class="fa fa-lock" aria-hidden="true"></i>
                    Private
                  </li>
               </ul>
              </div>
              <div class="group_content_right">
                <!-- for public group -->
                <a routerLink="/group/details/{{ item.id }}" [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" class="mt-2">
                  Visit
                  <i class="fa fa-angle-right" aria-hidden="true"></i>
                </a>
              </div>
             </div>
            </div>
          </div>
        </div>
      
        <div class="group_listing_view" *ngIf="groupType == 'yourGroup' && myGroupsList.joinedGroups?.length">
          <h5>Groups you've joined</h5>
          <div class="clearfix group_listing_box" *ngFor="let item of myGroupsList.joinedGroups; let i = index">
            <div class="d-block d-sm-flex align-items-center">
              <div class="group_listing_img">
                <img alt="essar-real-estate" [src]="item.logo" />
              </div>
             <div class="group_listing_content">
               <h2>
                 <span class="text-overflow-hidden" placement="bottom">{{ item.name }}</span>
              </h2>
              <div class="group_content_left group_title_icon">
               <ul>
                  <li class="text-overflow-hidden">
                    <i class="fa fa-users" aria-hidden="true"></i>
                    {{ item.asset_type.name }}
                  </li>
                  <li class="text-overflow-hidden" *ngIf="item.visibility == 'Public' ">
                    <i class="fa fa-globe" aria-hidden="true"></i>
                    Public
                  </li>
                  <li class="text-overflow-hidden" *ngIf="item.visibility == 'Private' ">
                    <i class="fa fa-lock" aria-hidden="true"></i>
                    Private
                  </li>
               </ul>
              </div>
              <div class="group_content_right">
                <!-- <button *ngIf="(item.is_joined_user !== 0)" type="button" class="ryec_btn">
                  Joined
                </button> -->
                <a routerLink="/group/details/{{ item.id }}" [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" class="mt-2">
                  Visit
                  <i class="fa fa-angle-right" aria-hidden="true"></i>
                </a>
              </div>
             </div>
            </div>
          </div>
        </div>
      </div>
    
    </div>
    
    <div [ngClass]="appName == 'RYC' ? 'ryc_pagination_view' : 'mrc_pagination_view'" *ngIf="totalGroupsCount > 0">
      <ngb-pagination [collectionSize]="collectionSize" [(page)]="currentPage" [maxSize]="maxSize" size="sm" (pageChange)="getNextPageBusinessList($event)"></ngb-pagination>
    </div>
    <!-- pagination_view End -->
  
    <div *ngIf="noData == true" style=" text-align: center;padding: 20px;">
      <img src="../assets/images/norecordfound.png" style="height:250px;margin:20px;">
      <h3>{{'ang_nomatches' | translate}}</h3>
    </div>
  </div>