import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/shared/services/common.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { environment } from '../../../../../environments/environment';


@Component({
  selector: 'ryec-job-apply-modal',
  templateUrl: './job-apply-modal.component.html',
  styleUrls: ['./job-apply-modal.component.css']
})
export class JobApplyModalComponent implements OnInit {
  @Input() jobName: string = "";
  @Input() jobId: number = 0;
  jobApplyModel: any = {};
  isSubmit: boolean = false;
  attachedFileName: string = "";
  attachedFile: any;
  postModel: FormData = new FormData();
  appName = environment.APP_NAME;

  constructor(
    public activeModal: NgbActiveModal,
    private httpS: HttpService,
    private cS: CommonService,
  ) { }

  ngOnInit() {
    const userDetail = this.cS.getUserDetails();
    if (userDetail) {
      this.jobApplyModel.email = userDetail.email;
      this.jobApplyModel.mobileNo = userDetail.phone;
    }
    this.jobApplyModel.job_vacancy_id = this.jobId;
  }

  setSubmitModelValue() {
    this.postModel.append("job_vacancy_id", this.jobApplyModel.job_vacancy_id);
    this.postModel.append("email", this.jobApplyModel.email);
    this.postModel.append("mobile_no", this.jobApplyModel.mobileNo);
    this.postModel.append("experience", this.jobApplyModel.experience);
    if (this.jobApplyModel.documentFile) {
      this.postModel.append("document_file", this.jobApplyModel.attachedFile);
    }
  }

  onFileChange(event: any) {
    const srcEle = event.srcElement;
    // console.log('srcEle', event)
    this.attachedFileName = srcEle.files[0].name;
    if (srcEle.files && srcEle.files[0]) {
      if (this.cS.checkValidDocSize(srcEle.files[0])) {
        this.jobApplyModel.attachedFile = srcEle.files[0];
        this.jobApplyModel.documentFile = srcEle.files[0];
        // this.postModel.append('document', srcEle.files[0]);
      } else {
        this.jobApplyModel.documentFile = "";
        // this.postModel.set('document', '');
        this.cS.showError("Please select file less than 2MB.");
      }
    }
  }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  onSubmit() {
    this.setSubmitModelValue();
    this.isSubmit = true;
    const apiUrl = environment.RYEC_API_URL + 'applyJob';
    this.httpS.postUpload(apiUrl, this.postModel).subscribe((res: any) => {
      if (res.status === 1) {
        this.cS.showSuccess(res.message);
        this.isSubmit = false;
        this.activeModal.dismiss('close');
      }
      else {
        this.cS.showError(res.message);
        this.isSubmit = false;
      }
    });
  }

}
