import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, HostListener } from '@angular/core';
 
import {
  debounceTime,
  map,
  filter
} from "rxjs/operators";
import { fromEvent } from 'rxjs/observable/fromEvent';
import { environment } from '../../../../environments/environment';
import { HomeService } from '../../services/home.service';
declare var $: any;

@Component({
  selector: 'ryec-city-auto-complete-search',
  templateUrl: './city-auto-complete-search.component.html',
  styleUrls: ['./city-auto-complete-search.component.css']
})
export class CityAutoCompleteSearchComponent implements OnInit {

  @Input() boxWidth: number = 0;
  @Input() boxHeight: number = 0;
  @Output() storeToParent = new EventEmitter();
  @ViewChild('locationSearchInput')
  locationSearchInput!: ElementRef;

  searchText: string = "";

  locationLists: any[] = [];
  location = '';
  search_location: string = "";
  allLocations: any[] = [];
  appName = environment.APP_NAME;
  constructor(
    public hS: HomeService,
    // private eRef: ElementRef,
  ) { }
  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    if (event.srcElement.className == 'home-city-search-wrapper' || event.srcElement.className == 'container' || event.srcElement.className == '') {
      if (this.appName == 'MRC') {
        $(".header-location-filter-mrc").css("display", "none");
      }
      else if (this.appName == 'RYC') {
        $(".header-location-filter-ryc").css("display", "none");
      }
      console.log("if" + event.srcElement.className);
    }
    if (event.srcElement.className == 'business_listing_view' || event.srcElement.className == 'sub_category_listing' || event.srcElement.className == '') {
      // $(".header-location-filter").css("display", "none");
      if (this.appName == 'MRC') {
        $(".header-location-filter-mrc").css("display", "none");
      }
      else if (this.appName == 'RYC') {
        $(".header-location-filter-ryc").css("display", "none");
      }
      console.log("if" + event.srcElement.className);
    }
  }
  ngOnInit() {
    // this.getAddressDetail();
  }
  ngAfterViewInit(): void {
    this.citySearch();
  }


  /**
   * deley api calling and called once user finish typed in search text box
   * @Yuvraj 15/11/2021
   */

  citySearch() {
    fromEvent(this.locationSearchInput.nativeElement, 'keyup').pipe(
      // get value
      map((event: any) => {
        return event.target.value;
      }),
      // if character length greater then 3
      // , filter(res => res.length >= 3)
      filter(res => {
        if (res && res.length >= 3) {
          return true;
        } else {
          if (this.appName == 'MRC') {
            $(this.locationSearchInput.nativeElement).closest('.location-auto-search').find(".header-location-filter-mrc").css("display", "none");
          }
          else if (this.appName == 'RYC') {
            $(this.locationSearchInput.nativeElement).closest('.location-auto-search').find(".header-location-filter-ryc").css("display", "none");
          }
          return false;
        }
      })

      // Time in milliseconds between key events
      , debounceTime(1000)

      // If previous query is diffent from current
      // , distinctUntilChanged()

      // subscription for response
    ).subscribe((postJson: string) => {
      // this.hS.getSearchLocationList({ searchText: postJson }).subscribe((res: any) => {
      this.hS.getSearchLocationList(postJson).subscribe((res: any) => {
        if (res.data) {
          if (res.data.length > 0) {
            if (this.appName == 'MRC') {
              $(this.locationSearchInput.nativeElement).closest('.location-auto-search').find(".header-location-filter-mrc").css("display", "block");
            }
            else if (this.appName == 'RYC') {
              $(this.locationSearchInput.nativeElement).closest('.location-auto-search').find(".header-location-filter-ryc").css("display", "block");
            }
            // $(this.locationSearchInput.nativeElement).closest('.location-auto-search').find(".header-location-filter").css("display", "block");
            this.locationLists = res.data;
          } else {
            if (this.appName == 'MRC') {
              $(this.locationSearchInput.nativeElement).closest('.location-auto-search').find(".header-location-filter-mrc").css("display", "none");
            }
            else if (this.appName == 'RYC') {
              $(this.locationSearchInput.nativeElement).closest('.location-auto-search').find(".header-location-filter-ryc").css("display", "none");
            }
            // $(this.locationSearchInput.nativeElement).closest('.location-auto-search').find(".header-location-filter").css("display", "none");
          }
        }
      }, err => {
        console.log(err);
      });
    });
  }

  /**
   * if user clear search box then check and also update on parent dom
   * @Yuvraj 17/12/21
   */
  checkVal() {
    if (this.searchText == "") {
      this.storeToParent.emit("");
    }
  }

  /**
   * if user select search result location update val on parent dom
   * @Yuvraj 17/12/21 
   */
  selectLocation(location: string) {
    this.storeToParent.emit(location);
    this.searchText = location;
    this.search_location = location;
    if (this.appName == 'MRC') {
      $(this.locationSearchInput.nativeElement).closest('.location-auto-search').find(".header-location-filter-mrc").css("display", "none");
    }
    else if (this.appName == 'RYC') {
      $(this.locationSearchInput.nativeElement).closest('.location-auto-search').find(".header-location-filter-ryc").css("display", "none");
    }
  }
}
