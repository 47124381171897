import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../environments/environment';
import { PostJsonBusinessList } from 'src/app/shared/class/data.model';
import { CommonService } from '../../services/common.service';
import { HomeService } from '../../services/home.service';
 

@Component({
  selector: 'ryec-event-attendie-users',
  templateUrl: './event-attendie-users.component.html',
  styleUrls: ['./event-attendie-users.component.css']
})
export class EventAttendieUsersComponent implements OnInit {
  @Input() eventId: number = 0;
  @Input() eventName: string = "";
  currentPage: number = 0;
  userListModel: any;
  // userListModel: any[] = [];
  perPageList = environment.USERS_LIST_LIMIT;
  totalsUserCount: number = 0;
  noData: boolean = false;
  collectionSize: number = 0;
  first: number = 1;
  last: number = 0;
  maxSize: number = 5;
  appName = environment.APP_NAME;

  constructor(
    public activeModal: NgbActiveModal,
    private hS: HomeService,
    private cS: CommonService,
  ) {
    this.currentPage = 1;
  }

  ngOnInit() {
    if (this.eventId) {
      this.getEventAttendeesList(this.eventId);
    }
  }

  getEventAttendeesList(eventId: number) {
    let postJson: PostJsonBusinessList;
    postJson = { event_id: eventId, page: this.currentPage, limit: this.perPageList }
    this.hS.getEventAttendeesListByEventId(postJson).subscribe((res: any) => {
      if (res.status == 1) {
        this.userListModel = res.data;
        this.totalsUserCount = res.total;
        this.countCollectionSize();
      }
      else {
        this.userListModel = [];
      }
    });
  }

  /**
  *Calculate pagination display on screen and count of total businesses
  */
  countCollectionSize() {
    if (this.totalsUserCount > this.perPageList) {
      this.collectionSize = Math.ceil(this.totalsUserCount / this.perPageList);
      this.collectionSize = this.collectionSize * 10;
      if (this.currentPage === 1) {
        this.first = 1;
        this.last = this.perPageList;
      } else {
        this.first = (this.currentPage - 1) * this.perPageList + 1;
        if (this.userListModel.length < this.perPageList) {
          this.last = (this.currentPage - 1) * this.perPageList + this.userListModel.length;
        } else {
          this.last = (this.currentPage - 1) * this.perPageList + this.perPageList;
        }
      }
    } else {
      this.last = this.totalsUserCount;
      this.collectionSize = this.perPageList;
    }

    if (this.totalsUserCount == 0 || this.totalsUserCount == undefined) {
      this.noData = true;
    } else {
      this.noData = false;
    }
    this.cS.scrollTop();
    // this.showBusinessOnMap();
  }

  getNextPageBusinessList(): void {
    this.getEventAttendeesList(this.eventId);
  }
}
