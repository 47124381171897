<div class="container tc_bg place-details">
    <div class="row" *ngIf="newsDetailsModel">
      <!-- Main text section -->
      <div class="col-sm-9">
        <div class="page-title">
          <div *ngIf="newsDetailsModel.name" class="d-flex justify-content-between position-relative flex-wrap">
            <h1 [ngStyle]="{'color':appName == 'RYC' ? 'var(--theme-ryc-based-secondary-color)' : 'var(--theme-mrc-based-primary-color)' }" class="headingTxt">
              {{ newsDetailsModel?.name }}
            </h1>
          </div>
          <div *ngIf="newsDetailsModel.categories != ''" class="d-flex justify-content-between align-items-center mt-3">
            <div class="place-rating mt-2">
              <span class="text-muted">
                News / {{ newsDetailsModel.categories }}
              </span>
            </div>
          </div>
        </div>
        <!-- Main Image Section -->
        <div class="row">
          <div class="col-md-12 mb-4">
            <div class="carousel-wrapper place_card p-0 m-0">
              <div class="item" >
                <a (click)="ImageModal()" class="">
                  <img class="mainImg" [src]="newsDetailsModel.business_logo_url" width="100%" height="533" loading="lazy"/>
                </a>
              </div>
            </div>
          </div>
        </div>
  
        <div *ngIf="newsDetailsModel.user != {}" class="userdetail-share-main story-user-section">
          <div class="user-detial-left">
              <div class="usericons" *ngIf="newsDetailsModel?.user?.profile_url">
                <img height="45" width="45" title="{{ newsDetailsModel?.name }}" class="lazyloaded" [src]="newsDetailsModel?.user?.profile_url" alt="{{ newsDetailsModel?.name }}">
              </div>
              <div class="brand-detial-main">
                <h4 *ngIf="newsDetailsModel.user.name != ''">
                    {{ newsDetailsModel.user.name }}
                </h4>
                <ul>
                    <li *ngIf="newsDetailsModel.created_at != ''">
                    {{ newsDetailsModel.created_at }}
                  </li>
                </ul>
              </div>
          </div>
          <div class="share-social-icons">
              <ul>
                <li>
                    <a class="whatsappshare" href="javascript:void(0);" (click)="onWhatsAppBtn()" data-action="share/whatsapp/share">
                      <i class="fa fa-whatsapp" aria-hidden="true"></i>
                    </a>
                </li>
                <li *ngIf="appName == 'MRC'">
                    <a href="https://twitter.com/myrajasthanclub" target="_blank">
                      <i class="fa fa-twitter" aria-hidden="true"></i>
                    </a>
                </li>
                <li *ngIf="appName == 'MRC'">
                  <a href="https://www.facebook.com/myrajasthanclub" target="_blank">
                  <i class="fa fa-facebook" aria-hidden="true"></i>
                  </a>
                </li>
                <li *ngIf="appName == 'RYC'">
                    <a href="https://twitter.com/TheRajputsClub" target="_blank">
                      <i class="fa fa-twitter" aria-hidden="true"></i>
                    </a>
                </li>
                
                <li *ngIf="appName == 'RYC'">
                    <a href="https://www.facebook.com/Ryuvaclub" target="_blank">
                    <i class="fa fa-facebook" aria-hidden="true"></i>
                    </a>
                </li>
              </ul>
          </div>
        </div>
  
        <!-- Description Content -->
        <div class="place_card place-desc" *ngIf="newsDetailsModel.description != ''">
          <div class="description" id="description" >
            <h2 [innerHtml]="newsDetailsModel.description" class="subHeading">
            </h2>
          </div>
        </div>
        <div class="place_card place-desc">
          <p> <b>अस्वीकरण (Disclaimer):</b> इस आर्टिकल/लेख/ में लिखे गए विचार, खबर से जुड़े तथ्य तथा समीक्षा लेखक के निजी विचार हैं. <span *ngIf="appName == 'RYC'">Rajputs Club</span>  <span *ngIf="appName == 'MRC'">MyRajasthan Club</span> ऐप इसके लिए किसी भी तरह से जिम्मेदार नहीं है। किसी भी प्रकार की असुविधा, असहमति, पुष्टीकरण दर्ज करवाने के लिए आप हमारी हेल्प लाइन या कॉन्टैक्ट पेज द्वारा संपर्क कर सकते हैं।</p>
        </div>
      </div>
  
      <!-- Related news list -->
      <div class="col-md-3">
        <div class="sidebar widget fixedElement" *ngIf="relatedNewsList.length > 0">
          <h3 class="recentPostHeaderTxt" [ngStyle]="{'color':appName == 'RYC' ? 'var(--theme-ryc-based-secondary-color)' : 'var(--theme-mrc-based-primary-color)' }">
            Related News
          </h3>
          <ul>
              <li *ngFor="let item of relatedNewsList; let i = index;">
                <div class="sidebar-thumb">
                  <img class="animated rollIn" [src]="item.business_logo_url" alt="{{ item.name }}" />
                </div>
                <!-- .Sidebar-thumb -->
                <div class="sidebar-content">
                  <h5 class="animated bounceInRight">
                    <a routerLink="/news/{{ item.business_slug }}">{{ item.name }}</a>
                  </h5>
                </div>
                <!-- .Sidebar-thumb -->
                <div class="sidebar-meta">
                  <span class="time"><i class="fa fa-clock-o"></i> {{ item.created_at_diff }}</span>
                  <span class="comment"><i class="fa fa-eye"></i> {{ item.visits }} Seen</span>
                </div>
                <!-- .Sidebar-meta ends here -->
              </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- Comment section -->
    <div class="row" *ngIf="newsDetailsModel">
      <div class="col-sm-9">
        <div class="place_card">
          <div class="tc_bg" style="border: 0;">
            <h4>Comments</h4>
            <div class="all_rating_outer">
              <!-- <a href="javascript:void();" (click)="loadCommentComponent()" [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" >
                Add a comment
              </a> -->
              <button [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" (click)="loadCommentComponent(newsDetailsModel.id, 0);">Add a comment</button>
            </div>
          </div>
          <p class="my-2" *ngIf="!newsDetailsModel?.totalComment">
            No Comment found..
          </p>
          <!-- ratings_reviews End -->
          <div class="read_review" *ngIf="newsDetailsModel?.totalComment">
            <div class="all_ratings ratings_reviews clearfix" *ngIf="commentList?.length" >
              <div class="ratings_comment" *ngFor="let item of commentList" >
                <div class="rating-user">
                  <div class="comment_img">
                    <img alt="user_profile" [src]="item.user.profile_url" loading="lazy" (error)="item.user.profile_url = '../../../../assets/images/default_user_profile.png'"/>
                  </div>
                  <div class="rate_details">
                    <div class="ratings_name w-100">
                      <div class="position-relative d-flex justify-content-between flex-wrap">
                        <h5>{{ item.user.name }}</h5>
                        <!-- <div class="rating_time">
                          <span>{{ item.created_at_diff }}</span>
                        </div> -->
                      </div>
                      <div class="comment_view">
                        <p>
                          {{ item.comment }}
                        </p>
                        <p>
                          <span>
                            {{ item.created_at_diff }}
                          </span>
                          <!-- <span>
                            <a href="javascript:void(0);" (click)="loadCommentComponent(newsDetailsModel.id, item.id);">
                              Reply
                            </a>
                          </span> -->
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div [ngClass]="appName == 'RYC' ? 'ryc_pagination_view' : 'mrc_pagination_view'" *ngIf="totalEventsCount > 0">
                <ngb-pagination [collectionSize]="collectionSize" [(page)]="currentPage" size="sm" (pageChange)="getNextPageBusinessList()"></ngb-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" class="back-to-top" *ngIf="isShow" (click)="gotoTop()">
      <img src="assets/images/up-arrow.svg" alt="Back to top" />
    </button>
  </div>
  <!-- Fix action buttons -->
  <div class="fix-action-btn">
    <button *ngIf="newsDetailsModel" type="button" [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" title="{{ 'ang_whatsApp' | translate }}" (click)="onWhatsAppBtn()" data-action="share/whatsapp/share">
      <i class="fa fa-whatsapp"></i>
    </button>
    <button *ngIf="!isMobile" [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" (click)="openShareOptions(contentShare)" title="{{ 'ang_share' | translate }}" >
      <i class="fa fa-share-alt"></i>
    </button>
    <button *ngIf="isMobile" [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" (click)="shareNow()" title="{{ 'ang_share' | translate }}" >
      <i class="fa fa-share-alt"></i>
    </button>
  </div>
  
  <ng-template #contentShare let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h5 class="modal-title">{{ "ang_share" | translate }}</h5>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="d('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="social_profile">
        <ul>
          <li>
              <a href="javascript:void(0)" (click)="shareOnFacebook()">
                <img alt="facebook" src="../assets/images/facebook.png" />
              </a>
          </li>
          <li>
            <a href="javascript:void(0)" (click)="shareOnTwitter()">
              <img alt="twitter" src="../assets/images/twitter.png" />
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" (click)="shareOnLinkedIn()">
              <img alt="linkedin" src="../assets/images/linkedin.png" />
            </a>
          </li>
        </ul>
      </div>
      <!-- social_profile End -->
    </div>
  </ng-template>
  