import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

import { CommonService } from '../../services/common.service';
import { HomeService } from '../../services/home.service';
import { HttpService } from '../../services/http.service';


@Component({
  selector: 'ryec-report-business-modal',
  templateUrl: './report-business-modal.component.html',
  styleUrls: ['./report-business-modal.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ReportBusinessModalComponent implements OnInit {

  @Input() assetTypeId: number = 0;
  @Input() entityId: number = 0;
  @Input() postId: number = 0;
  @Input() groupId: number = 0;
  @Input() type: string = "";

  appName = environment.APP_NAME;
  reasonsList: any[] = [];
  reportReasonModel: any = {};
  selected: any[] = [];
  isSubmit: boolean = false;
  constructor(
    public activeModal: NgbActiveModal,
    private homeService: HomeService,
    private httpS: HttpService,
    private cS: CommonService,
  ) { }

  ngOnInit() {
    if (this.assetTypeId || this.type) {
      this.getReportReasonLists(this.assetTypeId);
    }
    this.reportReasonModel.reason_id = [];
    this.reportReasonModel.comment = "";
  }

  getReportReasonLists(assetTypeId: number): void {
    let postJson: any;
    if(this.type == 'Post'){
      postJson = { type: this.type };
    }
    else if(this.type == 'group'){
      postJson = { type: this.type };
    }
    else{
      postJson = { asset_type_id: assetTypeId };
    }

    this.homeService.getReasonsByEntityTypeByAssetsId(postJson).subscribe((res: any) => {
      if (res.status === 1) {
        this.reasonsList = res.data;
      }
      else{
        this.reasonsList = [];
      }
    });
  }

  // check if the item are selected
  checked(item: any) {
    if (this.selected.indexOf(item.id) != -1) {
      return true;
    }
    else{
      return false;
    }
  }

  // when checkbox change, add/remove the item from the array
  onChange(checked: any, item: any) {
    if (checked) {
      this.selected.push(item.id);
    } else {
      this.selected.splice(this.selected.indexOf(item.id), 1);
    }
  }

  submitReason() {
    this.isSubmit = true;

    let apiPath: any;
    if(this.type == 'Post'){
      this.reportReasonModel.post_id = this.postId;
      if(this.groupId){ //if report on group post then pass groupid also
        this.reportReasonModel.site_id = this.groupId;
      }
      this.reportReasonModel.asset_type_id = this.reasonsList[0].asset_type_id;
      apiPath = 'reportPost'; //if report for post
    }
    else if(this.type == 'group'){
      this.reportReasonModel.entity_id = this.groupId;
      this.reportReasonModel.type = 'group'; //hard coded due to backend side site is use for submit @Yuvraj 14/07/2022
      this.reportReasonModel.asset_type_id = this.reasonsList[0].asset_type_id;
      apiPath = 'reportEntity'; //if report for business
    }
    else{
      this.reportReasonModel.entity_id = this.entityId;
      this.reportReasonModel.asset_type_id = this.assetTypeId;
      apiPath = 'reportEntity'; //if report for business
    }

    this.reportReasonModel.reason_id = this.selected;
    if (this.reportReasonModel.reason_id.length >= 1 && this.reportReasonModel.comment != "") {
      const apiUrl = environment.RYEC_API_URL + apiPath;
      this.httpS.post(apiUrl, this.reportReasonModel).subscribe((res: any) => {
        if (res.status === 1) {
          this.cS.showSuccess(res.message);
        }
        else {
          this.cS.showError(res.message);
        }
        this.activeModal.close();
      }, err => {
        if (err.error) {
          console.log(err.error);
        }
      });
    }
    else {
      this.isSubmit = false;
      if (this.reportReasonModel.reason_id.length > 3) {
        this.cS.showError('You can only select any 3 reasons only');
      }
      if (this.reportReasonModel.reason_id.length == 0) {
        this.cS.showError('Please select at least any one reasone');
      }
      if (this.reportReasonModel.comment == "") {
        this.cS.showError('Please enter comment');
      }
    }
  }

}
