import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../environments/environment';
import { CommonService } from '../../services/common.service';
import { HttpService } from '../../services/http.service';

@Component({
  selector: 'ryec-job-applicants-status-modal',
  templateUrl: './job-applicants-status-modal.component.html',
  styleUrls: ['./job-applicants-status-modal.component.css']
})
export class JobApplicantsStatusModalComponent implements OnInit {
  @Input() jobId: number = 0;
  @Input() applicantUserId: number = 0; 
  @Input() applicantUserName: string = ""; 
  @Input() statusType: string = ""; 
  model: any = {};
  isSubmit: boolean = false;
  appName = environment.APP_NAME;

  constructor(
    public activeModal: NgbActiveModal,
    private httpS: HttpService,
    private cS: CommonService,
  ) { }

  ngOnInit() {
    this.model.job_vacancy_id = this.jobId;
    this.model.user_id = this.applicantUserId;
    this.model.status = this.statusType;
  }

  onSubmit() {
    // this.setSubmitModel();
    console.log(this.model);
    this.isSubmit = true;
    const apiUrl = environment.RYEC_API_URL + 'UpdateJobStatus';
    this.httpS.post(apiUrl, this.model).subscribe((res: any) => {
      if (res.status === 1) {
        this.cS.showSuccess(res.message);
        this.activeModal.dismiss('done');
      }
      else {
        this.cS.showError(res.message);
      }
    });
  }

}
