import { Component, OnInit, EventEmitter, Output, ViewContainerRef, OnDestroy, Inject} from "@angular/core";
import { DOCUMENT, UpperCasePipe } from "@angular/common";
import { Title } from "@angular/platform-browser";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs/Subscription";
import "rxjs/add/operator/filter";
import "rxjs/add/operator/map";
import "rxjs/add/operator/mergeMap";
import { environment } from "../environments/environment";
import { CommonService } from "./shared/services/common.service";
import { HomeService } from "./shared/services/home.service";
import { MessageService } from "./shared/services/message.service";
declare let  ga: Function;

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit, OnDestroy {

	title = environment.TITLE;
	appName = environment.APP_NAME;
	routeUrl: string = "";

	location = {};
	@Output() locationchange: EventEmitter<number> = new EventEmitter();
	spinnerSub: Subscription;
	toggleSpinner: boolean;
	isPrivate: boolean;
	isMobileAppUrl: boolean = false;
	isHomePage: boolean = false;
	isPrivacyPolicyPage: boolean = false;

	constructor(
		private translate: TranslateService,
		public router: Router,
		private activatedRoute: ActivatedRoute,
		private titleService: Title,
		private upperCase: UpperCasePipe,
		private mS: MessageService,
		private hS: HomeService,
		private cS: CommonService,
		vRef: ViewContainerRef,
		//	public toastr: ToastsManager,
		@Inject(DOCUMENT) private doc: any
	) {
		// init toggleSpinner
		this.toggleSpinner = false;
		this.isPrivate = true;
		// init subscription

		this.spinnerSub = this.mS.getSpinner().subscribe(res => {
			setTimeout(() => {
				this.toggleSpinner = res;
			}, 0)
		});

		// set default lang tranlate provider to english
		// this.toastr.setRootViewContainerRef(vRef);
		this.translate.setDefaultLang(this.cS.getLang());
		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				if (event.url.indexOf("/website/") > -1 || event.url.indexOf("/success") > -1) {
					this.isPrivate = false;
				}
				ga('set', 'page', event.urlAfterRedirects);
				ga('send', 'pageview');
			}
		});
	}

	ngOnInit() {
		// subscribe to router events
		this.router.events
			.filter((event) => event instanceof NavigationEnd)
			.map(() => this.activatedRoute)
			.map((route) => {
				while (route.firstChild) { route = route.firstChild; }
				return route;
			})
			.filter((route) => route.outlet === 'primary')
			.mergeMap((route) => {
				route.params.subscribe(data => {
					if (data.slug) {
						console.log(this.upperCase.transform(data.slug));
					}
				});
				return route.data;
			})
			.subscribe((event) => {
				if (event.isHomePage) { //check if page is home then hide search and location filter from header @Yuvraj 27/12/21
					this.isHomePage = true;
				}
				else {
					this.isHomePage = false;
				}

				if (event.isPrivacyPolicyPage) { //check if page is privacy-policy then hide header section as @Yuvraj 12/07/22
					this.isPrivacyPolicyPage = true;
				}
				// this.titleService.setTitle(event.title);
				this.titleService.setTitle(this.title);
			});

		// get geolocation current Position
		if (window.location.href.indexOf("/success") === -1) {
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(this.geolocationSuccess.bind(this), this.geolocationError.bind(this));
			}
		}

		if (window.location.href.indexOf('mobileApp') > 0) {
			this.isMobileAppUrl = true;
		}
		//this.setGTagManager();
		this.setFavicon();
		//this.addGoogleAnalyticsObject();
	}

	private setGTagManager() {
		const s = this.doc.createElement('script');
		s.type = 'text/javascript';
		s.innerHTML = '(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push'
			+ '({\'gtm.start\':new Date().getTime(),event:\'gtm.js\'});var f=d.getElementsByTagName(s)'
			+ '[0],j=d.createElement(s),dl=l!=\'dataLayer\'?\'&l=\'+l:\'\';j.async=true;j.src='
			+ '\'https://www.googletagmanager.com/gtm.js?id=\'+i+dl;f.parentNode.insertBefore(j,f);})'
			+ '(window,document,\'script\',\'dataLayer\',\'' + environment.gTagManagerId + '\');';
		const head = this.doc.getElementsByTagName('head')[0];
		head.appendChild(s);
	}

	private addGoogleAnalyticsObject() {
		const s = this.doc.createElement('script');
		s.innerHTML = '(function (i, s, o, g, r, a, m) { i["GoogleAnalyticsObject"] = r;'
			+ '(i[r] = i[r] || function () { (i[r].q = i[r].q || []).push(arguments);}),'
			+ '(i[r].l = 1 * new Date());'
			+ '(a = s.createElement(o)),'
			+ '(m = s.getElementsByTagName(o)[0]);'
			+ 'a.async = 1;'
			+ 'a.src = g;'
			+ 'a.src = g;'
			+ 'm.parentNode.insertBefore(a, m);})'
			+ '( window, document, "script", "https://www.google-analytics.com/analytics.js", "ga");'
			+ 'ga("create", "UA-118032719-1", "auto");';
		const body = this.doc.getElementsByTagName('body')[0];
		body.appendChild(s);
	}

	private setFavicon() {
		const linkEle = this.doc.createElement('link');
		if (this.appName == 'RYC') {
			linkEle.href = './assets/images/raj_favicon.png'
		}
		else if (this.appName == 'BD') {
			linkEle.href = './assets/images/bharat-darshan-favicon.ico'
		}
		else {
			linkEle.href = './assets/images/favicon.ico'
		}
		linkEle.type = 'image/x-icon'
		linkEle.rel = 'icon'
		const head = this.doc.getElementsByTagName('head')[0];
		head.append(linkEle);
	}

	switchLanguage(lang: string) {
		this.translate.use(lang);
	}

	geolocationSuccess(position: { coords: any }) {
		this.location = position.coords;
		localStorage.setItem('latitude', position.coords['latitude']);
		localStorage.setItem('longitude', position.coords['longitude']);
		console.log(position.coords, '<<<<<location');
		this.cS.updateLatLong(position.coords['latitude'], position.coords['longitude']);
	}

	geolocationError() {
		console.log('Unable to retrive geolocation');
		this.hS.getNetworkLatLong();
	}

	ngOnDestroy(): void {
		this.spinnerSub.unsubscribe();
	}
}
