
<div class="container">
  <ng-template #businesscontent let-c="close" let-d="dismiss">
      <div class="modal-header">
        <h5 *ngIf="businessType === 'advertise'" class="modal-title">ADVERTISE</h5>
        <h5 *ngIf="businessType === 'website'" class="modal-title">CREATE WEBSITE</h5>
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="d('Cross click')"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <p *ngIf="businessType === 'advertise' && appName == 'MRC'" [ngClass]="appName == 'MRC' ? 'business-content-ryc' : 'business-content-mrc'">Advertise your business with My Rajasthan Club Network through various social media channels and highlight your business in My Rajasthan Club Platform. To Know more submit your inquiry.</p>
          <p *ngIf="businessType === 'advertise' && appName == 'RYC'" [ngClass]="appName == 'RYC' ? 'business-content-ryc' : 'business-content-mrc'">Advertise your business with Rajputs Club Network through various social media channels and highlight your business in Rajputs Club Platform. To Know more submit your inquiry.</p>
          <p *ngIf="businessType === 'website' && appName == 'MRC'" [ngClass]="appName == 'MRC' ? 'business-content-ryc' : 'business-content-mrc'">Create your business Website without need of domain and hosting on My Rajasthan Club Platform. To Know more submit your inquiry.</p>
          <p *ngIf="businessType === 'website' && appName == 'RYC'" [ngClass]="appName == 'RYC' ? 'business-content-ryc' : 'business-content-mrc'">Create your business Website without need of domain and hosting on Rajputs Club Platform. To Know more submit your inquiry.</p>
        <form class="form-horizontal" novalidate (submit)="submitBusinessDetail()">
          <div class="control-group">
            <div class="controls">
              <input
                type="text"
                name="businessForm.business_name"
                [(ngModel)]="businessForm.business_name"
                id="business_name"
                placeholder="{{ 'ang_businessprofessionname' | translate }}"
                class="input-xlarge"
                (ngModelChange)="changeTitleEvent($event)"
              />
              <!-- <textarea id="review" formGroupName="review" placeholder="Add review"></textarea> -->
              <p class="help-block" *ngIf="validTitle == true">
                {{ "ang_businessprofessionnamevalidation" | translate }}
              </p>
            </div>
          </div>

          <div class="control-group">
            <div class="controls">
              <input
                type="text"
                name="businessForm.name"
                [(ngModel)]="businessForm.name"
                id="name"
                placeholder="{{ 'ang_businessprofessionusername' | translate }}"
                class="input-xlarge"
                (ngModelChange)="changeTitleEvent($event)"
              />
              <!-- <textarea id="review" formGroupName="review" placeholder="Add review"></textarea> -->
              <p class="help-block" *ngIf="validTitle == true">
                {{ "ang_businessprofessionusernamevalidation" | translate }}
              </p>
            </div>
          </div>

          <div class="control-group">
            <div class="controls">
              <input
                type="text"
                name="businessForm.phoneno"
                [(ngModel)]="businessForm.phoneno"
                id="phoneno" (keypress)="numberOnly($event)" minlength="6" maxlength="13"
                placeholder="{{ 'ang_businessprofessionuserphone' | translate }}"
                class="input-xlarge"
                (ngModelChange)="changeTitleEvent($event)"
              />
              <!-- <textarea id="review" formGroupName="review" placeholder="Add review"></textarea> -->
              <p class="help-block" *ngIf="validTitle == true">
                {{ "ang_businessprofessionphonevalidation" | translate }}
              </p>
            </div>
          </div>

          <div class="control-group">
            <div class="controls">
              <input
                type="text"
                name="businessForm.city"
                [(ngModel)]="businessForm.city"
                id="city"
                placeholder="{{ 'ang_businessprofessionusercity' | translate }}"
                class="input-xlarge"
                (ngModelChange)="changeTitleEvent($event)"
              />
              <!-- <textarea id="review" formGroupName="review" placeholder="Add review"></textarea> -->
              <p class="help-block" *ngIf="validTitle == true">
                {{ "ang_businessprofessioncityvalidation" | translate }}
              </p>
            </div>
          </div>

          <div class="profile_btn">
            <button [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" [disabled]="validForm == false" type="submit" >
              {{ "ang_submit" | translate | uppercase }}
            </button>
          </div>
        </form>
      </div>
    </ng-template>
    <div class="container banner_poster" *ngIf="appName == 'RYC'">
      <div class="row">
        <div class="col-md-7">
          <div class="poster_icons">
            <ul>
              <li>
                <img src="assets/images/login_component/ithihas.svg" class="img-fluid"/>
                <p>Ithihas</p>
              </li>
              <li>
                <img src="assets/images/login_component/rojghar.svg" class="img-fluid"/>
                <p>Rojghar</p>
              </li>
              <li>
                <img src="assets/images/login_component/vyapar.svg" class="img-fluid"/>
                <p>Vyapar</p>
              </li>
              <li>
                <img src="assets/images/login_component/shiksha.svg" class="img-fluid"/>
                <p>Shiksha</p>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-5">
          <div class="ryc_home_image" style="background-image: url('assets/images/login_component/inner_sub_banner.png');">
            <h4>Recive online Payment <br>for your Business</h4>
            <p><a>Download PayG App</a></p>
          </div>
        </div>
      </div>
    </div>  
  <div class="row" *ngIf="appName !== 'RYC'">
      <div class="col-md-12 col-xs-12">
          <div class="home-city-search-wrapper">
              <div class="home-search-main">
                      <!-- <div class="home-form-group">
                        <ryec-city-auto-complete-search [boxWidth]="50" [boxHeight]="36" (storeToParent)="getChildLocationSearchValue($event)">
                        </ryec-city-auto-complete-search>
                      </div> -->
                      <div class="home-form-group">
                          <div class="home_search_here_view ">
                              <i [ngStyle]="{'color':appName == 'MRC' ? 'var(--theme-mrc-based-primary-color)' :appName == 'BD' ? 'var(--theme-bd-based-text-primary-color)' : 'var(--theme-ryc-based-secondary-color)' }" (click)="homeSearchBusiness()" style="cursor: pointer;" class="fa fa-search" aria-hidden="true"></i>
                              <input id="homeSearch" (keyup.enter)="homeSearchBusiness()" [(ngModel)]="homeSearchText" placeholder="Search for Businesses,  Social Organisations, Persons" type="text" #homeSearchInput>
                              <div [ngClass]="appName == 'MRC' ? 'home_auto_complete_list-mrc' : 'home_auto_complete_list-ryc'" class="home_auto_complete_list-mrc" style="display: none;">
                                  <perfect-scrollbar>
                                      <ul>
                                          <li *ngFor="let item of items" (click)="homeSearchBusinessByCategory(item.value)" class="home_search_input">{{item.value}}</li>
                                      </ul>
                                  </perfect-scrollbar>
                              </div>
                          </div>
                      </div>
                      <div class="form-group text-center search-btn">
                        <button type="button" name="homeSearchButton" (click)="homeSearchBusiness()" [ngClass]="appName == 'RYC' ? 'ryec_btn' :appName == 'BD' ? 'bd_btn': 'mrc_btn'" class="btn">Search</button>
                      </div>
                      <!-- this all menu move in hemberg menu -->
                      <div class="form-group text-center" *ngIf="false">
                        <span *ngIf="appName == 'MRC'"> {{'ang_searchformmessageMRC' | translate}} </span>
                        <span *ngIf="appName == 'RYC'"> {{'ang_searchformmessageRYC' | translate}} </span>
                          <br>
                          <br>
                          <div *ngIf="appName == 'RYC'">
                          {{'ang_supportryuvaclub' | translate}}
                          <a href="" *ngIf="!userDetail || userDetail?.membership_type == '0' || !userDetail?.membership_type" class="ryec_btn donate-btn" routerLink="/home/membership-plan">{{'ang_donate' | translate}}
                          </a>
                          <br>
                          </div>
                      </div>
                      <div class="form-group text-center create-website">
                          <!-- <a href="javascript:void(0)" *ngIf="userDetail && appName == 'RYC' && userDetail.isRajput == 1" class="ryec_btn free-listing-btn" (click)="addContentForBusiness(businesscontent, 'website')" >{{'ang_createwebsite' | translate}}
                          </a> -->
                          <a href="javascript:void(0)" *ngIf="userDetail && !userDetail.business_id && appName == 'RYC' && userDetail.isRajput == 1" class="ryec_btn free-listing-btn" routerLink="/home/add-business">{{'ang_free_listing' | translate}}
                          </a>
                          <!-- <a href="javascript:void(0)" *ngIf="userDetail && appName == 'RYC'" class="ryec_btn advertise-btn" (click)="addContentForBusiness(businesscontent, 'advertise')">{{'ang_advertise' | translate}}
                          </a> -->

                          <!-- <a href="javascript:void(0)" *ngIf="userDetail && appName == 'MRC'" class="mrc_btn free-listing-btn" (click)="addContentForBusiness(businesscontent, 'website')" >{{'ang_createwebsite' | translate}}
                          </a> -->
                          <a href="javascript:void(0)" *ngIf="userDetail && !userDetail.business_id && appName == 'MRC'" class="mrc_btn free-listing-btn" routerLink="/home/add-business">{{'ang_free_listing' | translate}}
                          </a>
                      </div>
                      <div class="form-group text-center create-website" *ngIf="appName == 'MRC'">
                          <a class="mrc_btn free-listing-btn" routerLink="/home/business-list/premium-business/relevance" style="font-size: 14px;">{{'ang_premiumbusinesses' | translate}}  
                          </a>
                          <a class="mrc_btn free-listing-btn" routerLink="/places" style="font-size: 14px;">
                            {{'ang_places' | translate}}
                          </a>
                          <a class="mrc_btn free-listing-btn" routerLink="/groups" style="font-size: 14px;">
                            {{'ang_groups' | translate}}
                          </a>
                          <a class="mrc_btn free-listing-btn" routerLink="/trending/service" style="font-size: 14px;">{{'ang_trendingservices' | translate}}
                          </a>
                          <a class="mrc_btn free-listing-btn" routerLink="/atoz/categories" style="font-size: 14px;">{{'ang_atozcategories' | translate}}
                          </a>
                      </div>
              </div>
          </div>
      </div>
  </div>
  <!-- Row  End -->
  <div class="row home-main-content mt-40">
      <div class="col-md-3">
          <!-- <div class="menu_RYEC category_menu" *ngIf="categoriesArr?.length > 0 && false">
              <h2>{{'ang_categories' | translate}}</h2>
              <ul>
                  <li *ngFor="let item of categoriesArr; let i=index">
                      <a href="" routerLink="/category/{{item.category_slug}}" (click)="saveParentCategories(item)">
                          <span class="all_category"><img [src]="item.image_url"></span>
                          <p>{{item.name}}</p></a>
                  </li>
                  <li *ngIf="categoriesArr?.length > 0">
                      <a href="javascript:void(0)" routerLink="/atoz/categories">
                          <span class="a-z-categories-hover"></span><p>{{'ang_atozcategories' | translate}}</p></a>
                  </li>
              </ul>
          </div> -->
          <!-- menu_RYEC End -->


          <!-- Trending Category section -->
          <div class="bd_view" *ngIf="appName !== 'RYC'">
            <div class="home_card fixedElement" *ngIf="trendingCategoriesLists.length">
                <div class="trend-category">
                    <h2 class="page-title">{{'ang_trendingCategories' | translate}}</h2>
                    <div [ngClass]="appName == 'RYC' ? 'category-list-ryc' : 'category-list-mrc'">
                        <ul>
                            <li *ngFor="let item of trendingCategoriesLists">
                              <div *ngIf="item.sub_category_count > 0; then showCategory; else showBusinessDetail"></div>
                                <ng-template #showCategory>
                                  <a routerLink="/category/{{item.category_slug}}" (click)="saveParentCategory(item)">
                                    
                                    {{ item.category_name }}
                                    <img [src]="item.category_logo" alt="{{ item.category_name }}" width="30px" />
                                  </a>
                                </ng-template>
                                <ng-template #showBusinessDetail>
                                  <a routerLink="/home/business-list/{{item.category_slug}}/relevance">
                                    
                                    {{ item.category_name }}
                                    <img [src]="item.category_logo" alt="{{ item.category_name }}" width="30px" />
                                  </a>
                                </ng-template>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
          </div>
          <div class="home_card fixedElement" *ngIf="appName == 'RYC'">
            <div class="trend-category">
              <h4 class="page-title"><img src="assets/images/login_component/ads.svg" class="img-fluid" /> News </h4>
              <div [ngClass]="appName == 'RYC' ? 'category-list-ryc' : 'category-list-mrc'">
                <ul *ngFor="let item of newslist | keyvalue"><h5>{{item.key}}</h5>
                  <li *ngFor="let subItem of item.value">
                    <a routerLink="/news/{{ subItem.business_slug }}">
                      <img src= {{subItem.business_logo_url}} width="60px" />
                      <p>
                        <br><b>{{subItem.name}}</b><br><span>{{subItem.created_at_diff|uppercase}}</span><br>
                        <span> created by {{subItem.user.name|lowercase}}</span><br>                      
                    </a>
                  </li>
                 
                </ul>
              </div>
            </div>
          </div>
      </div>

      <div class="col-md-6">
          <!-- <div class="map_section">
              <ngui-map zoom="13" center="{{center_lat}}, {{center_long}}">
                  <custom-marker position="{{user_lat}}, {{user_long}}">
                      <div>
                          <img src="assets/images/user-marker.png" height="40px" />
                      </div>
                  </custom-marker>
                  <marker *ngFor="let pos of businessMarkers" position="{{pos.latitude}}, {{pos.longitude}}" (click)="clicked($event, pos)">
                  </marker>
                  <info-window id="ibn">
                      <div style="width: 200px;cursor: pointer;" routerLink="/home/business-detail/{{business_slug}}">
                          <p>{{business_title}}</p>
                          <p>{{business_address}}</p>
                      </div>
                  </info-window>
              </ngui-map>
          </div> -->
          <!-- map_section End -->
          <ryec-home-post [isPostDetailPage]="isPostDetailPage" > </ryec-home-post>
          <!-- This section not needed -->
          <div *ngIf="false">
          <!-- Premium Business Start -->
          <div class="trending_services_view" *ngIf="premiumBusinessArr?.length > 0 && !userDetail && isPostDetailPage == 0">
              <h2>{{'ang_premiumbusinesses' | translate}}</h2>
              <a href="" class="view_all_btn" routerLink="/home/business-list/premium-business/relevance">{{'ang_viewall' | translate}}</a>
              <div class="row flex-container">
                  <div class="col-sm-12" *ngFor="let item of premiumBusinessArr | slice:0:4; let i=index">
                      <div class="clearfix business_listing_box">
                          <div class="business_listing_img">
                              <img alt="essar-real-estate" [src]="item.logo_thumbnail">
                          </div>
                          <div class="business_listing_content">
                              <div class="row">
                                  <div class="col-md-7 col-sm-12 col-xs-12">
                                      <div class="business_content_left business_title_icon">
                                          <h2>
                                              <img *ngIf="item.membership_type == 0" [src]="item.membership_type_icon" title="{{'ang_basicplantext' | translate}}" />
                                              <img *ngIf="item.membership_type == 1" [src]="item.membership_type_icon" title="{{'ang_premiumplantext' | translate}}" />
                                              <img *ngIf="item.membership_type == 2" [src]="item.membership_type_icon" title="{{'ang_lifetimeplantext' | translate}}" />
                                              <span class="text-overflow-hidden">{{item.name}}</span>
                                          </h2>
                                          <ul>
                                              <li class="text-overflow-hidden">
                                                  <i class="fa fa-user" aria-hidden="true" *ngIf="item.owners"></i>{{item.owners}}</li>
                                              <!-- <li class="text-overflow-hidden">
                                                          <i class="fa fa-mobile" aria-hidden="true" *ngIf="item.mobile"></i>{{item.country_code}}<span *ngIf="item?.country_code">-</span>{{item.mobile}}
                                                      </li> -->
                                              <li class="text-overflow-hidden" *ngIf="item.country">
                                                  <i class="fa fa-map-marker " aria-hidden="true"></i> {{item.city}}
                                                  <span *ngIf="item.city">, </span> {{item.state}}
                                                  <span *ngIf="item.state">, </span> {{item.country}}
                                              </li>

                                              <li class="text-overflow-hidden" *ngIf="item.categories_name_list != null">
                                                  <i class="fa fa-angle-right text-overflow-hidden" aria-hidden="true"></i>
                                                  <span>{{item.categories_name_list}}</span>
                                              </li>

                                              <!-- <li class="text-overflow-hidden" *ngIf="item.parent_categories?.length > 0">
                                                  <i class="fa fa-angle-right text-overflow-hidden" aria-hidden="true"></i>
                                                  <span *ngFor="let cat of item.parent_categories; let isLast=last; ">
                              {{cat.category_name}}{{isLast ? '' : ', '}}
                              </span>
                                              </li> -->
                                          </ul>
                                      </div>
                                  </div>
                                  <div class="col-md-5 col-sm-12 col-xs-12">
                                      <div class="business_content_right">
                                          <a href="" [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" routerLink="/home/business-detail/{{item.business_slug}}">{{'ang_viewmore' | translate}}
                          <i aria-hidden="true" class="fa fa-angle-right"></i>
                          </a>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <!-- col End -->
              </div>
              <!-- Row End -->
          </div>

          <!-- Recently Added Business -->
          <div class="trending_services_view" *ngIf="recentlyAddedBusinessArr?.length > 0 && !userDetail  && isPostDetailPage == 0">
              <h2>{{'ang_recenltyaddedbusinesses' | translate}} </h2>
              <a href="" class="view_all_btn" routerLink="/home/business-list/recently-added-business/relevance">{{'ang_viewall' | translate}}</a>
              <div class="row flex-container">
                  <div class="col-sm-12" *ngFor="let item of recentlyAddedBusinessArr | slice:0:4; let i=index">
                      <div class="clearfix business_listing_box">
                          <div class="business_listing_img">
                              <img alt="essar-real-estate" [src]="item.logo_thumbnail">
                          </div>
                          <div class="business_listing_content">
                              <div class="row">
                                  <div class="col-md-7 col-sm-12 col-xs-12">
                                      <div class="business_content_left business_title_icon">
                                          <h2>
                                              <img *ngIf="item.membership_type == 1" [src]="item.membership_type_icon" title="{{'ang_premiumplantext' | translate}}" />
                                              <img *ngIf="item.membership_type == 0" [src]="item.membership_type_icon" title="{{'ang_basicplantext' | translate}}" />
                                              <span class="text-overflow-hidden">{{item.name}}</span>
                                          </h2>
                                          <ul>
                                              <li class="text-overflow-hidden">
                                                  <i class="fa fa-user" aria-hidden="true" *ngIf="item.owners"></i>{{item.owners}}</li>
                                              <!-- <li class="text-overflow-hidden">
                                                              <i class="fa fa-mobile" aria-hidden="true" *ngIf="item.mobile"></i>{{item.country_code}}<span *ngIf="item?.country_code">-</span>{{item.mobile}}
                                                          </li> -->
                                              <li class="text-overflow-hidden" *ngIf="item.country">
                                                  <i class="fa fa-map-marker " aria-hidden="true"></i> {{item.city}}
                                                  <span *ngIf="item.city">, </span> {{item.state}}
                                                  <span *ngIf="item.state">, </span> {{item.country}}
                                              </li>
                                              <li class="text-overflow-hidden" *ngIf="item.parent_categories?.length > 0">
                                                  <i class="fa fa-angle-right text-overflow-hidden" aria-hidden="true"></i>
                                                  <span *ngFor="let cat of item.parent_categories; let isLast=last; ">
                              {{cat.category_name}}{{isLast ? '' : ', '}}
                              </span>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                                  <div class="col-md-5 col-sm-12 col-xs-12">
                                      <div class="business_content_right">
                                          <a href="" [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" routerLink="/home/business-detail/{{item.business_slug}}">{{'ang_viewmore' | translate}}
                          <i aria-hidden="true" class="fa fa-angle-right"></i>
                          </a>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <!-- Trending_services_view End -->
          <div class="trending_services_view trending_services_title" *ngIf="trendCategorieArr?.length > 0 && !userDetail  && isPostDetailPage == 0">
              <h2>{{'ang_trendingcategories' | translate}}</h2>
              <a href="" class="view_all_btn" routerLink="/trending/category">{{'ang_viewall' | translate}}</a>
              <div class="row">
                  <div class="col-md-4 col-sm-6 col-xs-6" *ngFor="let item of trendCategorieArr | slice:0:6; let i=index">
                      <div *ngIf="item.sub_category_count > 0; then showCategory; else showBusinessDetail"></div>
                      <ng-template #showCategory>
                          <a href="" routerLink="/category/{{item.category_slug}}" (click)="saveParentCategory(item)">
                              <div class="trending_box">
                                  <img [src]="item.category_logo" alt="legal">
                                  <div class="trending_title">{{item.category_name}}</div>
                                  <!-- trending_title -->
                              </div>
                              <!-- trending_box End -->
                          </a>
                      </ng-template>
                      <ng-template #showBusinessDetail>
                          <a href="" routerLink="/home/business-list/{{item.category_slug}}/relevance">
                              <div class="trending_box">
                                  <img [src]="item.category_logo" alt="legal">
                                  <div class="trending_title">{{item.category_name}}</div>
                                  <!-- trending_title -->
                              </div>
                              <!-- trending_box End -->
                          </a>
                      </ng-template>
                      <!-- col End -->
                  </div>
                  <!-- Row End -->
              </div>
          </div>

          <!-- trending_services_view End -->
          <div class="trending_services_view" *ngIf="trendServiceArr?.length > 0 && !userDetail && isPostDetailPage == 0">
              <h2>{{'ang_trendingservices' | translate}}</h2>
              <a href="" class="view_all_btn" routerLink="/trending/service">{{'ang_viewall' | translate}}</a>
              <div class="row">
                  <div class="col-md-4 col-sm-6 col-xs-6" *ngFor="let item of trendServiceArr | slice:0:6; let i=index">
                      <div *ngIf="item.sub_category_count > 0; then showCategory; else showBusinessDetail"></div>
                      <ng-template #showCategory>
                          <a href="" routerLink="/service/{{item.category_slug}}" (click)="saveParentCategory(item)">
                              <div class="trending_box">
                                  <img [src]="item.category_logo" alt="legal">
                                  <div class="trending_title">{{item.category_name}}</div>
                                  <!-- trending_title -->
                              </div>
                              <!-- trending_box End -->
                          </a>
                      </ng-template>
                      <ng-template #showBusinessDetail>
                          <a href="" routerLink="/home/business-list/{{item.category_slug}}/relevance">
                              <div class="trending_box">
                                  <img [src]="item.category_logo" alt="legal">
                                  <div class="trending_title">{{item.category_name}}</div>
                                  <!-- trending_title -->
                              </div>
                              <!-- trending_box End -->
                          </a>
                      </ng-template>
                      <!-- trending_box End -->
                  </div>
                  <!-- col End -->
              </div>
              <!-- Row End -->
          </div>
          </div>
      </div>
      <div class="col-md-3">
          <div class="desktop-visible">
            <!-- Sponser business -->
            <div class="bd_view">
              <div *ngIf="appName !== 'RYC'">
                <div class="home_card place-details" *ngIf="businessAndTopPlacesLists.business?.length">
                  <div class=" place_card">
                    <h2 class="page-title">{{'ang_sponsored_business' | translate}}</h2>
                    <div class="owl-carousel nearby-carousel owl-theme sliding">
                      <div class="item thumbnail" *ngFor="let item of businessAndTopPlacesLists.business">
                        <div class="tab_card place_card position-relative">
                          <a routerLink="{{ item.entity_type + item.business_slug }}" title="{{ item.name }}">
                            <i class="tab_img-wrap">
                              <img class="tab_card-image" [src]="item.business_logo_url" alt="{{ item.name }}"/>
                            </i>
                            <div class="tab_card-content">
                              <h4 class="font-600">
                                <a routerLink="{{ item.entity_type + item.business_slug }}" title="{{ item.name }}">
                                  {{ item.name }}
                                </a>
                              </h4>
                              <div class="d-flex justify-content-between place-category">
                                  <span class="category-text mr-2">
                                    <span>
                                      {{ item.categories.length > 1 ? (item.categories | slice: 0:25) + ".." : item.categories }}
                                    </span>
                                  </span>
                              </div>
                              <div class="ratings_reviews" *ngIf="item.total_review > 0" >
                                <div class="d-flex align-items-center rate_bar">
                                  <ngb-rating [ngClass]="appName == 'RYC' ? 'rating-star-ryc' : 'rating-star-mrc'" [(rate)]="item.avg_rating" [readonly]="true" (max)="5"></ngb-rating>
                                  <em class="review ml-2">({{ item.total_review }} Reviews)</em>
                                </div>
                              </div>
                              <div class="ratings_reviews" *ngIf="item.total_review == 0" >
                                <div class="d-flex align-items-center rate_bar">
                                  <ngb-rating [ngClass]="appName == 'RYC' ? 'rating-star-ryc' : 'rating-star-mrc'" [(rate)]="item.avg_rating" [readonly]="true" (max)="5"></ngb-rating>
                                  <em class="review ml-2">({{ item.total_review }} Reviews)</em>
                                </div>
                              </div>
                              <p>
                                {{ item.description.length > 1 ? (item.description | slice: 0:50) + ".." : item.description }}
                              </p>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Top Places -->
              <!-- <div class="home_card place-details" *ngIf="businessAndTopPlacesLists.place?.length">
                <div class=" place_card">
                  <h2 class="page-title">{{'ang_topPlaces' | translate}}</h2>
                  <div class="owl-carousel nearby-carousel owl-theme sliding">
                    <div class="item thumbnail" *ngFor="let item of businessAndTopPlacesLists.place">
                      <div class="tab_card place_card position-relative">
                          <a routerLink="{{ item.entity_type + item.business_slug }}" title="{{ item.name }}">
                            <i class="tab_img-wrap">
                                <img class="tab_card-image" [src]="item.business_logo_url" alt="{{ item.name }}"/>
                            </i>
                            <div class="tab_card-content">
                              <h4 class="font-600">
                                <a routerLink="{{ item.entity_type + item.business_slug }}" title="{{ item.name }}">
                                  {{ item.name }}
                                </a>
                              </h4>
                              <div class="d-flex justify-content-between place-category">
                                <span class="category-text mr-2">
                                  <span>
                                    {{ item.categories.length > 1 ? (item.categories | slice: 0:25) + ".." : item.categories }}
                                  </span>
                                </span>
                              </div>
                              <div class="ratings_reviews" *ngIf="item.total_review > 0" >
                                <div class="d-flex align-items-center rate_bar">
                                  <ngb-rating [ngClass]="appName == 'RYC' ? 'rating-star-ryc' : 'rating-star-mrc'" [(rate)]="item.avg_rating" [readonly]="true" (max)="5"></ngb-rating>
                                  <em class="review ml-2">({{ item.total_review }} Reviews)</em>
                                </div>
                              </div>
                              <div class="ratings_reviews" *ngIf="item.total_review == 0" >
                                <div class="d-flex align-items-center rate_bar">
                                  <ngb-rating [ngClass]="appName == 'RYC' ? 'rating-star-ryc' : 'rating-star-mrc'" [(rate)]="item.avg_rating" [readonly]="true" (max)="5"></ngb-rating>
                                  <em class="review ml-2">({{ item.total_review }} Reviews)</em>
                                </div>
                              </div>
                              <p>
                                {{ item.description.length > 1 ? (item.description | slice: 0:50) + ".." : item.description }}
                              </p>
                            </div>
                          </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
              <div class="home_card place-details" *ngIf="appName == 'RYC'">
                <div class="place_card">
                  <h2 class="page-title">Introduction <br>to Rajput Club</h2>
                  <div class="side_slider" style="background-image: url('assets/images/login_component/side_slider.png');">
                    <img src="assets/images/login_component/side_img.png" class="img-fluid" />
                  </div>
                  <h2 class="page-title">Introduction <br>to Rajput Club</h2>
                </div>
              </div>
              <!-- Top Places -->
              <div *ngIf="appName !== 'RYC'">
                <div class="home_card place-details" *ngIf="businessAndTopPlacesLists.place?.length">
                  <div class=" place_card">
                    <h2 class="page-title">{{'ang_topPlaces' | translate}}</h2>
                    <div class="owl-carousel nearby-carousel owl-theme sliding">
                      <div class="item thumbnail" *ngFor="let item of businessAndTopPlacesLists.place">
                        <div class="tab_card place_card position-relative">
                            <a routerLink="{{ item.entity_type + item.business_slug }}" title="{{ item.name }}">
                              <i class="tab_img-wrap">
                                  <img class="tab_card-image" [src]="item.business_logo_url" alt="{{ item.name }}"/>
                              </i>
                              <div class="tab_card-content">
                                <h4 class="font-600">
                                  <a routerLink="{{ item.entity_type + item.business_slug }}" title="{{ item.name }}">
                                    {{ item.name }}
                                  </a>
                                </h4>
                                <div class="d-flex justify-content-between place-category">
                                  <span class="category-text mr-2">
                                    <span>
                                      {{ item.categories.length > 1 ? (item.categories | slice: 0:25) + ".." : item.categories }}
                                    </span>
                                  </span>
                                </div>
                                <div class="ratings_reviews" *ngIf="item.total_review > 0" >
                                  <div class="d-flex align-items-center rate_bar">
                                    <ngb-rating [ngClass]="appName == 'RYC' ? 'rating-star-ryc' : 'rating-star-mrc'" [(rate)]="item.avg_rating" [readonly]="true" (max)="5"></ngb-rating>
                                    <em class="review ml-2">({{ item.total_review }} Reviews)</em>
                                  </div>
                                </div>
                                <div class="ratings_reviews" *ngIf="item.total_review == 0" >
                                  <div class="d-flex align-items-center rate_bar">
                                    <ngb-rating [ngClass]="appName == 'RYC' ? 'rating-star-ryc' : 'rating-star-mrc'" [(rate)]="item.avg_rating" [readonly]="true" (max)="5"></ngb-rating>
                                    <em class="review ml-2">({{ item.total_review }} Reviews)</em>
                                  </div>
                                </div>
                                <p>
                                  {{ item.description.length > 1 ? (item.description | slice: 0:50) + ".." : item.description }}
                                </p>
                              </div>
                            </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
  </div>
  <!-- Row  End -->
</div>
<!--container end-->

<!-- Landing Page Start -->
<!--container end-->

<div class="outer_bg">
  <section class="about_bg" *ngIf="env.APP_NAME == 'RYEC'">
      <div class="container">
          <div class="row">
              <div class="col-sm-6">
                  <div class="logo_outer">
                      <img src="assets/images/logo RYEC.png">
                  </div>
                  <div class="first_left_detail">
                      <h2>Promote/Advertise Your Business and Create Your Business Website with My Rajasthan Club</h2>
                      <h6>My Rajasthan Club is a business listing site & local search engine, especially for worldwide Rajasthan Community. Any Rajasthan Businessman, Professional, Industrialist & Entrepreneur can join this portal and list their business/service with all information. You can also create your business public website using My Rajasthan Club.</h6>
                  </div>
                  <div class="stor_link">
                      <a href="https://apps.apple.com/in/app/rajput-club/id1660935382" target="_blank">
                        <img src="assets/images/app_store_landing.png" alt="" class="img-responsive">
                      </a>
                      <a href="https://play.google.com/store/apps/details?id=com.rajputsclub" target="_blank">
                        <img src="assets/images/play_store_landing.png" alt="" class="img-responsive">
                      </a>
                  </div>
              </div>
              <div class="col-sm-6">
                  <div class="right_mockup">
                      <img src="assets/images/mockup1.png">
                  </div>
              </div>
          </div>
      </div><!--container end-->
  </section>
  <section class="lifetime_slider" *ngIf="env.APP_NAME == 'RYEC'">
      <div class="container">
          <h2 class="title">Our Lifetime Members</h2>
          <div class="row">
              <div class="col-sm-12">
                   <ngb-carousel>
                     <ng-template ngbSlide *ngFor="let list of memebersList">
                      <div class="member_card">
                          <span class="photo">
                              <img [src]="list.image" alt="">
                          </span>
                          <h3>{{ list?.bussiness_name }}</h3>
                          <h6>{{ list?.owner_name }}</h6>
                          <p>{{ list?.description }}</p>
                      </div>
                    </ng-template>
                  </ngb-carousel>
              </div>
          </div>
      </div>
  </section><!--member slider end-->
  <section *ngIf="env.APP_NAME == 'RYEC'">
      <div class="container">
          <h2 class="title">Create Business Website and Membership Plans</h2>
          <div class="row">
              <!-- <div class="col-sm-1">

              </div>
              <div class="col-sm-2">
                  <div class="year_plan">
                      <h3>25</h3>
                      <h6>years</h6>
                  </div>
              </div>
              <div class="col-sm-2">
                  <div class="year_plan">
                      <h3>10</h3>
                      <h6>years</h6>
                  </div>
              </div>
              <div class="col-sm-2">
                  <div class="year_plan">
                      <h3>05</h3>
                      <h6>years</h6>
                  </div>
              </div>
              <div class="col-sm-2">
                  <div class="year_plan">
                      <h3>03</h3>
                      <h6>years</h6>
                  </div>
              </div>
              <div class="col-sm-2">
                  <div class="year_plan">
                      <h3>01</h3>
                      <h6>years</h6>
                  </div>
              </div>
              <div class="col-sm-1">

              </div> -->
              <div class="col-sm-12">
                  <div class="bottom_plan_text">
                      <h4>To avail the business website and membership, please contact us on <a href="mailto:info@myrajasthanclub.com" title="Mail"> info@myrajasthanclub.com</a> or Call/WhatsApp/SMS us on +91 9099937890. We will get in touch with you.</h4>
                  </div>
              </div>
          </div>
      </div>
  </section><!--Premium plans end-->
  
  <section class="testimonial_slider" *ngIf="env.APP_NAME == 'RYEC'">
      <div class="container">
          <h2 class="title">Testimonials</h2>
          <div class="row">
              <div class="col-sm-12">
                   <ngb-carousel>
                     <ng-template ngbSlide>
                      <div class="review_content">
                          <p>Great app. Ihave created my website using My Rajasthan Club. We can use our Rajasthan community facilities here and by doing this .We’ll make our community strong in both economic and financial way.</p>
                          <h6>- Jatin Rohilla</h6>
                      </div>
                    </ng-template>
                    <ng-template ngbSlide>
                      <div class="review_content">
                          <p>Good app for Rajasthan community.It can improve the economy of My Rajasthan community by sharing services with each other.</p>
                          <h6>- Dashrathsinh jadeja</h6>
                      </div>
                    </ng-template>
                    <ng-template ngbSlide>
                      <div class="review_content">
                          <p>Best APP to unite our Rajasthan community. Good for our brothers and sisters to grow to their own potentials.</p>
                          <h6>- Divyarajsinh Vala</h6>
                      </div>
                    </ng-template>
                     <ng-template ngbSlide>
                      <div class="review_content">
                          <p>It's a nice start by the youngsters for the benefit of community on for the community anf by the community principle. Thanks to you all for giving such an excellent platform to grow up the business.</p>
                          <h6>- Vishwarajsinh Jadeja</h6>
                      </div>
                    </ng-template>
                    <ng-template ngbSlide>
                      <div class="review_content">
                          <p>Awesome application, kudos to founders who has taken this initiative to provide such an excellent platform for Community Business Development.</p>
                          <h6>- Jayrajsinh Jadeja</h6>
                      </div>
                    </ng-template>
                    <ng-template ngbSlide>
                      <div class="review_content">
                          <p>Such great platform for the Entrepreneurs to join the Rajasthan Business Revolution and may mataji bless to all my brothers and sisters for own enterpreneurs.</p>
                          <h6>- Rajdeepsinh Jadeja</h6>
                      </div>
                    </ng-template>
                     <ng-template ngbSlide>
                      <div class="review_content">
                          <p>"RYEC - of the Rajputs, by the Rajputs, for the Rajputs". Thums up for a great initiative.</p>
                          <h6>- Vikramsinh Solanki</h6>
                      </div>
                    </ng-template>
                    <ng-template ngbSlide>
                      <div class="review_content">
                          <p>It’s wonderful. Three weeks ago I listed my business on My Rajasthan Club , Till Today, I Got 4-5 business related calls as they want to purchase my products. Thanks for making such kind of app.</p>
                          <h6>- Natversinh Zala</h6>
                      </div>
                    </ng-template>
                  </ngb-carousel>
              </div>
          </div>
      </div>
  </section><!--testimonial slider end-->
</div>
<ngx-json-ld [json]="schemaJson"></ngx-json-ld>
