import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../environments/environment';
import { CommonService } from '../../services/common.service';
import { HttpService } from '../../services/http.service';
 

@Component({
  selector: 'ryec-group-member-remove-confirmation-modal',
  templateUrl: './group-member-remove-confirmation-modal.component.html',
  styleUrls: ['./group-member-remove-confirmation-modal.component.css']
})
export class GroupMemberRemoveConfirmationModalComponent implements OnInit {
  @Input() groupId: number = 0;
  @Input() removeUserId: number = 0;
  @Input() removeUserName: string = "";
  removeuserModel: any = {};
  isSubmit: boolean = false;
  appName = environment.APP_NAME;

  constructor(
    public activeModal: NgbActiveModal,
    private httpS: HttpService,
    private cS: CommonService,
  ) { }

  ngOnInit() {
    this.removeuserModel.site_id = this.groupId;
    this.removeuserModel.user_id = this.removeUserId;
    this.removeuserModel.reason = "";
  }

  onSubmit() {
    if (this.removeuserModel.reason !== '') {
      this.removeUser();
      // swal({
      //   title: 'Are you sure you want to Remove '+this.removeUserName+' ?',
      //   type: 'warning',
      //   confirmButtonText: 'Yes',
      //   cancelButtonText: 'No',
      //   showCancelButton: true,
      //   showCloseButton: true,
      //   reverseButtons: true,
      //   confirmButtonColor: '#3085d6',
      //   cancelButtonColor: '#d33',
      //   confirmButtonClass: 'btn btn-success',
      //   cancelButtonClass: 'btn btn-danger'
      // }).then((result) => {
      //   if (result.value) {
      //     this.removeUser();
      //   }
      // });
    }
  }

  removeUser() {
    this.isSubmit = true;
    const apiUrl = environment.RYEC_API_URL + 'removeGroupMember';
    this.httpS.post(apiUrl, this.removeuserModel).subscribe((res: any) => {
      if (res.status === 1) {
        this.cS.showSuccess(res.message);
        this.activeModal.dismiss('close');
      }
      else {
        this.cS.showError(res.message);
      }
      // this.activeModal.close();
    });
  }

}
