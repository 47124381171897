import { EventOrganizerList } from "./event-organizers-list.model";

export class EventModel {
    id: any;
    site_id: any;
    event_category: string;
    event_nature: string;
    street_address: string;
    title: string;
    description: string;
    event_type: string;
    event_price: any;
    event_location: string;
    show_map: boolean;
    latitude: any;
    longitude: any;
    website_url: string;
    event_repeat: string;
    repeat_date: string;
    event_organizers: any = [];
    start_date: string;
    start_time: string;
    end_date: string;
    end_time: string;
    location_id: any;
    event_end_date = "";
    timezone_id: any;
    cover_image: string;
    logo_image: string;
    external_link: string;
    organizer: string;
    constructor() {
        this.id = 0;
        this.site_id = 0;
        this.event_category = null as any;
        this.event_nature = "online";
        this.street_address = "";
        this.title = "";
        this.description = "";
        this.event_type = "free";
        this.event_price = 0;
        this.event_location = "";
        this.show_map = false;
        this.latitude = "";
        this.longitude = "";
        this.website_url = "";
        this.event_repeat = "not repeat";
        this.repeat_date = "";
        // this.event_organizers.push({
        //     "name": "",
        //     "email": "",
        //     "phone": ""
        // });
        this.event_organizers = new Array<EventOrganizerList>();
        this.start_date = "";
        this.start_time = "";
        this.end_date = "";
        this.end_time = "";
        this.location_id = 0
        this.event_end_date = "";
        this.timezone_id = 19;
        this.cover_image = "";
        this.logo_image = "";
        this.external_link = "";
        this.organizer = "";
    }
}