import { Component, OnInit } from "@angular/core";
// import { ActivatedRoute, Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import { HttpService } from "src/app/shared/services/http.service";
import { environment } from "../../../../environments/environment";
  
@Component({
  selector: "ryec-payment-successful",
  templateUrl: "./payment-successful.component.html",
  styleUrls: ["./payment-successful.component.css"],
})
export class PaymentSuccessfulComponent implements OnInit {
  res: any;
  status: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private httpS: HttpService,
    // private router: Router
  ) {}

  ngOnInit() {
    let productid = this.activatedRoute.snapshot.params.slug;
    console.log(productid);
    this.doPaymentOrderDone(productid);
  }

  doPaymentOrderDone(productid: any) {
    const postJson = {
      payment_order_id: productid,
    };

    const apiUrl = environment.RYEC_API_URL + "doPaymentOrderDone";
    this.httpS.post(apiUrl, postJson).subscribe(
      (res: any) => {
        console.log(res);
        this.status = res.status;
        //    this.router.navigate(['home']);
      },
      (err) => {
        this.status = 0;
        if (err.error) {
          console.log(err.error);
        }
      }
    );
  }
}
