import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PostJsonBusinessList } from 'src/app/shared/class/data.model';

  
import {
  marker
} from 'src/app/shared/class/data.model';
import {
  debounceTime,
  map,
  distinctUntilChanged,
  filter
} from "rxjs/operators";
import { fromEvent } from 'rxjs/observable/fromEvent';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/shared/services/common.service';
import { HomeService } from 'src/app/shared/services/home.service';
declare var $: any;
@Component({
  selector: 'ryec-entity-listing',
  templateUrl: './entity-listing.component.html',
  styleUrls: ['./entity-listing.component.css']
})
export class EntityListingComponent implements OnInit {

  currentPage: number;
  routerLink = '';
  activeUrl = '';
  BusinessList :any;
  sort_slug = '';
  maxSize = 5;
  totalBuinessCount = 0;
  collectionSize = 0;
  first = 1;
  last = 0;
  perPageList = environment.BUSINESS_LIST_LIMIT;
  endPage: number = environment.BUSINESS_LIST_LIMIT;
  center_lat = localStorage.getItem('latitude');
  center_long = localStorage.getItem('longitude');
  businessMarkers: marker[] = [];
  business_title = '';
  business_address = '';
  business_slug = '';
  noData = false;
  user_lat: any;
  user_long: any;
  isShow = true;
  entityType: string = "";
  searchText: string = "";
  items: any[] = [];
  appName = environment.APP_NAME;

  @ViewChild('homeSearchInput')
  homeSearchInput!: ElementRef;
  constructor(
    private activeRoute: ActivatedRoute,
    private hS: HomeService,
    private cS: CommonService,
    private router: Router) {
    this.currentPage = 1;
    const activeUrlArray = this.activeRoute.snapshot.url;
    this.user_lat = localStorage.getItem('latitude');
    this.user_long = localStorage.getItem('longitude');

    /*
    *get url slug
    */
    this.activeRoute.params.subscribe(data => {
      // this.routerLink = data.slug;
      console.log(data);
      this.routerLink = 'popularity'; //now sorting will be static 'popularity'
      if (activeUrlArray.length) {
        this.activeUrl = activeUrlArray[0].path;
        if (this.activeUrl == 'places') {
          this.entityType = 'place';
        }
        if (this.activeUrl == 'persons') {
          this.entityType = 'person';
        }
      }
      this.currentPage = 1;
      this.checkSorting();

      this.businessMarkers = [];
      this.checkRouterUrlForCallingAPI();
    });
  }

  ngOnInit() {
    if (this.center_lat === '' || this.center_lat === null) {
      this.hS.getNetworkLatLong();
    }
    this.cS.scrollTop();
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.homeSearh();
  }

  checkSorting() {
    /*
      *Check which type of sorting happend
      */
    if (this.routerLink === 'relevance') {
      this.sort_slug = 'relevance';
    }
    else if (this.routerLink === 'popularity') {
      this.sort_slug = 'popular';
    }
    else if (this.routerLink === 'ratings') {
      this.sort_slug = 'ratings';
    }
    else if (this.routerLink === 'near-by') {
      this.sort_slug = 'nearMe';
    }
    else if (this.routerLink === 'atoz') {
      this.sort_slug = 'AtoZ';
    }
    else if (this.routerLink === 'ztoa') {
      this.sort_slug = 'ZtoA';
    }
    else {
      this.router.navigateByUrl('/home');
    }
  }

  onSorting(sortType: string) {
    this.routerLink = sortType; //pass current string into routerLink
    this.checkSorting() //check and update sort_slug
    this.checkRouterUrlForCallingAPI() //then call api on sorting
  }

  checkRouterUrlForCallingAPI() {
    const postJson: PostJsonBusinessList = {
      sortBy: this.sort_slug,
      page: this.currentPage,
      limit: this.perPageList,
      entity_type: this.entityType
    };

    if (this.sort_slug === 'nearMe' || this.sort_slug === 'relevance') {
      postJson.radius = environment.LOCATION_RADIUS;
      postJson.latitude = <any>this.center_lat;
      postJson.longitude = <any>this.center_long;
    }

    if (this.sort_slug === 'relevance') {
      postJson.latitude = <any>this.center_lat;
      postJson.longitude = <any>this.center_long;
    }
    switch (this.activeUrl) {
      case 'persons':
        /*
        *Get all Recently added Business list from server
        */
        this.hS.getRecentlyAddedBusinessListing(postJson).subscribe((res: any) => {
          this.convertToLowerCase(res.data);
          this.BusinessList = res.data;
          this.totalBuinessCount = res.businessesTotalCount;
          this.countCollectionSize();
        }, (err: any) => {
          console.log(err);
        });
        break;
      case 'places':
        /*
        *Get all Recently added Business list from server
        */
        this.hS.getRecentlyAddedBusinessListing(postJson).subscribe((res: any) => {
          this.convertToLowerCase(res.data);
          this.BusinessList = res.data;
          this.totalBuinessCount = res.businessesTotalCount;
          this.countCollectionSize();
        }, (err: any) => {
          console.log(err);
        });
        break;
      default:
        /*
        *Get all Business list from server for category
        */
        this.getBusinessListByCategory();
        break;
    }
  }

  /**
  * Get Category wise Business listing
  * @returns void
  */
  getBusinessListByCategory(): void {
    const postJson: PostJsonBusinessList = {
      category_slug: this.activeUrl,
      page: this.currentPage,
      sortBy: this.sort_slug
    };

    this.hS.getBusinessListByCategory(postJson).subscribe((res: any) => {
      if (res.data.businesses) {
        this.BusinessList = res.data.businesses;
        this.totalBuinessCount = res.businessesTotalCount;
      }
      this.countCollectionSize();
    }, (err: any) => {
      console.log(err);
    });
  }

  /**
   * convert to lowercase entity type for passing in routing in url
   * @Yuvraj 24/12/21
   */
  convertToLowerCase(data: any){
    data.forEach((element: any) => {
      if(element.entity_type){
        element.entity_type = element.entity_type.toLowerCase();
      }
    });
  }

  getNextPageBusinessList(event: number): void {
    console.log(event);
    this.businessMarkers = [];
    this.checkRouterUrlForCallingAPI();
  }

  /**
  *Calculate pagination display on screen and count of total businesses
  */
  countCollectionSize() {
    if (this.totalBuinessCount > this.perPageList) {
      this.collectionSize = Math.ceil(this.totalBuinessCount / this.perPageList);
      this.collectionSize = this.collectionSize * 10;
      if (this.currentPage === 1) {
        this.first = 1;
        this.last = this.perPageList;
      } else {
        this.first = (this.currentPage - 1) * this.perPageList + 1;
        if (this.BusinessList.length < this.perPageList) {
          this.last = (this.currentPage - 1) * this.perPageList + this.BusinessList.length;
        } else {
          this.last = (this.currentPage - 1) * this.perPageList + this.perPageList;
        }
      }
    } else {
      this.last = this.totalBuinessCount;
      this.collectionSize = this.perPageList;
    }

    if (this.totalBuinessCount == 0 || this.totalBuinessCount == undefined) {
      this.noData = true;
    } else {
      this.noData = false;
    }
    this.cS.scrollTop();
    this.showBusinessOnMap();
  }

  /**
  *Load businesses on map
  */
  showBusinessOnMap() {
    for (const x in this.BusinessList) {
      if (this.BusinessList.hasOwnProperty(x)) {
        this.businessMarkers.push({
          latitude: this.BusinessList[x]['latitude'],
          longitude: this.BusinessList[x]['longitude'],
          title: this.BusinessList[x]['name'],
          address: this.BusinessList[x]['address'],
          business_slug: this.BusinessList[x]['business_slug']
        });
      }
    }
  }

  /**
  * open map click
  */
  clicked(marker: any, pos: any) {
    this.business_title = pos.title;
    this.business_address = pos.address;
    this.business_slug = pos.business_slug;
    let markerEvent = marker.target;
    markerEvent.nguiMapComponent.openInfoWindow('business_name', markerEvent);
  }

  /**
  * filter by name
  */
  filter(filtername: any) {
    if (filtername == 'asc') {
      this.isShow = false;
    } else {
      this.isShow = true;
    }
  }



  searchBusinessByCategory(category_name: string) {
    this.searchText = category_name;
    this.searchBusiness();
    $(".auto_complete_listing").css("display", "none");
  }

  searchBusiness() {
    $(".auto_complete_listing").css("display", "none");
    if (this.searchText != "") {
      const postJson: PostJsonBusinessList = {
        sortBy: this.sort_slug,
        page: this.currentPage,
        limit: this.perPageList,
        entity_type: this.entityType,
        searchText: this.searchText
      };

      if (this.sort_slug === 'nearMe' || this.sort_slug === 'relevance') {
        postJson.radius = environment.LOCATION_RADIUS;
        postJson.latitude = <any>this.center_lat;
        postJson.longitude = <any>this.center_long;
      }

      if (this.sort_slug === 'relevance') {
        postJson.latitude = <any>this.center_lat;
        postJson.longitude = <any>this.center_long;
      }
      this.hS.getRecentlyAddedBusinessListing(postJson).subscribe((res: any) => {
        this.convertToLowerCase(res.data);
        this.BusinessList = res.data;
        this.totalBuinessCount = res.businessesTotalCount;
        this.countCollectionSize();
      }, (err: any) => {
        console.log(err);
      });
    }
  }

  /**
   * deley api calling and called once user finish typed in search text box
   * @Yuvraj 23/11/2021
   */
  homeSearh() {
    fromEvent(this.homeSearchInput.nativeElement, 'keyup').pipe(
      // get value
      map((event: any) => {
        return event.target.value;
      }),
      // if character length greater then 3
      // , filter(res => res.length >= 3)
      filter(res => {
        if (res && res.length >= 3) {
          return true;
        } else {
          $(".auto_complete_listing").css("display", "none");
          return false;
        }
      })

      // Time in milliseconds between key events
      , debounceTime(1000)

      // If previous query is diffent from current
      , distinctUntilChanged()

      // subscription for response
    ).subscribe((postJson: string) => {
      this.hS.getSearchAutocomplete({ searchText: postJson, entity_type: this.entityType }).subscribe((res: any) => {
        if (res.data) {
          if (res.data.length > 0) {
            $(".auto_complete_listing").css("display", "block");
            this.items = res.data;
          } else {
            $(".auto_complete_listing").css("display", "none");
          }
        }
      }, (err: any) => {
        console.log(err);
      });
    });
  }
}
