import { Component, OnInit, HostListener, ElementRef } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbDatepickerConfig } from "@ng-bootstrap/ng-bootstrap";
import { CommonService } from "src/app/shared/services/common.service";
import { HttpService } from "src/app/shared/services/http.service";


import { environment } from "./../../../../environments/environment";

@Component({
  selector: "ryec-plasma-donor",
  templateUrl: "./plasma-donor.component.html",
  styleUrls: ["./plasma-donor.component.css"],
})
export class PlasmaDonorComponent implements OnInit {
  @HostListener("document:click", ["$event"])
  clickout(event: any) {
    if (this.eRef.nativeElement.contains(event.target)) {
      if (
        event.srcElement.className !== "dropbtn" &&
        event.srcElement.className.indexOf("ps_") === -1
      ) {
        this.showDropDown = false;
      }
    } else {
      this.showDropDown = false;
    }
  }
  plasmaDonorForm: FormGroup;
  statusAlert!: {
    status: 1 | 0 | 3;
    message: string;
  };
  userDetail: any;
  bloodGroups: Array<string> = [
    "A+",
    "A-",
    "B+",
    "B-",
    "AB+",
    "AB-",
    "O+",
    "O-",
  ];
  blood_group = "A+";
  city = "";
  showDropDown = false;
  perPageList = environment.BUSINESS_LIST_LIMIT;
  collectionSize = 0;
  donorData: any = [];
  currentPage: number;
  searchText = "";
  sortBy: string;
  order: string;
  totalCount = 0;
  appName = environment.APP_NAME;

  constructor(
    private fb: FormBuilder,
    private hS: HttpService,
    private configDP: NgbDatepickerConfig,
    private eRef: ElementRef,
    private cS: CommonService
  ) {
    this.plasmaDonorForm = this.fb.group({
      name: [null, Validators.compose([Validators.required])],
      mobile_number: [null, Validators.compose([Validators.required])],
      blood_group: [],
      city: [null, Validators.compose([Validators.required])],
      covid_start_date: [null, Validators.compose([Validators.required])],
    });

    const d = new Date();
    this.configDP.minDate = { year: 2019, month: 6, day: 1 };
    this.configDP.maxDate = {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate(),
    };

    this.currentPage = 1;
    this.sortBy = "name";
    this.order = "asc";

    this.userDetail = this.cS.getUserDetails();
    if (this.userDetail) {
      this.plasmaDonorForm.controls.name.setValue(this.userDetail.name);
      this.plasmaDonorForm.controls.mobile_number.setValue(
        this.userDetail.phone
      );
    }
  }

  ngOnInit() {
    this.cS.scrollTop();
    this.getPlasmaDonorList();
  }

  sort(colName: string) {
    if (colName !== this.sortBy) {
      this.sortBy = colName;
      this.order = "asc";
    } else {
      this.order = this.order === "asc" ? "desc" : "asc";
    }
    this.getPlasmaDonorList();
  }

  donorSearch(event: any) {
    const val = event.target.value;
    this.searchText = val;
  }

  getPlasmaDonorList(): void {
    const plasmadata = {
      page: this.currentPage,
      sortBy: this.sortBy,
      order: this.order,
      searchText: this.searchText,
      status: "active",
    };
    const apiUrl = environment.RYEC_API_URL + "getAllPlasmaDonor";
    this.hS.post(apiUrl, plasmadata).subscribe((res:any) => {
      if (res.status === 1) {
        this.cS.showSuccess(res.message);
        this.totalCount = res.total;
        this.donorData = res.data;
        this.countCollectionSize();
      } else {
        this.statusAlert = {
          status: res.status,
          message: res.message,
        };
      }
    });
  }

  countCollectionSize() {
    if (this.totalCount > this.perPageList) {
      this.collectionSize = Math.ceil(this.totalCount / this.perPageList);
      this.collectionSize = this.collectionSize * 10;
    } else {
      this.collectionSize = this.perPageList;
    }
  }

  onplasmaDonorFormSubmit() {
    const apiUrl = environment.RYEC_API_URL + "storePlasmaDonor";
    const allValue = this.plasmaDonorForm.value;
    allValue.blood_group = this.blood_group;
    if (allValue.covid_start_date) {
      const year = allValue.covid_start_date.year;
      const month =
        allValue.covid_start_date.month <= 9
          ? "0" + allValue.covid_start_date.month
          : allValue.covid_start_date.month;
      const day =
        allValue.covid_start_date.day <= 9
          ? "0" + allValue.covid_start_date.day
          : allValue.covid_start_date.day;
      allValue.covid_start_date = year + "-" + month + "-" + day;
    }

    this.hS.post(apiUrl, allValue).subscribe((res:any) => {
      if (res.status === 1) {
        this.cS.showSuccess(res.message);
        this.plasmaDonorForm.reset();
        this.getPlasmaDonorList();
      } else {
        this.statusAlert = {
          status: res.status,
          message: res.message,
        };
      }
    });
  }

  // handleEditClick(row: any) {
  //   console.log("row", row);
  // }

  // handleDeleteClick(row: any) {
  //   console.log("row", row);
  // }

  onEvent(event: any) {
    console.log(event);
    this.showDropDown = true;
  }

  splitString(stringToSplit: string, Seperator: string): Array<string> {
    return stringToSplit.split(Seperator);
  }

  selectGroup(bg: string) {
    console.log(bg);
    this.blood_group = bg;
    this.showDropDown = false;
  }

  selectBloodGroup() {
    if (this.showDropDown) {
      this.showDropDown = false;
    } else {
      this.showDropDown = true;
    }
  }
}
