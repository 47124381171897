<div class="create-group_page">
  <div class="container">
      <div class="row">
          <div class="col-12">
              <div class="preview_section">
                  <div class="preview_desc">
                      <div class="mobile-actions">
                          <a href="javascript:void(0);" alt="Back"><i class="fa fa-long-arrow-left" aria-hidden="true"></i></a>
                          <a href="javascript:void(0);" alt="Share"><i class="fa fa-share" aria-hidden="true"></i></a>
                      </div>
                      <div class="preview-cover_photo" (click)="OpenCoverImageModal(ZoomImageModal)">
                          <div *ngIf="jobsDetailsModel.image_fullurl">
                            <div class="cover_photo">
                              <img alt="Cover" [src]="jobsDetailsModel.image_fullurl">
                            </div>
                          </div>
                          <!-- if no cover img then default -->
                          <div class="cover_photo"  *ngIf="jobsDetailsModel.image_fullurl == ''">
                              <img alt="Cover" src="assets/images/logo RYEC.png">
                          </div>
                      </div>
                      <div class="group_card">
                          <div class="preview_details">
                              <div class="w-100">
                                <div class="d-flex justify-content-between align-items-start sticky-header">
                                    <h2 class="group_name" *ngIf="jobsDetailsModel.title">{{ jobsDetailsModel.title }}</h2>
                                    <div class="d-flex btn-wrapper" >
                                      <button *ngIf="jobCreatedByUserId !== userId && jobsDetailsModel.isUserApplyJob == false" type="button" (click)="loadJobApplyModalComponent(jobsDetailsModel)" [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'">
                                          <!-- <i class="fa fa-user-plus" aria-hidden="true"></i> -->
                                          Apply
                                      </button>
                                      <button *ngIf="jobCreatedByUserId !== userId && jobsDetailsModel.isUserApplyJob == true && jobsDetailsModel.is_short_listed == 0 && jobsDetailsModel.is_rejected == 0 && jobsDetailsModel.is_selected == 0" type="button" (click)="onWithdrawJobApply(jobsDetailsModel)" [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'">
                                          Withdraw Application
                                      </button>
                                      <button *ngIf="jobCreatedByUserId !== userId && jobsDetailsModel.isUserApplyJob == true && jobsDetailsModel.is_short_listed == 1 && jobsDetailsModel.is_selected == 0" type="button" [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'">
                                          Short List
                                      </button>
                                      <button *ngIf="jobCreatedByUserId !== userId && jobsDetailsModel.isUserApplyJob == true && jobsDetailsModel.is_rejected == 1" type="button" [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'">
                                          Rejected
                                      </button>
                                      <button *ngIf="jobCreatedByUserId !== userId && jobsDetailsModel.isUserApplyJob == true && jobsDetailsModel.is_selected == 1" type="button" [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'">
                                          Selected
                                      </button>
                                      <span>
                                        &nbsp;
                                      </span>
                                      <button *ngIf="!isMobile" type="button" (click)="openShareOptions(contentShare)" class="share_btn" [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'">
                                          <i class="fa fa-share" aria-hidden="true"></i>
                                      </button>
                                      <button *ngIf="isMobile" type="button" (click)="shareNow()" [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" class="share_btn">
                                          <i class="fa fa-share" aria-hidden="true"></i>
                                      </button>
                                      <div ngbDropdown *ngIf="jobCreatedByUserId == userId">
                                          <button type="button" [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" id="dropdownConfig" ngbDropdownToggle>
                                              <i class="fa fa-bars" aria-hidden="true"></i>
                                          </button>
                                          <div ngbDropdownMenu aria-labelledby="dropdownConfig">
                                            <p *ngIf="jobsDetailsModel.user.id == userId">
                                              <button type="button" class="btn" (click)="onEdit(jobsDetailsModel.id);" ngbDropdownItem [ngClass]="appName == 'RYC' ? 'actionBtnRYC' : 'actionBtnMRC'">
                                                  Edit
                                              </button>
                                            </p>
                                              <p *ngIf="(jobsDetailsModel.user.id == userId)">
                                                <button type="button" class="btn" ngbDropdownItem (click)="onCloseJob(jobsDetailsModel.id, jobsDetailsModel.title)" [ngClass]="appName == 'RYC' ? 'actionBtnRYC' : 'actionBtnMRC'">
                                                  Close Job
                                                </button>
                                              </p>
                                              <p *ngIf="(jobsDetailsModel.user.id == userId)">
                                                <button type="button" class="btn" ngbDropdownItem (click)="onJobDelete(jobsDetailsModel.id, jobsDetailsModel.title)" [ngClass]="appName == 'RYC' ? 'actionBtnRYC' : 'actionBtnMRC'">
                                                  Delete Job
                                                </button>
                                              </p>
                                          </div>
                                      </div>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-between align-items-start ">
                                    <div class="group_desc">
                                      <p *ngIf="jobsDetailsModel.company_name">
                                        <span>
                                          <i class="fa fa-building" aria-hidden="true"></i>
                                          {{ jobsDetailsModel.company_name }}
                                        </span>
                                      </p>
                                      <p *ngIf="jobsDetailsModel.location">
                                        <span>
                                          <i class="fa fa-map-marker" aria-hidden="true"></i>
                                          {{ jobsDetailsModel.location.district }}, {{ jobsDetailsModel.location.state }}, {{ jobsDetailsModel.location.country }}
                                        </span>
                                      </p>
                                      <p>
                                        <span *ngIf="jobsDetailsModel.external_link">
                                          <a [href]="jobsDetailsModel.external_link" target="_blank">
                                            <i class="fa fa-globe" aria-hidden="true"></i>
                                            {{ jobsDetailsModel.external_link }}
                                          </a>
                                        </span>
                                      </p>
                                    </div>                                       
                                </div>
                              </div>
                          </div>
                          <div class="group-action_tab">
                              <!-- <mat-tab-group mat-align-tabs="start">
                                  <mat-tab label="Details"> -->
                                      <ng-template mat-tab-label>
                                        <div class="container">
                                          <span>About</span>
                                        </div>
                                      </ng-template>
                                      <div>
                                        <div class="member_section request-pending_section p-3 group_card my-4" *ngIf="userId === jobCreatedByUserId">
                                          <ul>
                                            <li class="d-flex justify-content-between align-items-center">
                                              <span *ngIf="jobsDetailsModel.job_applied_count == 1">
                                                Job Applicant ({{ jobsDetailsModel.job_applied_count }})
                                              </span>
                                              <span *ngIf="jobsDetailsModel.job_applied_count > 1">
                                                Job Applicants ({{ jobsDetailsModel.job_applied_count }})
                                              </span>
                                              <span *ngIf="jobsDetailsModel.job_applied_count == 0">
                                                Job Applicant (0)
                                              </span>
                                              <button [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" type="button" (click)="loadApplicantComponent(jobsDetailsModel.id)">Manage</button>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div>
                                        <div class="d-flex justify-content-between align-items-start flex-wrap m-2">
                                          <div class="about_section group_card my-4">
                                            <div class="d-flex justify-content-between align-items-center detail-title">
                                              <h3>{{ jobsDetailsModel.title }}</h3>
                                              <button class="btn p-0" *ngIf="jobCreatedByUserId == userId" type="button" (click)="onEdit(jobsDetailsModel.id);">
                                                <i class="fa fa-cog"></i>
                                              </button>
                                            </div>
                                            <ul class="about_list">
                                              <li>
                                                <h5>Minimum Experience Needed</h5>
                                                <p class="description_box" *ngIf="jobsDetailsModel.experience == 0">No Experience Needed</p>
                                                <p class="description_box" *ngIf="jobsDetailsModel.experience > 0">{{ jobsDetailsModel.experience }}</p>
                                              </li>
                                              <li *ngIf="jobsDetailsModel.workplace_type != ''">
                                                <h5>Workplace Type</h5>
                                                <p class="description_box">{{ jobsDetailsModel.workplace_type }}</p>
                                              </li>
                                              <li *ngIf="jobsDetailsModel.employment_type != ''">
                                                <h5>Employment Type</h5>
                                                <p class="description_box">{{ jobsDetailsModel.employment_type }}</p>
                                              </li>
                                              <li *ngIf="jobsDetailsModel.qualification?.length">
                                                <h5>Required Degree</h5>
                                                <ul class="degree">
                                                  <li *ngFor="let item of jobsDetailsModel.qualification; let i = index">
                                                    {{item}}
                                                  </li>
                                                </ul>
                                              </li>
                                              <li *ngIf="jobsDetailsModel.jobskill?.length">
                                                <h5>Required Skills</h5>
                                                <ul class="degree">
                                                  <li *ngFor="let item of jobsDetailsModel.jobskill; let i = index">
                                                    {{item.skill_name}}
                                                  </li>
                                                </ul>
                                              </li>
                                              <li *ngIf="jobsDetailsModel.description != ''">
                                                <h5>Desciption</h5>
                                                <p class="description_box">{{ jobsDetailsModel.description }}</p>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                  <!-- </mat-tab>
                              </mat-tab-group> -->
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <!-- <button [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" class="back-to-top" *ngIf="isShow" (click)="gotoTop()">
      <img src="assets/images/up-arrow.svg" alt="Back to top" />
  </button> -->
</div>

<ng-template #contentShare let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h5 class="modal-title">{{ "ang_share" | translate }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="social_profile">
      <ul>
        <li>
            <a href="javascript:void(0)" (click)="shareOnFacebook()">
              <img alt="facebook" src="../assets/images/facebook.png" />
            </a>
        </li>
        <li>
          <a href="javascript:void(0)" (click)="shareOnTwitter()">
            <img alt="twitter" src="../assets/images/twitter.png" />
          </a>
        </li>
        <li>
          <a href="javascript:void(0)" (click)="shareOnLinkedIn()">
            <img alt="linkedin" src="../assets/images/linkedin.png" />
          </a>
        </li>
      </ul>
    </div>
    <!-- social_profile End -->
  </div>
</ng-template>
<!-- Image carousel Modal -->
<ng-template #ZoomImageModal let-c="close" let-d="dismiss">
<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <img *ngIf="jobsDetailsModel.image_fullurl" [src]="jobsDetailsModel.image_fullurl"/>
  <img *ngIf="!jobsDetailsModel.image_fullurl" src="assets/images/logo RYEC.png" />
</div>
</ng-template>
