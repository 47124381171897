<div class="modal-header">
    <h4 *ngIf="loadType == 'taggedUsers'">Tagged Users</h4>
    <h4 *ngIf="loadType == 'loadPostLikedUsers'">Liked Users</h4>
    <h4 *ngIf="loadType == 'loadPostSharedUsers'">Shared Users</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('close')" >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  
  <div class="modal-body requested-user_list">
    <div id="container" class="container">
      <div class="row">
        <div class="col-md-12 my-2" *ngIf="userListModel.length">
          <div class="creator_sec" *ngFor="let item of userListModel; let i = index">
            <div class="member_details creator">
                <span class="member_img">
                  <img *ngIf="!item.user.profile_url" src="assets/images/default_user_profile.png" alt="user"/>
                  <img *ngIf="item.user.profile_url" [src]="item.user.profile_url" alt="{{ item.user.name }}"/>
                </span>
                <div class="member_data">
                  <div class="d-flex justify-content-between align-items-start">
                    <h5>
                      <!-- <a href="javascript:void(0);">
                        {{ item.user.name }}
                      </a> -->
                      <a routerLink="/home/business-detail/{{item.user.singlebusiness.business_slug}}" *ngIf="item.user.singlebusiness; else postTagUserName">
                        {{ item.user.name }}
                      </a>
                      <span *ngIf="item.user.singlebusiness">  | </span>
                      <a *ngIf="item.user.singlebusiness" routerLink="/home/business-detail/{{item.user.singlebusiness.business_slug}}" target="_blank" (click)="activeModal.dismiss('close')">
                          {{ item.user.singlebusiness.name }}
                      </a>
                      <ng-template #postTagUserName>
                          {{ item.user.name }}
                      </ng-template>
                    </h5>
                  </div>
                </div>
            </div>
          </div>
        </div>
        <div class="pagination_view" *ngIf="totalsUserCount > 0 && loadType == 'loadPostLikedUsers' || loadType == 'loadPostSharedUsers'">
          <ngb-pagination [collectionSize]="collectionSize" [(page)]="currentPage" [maxSize]="maxSize" size="sm" (pageChange)="getNextPageBusinessList()"></ngb-pagination>
        </div>
        <!-- pagination_view End -->
        <div class="col-md-12 my-2" *ngIf="userListModel.length == 0">
          <p>No users found..</p>
        </div>
      </div>
    </div>
  </div>