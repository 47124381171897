import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad, CanActivateChild {
  constructor(
    private authService: AuthService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {

		console.log(route);
    console.log(state);
    // Jigar's code
    let str = state['url'].split("/");
    if (!str[2]) {
      str[2] = 'dumy';
    }
    switch (str[2]) {
      case 'market-place':
      case 'market-place-detail':
      case 'create-market-place':
      case 'edit-market-place':
      console.log('on default');
        break;
      default:
      console.log('default');
      localStorage.removeItem('setFilters');
    }
    // -- //
		if (this.authService.isLoggedIn()) {

			return true;
		}
		// do something if not logged in
		console.log(state.url);
		this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
		//this.router.navigate(['login']);
		return false;
	}

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return this.canActivate(childRoute, state);
  }

  canLoad(route: Route): boolean | Observable<boolean> | Promise<boolean> {
    console.log(route);
    if (this.authService.isLoggedIn()) {
      return true;
    }
    return false;
  }

}
