import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { PostJsonBusinessList } from 'src/app/shared/class/data.model';
import { debounceTime, map, distinctUntilChanged, filter } from "rxjs/operators";
import { fromEvent } from 'rxjs/observable/fromEvent';

import { ActivatedRoute, Router } from '@angular/router';
import { JobApplicantsStatusModalComponent } from '../../../../shared/component/job-applicants-status-modal/job-applicants-status-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HomeService } from 'src/app/shared/services/home.service';
import { CommonService } from 'src/app/shared/services/common.service';
declare var $: any;

@Component({
  selector: 'ryec-job-applicant-users-listing',
  templateUrl: './job-applicant-users-listing.component.html',
  styleUrls: ['./job-applicant-users-listing.component.css']
})
export class JobApplicantUsersListingComponent implements OnInit {
  searchText: string = "";
  items: any[] = [];
  usersList: any[] = [];
  sort_slug: string = "";
  currentPage: number = 0;
  perPageList = environment.BUSINESS_LIST_LIMIT;
  endPage: number = environment.BUSINESS_LIST_LIMIT;
  totalUsersCount: any = 0;
  first: number = 1;
  last: number = 0;
  maxSize: number = 5;
  noData: boolean = false;
  sortingType: string = "all";
  collectionSize: number = 0;
  isSubmit: boolean = false;
  jobId: number = 0;
  searchUserId: number = 0;
  appName = environment.APP_NAME;

  @ViewChild('homeSearchInput')
  homeSearchInput!: ElementRef;
  constructor(
    private hS: HomeService,
    private router: Router,
    private cS: CommonService,
    // private modalService: NgbModal,
    private activeRoute: ActivatedRoute,
    private modalService: NgbModal,
  ) {
    this.currentPage = 1;
    this.activeRoute.params.subscribe(data => {
      this.jobId = data.slug;
    });
  }

  ngOnInit() {
    this.currentPage = 1;
    this.checkSorting();
    this.checkRouterUrlForCallingAPI();
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.homeSearh();
  }

  searchBusiness() {
    $(".auto_complete_listing").css("display", "none");
    if (this.searchText != "") {
      const postJson: PostJsonBusinessList = {
        sortBy: this.sort_slug,
        page: this.currentPage,
        limit: this.perPageList,
        job_vacancy_id: this.jobId,
        user_id: this.searchUserId
      };

      // if (this.sort_slug === 'nearMe' || this.sort_slug === 'relevance') {
      //   postJson.radius = environment.LOCATION_RADIUS;
      //   postJson.latitude = <any>this.center_lat;
      //   postJson.longitude = <any>this.center_long;
      // }

      // if (this.sort_slug === 'relevance') {
      //   postJson.latitude = <any>this.center_lat;
      //   postJson.longitude = <any>this.center_long;
      // }
      this.getApplicantUsersList(postJson);
    }
  }

  /**
   * deley api calling and called once user finish typed in search text box
   * @Yuvraj 15/04/2021
   */
  homeSearh() {
    fromEvent(this.homeSearchInput.nativeElement, 'keyup').pipe(
      // get value
      map((event: any) => {
        return event.target.value;
      }),
      // if character length greater then 3
      // , filter(res => res.length >= 3)
      filter(res => {
        if (res && res.length >= 3) {
          return true;
        } else {
          $(".auto_complete_listing").css("display", "none");
          return false;
        }
      })

      // Time in milliseconds between key events
      , debounceTime(1000)

      // If previous query is diffent from current
      , distinctUntilChanged()

      // subscription for response
    ).subscribe((postJson: string) => {
      this.hS.jobApplicantsAutoSuggestion({ searchText: postJson, job_vacancy_id: this.jobId }).subscribe((res: any) => {
        if (res.data) {
          if (res.data.length > 0) {
            $(".auto_complete_listing").css("display", "block");
            this.items = res.data;
          } else {
            $(".auto_complete_listing").css("display", "none");
          }
        }
      }, err => {
        console.log(err);
      });
    });
  }

  searchBusinessByCategory(id: number, name: string) {
    this.searchText = name;
    this.searchUserId = id;
    this.searchBusiness();
    $(".auto_complete_listing").css("display", "none");
  }

  checkSorting() {
    /*
      *Check which type of sorting happend
      */
    if (this.sortingType === 'all') {
      this.sort_slug = 'all';
    }
    else if (this.sortingType === 'shortListed') {
      this.sort_slug = 'shortListed';
    }
    else if (this.sortingType === 'rejected') {
      this.sort_slug = 'rejected';
    }
    else if (this.sortingType === 'selected') {
      this.sort_slug = 'selected';
    }
    else {
      this.router.navigateByUrl('/home');
    }
  }

  /**
   * if sort type is change call api
   * @Yuvraj 18/01/2022
   */
  onSortTypeChange() {
    this.checkSorting();
    this.checkRouterUrlForCallingAPI();
  }

  checkRouterUrlForCallingAPI() {
    const postJson: PostJsonBusinessList = {
      job_vacancy_id: this.jobId,
      sortBy: this.sort_slug,
      page: this.currentPage,
      limit: this.perPageList
    };
    this.getApplicantUsersList(postJson);
  }

  getApplicantUsersList(postJson: any) {
    this.hS.getApplicantUsersListByJobVacancyId(postJson).subscribe((res: any) => {
      if (res.status == 1) {
        // this.convertToLowerCase(res.data);
        this.usersList = res.data.applicant_users;
        this.totalUsersCount = res.total;
        this.countCollectionSize();
      }
      else {
        this.usersList = [];
      }
    });
  }

  loadJobApplicantUserStatusModalComponent(item: any, type: string) {
    const modalReference = this.modalService.open(JobApplicantsStatusModalComponent, { windowClass: 'mobile_popup' });
    modalReference.componentInstance.jobId = this.jobId; //job id
    modalReference.componentInstance.applicantUserId = item.applicant_id; //user id
    modalReference.componentInstance.applicantUserName = item.applicant_name; //user name
    modalReference.componentInstance.statusType = type; //update status type
    modalReference.result.then((result: any) => {
      console.log(`Closed with: ${result}`);
    }, (reason: any) => {
      if (reason == 'done') {
        this.checkSorting();
        this.checkRouterUrlForCallingAPI();
      }
    });
  }

  /**
 *Calculate pagination display on screen and count of total businesses
 */
  countCollectionSize() {
    if (this.totalUsersCount > this.perPageList) {
      this.collectionSize = Math.ceil(this.totalUsersCount / this.perPageList);
      this.collectionSize = this.collectionSize * 10;
      if (this.currentPage === 1) {
        this.first = 1;
        this.last = this.perPageList;
      } else {
        this.first = (this.currentPage - 1) * this.perPageList + 1;
        if (this.usersList.length < this.perPageList) {
          this.last = (this.currentPage - 1) * this.perPageList + this.usersList.length;
        } else {
          this.last = (this.currentPage - 1) * this.perPageList + this.perPageList;
        }
      }
    } else {
      this.last = this.totalUsersCount;
      this.collectionSize = this.perPageList;
    }

    if (this.totalUsersCount == 0 || this.totalUsersCount == undefined) {
      this.noData = true;
    } else {
      this.noData = false;
    }
    this.cS.scrollTop();
    // this.showBusinessOnMap();
  }

  getNextPageBusinessList(): void {
    console.log(event);
    this.checkRouterUrlForCallingAPI();
  }

}
