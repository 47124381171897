<div class="top_header_view" [ngStyle]="{'background':appName == 'MRC' ? 'var(--theme-mrc-based-primary-color)' : 'var(--theme-ryc-based-primary-color)' }">
   <div class="container">
      <div class="row">
         <div class="col-md-5 col-sm-5 col-xs-12">
            <div class="logo_view">
               <a href="javascript:void(0)" routerLink="/home">
               <img *ngIf="appName == 'MRC'" src="../assets/images/logo_new.png" alt="logo">
               <img *ngIf="appName == 'RYC'" src="../assets/images/logo.png" alt="logo">
               <img *ngIf="appName == 'BD'" src="../assets/images/bharat-darshan-logo.png" alt="logo">
               </a>
            </div>
            <!-- logo_view End -->
         </div>
         <!-- col End -->
         <div class="col-md-7 col-sm-7 col-xs-12">
            <div class="top_menu_section">
               <!-- alert_message End -->
            </div>
            <!-- top_menu_section End -->
         </div>
         <!-- col End -->
      </div>
      <!-- Row End -->
   </div>
   <!-- container End -->
 </div>
 <ng-template #appInstall let-c="close" let-d="dismiss">
   <div class="modal-body mobile-download-alert">
      <div class="alert p-2 alert-dismissible fade mb-0 show" role="alert">
         <!-- <button aria-label="Close" class="close p-3" type="button"><span aria-hidden="true">×</span></button> -->
         <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
         <span aria-hidden="true">&times;</span>
         </button>
         <div class="row mt-3">
            <div class="col-sm-12">
               <div class="" *ngIf="appName == 'RYC'">
                  <h4 class="font-weignt-bold"> {{ 'ang_RYCMobileAppTitle' | translate }}</h4>
                  <p class="card-text">{{ 'ang_forBetterExperience' | translate }}
                     <a href="{{ appDownloadUrl }}" target="_blank">{{ 'ang_downloadOurApp' | translate }}</a>
                  </p>
               </div>
               <div class="" *ngIf="appName == 'MRC'">
                  <h4 class="font-weignt-bold"> {{ 'ang_MRCMobileAppTitle' | translate }}</h4>
                  <p class="card-text">{{ 'ang_forBetterExperience' | translate }}
                     <a href="{{ appDownloadUrl }}" target="_blank">{{ 'ang_downloadOurApp' | translate }}</a>
                  </p>
               </div>
            </div>
         </div>
      </div>
   </div>
 </ng-template>
 <a style="display: none;" id="app-download-btn" href="javascript:void(0)" (click)="showAppDownloadModal(appInstall)">open</a>
 <!-- top_header_view End -->
 <script type="text/javascript ">
   $(document).ready(function() {
       $(".menu_icon ").click(function() {
           if(appName == 'RYC'){
               $(".nav_bar_RYC ").slideToggle("slow ");
           }
           else{
               $(".nav_bar_MRC ").slideToggle("slow ");
           }
       });
   });
 </script>