<div class="business_detail_banner">
  <img class="background_image" style="background: url('assets/images/default-logo.png') no-repeat center" alt="eddar-real" *ngIf="businessDetail?.data?.business_images?.length == 0 && appName == 'MRC'" />
  <img class="background_image" style="background: url('assets/images/default-logo-ryc.png') no-repeat center" alt="eddar-real" *ngIf="businessDetail?.data?.business_images?.length == 0 && appName == 'RYC'" />
  <div *ngIf="appName == 'RYC'">
    <img alt="eddar-real" src="assets/images/default-logo-ryc.png"
      *ngIf="businessDetail?.data?.business_images?.length == 0" style="object-fit: contain" />
  </div>
  <div *ngIf="appName == 'MRC'">
    <img alt="eddar-real" src="assets/images/default-logo.png"
      *ngIf="businessDetail?.data?.business_images?.length == 0" style="object-fit: contain" />
  </div>
  <ngb-carousel *ngIf="businessDetail?.data?.business_images?.length > 0" (activeId)="activeId">
    <ng-template ngbSlide *ngFor="
        let image of businessDetail?.data?.business_images;
        let index = index
      " (id)="index">
      <div id="parent_div" style="position: relative; height: 100%; width: 100%">
        <div [ngStyle]="{ background: 'url(' + image.image_name + ') no-repeat' }" id="background"
          class="background_image"></div>
        <div class="bus_slider_img">
          <img style="object-fit: contain" [src]="image.image_name" alt="Random first slide" />
        </div>
      </div>
    </ng-template>
  </ngb-carousel>
</div>
<!-- business_detail_banner End -->
<div class="business_type_view">
  <div class="container">
    <div class="business_title">
      <div class="d-flex align-items-center flex-wrap">
        <i class="business-logo position-relative">
          <img *ngIf="businessDetail?.data?.business_logo" [src]="businessDetail?.data?.business_logo" alt="{{businessDetail?.data?.name}}" />
          <img *ngIf="!businessDetail?.data?.business_logo && appName == 'MRC'" src="assets/images/default-logo.png" alt="Business logo" />
          <img *ngIf="!businessDetail?.data?.business_logo && appName == 'RYC'" src="assets/images/default-logo-ryc.png" alt="Business logo" />
          <div class="membership-plan">
            <img *ngIf="businessDetail?.data?.membership_type == 1" [src]="businessDetail?.data?.membership_type_icon"
              title="{{ 'ang_premiumplantext' | translate }}" />
            <img *ngIf="businessDetail?.data?.membership_type == 0" [src]="businessDetail?.data?.membership_type_icon"
              title="{{ 'ang_basicplantext' | translate }}" />
          </div>
        </i>
        <h2 title="{{ businessDetail?.data?.name }}">
          {{ businessDetail?.data?.name }}
        </h2>
      </div>
    </div>
  </div>
  <!-- container End -->
</div>
<!-- business_type_view End -->
<!-- <mat-tab-group mat-align-tabs="start" (selectedTabChange)="onTabClick($event)">
  <mat-tab> -->
    <ng-template mat-tab-label>
      <div class="container">
        <span>About <i class="fa fa-info-circle" aria-hidden="true"></i></span>
      </div>
    </ng-template>
    <div class="about-content">
      <div class="business_detail_content">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-md-5 col-sm-12 col-xs-12">
              <div class="left-content-wrapper">
                <div class="photo_gallery" *ngIf="businessDetail?.data?.business_images?.length > 0">
                  <ul>
                    <li *ngFor="let image of businessDetail?.data?.business_images; let index = index" (click)="openImageViwer(index, contentImage)">
                      <img alt="fs" [src]="image.image_name" />
                    </li>
                  </ul>
                </div>
                <!-- photo_gallery End -->
                <ng-template [ngIf]="assetTypeFieldsModel.contact_details == '1'">
                  <div class="other_information">
                    <h3 class="detail-title">
                      {{ "ang_contactinformation" | translate }}
                    </h3>
                    <div class="inform_view" *ngIf="businessDetail?.data?.mobile">
                      <span class="mobile">
                        <i class="fa fa-mobile" aria-hidden="true"></i>
                      </span>
                      <div>
                        <a href="javascript:void(0)">{{ businessDetail?.data?.country_code }}
                          {{ businessDetail?.data?.mobile }}</a>
                      </div>
                      <div *ngIf="!businessDetail?.data?.mobile">-</div>
                    </div>
                    <!-- inform_view End -->
                    <div class="inform_view" *ngIf="businessDetail?.data?.phone">
                      <span class="call">
                        <i class="fa fa-phone" aria-hidden="true"></i>
                      </span>
                      <div>
                        <a href="javascript:void(0)">{{
                          businessDetail?.data?.phone
                          }}</a>
                      </div>
                      <div *ngIf="!businessDetail?.data?.phone">-</div>
                    </div>
                    <!-- inform_view End -->
                    <div class="inform_view" *ngIf="businessDetail?.data?.address">
                      <span class="mobile">
                        <i class="fa fa-map-marker" aria-hidden="true"></i>
                      </span>
                      <div>{{ businessDetail?.data?.address }}</div>
                    </div>
                    <!-- inform_view End -->
                    <div class="inform_view" *ngIf="businessDetail?.data?.country">
                      <span>
                        <i class="fa fa-building-o" aria-hidden="true"></i>
                      </span>
                      <div *ngIf="businessDetail?.data?.country">
                        {{ businessDetail?.data?.country }}
                      </div>
                      <div *ngIf="!businessDetail?.data?.country">-</div>
                    </div>
                    <!-- inform_view End -->
                    <div class="inform_view" *ngIf="businessDetail?.data?.email">
                      <span>
                        <i class="fa fa-envelope" aria-hidden="true"></i>
                      </span>
                      <div *ngIf="businessDetail?.data?.email">
                        <a href="javascript:void(0)">{{
                          businessDetail?.data?.email
                          }}</a>
                      </div>
                      <div *ngIf="!businessDetail?.data?.email">-</div>
                    </div>
                    <!-- inform_view End -->
                    <div class="inform_view" *ngIf="businessDetail?.data?.website">
                      <span>
                        <i class="fa fa-globe" aria-hidden="true"></i>
                      </span>
                      <div>
                        <a href="{{ businessDetail?.data?.website }}" target="_blank">{{ businessDetail?.data?.website
                          }}</a>
                      </div>
                      <div *ngIf="!businessDetail?.data?.website">-</div>
                    </div>
                    <!-- inform_view End -->
                  </div>
                </ng-template>
                <!-- other_information End -->
                
                <ng-template [ngIf]="assetTypeFieldsModel.category == '1'">
                  <div class="business_activities bus_main_cat">
                    <h2 class="detail-title">
                      {{ "ang_businesscategories" | translate }}
                    </h2>
                    <!-- <h2>
                          <span *ngFor="let cat of businessDetail?.data?.parent_categories; let isLast=last; ">
                              {{cat.name}}{{isLast ? '' : ', '}}
                          </span>
                          </h2> -->
                    <!-- <ng-container *ngFor="let category of businessDetail?.data?.category_hierarchy; let index = index">
                      <p *ngIf="index < 2">
                        <span>
                          {{ category.name }}
                          <span *ngIf="category?.sub_category?.length > 0">
                            <p class="sub-cate" *ngFor="let sub_cate of category?.sub_category">
                              <span>{{ sub_cate.name }}</span>
                            </p>
                          </span>
                        </span>
                      </p>
                    </ng-container> -->
                    <div class="selected-category_wrapper">
                      <div class="business-selected_category" *ngFor="let category of businessDetail?.data?.category_hierarchy; let index = index">
                        <h5 class="main-category"> {{ category.name }}</h5>
                        <ul *ngIf="category?.sub_category?.length > 0">
                            <li *ngFor="let sub_cate of category?.sub_category"><i class="fa fa-level-up" aria-hidden="true"></i>{{ sub_cate.name }}</li>
                        </ul>
                      </div>
                  </div>
                    <!-- <a href="javascript:void(0)" (click)="openAllCategories(contentCategory)" *ngIf="business_category?.length > 4">
                      {{ "ang_more" | translate }}
                    </a> -->
                    <p *ngIf="business_category?.length == 0">
                      {{ "ang_nocategoryfound" | translate }}
                    </p>
                  </div>
                </ng-template>
                
                <ng-template [ngIf]="assetTypeFieldsModel.business_activities == '1'">
                  <div class="business_activities">
                    <h2 class="detail-title">
                      {{ "ang_businessactivities" | translate }}
                    </h2>
                    <p *ngFor="let activity of businessDetail?.data?.business_activities">
                      {{ activity.activity_title }}
                    </p>
                    <p *ngIf="businessDetail?.data?.business_activities?.length == 0">
                      {{ "ang_noactivityfound" | translate }}
                    </p>
                  </div>
                </ng-template>
                <!-- business_activities End -->

                <div class="ratings_reviews">
                  <h2 class="detail-title">
                    {{ "ang_ratingsandreview" | translate }}
                  </h2>
                  <div class="star_section" *ngIf="businessDetail?.data?.rating?.total > 0">
                    <div class="rate_bar" *ngIf="businessDetail?.data?.rating?.avg_rating">
                      <ng-template #t let-fill="fill">
                        <span class="star" [class.full]="fill === 100">
                          <span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
                        </span>
                      </ng-template>
                      <span class="rate">
                        {{ businessDetail?.data?.rating?.avg_rating }}
                      </span>
                      <ngb-rating [(rate)]="businessDetail.data.rating.avg_rating" [starTemplate]="t" [readonly]="true"(max)="5">
                      </ngb-rating>
                    </div>
                    <span class="review">
                      {{ businessDetail?.data?.rating?.total }}
                      {{ "ang_review" | translate }}
                    </span>
                  </div>
                  <div [ngClass]="appName == 'RYC' ? 'progress_bar_cst_ryc' : 'progress_bar_cst_mrc'" *ngIf="businessDetail?.data?.rating?.total > 0">
                    <span>{{ "ang_5star" | translate }}</span>
                    <p>
                      <ngb-progressbar [value]="start_5"></ngb-progressbar>
                    </p>
                    <span>{{ "ang_4star" | translate }}</span>
                    <p>
                      <ngb-progressbar [value]="start_4"></ngb-progressbar>
                    </p>
                    <span>{{ "ang_3star" | translate }}</span>
                    <p>
                      <ngb-progressbar [value]="start_3"></ngb-progressbar>
                    </p>
                    <span>{{ "ang_2star" | translate }}</span>
                    <p>
                      <ngb-progressbar [value]="start_2"></ngb-progressbar>
                    </p>
                    <span>{{ "ang_1star" | translate }}</span>
                    <p>
                      <ngb-progressbar [value]="start_1"></ngb-progressbar>
                    </p>
                  </div>
                  <p *ngIf="!businessDetail?.data?.rating?.total">
                    {{ "ang_noratingsandreviewfound" | translate }}
                  </p>
                </div>
                <!-- ratings_reviews End -->
                
                <ng-template [ngIf]="assetTypeFieldsModel.hours_of_opration == '1'">
                  <div class="hours_operation">
                    <h2 class="detail-title">
                      {{ "ang_hoursofoperation" | translate }}
                      <!-- <span *ngIf="businessDetail?.data?.timezone">({{businessDetail?.data?.timezone}})</span> -->
                    </h2>
                    <h2>
                      <span style="font-size: 16px" *ngIf="businessDetail?.data?.current_open_status">{{
                        businessDetail?.data?.current_open_status
                        }}<span style="color: #000; font-size: 16px" *ngIf="businessDetail?.data?.timings">
                          : {{ businessDetail?.data?.timings }}</span></span>
                    </h2>
                    <div class="open_time" *ngFor="let time of businessDetail?.data?.hoursOperation">
                      <span class="day_type">{{ time.name }}</span>
                      <span class="time_type" *ngIf="time.open_close == 'Open'">{{ time.start_time }} - {{ time.end_time
                        }}</span>
                      <span class="time_type" *ngIf="time.open_close == 'Closed'">{{ time.open_close }}</span>
                    </div>
                    <div *ngIf="!businessDetail?.data?.hoursOperation">
                      {{ "ang_nohoursofoprationfound" | translate }}
                    </div>
                    <!-- open_time End -->
                  </div>
                </ng-template>
                <!-- hours_operation End -->
                
                <div class="year_established">
                  <h2 class="detail-title">
                    {{ "ang_yearestablished" | translate }}
                  </h2>
                  <p *ngIf="businessDetail?.data?.year_of_establishment">
                    {{ businessDetail?.data?.year_of_establishment }}
                  </p>
                  <p *ngIf="!businessDetail?.data?.year_of_establishment">
                    {{ "ang_noyearfound" | translate }}
                  </p>
                </div>
                <!-- year_established End -->
                
                <ng-template [ngIf]="assetTypeFieldsModel.social_profiles == '1'">
                  <div class="social_profile" *ngIf="businessDetail?.data?.social_profiles">
                    <h2 class="detail-title">
                      {{ "ang_socialprofile" | translate }}
                    </h2>
                    <ul>
                      <li *ngIf="businessDetail?.data?.social_profiles?.facebook_url">
                        <a href="{{ businessDetail?.data?.social_profiles?.facebook_url }}" target="_blank">
                          <img alt="facebook" src="../assets/images/facebook.png" />
                        </a>
                      </li>
                      <li *ngIf="!businessDetail?.data?.social_profiles?.facebook_url">
                        <a href="javascript:void(0)">
                          <img alt="facebook" src="../assets/images/facebook-edit.png"/>
                        </a>
                      </li>
                      <li *ngIf="businessDetail?.data?.social_profiles?.twitter_url">
                        <a href="{{ businessDetail?.data?.social_profiles?.twitter_url }}" target="_blank">
                          <img alt="twitter" src="../assets/images/twitter.png" />
                        </a>
                      </li>
                      <li *ngIf="!businessDetail?.data?.social_profiles?.twitter_url">
                        <a href="javascript:void(0)">
                          <img alt="twitter" src="../assets/images/twitter-edit.png" />
                        </a>
                      </li>
                      <li *ngIf="businessDetail?.data?.social_profiles?.linkedin_url">
                        <a href="{{ businessDetail?.data?.social_profiles?.linkedin_url }}" target="_blank">
                          <img alt="linkedin" src="../assets/images/linkedin.png" />
                        </a>
                      </li>
                      <li *ngIf=" !businessDetail?.data?.social_profiles?.linkedin_url">
                        <a href="javascript:void(0)">
                          <img alt="linkedin" src="../assets/images/linkedin-edit.png" />
                        </a>
                      </li>
                      <li *ngIf="businessDetail?.data?.social_profiles?.instagram_url">
                        <a href="{{businessDetail?.data?.social_profiles?.instagram_url}}" target="_blank">
                          <img alt="instagram" src="../assets/images/instagram.png" />
                        </a>
                      </li>
                      <li *ngIf="!businessDetail?.data?.social_profiles?.instagram_url">
                        <a href="javascript:void(0)">
                          <img alt="instagram" src="../assets/images/instagram-edit.png" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </ng-template>
                <!-- social_profile End -->
              </div>
            </div>
            <div class="col-lg-8 col-md-7 col-sm-12 col-xs-12">
              <div class="essar_contant" *ngIf="businessDetail?.data?.membership_type == 0">
                <h4 class="detail-title">
                  {{ "ang_getpremiummembership" | translate }}
                </h4>
                <p *ngIf="appName=='MRC'">
                  You are currently on <b>Basic(Free) Membership</b>. Support My
                  Rajsthan Club applying for Premium Membership and create
                 your <b>Free Public Business Website</b>.
               </p>
               <p *ngIf="appName=='RYC'">
                 You are currently on <b>Basic(Free) Membership</b>. Support 
                  Rajput Club by applying for Premium Membership and create
                 your <b>Free Public Business Website</b>.
               </p>
               <p *ngIf="appName=='BD'">
                 You are currently on <b>Basic(Free) Membership</b>. Support Bharat
                  Darshan by applying for Premium Membership and create
                 your <b>Free Public Business Website</b>.
               </p>
                <div class="owner_more">
                  <a href="" [ngClass]="appName == 'RYC' ? 'ryec_btn' :appName == 'BD' ? 'bd_btn' : 'mrc_btn'" routerLink="/home/membership-plan">
                    {{ "ang_goto_premium_membership_plan" | translate }}
                  </a>
                </div>
                <!-- read_more End -->
              </div>
              <div class="essar_contant">
                <h4 class="detail-title">{{ businessDetail?.data?.name }}</h4>
                <!-- <p >
                  {{ businessDetail?.data?.descriptions | trimstr: trimLength }}
                </p> -->
                <p *ngIf="businessDetail?.data?.descriptions" [innerHtml]="businessDetail?.data?.descriptions.length > trimLength ? (businessDetail?.data?.descriptions | trimstr: trimLength) + '..' : businessDetail?.data?.descriptions"
                  ></p>
                <div class="read_more" *ngIf="businessDetail?.data?.descriptions">
                  <a href="javascript:void(0)" *ngIf="businessDetail?.data?.descriptions?.length > trimLength" (click)="openBusinessDescription(contentDetail)">
                    {{ "ang_readmore" | translate }}
                  </a>
                </div>
                <p *ngIf="!businessDetail?.data?.descriptions">
                  {{ "ang_nodescriprionfound" | translate }}
                </p>
                <!-- read_more End -->
              </div>
              <!-- essar_contant End -->

              <ng-template [ngIf]="assetTypeFieldsModel.owner_info == '1'">
                <div class="owner_information">
                  <h4 class="detail-title">
                    {{ "ang_ownerinformation" | translate }}
                  </h4>
                  <div class="row owner_box" *ngFor="let owner of businessDetail?.data?.owners">
                    <div class="col-md-3 col-lg-3 owner_img_outer">
                      <div class="owner_profile">
                        <!-- <img alt="instagram" [src]="owner.image_url"> -->
                        <img [src]="owner?.image_url" />
                      </div>
                      <!-- owner_profile End -->
                    </div>
                    <div class="col-md-4 col-lg-5">
                      <div class="owner_info_content">
                        <h2>{{ owner.name }}</h2>
                        <p>{{ owner.email }}</p>
                        <p>
                          {{ owner.country_code }}<span *ngIf="owner?.country_code">-</span>{{ owner.phone }}
                        </p>
                      </div>
                      <!-- owner_info_content End -->
                    </div>
                    <div class="col-md-5 col-lg-4">
                      <div class="owner_more">
                        <a href="javascript:void()" [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" (click)="viewOwnerData(owner.id)">
                          {{ "ang_viewmore" | translate }}
                          <i aria-hidden="true" class="fa fa-angle-right"></i>
                        </a>
                      </div>
                      <!-- owner_more End -->
                    </div>
                  </div>
                  <div *ngIf="businessDetail?.data?.owners?.length == '0'">
                    {{ "ang_noownerfound" | translate }}
                  </div>
                </div>
              </ng-template>
              <!-- owner_information End -->

              <ng-template [ngIf]="assetTypeFieldsModel.product == '1'">
                <div class="our_products" *ngIf="businessDetail?.data?.products?.length > 0">
                  <h2 class="detail-title">
                    {{ "ang_ourproducts" | translate }}
                  </h2>
                  <div class="our_products_slider">
                    <div ng2-carouselamos (items)="businessDetail?.data?.products" ($item)="itemTemplate"
                      ($prev)="prevTemplate" ($next)="nextTemplate" (onSelectedItem)="
                        selectedItem = $event.item; selectedIndex = $event.index
                      "></div>
                    <ng-template #prevTemplate>
                      <i aria-hidden="true" class="fa fa-angle-left" [ngClass]="{
                          'no-slider': businessDetail?.data?.products?.length < 4
                        }"></i>
                    </ng-template>
                    <ng-template #nextTemplate>
                      <i aria-hidden="true" class="fa fa-angle-right" [ngClass]="{
                          'no-slider': businessDetail?.data?.products?.length < 4
                        }"></i>
                    </ng-template>
                    <ng-template let-item let-i="index" #itemTemplate>
                      <div style="min-width: 155px; margin-right: 10px">
                        <div class="pro_img_outer">
                          <img [src]="item.image_url" style="width: 100%"
                            (click)="openProductDetail(item.id, contentProduct)" />
                        </div>
                        <div class="item_name">{{ item.name }}</div>
                      </div>
                    </ng-template>
                  </div>
                  <div *ngIf="businessDetail?.data?.products?.length == 0">
                    {{ "ang_noproductfound" | translate }}
                  </div>
                  <!-- our_products_slider End -->
                </div>
              </ng-template>

              <ng-template [ngIf]="assetTypeFieldsModel.online_stores == '1'">
                <div class="our_products" *ngIf="businessStoresList.length > 0">
                  <h2 class="detail-title">
                    {{ "ang_onlineStores" | translate }}
                  </h2>
                  <div class="our_products_slider online-store_slider">
                    <div ng2-carouselamos (items)="businessStoresList" ($item)="itemTemplate" ($prev)="prevTemplate"
                      ($next)="nextTemplate" (onSelectedItem)="
                        selectedItem = $event.item; selectedIndex = $event.index
                      "></div>
                    <ng-template #prevTemplate>
                      <i aria-hidden="true" class="fa fa-angle-left" [ngClass]="{
                          'no-slider ': businessStoresList?.length < 4
                        }"></i>
                    </ng-template>
                    <ng-template #nextTemplate>
                      <i aria-hidden="true" class="fa fa-angle-right" [ngClass]="{
                          'no-slider ': businessStoresList?.length < 4
                        }"></i>
                    </ng-template>
                    <ng-template let-item let-i="index" #itemTemplate>
                      <div class="item_wrapper">
                        <div class="pro_img_outer">
                          <img [src]="item.logo" style="width: 100%" (click)="onStoreClick(item.url)" />
                        </div>
                        <div class="item_name">{{ item.name }}</div>
                      </div>
                    </ng-template>
                  </div>
                  <div *ngIf="businessStoresList?.length == 0">
                    {{ "ang_nostoresfound" | translate }}
                  </div>
                  <!-- online store End -->
                </div>
              </ng-template>

              <ng-template [ngIf]="assetTypeFieldsModel.services == '1'">
                <div class="our_products" *ngIf="businessDetail?.data?.services?.length > 0">
                  <h2 class="detail-title">
                    {{ "ang_ourservices" | translate }}
                  </h2>
                  <div class="our_products_slider">
                    <div ng2-carouselamos (items)="businessDetail?.data?.services" ($item)="serviceItemTemplate"
                      ($prev)="prevTemplate" ($next)="nextTemplate" (onSelectedItem)="
                        selectedServiceItem = $event.item;
                        selectedServiceIndex = $event.index
                      "></div>
                    <ng-template #prevTemplate>
                      <i aria-hidden="true" class="fa fa-angle-left" [ngClass]="{
                          'no-slider': businessDetail?.data?.services?.length < 4
                        }"></i>
                    </ng-template>
                    <ng-template #nextTemplate>
                      <i aria-hidden="true" class="fa fa-angle-right" [ngClass]="{
                          'no-slider': businessDetail?.data?.services?.length < 4
                        }"></i>
                    </ng-template>
                    <ng-template let-item let-i="index" #serviceItemTemplate>
                      <div style="min-width: 155px; margin-right: 10px">
                        <div class="pro_img_outer">
                          <img [src]="item.image_url" style="width: 100%"
                            (click)="openServiceDetail(item.id, contentService)" />
                        </div>
                        <div class="item_name">{{ item.name }}</div>
                      </div>
                    </ng-template>
                  </div>
                  <div *ngIf="businessDetail?.data?.services?.length == 0">
                    {{ "ang_noservicefound" | translate }}
                  </div>
                  <!-- our_services_slider End -->
                </div>
              </ng-template>

              <!-- our_products End -->
              <div class="all_ratings clearfix">
                <h2 class="detail-title">{{ "ang_allratings" | translate }}</h2>
                <div class="ratings_comment" *ngFor="let review of allRatings">
                  <div class="ratings_name">
                    {{ review.name }} -
                    {{ review.timestamp | date: "hh:mm dd.MM.yyyy" }}
                    <div class="toatl_ratings">
                      <div class="rate_bar">
                        <ngb-rating [readonly]="true" [(rate)]="review.rating" (max)="5"></ngb-rating>
                      </div>
                    </div>
                    <!-- toatl_ratings End -->
                  </div>
                  <!-- ratings_name End -->
                  <div class="comment_img">
                    <img alt="user_profile" [src]="review.image_url" />
                  </div>
                  <!-- comment_img End -->
                  <div class="comment_view">
                    <p>{{ review.review }}</p>
                    <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ratione, pariatur. Ducimus, eaque delectus sed. Molestiae dolorem dolor cumque numquam delectus aut? Et aliquam quisquam voluptatum delectus atque aut velit repudiandae.</p> -->
                  </div>
                  <!-- comment_view End -->
                </div>
                <div *ngIf="!businessDetail?.data?.rating?.total">
                  {{ "ang_noratingsfound" | translate }}
                </div>
                <!-- ratings_comment End -->
                <div [ngClass]="appName == 'RYC' ? 'ryc_pagination_view' : 'mrc_pagination_view'" *ngIf="businessDetail?.data?.rating?.total">
                  <ngb-pagination [collectionSize]="collectionSize" [(page)]="currentPage" size="sm"
                    (pageChange)="getNextPageRatingList($event)"></ngb-pagination>
                </div>
                <!-- pagination_view End -->
              </div>
              <!-- all_ratings End -->
            </div>
            <!-- col End -->
          </div>
          <!-- row End -->
          <!-- container -->
        </div>
        <!-- Fix action buttons -->
        <div class="fix-action-btn">
          <a href="javascript:void(0)" routerLink="/home/business-profile/{{ business_slug }}" [ngClass]="appName == 'RYC' ? 'ryec_btn' :appName == 'BD' ? 'bd_btn' : 'mrc_btn'" title="Edit">
            <span>
              <i class="fa fa-pencil" aria-hidden="true"></i>
              {{ "ang_edit" | translate }}
            </span>
          </a>
          <a href="https://www.google.co.in/maps/place/{{ businessDetail?.data?.address }}/@{{ businessDetail?.data?.latitude }},{{ businessDetail?.data?.longitude }}" [ngClass]="appName == 'RYC' ? 'ryec_btn' :appName == 'BD' ? 'bd_btn' : 'mrc_btn'" title="Location" target="_blank">
            <span>
              <i class="fa fa-map-marker" aria-hidden="true"></i>
              {{ "ang_directions" | translate }}
            </span>
          </a>
          <a *ngIf="!isMobile && isPageLoaded" href="javascript:void(0);" [ngClass]="appName == 'RYC' ? 'ryec_btn' :appName == 'BD' ? 'bd_btn' : 'mrc_btn'" (click)="openShareOptions(contentShare)" title="{{ 'ang_share' | translate }}" >
            <span>
              <i class="fa fa-share-alt"></i>
              {{ "ang_share" | translate }}
            </span>
          </a>
          <a *ngIf="isMobile && isPageLoaded" [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" (click)="shareNow()" title="{{ 'ang_share' | translate }}" >
            <span>
              <i class="fa fa-share-alt"></i>
              {{ "ang_share" | translate }}
            </span>
          </a>
        </div>
      </div>
      <!-- business_detail_content End -->
      <!--- Image viwer in model view-->
      <ng-template #contentImage let-c="close" let-d="dismiss">
        <div class="modal-header">
          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <ngb-carousel *ngIf="businessDetail?.data?.business_images?.length > 0" (activeId)="activeId">
            <ng-template ngbSlide *ngFor="
                let image of businessDetail?.data?.business_images;
                let index = index
              " (id)="index">
              <img [src]="image.image_name" alt="Random first slide" style="width: 100%" />
            </ng-template>
          </ngb-carousel>
        </div>
      </ng-template>
      <!--- Image viwer in model view End-->
      <!--- Product Detail view in model view-->
      <ng-template #contentProduct let-c="close" let-d="dismiss">
        <div class="modal-header">
          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <ngb-carousel *ngIf="productDetail?.product_images?.length > 0">
            <ng-template ngbSlide *ngFor="let image of productDetail?.product_images">
              <div class="img_outer">
                <img [src]="image.image_original" alt="Random first slide" style="width: 100%; height: 200px" />
              </div>
            </ng-template>
          </ngb-carousel>
          <div class="description">
            <h4>{{ productDetail.name }}</h4>
            <span class="cost" *ngIf="productDetail?.cost"><i class="fa fa-inr" aria-hidden="true"></i>{{
              productDetail?.cost }}</span>
            <div class="content">
              <p>{{ productDetail.descriptions }}</p>
            </div>
          </div>
        </div>
      </ng-template>
      <!--- Product Detail view in model view End-->
      <!--- Service Detail view in model view-->
      <ng-template #contentService let-c="close" let-d="dismiss">
        <div class="modal-header">
          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="img_outer">
            <img [src]="serviceDetail.original_logo" alt="Random first slide" style="width: 100%; height: 200px" />
          </div>
          <div class="description">
            <h4>{{ serviceDetail?.name }}</h4>
            <span class="cost" *ngIf="serviceDetail?.cost"><i class="fa fa-inr" aria-hidden="true"></i>{{
              serviceDetail?.cost }}</span>
            <div class="content">
              <p>{{ serviceDetail.descriptions }}</p>
            </div>
          </div>
        </div>
      </ng-template>
      <!--- Service Detail view in model view End-->
      <!--- Business list description detail view-->
      <ng-template #contentDetail let-c="close" let-d="dismiss">
        <div class="modal-header">
          <h5 class="modal-title">{{ businessDetail?.data?.name }}</h5>
          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <perfect-scrollbar style="height: 450px">
            <p>{{ businessDetail?.data?.descriptions }}</p>
          </perfect-scrollbar>
        </div>
      </ng-template>
      <!--- Business list description detail view End-->
      <!--- Business list All Categories view-->
      <!-- <ng-template #contentCategory let-c="close" let-d="dismiss">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ "ang_businesscategories" | translate }}
          </h5>
          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <ul>
            <li *ngFor="let category of business_category; let index = index">
              {{ category }}
            </li>
          </ul>
        </div>
      </ng-template> -->
      <!--- Business list All Categories view End-->
      <!--- Share business view-->
      <ng-template #contentShare let-c="close" let-d="dismiss">
        <div class="modal-header">
          <h5 class="modal-title">{{ "ang_share" | translate }}</h5>
          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="social_profile">
            <ul>
              <li>
                <a href="javascript:void(0);" (click)="shareOnFacebook()">
                  <img alt="facebook" src="../assets/images/facebook.png" />
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" (click)="shareOnTwitter()">
                  <img alt="twitter" src="../assets/images/twitter.png" />
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" (click)="shareOnLinkedIn()">
                  <img alt="linkedin" src="../assets/images/linkedin.png" />
                </a>
              </li>
            </ul>
          </div>
          <!-- social_profile End -->
        </div>
      </ng-template>
      <!--- Share business view End-->
    </div>
  <!-- </mat-tab> -->
  <!-- About Section End -->

  <!-- Post Section Start-->
  <!-- <mat-tab label="Post"> -->
    <ng-template mat-tab-label>
      <span>Post <i class="fa fa-newspaper-o" aria-hidden="true"></i></span>
    </ng-template>
    <ryec-post *ngIf="isPostView && userId" [userId]="userId" [isSelfBusiness]="true"></ryec-post>
  <!-- </mat-tab> -->
  
  <!-- <mat-tab> -->
    <ng-template mat-tab-label>
      <span>Marketplace Ads
        <i><img src="assets/images/marketplace.svg" alt="Marketplace" width="35px" /></i></span>
    </ng-template>
    <div class="market-place_content">
      <div class="container">
        <div class="coming_text marketplace_text">
          <h4>{{ "ang_marketplace_available_on_android_ios" | translate }}</h4>
          <div class="row">
            <div class="col-md-12">
              <div class="stor_link">
                <a href="https://apps.apple.com/in/app/myrajasthan-club/id1545374835" target="_blank">
                  <img src="assets/images/app_store_landing.png" alt="" class="img-responsive" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=club.myrajasthan" target="_blank">
                  <img src="assets/images/play_store_landing.png" alt="" class="img-responsive" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  <!-- </mat-tab> -->
  <!-- Market Place End -->

<!-- </mat-tab-group> -->
