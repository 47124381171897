<div class="business_detail_banner" *ngIf="totalBuinessCount > 0">
  <img alt="eddar-real" src="assets/images/business-header.png" >
</div>
<!-- location_view End -->
<div class="sort_by_tab" *ngIf="totalBuinessCount > 0">
  <span>{{'ang_sortby' | translate}}</span>
   <ul>
      <li>
          <a href="" [ngClass]="(routerLink == 'relevance') ? 'active' : ''" routerLink="/home/agent-business-list/relevance">{{'ang_relevance' | translate}}</a>
      </li>
      <li>
          <a href="" [ngClass]="(routerLink == 'recently-added') ? 'active' : ''" routerLink="/home/agent-business-list/recently-added">{{'ang_recentlyadded' | translate}}</a>
        </li>
      <li>
          <a href="" [ngClass]="(routerLink == 'subscription-plan') ? 'active' : ''" routerLink="/home/agent-business-list/subscription-plan">{{'ang_subscription' | translate}}</a>
        </li>
      <li>
          <a href="" [ngClass]="(routerLink == 'renewal-date') ? 'active' : ''" routerLink="/home/agent-business-list/renewal-date">{{'ang_renew' | translate}}</a>
        </li>
   </ul>
</div>
<!-- sort_by_tab End -->
<div class="container">
    <div class="business_listing_view" *ngIf="totalBuinessCount > 0">
      <!-- <div class="total_result">
        ({{'ang_showing' | translate}} {{first}}-{{last}} {{'ang_of' | translate}} {{totalBuinessCount}} {{'ang_businesses' | translate}})
    </div> -->
      <!-- total_result End -->
      <div class="clearfix business_listing_box" *ngFor="let item of BusinessList; let index = index; ">
        <div class="business_listing_img">
          <img alt="essar-real-estate" [src]="item.business_image">
        </div>
      <!-- business_listing_img End -->
        <div class="business_listing_content">
            <div class="row">
              <div class="col-md-7 col-sm-7 col-xs-12">
                  <div class="business_content_left business_title_icon">
                    <h2>
                      <img *ngIf="item.membership_type == 0" [src]="item.membership_type_icon" title="{{'ang_basicplantext' | translate}}" />
                      <img *ngIf="item.membership_type == 1" [src]="item.membership_type_icon" title="{{'ang_premiumplantext' | translate}}" />
                      <img *ngIf="item.membership_type == 2" [src]="item.membership_type_icon" title="{{'ang_lifetimeplantext' | translate}}" />
                      <span class="text-overflow-hidden">{{item.name}}</span>
                    </h2>
                <ul>
                  <li class="text-overflow-hidden">
                    <i class="fa fa-user" aria-hidden="true" *ngIf="item.owners"></i>{{item.owners}}</li>
                  <li class="text-overflow-hidden">
                    <i class="fa fa-mobile" aria-hidden="true" *ngIf="item.mobile"></i>{{item.mobile}}</li>
                  <!-- <li>
                    <i class="fa fa-map-marker" aria-hidden="true" *ngIf="item.address"></i>{{item.address}}</li> -->
                  <li class="text-overflow-hidden" *ngIf="item.country">
                    <i class="fa fa-map-marker " aria-hidden="true"></i>
                      {{item.city}}<span *ngIf="item.city">, </span> {{item.state}}<span *ngIf="item.state">, </span> {{item.country}}
                  </li>
                  <!-- <li class="text-overflow-hidden" *ngIf="item.categories?.length > 0">
                    <i class="fa fa-angle-right text-overflow-hidden" aria-hidden="true"></i>
                    <span *ngFor="let cat of item.categories; let isLast=last; ">
                    {{cat.category_name}}{{isLast ? '' : ', '}}
                    </span>
                  </li> -->
                  <li class="text-overflow-hidden">
                    <i class="fa fa-angle-right" aria-hidden="true" *ngIf="item.parent_category_name"></i>{{item.parent_category_name}}</li>
                </ul>
                  </div>
                  <!-- business_content_left End -->
              </div>
          <div class="col-md-5 col-sm-5 col-xs-12">
            <div class="business_content_right" *ngIf="item.approved == '1'">
              <a [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" (click)="viewBusiness()" routerLink="/home/business-detail/{{item.business_slug}}">{{'ang_viewmore' | translate}}
              <i class="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </div>
            <div class="business_content_right" *ngIf="item.approved == '0'">
                <a href="javascript:void(0)" [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'">{{'ang_pending' | translate}}</a>
              </div>
          <!-- business_content_right -->
          </div>
            </div>
        </div>
        <!-- business_listing_content End -->
      </div>
    <!-- row End -->
  </div>
  <!-- business_listing_view End -->
  <!-- <div class="pagination_view" *ngIf="totalBuinessCount > 0">
    <ngb-pagination [collectionSize]="collectionSize" [(page)]="currentPage" [maxSize]="maxSize" size="sm"
      (pageChange)="getNextPageBusinessList($event)"></ngb-pagination>
  </div> -->
  <!-- pagination_view End -->

  <div *ngIf="noData == true" style=" text-align: center;padding: 20px;">
    <img src="../assets/images/norecordfound.png" style="height:250px;margin:20px;">
    <h3>{{'ang_nomatches' | translate}}</h3>
  </div>
</div><!--container end-->


