import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'ryec-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  @Input()
  isPrivate: boolean = true;
  year: number;
  appName = environment.APP_NAME;

  constructor() {
    this.year = new Date().getFullYear();
  }


  ngOnInit() { }

}
