import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'ryec-header-simple',
  templateUrl: './header-simple.component.html',
  styleUrls: ['./header-simple.component.css']
})
export class HeaderSimpleComponent implements OnInit {
  appName = environment.APP_NAME;
appDownloadUrl: any;
  constructor(
    private modalService: NgbModal,
  ) { }

  ngOnInit() {
  }

  showAppDownloadModal(content: any) {
    this.modalService.open(content, { windowClass: 'mobile_popup download-app' });
  }

}
