<div class="container">
  <div class="member_bg">
    <h1 *ngIf="appName == 'MRC'">{{ 'ang_support_my_rajasthan_club' | translate }}</h1>
    <h1 *ngIf="appName == 'RYC'">SUPPORT Rajputs Club</h1>

    <div *ngIf="isPlanActive == 1" class="activeMemberPlan">
      <div class="col-sm-4 col-md-4 flex-items">
        <div class="new_membership_box">
          <h2>
            Thank you for supporting MyRajasthan Club.<br />
            Your current membership plan details.
          </h2>
        </div>
        <div class="plan_box">
          <h3>{{ currentMembershipPlan?.type }}</h3>
          <img [src]="currentMembershipPlan?.logo_thumbnail" />
          <h4>
            <i class="fa fa-inr" aria-hidden="true"></i>{{ currentMembershipPlan?.price }}
          </h4>

          <div class="applied-text">
            <p>Start Date : {{ currentMembershipPlan?.start_date }}</p>
          </div>
          <div class="applied-text">
            <p>End Date : {{ currentMembershipPlan?.end_date }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-12 text-center new_membership_box" *ngIf="isPlanActive == 0 && appName == 'MRC'">
      <h2>
        {{ "ang_support_1_MRC" | translate }}
        <br>
        {{ "ang_support_2_MRC" | translate }}
      </h2>
      <h2>
        <b> {{ "ang_support_3_MRC" | translate }}</b>
      </h2>
    </div>
    <div class="col-lg-12 text-center new_membership_box" *ngIf="isPlanActive == 0 && appName == 'RYC'">
      <h2>
        {{ "ang_support_1_RYC" | translate }}
        <br>
        {{ "ang_support_2_RYC" | translate }}
      </h2>
      <h2>
        <b> {{ "ang_support_3_RYC" | translate }}</b>
      </h2>
    </div>

    <h1>{{ "ang_premiunmemberbenifits" | translate }}</h1>

    <div class="special_plans_view">
      <div class="flex-container">
        <div class="col-md-4 col-sm-6 col-xs-12">
          <div class="new_membership_box">
            <div [ngClass]="appName == 'RYC' ? 'membership_icon_ryc' : 'membership_icon_ryc'">
              <img alt="" src="assets/images/position-near-by.png" />
            </div>
            <h2>{{ "ang_freewebsite" | translate }}</h2>
          </div>
        </div>
        <div class="col-md-4 col-sm-6 col-xs-12">
          <div class="new_membership_box">
            <div [ngClass]="appName == 'RYC' ? 'membership_icon_ryc' : 'membership_icon_ryc'">
              <img alt="" src="assets/images/social-media.png" />
            </div>
            <h2>{{ "ang_social_media_promotion" | translate }}</h2>
          </div>
        </div>
        <div class="col-md-4 col-sm-6 col-xs-12">
          <div class="new_membership_box">
            <div [ngClass]="appName == 'RYC' ? 'membership_icon_ryc' : 'membership_icon_ryc'">
              <img alt="" src="assets/images/priority-search.png" />
            </div>
            <h2>{{ "ang_toplistingresults" | translate }}</h2>
          </div>
        </div>

        <div class="col-md-4 col-sm-6 col-xs-12">
          <div class="new_membership_box">
            <div [ngClass]="appName == 'RYC' ? 'membership_icon_ryc' : 'membership_icon_ryc'">
              <img alt="" src="assets/images/investor_dark.png" />
            </div>
            <h2>{{ "ang_investmentopportunitiesaccess" | translate }}</h2>
          </div>
        </div>

        <!--
      <div class="col-md-4 col-sm-6 col-xs-12">
          <div class="new_membership_box">
            <div class="membership_icon">
              <img alt="" src="assets/images/product-listing.png" />
            </div>
            <h2>{{ "ang_toppositionlisting" | translate }}</h2>
          </div>
        </div>
      -->
        <div class="col-md-4 col-sm-6 col-xs-12">
          <div class="new_membership_box">
            <div [ngClass]="appName == 'RYC' ? 'membership_icon_ryc' : 'membership_icon_ryc'">
              <img alt="" src="assets/images/premium-business.png" />
            </div>
            <h2>{{ "ang_listingtab" | translate }}</h2>
          </div>
        </div>

        <div class="col-md-4 col-sm-6 col-xs-12">
          <div class="new_membership_box">
            <div [ngClass]="appName == 'RYC' ? 'membership_icon_ryc' : 'membership_icon_ryc'">
              <img alt="" src="assets/images/conference-invitation.png" />
            </div>
            <h2 *ngIf="appName == 'MRC'">{{ "ang_invitation_to_conference_MRC" | translate }}</h2>
					  <h2 *ngIf="appName == 'RYC'">{{ "ang_invitation_to_conference_RYC" | translate }}</h2>
            <h2 *ngIf="appName == 'BD'">{{ "ang_invitation_to_conference_BD" | translate }}</h2>
          </div>
        </div>

        <div class="col-md-4 col-sm-6 col-xs-12">
          <div class="new_membership_box">
            <div [ngClass]="appName == 'RYC' ? 'membership_icon_ryc' : 'membership_icon_ryc'">
              <img alt="" src="assets/images/product-listing.png" />
            </div>
            <h2>{{ "ang_unlimited_productlisting" | translate }}</h2>
          </div>
        </div>
        <div class="col-md-4 col-sm-6 col-xs-12">
          <div class="new_membership_box">
            <div [ngClass]="appName == 'RYC' ? 'membership_icon_ryc' : 'membership_icon_ryc'">
              <img alt="" src="assets/images/product-listing.png" />
            </div>
            <h2>{{ "ang_unlimited_servicelisting" | translate }}</h2>
          </div>
        </div>
        <div class="col-md-4 col-sm-6 col-xs-12">
          <div class="new_membership_box">
            <div [ngClass]="appName == 'RYC' ? 'membership_icon_ryc' : 'membership_icon_ryc'">
              <img alt="" src="assets/images/marketplace_dark.png" />
            </div>
            <h2>{{ "ang_unlimited_marketplace" | translate }}</h2>
          </div>
        </div>
        <div class="col-md-4 col-sm-6 col-xs-12">
          <div class="new_membership_box">
            <div [ngClass]="appName == 'RYC' ? 'membership_icon_ryc' : 'membership_icon_ryc'">
              <img alt="" src="assets/images/details_inquires.png" />
            </div>
            <h2>{{ "ang_unlimited_business_inquiries" | translate }}</h2>
          </div>
        </div>
        <div class="col-md-4 col-sm-6 col-xs-12">
          <div class="new_membership_box">
            <div [ngClass]="appName == 'RYC' ? 'membership_icon_ryc' : 'membership_icon_ryc'">
              <img alt="" src="assets/images/chat-potential.png" />
            </div>
            <h2>{{ "ang_chatwithpotentialcustomers" | translate }}</h2>
          </div>
        </div>
        <div class="col-md-4 col-sm-6 col-xs-12">
          <div class="new_membership_box">
            <div [ngClass]="appName == 'RYC' ? 'membership_icon_ryc' : 'membership_icon_ryc'">
              <img alt="" src="assets/images/review-business.png" />
            </div>
            <h2>{{ "ang_reviewandrating" | translate }}</h2>
          </div>
        </div>

      </div> <!-- end of main container -->



    </div>



    <h1 style="margin-top: 40px">
      {{ "ang_premiummembershipplan" | translate }}
    </h1>

    <div class="flex-container plans_outer">
      <div class="col-sm-6 col-md-4 flex-items" *ngFor="let plan of membershipPlansList; let ind = index">
        <div class="plan_box">
          <h3>{{ plan.name }}</h3>
          <img [src]="plan.logo_thumbnail" />
          <h4>
            <i class="fa fa-inr" aria-hidden="true"></i>{{ plan.price }}
          </h4>
          <div class="plan_btn">
            <button href="javascript::void(0);" [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'BD'? 'bd_btn': 'mrc_btn'" (click)="selectMembershipPlan(plan, content)">
              {{ "ang_book_now" | translate }}
            </button>
          </div>
          <!--
					<div class="applied-text" *ngIf="plan.pendingStatus == 1">
						<p>{{'ang_applied_plan' | translate}}</p>
					</div>
					-->
        </div>
      </div>
    </div>

    <div class="col-sm-12">
      <div class="bottom_plan_text" *ngIf="appName == 'MRC'">
        <h4>
          {{"ang_whywaitMRC" | translate }}
        </h4>
        <h4>
          {{ "ang_avilpremiummembership" | translate }}
          <a [ngStyle]="{'color':appName == 'MRC' ? 'var(--theme-ryc-based-secondary-color)' : 'var(--theme-mrc-based-primary-color)' }" href="mailto:info@myrajasthanclub.com" title="Mail">info@myrajasthanclub.com</a>
          {{ "ang_callon" | translate }} +91 9166319568.
          {{ "ang_wewillgettouchwithyou" | translate }}.
        </h4>
        <!-- <span>OR</span>
			     <h4>Call whatsapp / SMS @ Mahipalsinh Rana +91 9909115555</h4> -->
      </div>
      <div class="bottom_plan_text" *ngIf="appName == 'RYC'">
        <h4>
          {{"ang_whywaitRYC" | translate }}
        </h4>
        <h4>
          {{ "ang_avilpremiummembership" | translate }}
          <a href="mailto:info@rajputsclub.com" title="Mail">info@rajputsclub.com</a>
          {{ "ang_callon" | translate }} +91 9680419568.
          {{ "ang_wewillgettouchwithyou" | translate }}.
        </h4>
        <!-- <span>OR</span>
			     <h4>Call whatsapp / SMS @ Mahipalsinh Rana +91 9909115555</h4> -->
      </div>
    </div>

  </div>
</div>

<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 *ngIf="lang == 'en'">
      {{ "ang_subscribe_to" | translate }} {{ selectedPlanDetail?.type }}
      {{ "ang_plan" | translate }}
    </h4>
    <h4 *ngIf="lang != 'en'">
      {{ selectedPlanDetail?.type }} {{ "ang_plan" | translate }}
      {{ "ang_subscribe_to" | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div>
      <p>
        {{ "ang_by_subscribe_text" | translate }}
        {{ selectedPlanDetail?.type }}.
      </p>
      <ul>
        <li>{{ "ang_freewebsite" | translate }}</li>
        <li>{{ "ang_toppositiontab" | translate }}</li>
        <li>{{ "ang_listingtab" | translate }}</li>
        <li>{{ "ang_toplistingresults" | translate }}</li>
        <li *ngIf="appName == 'MRC'">{{ "ang_invitation_to_conference_MRC" | translate }}</li>
        <li *ngIf="appName == 'RYC'">{{ "ang_invitation_to_conference_RYC" | translate }}</li>
        <li *ngIf="appName == 'BD'">{{ "ang_invitation_to_conference_BD" | translate }}</li>
        <li>{{ "ang_social_media_promotion" | translate }}</li>
      </ul>
      <div class="price">
        <h4>
          {{ "ang_total_amount" | translate }}:
          <i class="fa fa-inr" aria-hidden="true"></i>
          {{ selectedPlanDetail.price }}
        </h4>
      </div>
    </div>
    <div class="profile_btn">
      <button [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" type="submit" (click)="submitSubscribePlan()">
        {{ "ang_continue" | translate }}
      </button>
    </div>
  </div>
</ng-template>

<ng-template #contentSuccess let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 *ngIf="lang == 'en'">
      {{ "ang_subscribe_to" | translate }} {{ selectedPlanDetail?.type }}
      {{ "ang_plan" | translate }}
    </h4>
    <h4 *ngIf="lang != 'en'">
      {{ selectedPlanDetail?.type }} {{ "ang_plan" | translate }}
      {{ "ang_subscribe_to" | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div>
      <p class="success-text">
        Your membership plan request sent successfully. We will get back to you
        sortly.
      </p>
    </div>
    <div class="profile_btn success-btn">
      <button [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" type="submit" (click)="closeSuccesssPopup()">
        {{ "ang_ok" | translate }}
      </button>
    </div>
  </div>
</ng-template>