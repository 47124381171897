<div class="group-edit_page py-4">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h3 class="detail-title">Event Create</h3>
        <ngb-accordion #acc="ngbAccordion" [closeOthers]="true" activeIds="ngb-panel-0">
            <ngb-panel>
              <div class="panel-title">
                <ng-template ngbPanelTitle>
                  <span>Event Create <i class="fa fa-caret-down"></i></span>
                </ng-template>
              </div>
              <div class="panel-body">
                <ng-template ngbPanelContent>
                  <form name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" autocomplete="off" novalidate>
                    <div class="card-body">
                      <div class="edit_form edit-general_setting">
                        <div class="control-group">
                          <div class="controls">
                            <label class="control_label">Logo Image<span class="required_feild">*</span></label>
                            <div id="dropZoon" class="upload-area__drop-zoon drop-zoon">
                              <button *ngIf="imagePost" type="button" class="btn edit_btn">
                                  <i class="fa fa-pencil" aria-hidden="true"></i>
                                  <input type="file" id="images" name="images" class="drop-zoon__file-input" (change)="onLogoImageChange($event)" accept="image/jpg, image/jpeg, image/png" (click)="$event.target.value=null">
                              </button>
                              <span class="drop-zoon__icon">
                                  <i *ngIf="!imagePost" class="fa fa-upload" aria-hidden="true"></i>
                                  <img *ngIf="imagePost" alt="Cover" [src]="imageShow" />
                              </span>
                              <input type="file" id="logo" name="logo" class="drop-zoon__file-input" (change)="onLogoImageChange($event)" accept="image/jpg, image/jpeg, image/png" (click)="$event.target.value=null" required/>
                            </div>
                            <input type="hidden" name="logo_image" class="input-xlarge" required [ngClass]="{ 'state-error': (f.submitted && logo_image.invalid) || (logo_image.touched && logo_image.invalid)}" [(ngModel)]="eventForm.logo_image" #logo_image="ngModel" />
                            <div *ngIf="(f.submitted && logo_image.invalid) || (logo_image.touched && logo_image.invalid)" class="note">
                              <div *ngIf="logo_image.errors?.required">Logo required !</div>
                            </div>
                          </div>
                        </div>
                        <div class="control-group">
                          <div class="controls">
                            <label class="control_label">Cover Image <span class="required_feild">*</span></label>
                            <div id="dropZoon" class="upload-area__drop-zoon drop-zoon">
                              <button *ngIf="postConverImage" type="button" class="btn edit_btn">
                                  <i class="fa fa-pencil" aria-hidden="true"></i>
                                  <input type="file" id="images" name="images" class="drop-zoon__file-input" (change)="onCoverImageChange($event)" accept="image/jpg, image/jpeg, image/png" (click)="$event.target.value=null">
                              </button>
                              <span class="drop-zoon__icon">
                                  <i *ngIf="!postConverImage" class="fa fa-upload" aria-hidden="true"></i>
                                  <img *ngIf="postConverImage" alt="Cover" [src]="converImageShow" />
                              </span>
                              <input type="file" id="logo" name="logo" class="drop-zoon__file-input" (change)="onCoverImageChange($event)" accept="image/jpg, image/jpeg, image/png" (click)="$event.target.value=null" required/>
                            </div>
                            <input type="hidden" name="cover_image" class="input-xlarge" required [ngClass]="{ 'state-error': (f.submitted && cover_image.invalid) || (cover_image.touched && cover_image.invalid)}" [(ngModel)]="eventForm.cover_image" #cover_image="ngModel" />
                            <div *ngIf="(f.submitted && cover_image.invalid) || (cover_image.touched && cover_image.invalid)" class="note">
                              <div *ngIf="cover_image.errors?.required">Cover image required !</div>
                            </div>
                          </div>
                        </div>
                        <div class="control-group">
                          <div class="controls">
                            <label class="control_label">Event Name <span class="required_feild">*</span></label>
                            <p>
                              <input type="text" id="title" name="title" placeholder="Enter event title" class="form-control" required [ngClass]="{ 'state-error': (f.submitted && title.invalid) || (title.touched && title.invalid)}" [(ngModel)]="eventForm.title" #title="ngModel" />
                            </p>
                            <div *ngIf="(f.submitted && title.invalid) || (title.touched && title.invalid)" class="note">
                              <div *ngIf="title.errors?.required">Event title is required !</div>
                            </div>
                          </div>
                        </div>
                        <div class="control-group">
                          <div class="controls">
                              <label class="control_label">Description <span class="required_feild">*</span></label>
                              <p>
                                <textarea type="text" id="description" name="description" placeholder="Enter description" class="form-control" [ngClass]="{ 'state-error': (f.submitted && description.invalid) || (description.touched && description.invalid)}" [(ngModel)]="eventForm.description" #description="ngModel" required></textarea>
                              </p>
                              <div *ngIf="(f.submitted && description.invalid) || (description.touched && description.invalid)" class="note">
                                <div *ngIf="description.errors?.required">Description required !</div>
                              </div>
                          </div>
                        </div>
                        <div class="control-group">
                          <div class="controls">
                            <label class="control_label">Category <span class="required_feild">*</span></label>
                            <p class="w-100">
                              <select class="form-control" name="event_category" [ngClass]="{ 'state-error': (f.submitted && event_category.invalid) || (event_category.touched && event_category.invalid)}" [(ngModel)]="eventForm.event_category" #event_category="ngModel" required>
                                <option [ngValue]="null">--Select--</option>
                                <option *ngFor="let item of eventCategoriesList; let i = index" [ngValue]="item">{{ item }}</option>
                              </select>
                            </p>
                            <div *ngIf="(f.submitted && event_category.invalid) || (event_category.touched && event_category.invalid)" class="note">
                              <div *ngIf="event_category.errors?.required">Category required !</div>
                            </div>
                          </div>
                        </div>
                        <div class="control-group">
                          <div class="controls">
                            <label class="control_label">Event Type </label>
                            <p class="w-100">
                              <select class="form-control" name="event_type" [ngClass]="{ 'state-error': (f.submitted && event_type.invalid) || (event_type.touched && event_type.invalid)}" [(ngModel)]="eventForm.event_type" #event_type="ngModel" required>
                                <option *ngFor="let item of eventsList; let i = index" [ngValue]="item.id">{{ item.value }}</option>
                              </select>
                            </p>
                            <div *ngIf="(f.submitted && event_type.invalid) || (event_type.touched && event_type.invalid)" class="note">
                              <div *ngIf="event_type.errors?.required">Event type is required !</div>
                            </div>
                          </div>
                        </div>
                        <div class="control-group" *ngIf="eventForm.event_type == 'paid'">
                          <div class="controls">
                            <label class="control_label">Amount <span class="required_feild">*</span></label>
                            <p>
                              <input type="number" id="event_price" name="event_price" placeholder="Enter amount" class="form-control" min="0" required [ngClass]="{ 'state-error': (f.submitted && event_price.invalid) || (event_price.touched && event_price.invalid)}" [(ngModel)]="eventForm.event_price" minlength="1" #event_price="ngModel" />
                            </p>
                            <div *ngIf="(f.submitted && event_price.invalid) || (event_price.touched && event_price.invalid)" class="note">
                              <div *ngIf="event_price.errors?.required">Amount is required !</div>
                              <div *ngIf="event_price.errors?.minlength">Min Amount is required !</div>
                            </div>
                          </div>
                        </div>
                        <div class="control-group">
                          <div class="controls">
                            <label class="control_label">Schedule Type <span class="required_feild">*</span></label>
                            <p class="w-100">
                              <select class="form-control" name="event_repeat" [ngClass]="{ 'state-error': (f.submitted && event_repeat.invalid) || (event_repeat.touched && event_repeat.invalid)}" [(ngModel)]="eventForm.event_repeat" #event_repeat="ngModel" required>
                                <option *ngFor="let item of scheduleList; let i = index" [ngValue]="item.id">{{ item.value }}</option>
                              </select>
                            </p>
                            <div *ngIf="(f.submitted && event_repeat.invalid) || (event_repeat.touched && event_repeat.invalid)" class="note">
                              <div *ngIf="event_repeat.errors?.required">Schedule is required !</div>
                            </div>
                          </div>
                        </div>
                        <div class="control-group">
                          <div class="controls">
                            <label class="control_label">TimeZone </label>
                            <div class="city_dropdown hours">
                              <div class="dropdown">
                                 <button type="button" (click)="showTimeZoneDropDown()" class="dropbtn">
                                    {{ timezone }}
                                 </button>
                                 <div id="myDropdown" class="dropdown-content" *ngIf="isTimeZoneShow">
                                    <div class="city_search_input">
                                       <i _ngcontent-c1="" aria-hidden="true" class="fa fa-search"></i>
                                       <input type="text" placeholder="{{ 'ang_searchhere' | translate }}" id="myInput" name="timezoneSearch" [(ngModel)]="timezoneSearch" (input)="filterTimeZone()"
                                          class="city-search" />
                                    </div>
                                    <perfect-scrollbar style="height: 245px">
                                       <a href="javascript:void()" (click)="selectTimeZone(time)"
                                          *ngFor="let time of timezoneArr">{{ time.value }} - {{ time.name }}</a>
                                    </perfect-scrollbar>
                                 </div>
                              </div>
                            </div>
                            <p>
                              <input type="hidden" id="timezone_id" name="timezone_id" class="form-control" required [ngClass]="{ 'state-error': (f.submitted && timezone_id.invalid) || (timezone_id.touched && timezone_id.invalid)}" [(ngModel)]="eventForm.timezone_id" #timezone_id="ngModel" />
                            </p>
                            <div *ngIf="(f.submitted && timezone_id.invalid) || (timezone_id.touched && timezone_id.invalid)" class="note">
                              <div *ngIf="timezone_id.errors?.required">TimeZone is required !</div>
                            </div>
                          </div>
                        </div>
                        <div class="control-group">
                          <div class="controls">
                            <label class="control_label">Start Event Date <span class="required_feild">*</span></label>
                            <p>
                              <input id="start_date" name="start_date" type="date" placeholder="Enter Start Date"
                              class="form-control" [ngClass]="{ 'state-error': (f.submitted && start_date.invalid) || (start_date.touched && start_date.invalid)}" [(ngModel)]="eventForm.start_date" #start_date="ngModel" [min]="eventForm.start_date" (keydown)="disableDate()">
                            </p>
                            <div *ngIf="(f.submitted && start_date.invalid) || (start_date.touched && start_date.invalid)" class="note">
                              <div *ngIf="start_date.errors?.required">Start event date is required !</div>
                            </div>
                          </div>
                        </div>
                        <div class="control-group">
                          <div class="controls">
                            <label class="control_label">Start Event Time <span class="required_feild">*</span></label>
                            <p>
                              <input id="start_time" name="start_time" type="time" placeholder="Enter Start Date" class="form-control" [ngClass]="{ 'state-error': (f.submitted && start_time.invalid) || (start_time.touched && start_time.invalid)}" [(ngModel)]="eventForm.start_time" #start_time="ngModel" [value]="eventForm.start_time">
                            </p>
                            <div *ngIf="(f.submitted && start_time.invalid) || (start_time.touched && start_time.invalid)" class="note">
                              <div *ngIf="start_time.errors?.required">Start event time is required !</div>
                            </div>
                          </div>
                        </div>
                        <div class="control-group">
                          <div class="controls">
                            <label class="control_label">End Event Date <span class="required_feild">*</span></label>
                            <p>
                              <input id="end_date" name="end_date" type="date" placeholder="Enter Start Date"
                              class="form-control" [ngClass]="{ 'state-error': (f.submitted && end_date.invalid) || (end_date.touched && end_date.invalid)}" [(ngModel)]="eventForm.end_date" #end_date="ngModel" [min]="eventForm.start_date" (keydown)="disableDate()">
                            </p>
                            <div *ngIf="(f.submitted && end_date.invalid) || (end_date.touched && end_date.invalid)" class="note">
                              <div *ngIf="end_date.errors?.required">End event date is required !</div>
                            </div>
                          </div>
                        </div>
                        <div class="control-group">
                          <div class="controls">
                            <label class="control_label">End Event Time <span class="required_feild">*</span></label>
                            <p>
                              <input id="end_time" name="end_time" type="time" placeholder="Enter Start Date" class="form-control" [ngClass]="{ 'state-error': (f.submitted && end_time.invalid) || (end_time.touched && end_time.invalid)}" [(ngModel)]="eventForm.end_time" #end_time="ngModel">
                            </p>
                            <div *ngIf="(f.submitted && end_time.invalid) || (end_time.touched && end_time.invalid)" class="note">
                              <div *ngIf="end_time.errors?.required">End event time is required !</div>
                            </div>
                          </div>
                        </div>
                        <div class="control-group" *ngIf="eventForm.event_repeat !== 'not repeat'">
                          <div class="controls">
                            <label class="control_label">Event Last Date <span class="required_feild">*</span></label>
                            <p>
                              <input id="event_end_date" name="event_end_date" type="date" placeholder="Enter Start Date"
                              class="form-control" [ngClass]="{ 'state-error': (f.submitted && event_end_date.invalid) || (event_end_date.touched && event_end_date.invalid)}" [(ngModel)]="eventForm.event_end_date" #event_end_date="ngModel" [min]="eventForm.start_date" (keydown)="disableDate()">
                            </p>
                            <div *ngIf="(f.submitted && event_end_date.invalid) || (event_end_date.touched && event_end_date.invalid)" class="note">
                              <div *ngIf="event_end_date.errors?.required">Event last date is required !</div>
                            </div>
                          </div>
                        </div>
                        <div class="control-group">
                          <div class="controls">
                            <label class="control_label">Event Nature </label>
                            <p class="w-100">
                              <select class="form-control" name="event_nature" [ngClass]="{ 'state-error': (f.submitted && event_nature.invalid) || (event_nature.touched && event_nature.invalid)}" [(ngModel)]="eventForm.event_nature" #event_nature="ngModel" required>
                                <option *ngFor="let item of eventNaturesList; let i = index" [ngValue]="item.id">{{ item.value }}</option>
                              </select>
                            </p>
                            <div *ngIf="(f.submitted && event_nature.invalid) || (event_nature.touched && event_nature.invalid)" class="note">
                              <div *ngIf="event_nature.errors?.required">Event nature is required !</div>
                            </div>
                          </div>
                        </div>
                        <div class="control-group" *ngIf="eventForm.event_nature == 'online'">
                          <div class="controls">
                            <label class="control_label">Event Link <span class="required_feild">*</span></label>
                            <p class="w-100">
                              <input type="text" id="external_link" name="external_link" placeholder="Eg. https://www.event/register.com
                              " class="form-control" [ngClass]="{ 'state-error': (f.submitted && external_link.invalid) || (external_link.touched && external_link.invalid)}" [(ngModel)]="eventForm.external_link" #external_link="ngModel" pattern="https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)" [ngModelOptions]="{ updateOn: 'change' }" required/>
                            </p>
                            <div *ngIf="(f.submitted && external_link.invalid) || (external_link.touched && external_link.invalid)" class="note">
                              <div *ngIf="external_link.errors?.required">Link required !</div>
                              <div class="error_message" *ngIf="external_link.errors?.pattern">
                                Invalid Link !
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="control-group" *ngIf="eventForm.event_nature == 'offline'">
                          <div class="controls">
                              <label class="control_label">Venue <span class="required_feild">*</span></label>
                              <p>
                                <textarea type="text" id="event_location" name="event_location" placeholder="Enter venue" class="form-control" [ngClass]="{ 'state-error': (f.submitted && event_location.invalid) || (event_location.touched && event_location.invalid)}" [(ngModel)]="eventForm.event_location" #event_location="ngModel" required></textarea>
                              </p>
                              <div *ngIf="(f.submitted && event_location.invalid) || (event_location.touched && event_location.invalid)" class="note">
                                <div *ngIf="event_location.errors?.required">Venue required !</div>
                              </div>
                          </div>
                        </div>
                        <div class="control-group" *ngIf="eventForm.event_nature == 'offline'">
                          <div class="controls">
                            <label class="control_label">Street Address <span class="required_feild">*</span></label>
                            <p>
                              <input type="text" id="street_address" name="street_address" placeholder="Enter street address" class="form-control" required [ngClass]="{ 'state-error': (f.submitted && street_address.invalid) || (street_address.touched && street_address.invalid)}" [(ngModel)]="eventForm.street_address" #street_address="ngModel" />
                            </p>
                            <div *ngIf="(f.submitted && street_address.invalid) || (street_address.touched && title.invalid)" class="note">
                              <div *ngIf="street_address.errors?.required">Street address is required !</div>
                            </div>
                          </div>
                        </div>
                        <div class="control-group" *ngIf="eventForm.event_nature == 'offline'">
                          <div class="controls location-auto-search">
                            <label class="control_label">Pincode <span class="required_feild">*</span></label>
                            <p class="w-100">
                              <input type="text" name="searchTextVal" placeholder="{{'ang_pincode' | translate}}" class="input-xlarge" (input)="locationSearch($event.target.value)" required [ngClass]="{ 'state-error': (f.submitted && searchTextVal.invalid) || (searchTextVal.touched && searchTextVal.invalid)}" [(ngModel)]="searchText" #searchTextVal="ngModel" />
                            </p>
                            <p class="help-block" *ngIf="isLocationNotFound">
                              {{ "ang_invalidPincodeValidation" | translate }}
                            </p>
                            <div *ngIf="(f.submitted && searchTextVal.invalid) || (searchTextVal.touched && searchTextVal.invalid)" class="note">
                              <div *ngIf="searchTextVal.errors?.required">Pincode required !</div>
                            </div>
                            <div class="member-list" style="display: none;">
                              <perfect-scrollbar style="height: 210px;">
                                <ul>
                                  <li class="search_input" *ngFor="let item of locationsList; let i = index;" (click)="selectlocation(item.location_id, item.district, item.state, item.country_name)">
                                    {{ item.district }} , {{ item.state }} , {{ item.country_name }}
                                  </li>
                                </ul>
                              </perfect-scrollbar>
                            </div>
                          </div>
                        </div>
                        <div class="control-group" *ngIf="eventForm.event_nature == 'offline'">
                          <label class="control_label">Show map to users ?</label>
                          <span *ngIf="isShowMap === false">No</span>
                          <span *ngIf="isShowMap === true">Yes</span>
                          <label class="switch">
                            <input type="checkbox" name="show_map" [(ngModel)]="eventForm.show_map" [checked]="eventForm.show_map" value="Yes" (ngModelChange)="onChangeMap($event)">
                            <span class="slider round"></span>
                          </label>
                        </div>
                        <div class="control-group">
                          <div class="controls">
                            <label class="control_label">Organizers <span class="required_feild">*</span></label>
                            <a class="add-skill-link" href="javacript:void(0);" (click)="loadOrganizerModal(organizerComponent);"> Add Organizer</a>
                            <div>
                              <input type="hidden" name="event_organizers" class="form-control" required [ngClass]="{ 'state-error': (f.submitted && event_organizers.invalid) || (event_organizers.touched && event_organizers.invalid)}" [(ngModel)]="eventForm.organizer" #event_organizers="ngModel" />
                              <div *ngIf="(f.submitted && event_organizers.invalid) || (event_organizers.touched && event_organizers.invalid)" class="note">
                                <div *ngIf="event_organizers.errors?.required">Organizer required !</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="control-group" *ngIf="isOrganizerExisit">
                          <section class="col col-12">
                            <div class="table-responsive">
                              <table class="table table-striped">
                                <thead class="">
                                  <tr>
                                    <th>Organizer Name</th>
                                    <th>Organizer Email</th>
                                    <th>Organizer Mobile No</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let item of eventForm.event_organizers; let i = index">
                                    <td>{{ item.name }}</td>
                                    <td>{{ item.email }}</td>
                                    <td>{{ item.phone }}</td>
                                    <td>
                                      <a (click)="deleteRow(i)" class="text-danger">
                                        <i class="fa fa-trash"></i>
                                      </a>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </section>
                        </div>
                        <button type="submit" [disabled]="isSubmit" class="ryec_btn ml-auto mr-0 d-block">Save</button>
                      </div>
                    </div>
                  </form>
                </ng-template>
              </div>
            </ngb-panel>
        </ngb-accordion>
      </div>
    </div>
  </div>
</div>

<!-- tag post user -->
<ng-template #organizerComponent let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="text-primary">
      Add Organizer
    </h4>
    <button id="closeOrganizerComponent" type="button" class="close d-none" aria-label="Close" (click)="d('Cross click');">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div id="container" class="container">
      <div class="row">
        <div class="col-md-12 my-2">
          <div class="info-form">
            <form name="form" (ngSubmit)="f.form.valid && onAddSOrganizerSubmit()" #f="ngForm" autocomplete="off" novalidate>
              <div class="row">
                <div class="col-md-12 bottom_btn mb-4">
                   <button type="button" class="btn btn-outline-success float-right my-2" (click)="addNewRow()">
                      Add Organizer
                   </button>
                </div>
              </div>
              <div class="form-row my-4" *ngFor="let item of selectedOrganizer; let i = index">
                <div class="form-group form-wrap col-md-4">
                  <label class="control-label" for="url">Organizer Name <span>*</span></label>
                  <input id="name{{ i }}" type="name" class="form-control" [value]="item.name" [(ngModel)]="item.name" name="name{{ i }}" placeholder="Enter organizer name" #name="ngModel" [ngClass]="{ 'state-error': (f.submitted && name.invalid) || (name.touched && name.invalid)}" required />
                  <div *ngIf="(f.submitted && name.invalid) || (name.touched && name.invalid)" class="note">
                    <div *ngIf="name.errors?.required">Name is required</div>
                  </div>
                </div>
                <div class="form-group form-wrap col-md-4">
                  <label class="control-label" for="url">Organizer Email <span>*</span></label>
                  <input id="email{{ i }}" type="email" class="form-control" [value]="item.email" [(ngModel)]="item.email" name="email{{ i }}" placeholder="Enter organizer email" #email="ngModel" [ngClass]="{ 'state-error': (f.submitted && email.invalid) || (email.touched && email.invalid)}" required />
                  <div *ngIf="(f.submitted && email.invalid) || (email.touched && email.invalid)" class="note">
                    <div *ngIf="email.errors?.required">Email is required</div>
                  </div>
                </div>
                <div class="form-group form-wrap col-md-4">
                  <label class="control-label" for="url">Organizer Phone <span>*</span></label>
                  <input minlength="6" maxlength="13" type="tel" class="form-control" id="phone{{ i }}" name="phone{{ i }}" placeholder="Enter organizer phone no" [ngClass]="{ 'state-error': (f.submitted && phone.invalid) || (phone.touched && phone.invalid)}" [value]="item.phone" [(ngModel)]="item.phone" #phone="ngModel" (keypress)="numberOnly($event)" required/>
                  <div *ngIf="(f.submitted && phone.invalid) || (phone.touched && phone.invalid)" class="note">
                    <div *ngIf="phone.errors?.required">Phone no is required</div>
                  </div>
                  <div class="bottom_btn remove-btn">
                    <button class="text-danger float-right" type="button" (click)="deleteRow(i)">
                      <i class="fa fa-trash"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <p class="pull-right" *ngIf="selectedOrganizer.length">
                  <button [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" [disabled]="isSubmit" type="submit">
                    {{ "ang_submit" | translate | uppercase }}
                  </button>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!-- tag post user -->