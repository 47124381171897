import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EventModel } from 'src/app/shared/model/event.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { HomeService } from 'src/app/shared/services/home.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { environment } from 'src/environments/environment';
import swal from 'sweetalert2';

  
declare var $: any;

@Component({
  selector: 'ryec-event-create',
  templateUrl: './event-create.component.html',
  styleUrls: ['./event-create.component.css'],
  providers: [DatePipe]
})
export class EventCreateComponent implements OnInit {
  groupId: any = 0;
  eventForm = new EventModel();
  eventModelData: FormData = new FormData();
  isSubmit: boolean = false;
  eventCategoriesList: any[] = [];
  eventsList: any[] = [];
  scheduleList: any[] = [];
  eventNaturesList: any[] = [];
  searchText: string = "";
  timezoneSearch: string = "";
  isLocationNotFound: boolean = false;
  isTimeZoneShow: boolean = false;
  locationsList: any[] = [];
  isShowMap: boolean = false;
  isOrganizerExisit: boolean = false;
  selectedOrganizer: any[] = [];
  timezoneArr: any[] = [];
  allTimezoneArr: any[] = [];
  timezone = 'UTC +05:30 - Asia/Kolkata';
  modalReference: any;
  imagePost: any;
  imageShow: any = "assets/images/logo RYEC.png";
  postConverImage: any;
  converImageShow: any = "assets/images/logo RYEC.png";
  appName = environment.APP_NAME;
  constructor(
    private homeService: HomeService,
    private modalService: NgbModal,
    private cS: CommonService,
    public httpService: HttpService,
    private datePipe: DatePipe,
    private activeRoute: ActivatedRoute,
    private router: Router,
  ) {
    this.activeRoute.params.subscribe(data => {
      this.groupId = data.slug;
    });
  }

  ngOnInit() {
    this.getEventCategoryList();
    this.getEventTypeList();
    this.getEventNaturesList();
    this.getTimezone();
    this.getScheduleList();
    this.setDefultTimeAndDate();
  }

  getEventCategoryList() {
    // this.eventCategoriesList = [
    //   { id: 1, value: 'Celebration' },
    //   { id: 2, value: 'Meeting' },
    // ];

    this.homeService.getEventCategoryList().subscribe((res: any) => {
      this.eventCategoriesList = res.data;
      console.log(res);
    });
  }

  getTimezone() {
    console.log('call');
    const apiUrl = environment.RYEC_API_URL + 'getTimezone';
    this.httpService.get(apiUrl).subscribe((res: any) => {
      if (res['status'] === 1) {
        this.timezoneArr = res.data;
        this.allTimezoneArr = this.timezoneArr;
      }
    });
  }

  showTimeZoneDropDown() {
    if (this.isTimeZoneShow) {
      this.isTimeZoneShow = false;
    } else {
      this.isTimeZoneShow = true;
    }
  }

  filterTimeZone() {
    this.timezoneArr = [];
    if (this.timezoneSearch !== '') {
      this.allTimezoneArr.filter((item: any) => {
        const search = item['value'] + ' ' + item['name'];
        if (search.toLowerCase().indexOf(this.timezoneSearch.toLowerCase()) > -1) {
          this.timezoneArr.push(item);
        }
      });
    } else {
      this.timezoneArr = this.allTimezoneArr;
    }
  }

  selectTimeZone(timezone: any) {
    this.timezone = timezone.value + ' - ' + timezone.name;
    // this.addBusinessForm.controls.timezone.setValue(timezone.name);
    this.isTimeZoneShow = false;
    // this.addBusinessForm.controls.timezoneSearch.setValue('');
    // this.timezoneArr = this.allTimezoneArr;
  }

  getEventTypeList() {
    this.eventsList = [
      { id: 'free', value: 'Free' },
      { id: 'paid', value: 'Paid' },
    ];
  }

  getScheduleList() {
    this.scheduleList = [
      { id: 'not repeat', value: 'Not Repeat' },
      { id: 'daily', value: 'Daily' },
      { id: 'weekly', value: 'Weekly' },
      { id: 'monthly', value: 'Monthly' },
      { id: 'yearly', value: 'Yearly' },
    ];
  }

  getEventNaturesList() {
    this.eventNaturesList = [
      { id: 'online', value: 'Online' },
      { id: 'offline', value: 'Offline' },
    ];
  }

  locationSearch(event: any) {
    // const val = event.target.value;
    const val = event;
    if (val === "" || val.length < 3) {
      $(".member-list").css("display", "none");
    }
    if (val.length >= 3) {
      const postJson = { pincode: val };

      this.homeService.getLocationByPinCode(postJson).subscribe((res: any) => {
        if (res.data) {
          if (res.data.length > 0) {
            $(".member-list").css("display", "block");
            this.locationsList = res.data;
            this.isLocationNotFound = false;
          } else {
            this.locationsList = [];
            this.isLocationNotFound = true;
            $(".member-list").css("display", "none");
          }
        }
      });
    }
  }

  selectlocation(id: number, district: string, state: string, countryName: string) {
    this.searchText = district + ' , ' + state + ' , ' + countryName;
    this.eventForm.location_id = id;
    // this.jobForm.location_id = id;
    $(".member-list").css("display", "none");
  }

  setDefultTimeAndDate() {
    this.eventForm.start_date = this.datePipe.transform(new Date(), 'yyyy-MM-dd')!;
    this.eventForm.end_date = this.datePipe.transform(new Date(), 'yyyy-MM-dd')!;
    this.eventForm.start_time = new Date().toLocaleTimeString([], { hour: '2-digit', minute: "2-digit", hour12: false });
    this.eventForm.end_time = new Date().toLocaleTimeString([], { hour: '2-digit', minute: "2-digit", hour12: false });
    this.eventForm.event_end_date = this.datePipe.transform(new Date(), 'yyyy-MM-dd')!;
  }

  onChangeMap(event: any) {
    if (event == true) {
      this.isShowMap = true;
    }
    else {
      this.isShowMap = false;
    }
  }

  addNewRow() {
    this.selectedOrganizer.push({
      name: "",
      email: "",
      phone: ""
    });
  }

  loadOrganizerModal(content: any) {
    if (this.selectedOrganizer.length == 0) {
      this.addNewRow();
    }
    // this.modalReference = this.modalService.open(content, { windowClass: "modal-md" });
    this.modalReference = this.modalService.open(content, { size: "lg" });
    this.modalReference.result.then(
      (result: any) => {
        console.log(`Closed with: ${result}`);
      },
      (reason: any) => {
        console.log(reason);
      }
    );
  }

  onAddSOrganizerSubmit() {
    // this.eventForm.event_organizers = JSON.parse(JSON.stringify(this.selectedOrganizer));
    this.eventForm.event_organizers = this.selectedOrganizer;
    if (this.eventForm.event_organizers.length) {
      this.isOrganizerExisit = true;
      this.eventForm.organizer = "abc";
    }
    console.log(this.eventForm.event_organizers);
    setTimeout(() => {
      let element: HTMLElement = document.getElementById('closeOrganizerComponent') as HTMLElement;
      element.click();
    }, 200);
  }

  deleteRow(index: number) {
    swal.fire({
      title: 'Are you sure you want to remove ?',
  
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      showCancelButton: true,
      showCloseButton: true,
      reverseButtons: true,
     
    }).then((result) => {
      if (result.value) {
        this.selectedOrganizer.splice(index, 1);
        let tempData = JSON.parse(JSON.stringify(this.selectedOrganizer));
        this.selectedOrganizer = [];
        this.selectedOrganizer = tempData;
        this.cS.showSuccess("Organizer remove successfully");
        if (this.selectedOrganizer.length == 0) {
          this.isOrganizerExisit = false;
          this.eventForm.organizer = "";
        }
      }
    });
  }

  onLogoImageChange(event: any) {
    const srcEle = event.target;
    if (srcEle.files && srcEle.files[0]) {
      if (this.cS.checkValidImage(srcEle.files[0])) {
        this.readLogoImageURL(srcEle.files[0]);
      } else {
        this.cS.showError("Please select file less than 5MB.");
      }
    }
  }

  readLogoImageURL(input: any) {
    const self = this;
    if (input) {
      const reader = new FileReader();
      reader.onload = function (event: Event) {
        if (event.target) {
          self.imageShow = reader.result;
          self.eventForm.logo_image = input;
          self.imagePost = input;
        }
      };
      reader.readAsDataURL(input);
    }
  }

  onCoverImageChange(event: any) {
    const srcEle = event.target;
    var filesAmount = event.target.files.length;
    for (let i = 0; i < filesAmount; i++) {
      if (srcEle.files && srcEle.files[i]) {
        this.converImageShow = "";
        if (this.cS.checkValidImage(srcEle.files[i])) {
          this.readCoverImageURL(srcEle.files[i]);
        } else {
          this.converImageShow = "assets/images/logo RYEC.png";
          this.cS.showError("Please select file less than 5MB.");
        }
      }
    }
  }

  readCoverImageURL(input: any) {
    const self = this;
    if (input) {
      const reader = new FileReader();
      reader.onload = function (event: Event) {
        if (event.target) {
          self.converImageShow = reader.result;
          // self.converImageShow.push(reader.result);
          self.postConverImage = input;
          self.eventForm.cover_image = input;
        }
      };
      reader.readAsDataURL(input);
    }
  }

  setSubmitModelValue() {
    if (this.groupId) {
      this.eventModelData.append("site_id", this.groupId);
    }
    this.eventModelData.append("logo_image", this.eventForm.logo_image);
    this.eventModelData.append("cover_image", this.eventForm.cover_image);
    this.eventModelData.append("title", this.eventForm.title);
    this.eventModelData.append("description", this.eventForm.description);
    this.eventModelData.append("event_category", this.eventForm.event_category);
    this.eventModelData.append("event_type", this.eventForm.event_type);
    this.eventModelData.append("event_price", this.eventForm.event_price);
    this.eventModelData.append("event_location", this.eventForm.event_location);
    this.eventModelData.append("street_address", this.eventForm.street_address);
    this.eventModelData.append("location_id", this.eventForm.location_id);
    let isShowMapVal = (this.eventForm.show_map) ? '1' : '0';
    this.eventModelData.append("show_map", isShowMapVal);
    this.eventModelData.append("event_repeat", this.eventForm.event_repeat);
    this.eventModelData.append("start_date", this.eventForm.start_date);
    this.eventModelData.append("start_time", this.eventForm.start_time);
    this.eventModelData.append("end_date", this.eventForm.end_date);
    this.eventModelData.append("end_time", this.eventForm.end_time);
    this.eventModelData.append("timezone_id", this.eventForm.timezone_id);
    this.eventModelData.append("event_end_date", this.eventForm.event_end_date);
    this.eventModelData.append("event_nature", this.eventForm.event_nature);
    this.eventModelData.append("external_link", this.eventForm.external_link);
    this.eventModelData.append('event_organizers', JSON.stringify(this.selectedOrganizer));
  }

  onSubmit() {
    this.setSubmitModelValue();
    this.isSubmit = true;
    const apiUrl = environment.RYEC_API_URL + 'createEvent';
    this.httpService.postUpload(apiUrl, this.eventModelData).subscribe((res: any) => {
      if (res.status === 1) {
        this.cS.showSuccess(res.message);
        this.isSubmit = false;
        this.router.navigate(['/event/details/' + res.data.id]);
      }
      else {
        this.cS.showError(res.message);
        this.isSubmit = false;
      }
    });
  }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  disableDate() {
    return false;
  }

}
