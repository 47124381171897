<div class="modal-header">
    <!-- <h4 class="text-primary">{{ "ang_writeyourenquiry" | translate }}</h4> -->
    <h4 [ngStyle]="{'color':appName == 'RYC' ? 'var(--theme-ryc-based-secondary-color)' : 'var(--theme-mrc-based-primary-color)' }">Comment</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')" >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  
  <div class="modal-body">
    <div id="container" class="container">
      <div class="row">
        <div class="col-md-12 my-2">
          <div class="info-form">
            <form name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" autocomplete="off" novalidate>
              <div class="form-group">
                <label for="comment">Comment <span>*</span></label>
                <textarea class="form-control" id="comment" name="comment" rows="3" placeholder="Please leave comment.." required [ngClass]="{ 'state-error': (f.submitted && comment.invalid) || (comment.touched && comment.invalid)}" [(ngModel)]="commentModel.comment" #comment="ngModel"></textarea>
                <div *ngIf="(f.submitted && comment.invalid) || (comment.touched && comment.invalid)" class="note">
                  <div *ngIf="comment.errors?.required">Comment is required</div>
                </div>
              </div>
              <div class="form-group">
                <p class="pull-right">
                  <button [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" [disabled]="isSubmit" type="submit">
                    {{ "ang_submit" | translate | uppercase }}
                  </button>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>