<div class="container">
    <div class="member_bg">
        <h1>{{'ang_my_cart' | translate}}</h1>

        <div class="row justify-content-center">
            <div class="col-sm-11">
                <div class="cart-table">
                    <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">{{'ang_your_plan' | translate}}</th>

                                    <th scope="col">{{'ang_quantity' | translate}}</th>
                                    <th scope="col">{{'ang_price' | translate}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{{selectedPlanDetail?.name}} - {{'ang_premium_membership_plan' | translate}}</td>

                                    <td>1</td>
                                    <td> <i class="fa fa-inr" aria-hidden="true"></i> {{selectedPlanDetail?.price}}</td>
                                </tr>
                                <tr>
                                    <td colspan="2" class="subtotal">{{'ang_total' | translate}}</td>
                                    <td class="amount"> <i class="fa fa-inr" aria-hidden="true"></i> {{selectedPlanDetail?.price}}</td>
                                    <!-- <td>@fat</td> -->
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-sm-4 col-md-3">
                <div class="pay-btn" (click)="doPayment()">
                    <a href="javascript:void()" class="ryec_btn btn-block">{{'ang_pay_now' | translate}}
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>