<div class="post_fb">
  <div class="container">
    <div>
      <div class="row">
        <!-- <div class="col-md-6">
                <div class="fb_tabs">
                    <ul class="nav nav-pills side_nav" role="tablist">
                    <li *ngFor="let type of postType; let first = first" [ngClass]="{ firstItem: first }" role="presentation"
                        class="accounts_li" [class.active]="selectedType === type">
                        <a (click)="handleChangeTab(type)" aria-controls="one" role="tab" data-toggle="tab">{{ type }}</a>
                    </li>
                    </ul>
                </div>
            </div> -->

        <!-- <div class="col-md-3">
          <div class="input-group rounded">
            <input
              type="search"
              class="form-control rounded"
              placeholder="Search"
              aria-label="Search"
              aria-describedby="search-addon"
            />
            <span class="input-group-text border-0" id="search-addon">
              <i class="fa fa-search" aria-hidden="true"></i>
            </span>
          </div>
        </div>

        <div class="col-md-3">
          <select
            name="dtBasicExample_length"
            aria-controls="dtBasicExample"
            class="custom-select custom-select-sm form-control form-control-sm"
          >
            <option value="10">Sort By</option>
            <option value="10">Relavance Popular</option>
            <option value="25">Near By</option>
            <option value="50">A-Z</option>
            <option value="100">Z-A</option>
          </select>
        </div> -->
      </div>
    </div>
  </div>
</div>

<!--- Share business view-->
<ng-template #contentSocialShare let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h5 class="modal-title">{{ "ang_share" | translate }}</h5>
    <button id="closeBtn" type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="social_profile">
      <ul>
        <li>
          <a href="javascript:void(0)" (click)="shareOnFacebook()">
            <img alt="facebook" src="../assets/images/facebook.png" />
          </a>
        </li>
        <li>
          <a href="javascript:void(0)" (click)="shareOnTwitter()">
            <img alt="twitter" src="../assets/images/twitter.png" />
          </a>
        </li>
        <li>
          <a href="" (click)="shareOnLinkedIn()">
            <img alt="linkedin" src="../assets/images/linkedin.png" />
          </a>
        </li>
      </ul>
    </div>
    <!-- social_profile End -->
  </div>
</ng-template>
<!--- Share business view End-->

<!-- <div> -->
  <!-- <div class="container">
    <div class="row"> -->
      <div class="home-post_wrapper">
        <div class="popup_post">
          <div class="popup_post_one">
            <div class="share_post_img">
              <img *ngIf="
                  !cS.getUserDetails() ||
                    (cS.getUserDetails() &&
                      cS.getUserDetails()?.profile_url == '');
                  else createPostProfilePhoto1
                " class="profile_icon" src="../assets/images/default_user_profile.png" />
              <ng-template #createPostProfilePhoto1>
                <img class="profile_icon" [src]="cS.getUserDetails()?.profile_url" />
              </ng-template>
            </div>
            <ng-template #content let-c="close" let-d="dismiss">
              <form [formGroup]="postForm" novalidate (ngSubmit)="submitpost(postId)">
                <div class="modal-header">
                  <h4 class="modal-title">
                    {{ postId > 0 ? ("ang_edit_post" | translate) : ("ang_new_post" | translate) }}
                  </h4>
                  <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="row profile">
                    <div class="col-5 profile-img">
                      <!-- <img _ngcontent-c1="" alt="" class="profile_menu_icon"
                        src="../assets/images/default-logo.png" /> -->
                      <img *ngIf="
                          !cS.getUserDetails() ||
                            (cS.getUserDetails() &&
                              cS.getUserDetails()?.profile_url == '');
                          else createPostProfilePhoto
                        " class="profile_menu_icon" src="../assets/images/default_user_profile.png" />
                      <ng-template #createPostProfilePhoto>
                        <img class="profile_menu_icon" [src]="cS.getUserDetails()?.profile_url" />
                      </ng-template>
                    </div>
                    <div class="col-7">
                      <label class="control-label" for="category">
                        {{ "ang_category" | translate
                        }}<span class="required_field" style="font-size: 17px">*</span>
                      </label>
                      <select name="category" formControlName="category" class="form-control pointer"
                        [(ngModel)]="categoryName">
                        <option value="" selected disabled hidden>
                          Select Category
                        </option>
                        <option *ngFor="let category of categories" [ngValue]="category">
                          {{ category }}
                        </option>
                      </select>
                      <p class="help-block" *ngIf="
                          postForm.controls.category.touched &&
                          postForm.controls.category.errors?.required
                        ">
                        {{ "ang_category_error" | translate }}
                      </p>
                    </div>
                    <!-- <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                      <label class="control-label" for="city">
                        {{ "ang_city" | translate }}
                      </label>
                      <div class="location_dropdown">
                        <div class="dropdown">
                          <a href="javascript:;" name="cityLocationDropdown" (click)="cityShowLocationDropDown()"
                            class="dropbtn form-control control-label">{{ city_search_location }}</a>
                          <div class="dropdown-content" *ngIf="cityIsShow">
                            <div class="location_search_input">
                              <i _ngcontent-c1="" aria-hidden="true" class="fa fa-search"></i>
                              <input id="myInput" type="text" placeholder="Search City" name="cityLocation"
                                formControlName="city" class="location-search" [(ngModel)]="cityName"
                                (input)="cityFilterLocation()" />
                            </div>
                            <perfect-scrollbar style="height: 170px">
                              <p *ngFor="let city of cityLocations" class="home-location city_search_input"
                                (click)="citySelectLocation(city.name)">
                                {{ city.name }}
                              </p>
                            </perfect-scrollbar>
                          </div>
                        </div>
                      </div>
                    </div> -->
                  </div>
                  <div class="form-group file-area">
                    <!--  <input type="text" formControlName="message" id="message" placeholder="Write your message..."
                      class="input-xlarge" /> -->
                    <textarea formControlName="message" id="message" placeholder='{{ "ang_write_message" | translate }}...'
                      class="input-xlarge" class="form-control" [(ngModel)]="messageValue"></textarea>
                    <p class="help-block" *ngIf="
                        postForm.controls.message.touched &&
                        postForm.controls.message.errors?.required
                      ">
                      {{ "ang_message_error" | translate }}
                    </p>
                  </div>

                  <div class="form-group file-area">
                    <div *ngFor="let image of postImages; let ind = index" class="file-img-wrapper">
                      <a href="javascript:;" class="file-delete-icon" (click)="deletePostImages(ind, image.id)"><i
                          aria-hidden="true" class="fa fa-remove"></i></a>
                      <img [src]="image.full_url" height="150" width="200" style="margin: 3px" />
                      <br />
                    </div>
                    <div *ngFor="let url of images; let ind = index" class="file-img-wrapper">
                      <a *ngIf="images.length > 0" href="javascript:;" class="file-delete-icon"
                        (click)="deletePostImages(ind)"><i aria-hidden="true" class="fa fa-remove"></i></a>
                      <img [src]="url" height="150" width="200" style="margin: 3px" />
                      <br />
                    </div>
                    <div class="file-dummy">
                      <div class="success">{{ "ang_add_images" | translate }}</div>
                      <div class="file_images">
                        <i class="fa fa-image" aria-hidden="true"></i>
                        <input type="file" (change)="onSelectFile($event)" multiple="" id="file" class="input-xlarge" />
                        <!-- <i class='attachment'
                            (click)="toggled = !toggled"
                            [(emojiPickerIf)]="toggled"
                            [emojiPickerDirection]="'top'"
                            (emojiPickerSelect)="handleSelection($event)"><img src="../../assets/images/emoji.png"></i> -->
                        <!-- <img src="../../assets/images/checkin.png" /> -->
                      </div>
                    </div>
                    <!-- <p
                      class="help-block"
                      *ngIf="
                        postForm.controls.message.touched &&
                        (postForm.controls.message.errors?.required ||
                          postForm.controls.message.errors?.pattern)
                      "
                    >
                      File is required
                    </p> -->
                  </div>
                  <!-- <div class="form-group file-area">
                    <input type="text" formControlName="title" id="title" placeholder="Add title"
                      class="form-control" />
                  </div> -->
                  <div class="form-group file-area">
                    <input type="text" formControlName="externallink" id="externallink" placeholder='{{ "ang_add_external_link" | translate }}'
                      class="form-control" [(ngModel)]="externalLink" />
                    <p class="help-block" *ngIf="
                        postForm.controls.externallink.touched &&
                        postForm.controls.externallink.errors?.pattern
                      ">
                      {{ "ang_external_pattern_error" | translate }}
                    </p>
                  </div>
                </div>

                <div class="modal-footer">
                  <button class="btn btn-primary mb-2" type="submit" [disabled]="!postForm.valid">
                    {{ postId > 0 ? ("ang_save" | translate) : ("ang_post" | translate) }}
                  </button>
                </div>
              </form>
            </ng-template>
            <div class="popup_post_button">
              <button class="pointer" style="margin-left: 10px" (click)="openSm(content)">
                {{ "ang_home_tab_create_post" | translate }}
              </button>
            </div>
          </div>
          <br style="clear: both" />
          <div class="seprator"></div>

          <div class="video_streming">
            <div class="row">
              <div class="col-md-12">
                <p class="pointer" (click)="openSm(content)">
                  <i class="fa fa-picture-o camera" aria-hidden="true"></i>{{ "ang_photo" | translate }}
                </p>
              </div>
              <!-- <div class="col-md-6">
                <p>
                  <i class="fa fa-smile-o feeling" aria-hidden="true"></i>Feeling/Activity
                </p>
              </div> -->
            </div>
          </div>
        </div>
        <div class="highlights_div" *ngIf="appName == 'RYC'">
          <p><span>Highlights</span> </p>
          <div class="row">
            <div class="col-md-2 col-2">
              <ul *ngFor="let item of highlights | keyvalue">
                <img src= {{item.value.banner_fullurl}} width="60px" />
                <br><b>{{item.value.button_name}}</b><br>
              </ul>
             </div>
          </div>
          <div class="post_tabs">
            <ul>
              <li><a style="background: #EB4132; border-radius: 33px; color: #fff;">Timeline</a></li>
              <li><a>News</a></li>
              <li><a>Survey</a></li>
              <li><a>Events</a></li>
            </ul>
          </div>
        </div>
        
        <div class="row tab-content">
          <div *ngIf="selectedType === postType[0]" role="tabpanel" class="tab-pane active" id="one" style="width: 100%;">
            <div *ngIf="posts.length > 0" class="col-md-12">
              <div *ngFor="let post of posts" class="main_post_list">
                <div class="post_list">
                  <div class="share_post">
                    <div class="share_post_top">
                      <div class="share_post_img">
                        <a href="" *ngIf="post.business_slug; else postUserPhoto" routerLink="/home/business-detail/{{
                            post.business_slug
                          }}" target="_blank">
                          <img *ngIf="
                              post.user.profile_url == '';
                              else profilePhoto
                            " class="profile_icon" src="../assets/images/default_user_profile.png" />
                          <ng-template #profilePhoto>
                            <img class="profile_icon" [src]="post.user.profile_url" />
                          </ng-template>
                        </a>
                        <ng-template #postUserPhoto>
                          <img *ngIf="
                              post.user.profile_url == '';
                              else profilePhoto
                            " class="profile_icon" src="../assets/images/default_user_profile.png" />
                          <ng-template #profilePhoto>
                            <img class="profile_icon" [src]="post.user.profile_url" />
                          </ng-template>
                        </ng-template>
                      </div>
                      <div class="share_post_head">
                        <div class="post_title-wrapper">
                          <h4 [ngStyle]="{'color':appName == 'RYC' ? 'var(--theme-ryc-based-secondary-color)' :  appName == 'BD' ? 'var( --theme-bd-based-primary-color)' : 'var(--theme-mrc-based-primary-color)' }">
                            <a routerLink="/home/business-detail/{{post.business_slug}}" *ngIf="post.business_slug; else postUserName" [ngStyle]="{'color':appName == 'RYC' ? 'var(--theme-ryc-based-secondary-color)' : 'var(--theme-mrc-based-primary-color)' }">
                              {{ post.user.name }}
                            </a>
                            <span *ngIf="post.business_slug"> &nbsp; | &nbsp;</span>
                            <a *ngIf="post.business_slug" routerLink="/home/business-detail/{{post.business_slug}}" target="_blank" [ngStyle]="{'color':appName == 'RYC' ? 'var(--theme-ryc-based-secondary-color)' : 'var(--theme-mrc-based-primary-color)' }">
                              {{ post.business_name }}
                            </a>
                            <ng-template #postUserName>
                              {{ post.user.name }}
                            </ng-template>
                          </h4>
                          <p>
                            {{ post.category }} |
                            <span [ngStyle]="{'color':appName == 'RYC' ? 'var(--theme-ryc-based-secondary-color)' : appName == 'BD' ? 'var( --theme-bd-based-primary-color)' : 'var(--theme-mrc-based-primary-color)' }" class="post-time">{{
                              post.created_at_diff
                              }}</span>
                          </p>
                        </div>
                        <div ngbDropdown class="d-inline-block" *ngIf="checkAuth()">
                          <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle><i class="fa fa-ellipsis-v" aria-hidden="true"></i></button>
                          <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-menu-right">
                            <button class="dropdown-item" type="button" ngbDropdownItem *ngIf="post.user_id !== user_id" (click)="loadReportPostComponent(post.id)">
                              Report Post
                            </button>
                            <button class="dropdown-item" ngbDropdownItem *ngIf="post.user_id === user_id" (click)="editPostByPostId(content, post.id)">
                              <span>
                                <!-- <i class="fa fa-pencil pointer edit-icon"
                                aria-hidden="true"  title="Edit"></i> -->
                                Edit</span>
                            </button>
                            <button class="dropdown-item" ngbDropdownItem *ngIf="post.user_id === user_id" (click)="deletePostByPostId(post.id)" >
                              <span >
                                <!-- <i aria-hidden="true" class="fa fa-times pointer"
                                 title="Delete"></i> -->
                                 Delete</span>
                            </button>
                          </div>
                        </div>
                      </div>
                      
                      
                    </div>
                    <p class="post_comment">
                        <span style="white-space: pre-line;">
                      {{
                      (readMore && readMorePost == post.id) ? post.content : (post.content | slice: 0:130)
                      }}
                      </span>
                      <span *ngIf="readMorePost != post.id && post.content.length > 130">...</span>
                      <a href="javascript:;" *ngIf="readMorePost != post.id &&  post.content.length > 130"
                        (click)="readMore = true;readMorePost = post.id">See More</a>
                      <a href="javascript:;" *ngIf="readMore && readMorePost == post.id && post.content.length > 130" (click)="readMore = false;readMorePost = 0">See less</a>
                    </p>
                  </div>

                  <div *ngIf="post.images.length > 0" class="share_image add_padding">

                        <!-- if image length is 1 or 2 -->
                        <div class="row img-length-1-2" *ngIf="post.images.length < 3">
                        <div class="{{ post.images.length == 1 ? 'col-md-12' : 'col-md-6' }} " *ngFor="let item of post.images; let index = index">
                            <a class="btn btn-lg btn-outline-primary open-post-popup"
                            (click)="openVerticallyCentered(postImage, post.id,index)">
                                <img [src]="item.full_url" />
                            </a>
                        </div>
                        </div>

                        <!-- If image length is 3 or more thann 3 -->
                        <div class="row" *ngIf="post.images.length > 2">
                            <div class="col-sm-12">
                            <div class="img-wrapper {{
                                post.images.length == 3 ? 'total-img-3' : ''
                            }}">
                                    <div class="img-auto-adjust-{{ index }}" *ngFor="
                                    let item of post.images;
                                    let index = index
                                    ">
                                    <a class="btn btn-lg btn-outline-primary open-post-popup"
                                    (click)="openVerticallyCentered(postImage, post.id,index)">
                                        <img [src]="item.full_url" />
                                    </a>
                                </div>
                            </div>
                            </div>
                        </div>
                  </div>

                  <div class="like_buttons">
                    <p *ngIf="post.likes_count > 0; else viewCount">
                      <a href="javascript:void(0);" (click)="loadGroupPostLikedSharedTaggedUsersListModalComponentForLiked(post.id);">
                        <i class="fa fa-thumbs-o-up" aria-hidden="true"></i>
                        <span>{{ post.likes_count }}</span>
                      </a>
                      <!-- <span *ngFor="let item of post.likes; let index = index"><span *ngIf="index < 2">{{ index > 0 ? ', ' : '' }}{{ item.name }} </span></span>
                        <span *ngIf="post.likes_count > 2">and {{ post.likes_count - 2 }} others</span> -->

                      <span style="float: right" *ngIf="post.share_count != ''">
                        <a href="javascript:void(0);" (click)="loadGroupPostLikedSharedTaggedUsersListModalComponentForShared(post.id);">
                          <span>{{ post.share_count }}</span>
                          {{ post.share_count == 1 ? ("ang_share" | translate) : ("ang_shares" | translate) }}
                        </a>
                      </span>
                      <span style="float: right; margin-right: 10px" *ngIf="post.views_count != ''">
                        <span>{{ post.views_count }}</span>
                        {{ post.views_count == 1 ? ("ang_view" | translate) : ("ang_views" | translate) }}
                      </span>
                    </p>
                    <ng-template #viewCount>
                      <p style="text-align: right">
                        <span *ngIf="post.views_count != ''">
                          <!-- <i class="fa fa fa-eye" aria-hidden="true"></i> -->
                          <span>{{ post.views_count }}</span>
                          {{ post.views_count == 1 ? ("ang_view" | translate) : ("ang_views" | translate) }}
                        </span>
                        <span *ngIf="post.share_count != ''">
                          <a href="javascript:void(0);" (click)="loadGroupPostLikedSharedTaggedUsersListModalComponentForShared(post.id);">
                            <span>{{ post.share_count }}</span>
                            {{ post.share_count == 1 ? ("ang_share" | translate) : ("ang_shares" | translate) }}
                          </a>
                        </span>
                      </p>
                    </ng-template>

                    <div class="like_share_buttons">
                      <div class="row">
                        <div class="col-md-4">
                          <a *ngIf="appName == 'MRC'" href="javascript:void(0)" (click)="handleLikePost(post.id,$event)" [ngClass]="{ likedMrc: post.is_like_by_user === 1 }">
                            <i class="fa fa-thumbs-o-up" aria-hidden="true"></i>
                            {{ "ang_like" | translate }}
                          </a>
                          <a *ngIf="appName == 'RYC'" href="javascript:void(0)" (click)="handleLikePost(post.id,$event)" [ngClass]="{ likedRyc: post.is_like_by_user === 1 }">
                            <i class="fa fa-thumbs-o-up" aria-hidden="true"></i>
                            {{ "ang_like" | translate }}
                          </a>
                          <a *ngIf="appName == 'BD'" href="javascript:void(0)" (click)="handleLikePost(post.id,$event)" [ngClass]="{ likedbd: post.is_like_by_user === 1 }">
                            <i class="fa fa-thumbs-o-up" aria-hidden="true"></i>
                            {{ "ang_like" | translate }}
                          </a>
                        </div>
                        <div class="col-md-4">
                          <a *ngIf="
                              post.external_link && post.external_link != 'null'
                            " target="_blank" [href]="post.external_link"><i class="fa fa-globe"
                              aria-hidden="true"></i> {{ "ang_visit_link" | translate }}</a>
                        </div>
                        <div class="col-md-4">
                          <a href="javascript:void(0)" (click)="
                              openShareOptions(
                                contentSocialShare,
                                post.id,
                                post.content
                              )
                            "><i class="fa fa-share" aria-hidden="true"></i>{{ "ang_share" | translate }}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="col-md-12">
              <div class="main_post_list">
                <div class="post_list">
                  <div class="share_post">
                    <div class="share_post_top">
                      <div class="share_post_img">
                        <img
                          class="profile_icon"
                          src="../assets/images/default_user_profile.png"
                        />
                      </div>
                      <div class="share_post_head">
                        <h4>Jaydevsinh Vaghela share post</h4>
                        <p>
                          14 hrs ·
                          <i class="fa fa-globe" aria-hidden="true"></i>
                        </p>
                      </div>
                    </div>
                    <p class="post_comment">
                      જયમાતાજી આપ સર્વે ગિરાસદાર રાજપૂત સમાજ ને જાણ કરવામાં આવે
                      છે કે જૂનાગઢ રાજપૂત સમાજ દ્વારા જૂનાગઢ જિલ્લાના અથવા
                      બહારથી આવેલ કોઈપણ આપણા ગીરસદાર રાજપૂત સમાજ પરિવાર… See
                      more
                    </p>
                  </div>

                  <div class="share_image add_padding">
                    <div class="row">
                      <div class="col-md-12 padding_less">
                        <img src="../../assets/images/fs.jpg" />
                      </div>
                      <div class="col-md-4 padding_less">
                        <img src="../../assets/images/janaksinh.jpg" />
                      </div>
                      <div class="col-md-4 padding_less">
                        <img src="../../assets/images/rajputan-cloths.jpg" />
                      </div>
                      <div class="col-md-4 padding_less">
                        <img src="../../assets/images/Rana.png" />
                      </div>
                    </div>
                  </div>

                  <div class="like_buttons">
                    <p>
                      <img src="../../assets/images/thumb.svg" />Mohit, Sumit
                      and 23 others
                    </p>

                    <div class="like_share_buttons">
                      <div class="row">
                        <div class="col-md-4">
                          <a href="#"
                            ><i class="fa fa-thumbs-o-up" aria-hidden="true"></i
                            >Like</a
                          >
                        </div>
                        <div class="col-md-4">
                          <a href="#"
                            ><i class="fa fa-bug" aria-hidden="true"></i
                            >Report</a
                          >
                        </div>
                        <div class="col-md-4">
                          <a href="#"
                            ><i class="fa fa-share" aria-hidden="true"></i
                            >Share</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
          <!-- <div *ngIf="selectedType === postType[1]" role="tabpanel" class="tab-pane active" id="two">
            <div *ngIf="posts.length > 0" class="col-md-12">
              <div *ngFor="let post of posts" class="main_post_list">
                <div class="post_list">
                  <div class="share_post">
                    <div class="share_post_top">
                      <div class="share_post_img">
                        <img class="profile_icon"
                          src="../assets/images/default_user_profile.png" />
                      </div>
                      <div class="share_post_head">
                        <h4>{{ post.title }}</h4>
                        <p>
                          <i class="fa fa-globe" aria-hidden="true"></i>
                        </p>
                      </div>
                      <span *ngIf="post.user_id === user_id"><i aria-hidden="true" class="fa fa-times"
                          (click)="deletePostByPostId(post.id)"></i></span>
                    </div>
                    <p class="post_comment">
                      {{ post.content | slice: 0:50 }}
                    </p>
                  </div>

                  <div *ngIf="post.images" class="share_image add_padding">
                    <div class="row">
                      <div class="col-md-6 padding_less" *ngFor="let item of post.images">
                        <img [src]="item.full_url" />
                      </div>
                    </div>
                  </div>

                  <div class="like_buttons">
                    <p *ngIf="post.likes_count > 0">
                      <i class="fa fa-thumbs-o-up" aria-hidden="true"></i>
                      <span *ngFor="let item of post.likes; let index = index"><span *ngIf="index < 2">{{ item.name
                          }},</span></span>
                      <span *ngIf="post.likes_count > 2">and {{ post.likes_count - 2 }} others</span>
                    </p>

                    <div class="like_share_buttons">
                      <div class="row">
                        <div class="col-md-6">
                          <a href="javascript:void(0)" (click)="handleLikePost(post.id)"
                            [ngClass]="{ liked: post.is_like_by_user === 1 }"><i class="fa fa-thumbs-o-up"
                              aria-hidden="true"></i>Like</a>
                        </div>
                        <div class="col-md-6">
                          <a href="javascript:void(0)"
                            (click)="openShareOptions(contentSocialShare, post.id, post.title)"><i class="fa fa-share"
                              aria-hidden="true"></i>Share</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <!-- <div class="col-md-1" style="padding: 0px" *ngIf="postDetailId > 0 && cS.getUserDetails()">
        <a href="" class="view_all_btn" (click)="viewAllPost()" routerLink="/home">{{ "ang_viewall" | translate }}</a>
        <a  href="" class="view_all_btn" (click)="viewAllPost()">{{'ang_viewall' | translate}}</a>
      </div> -->
      <!-- <div class="col-md-4">
        <div class="about post_list">
          <h2>About</h2>
          <p>
            This social networking website only For kshatriya Rajput. Please
            invite ur all kshatriya rajput in this Group… See more
          </p>
          <ul>
            <li>
              <p><i class="fa fa-globe" aria-hidden="true"></i> Public</p>
              Anyone can see who's in the group and what they post.
            </li>
            <li>
              <p><i class="fa fa-eye" aria-hidden="true"></i> Visible</p>
              Anyone can find this group.
            </li>
            <li>
              <p>
                <i class="fa fa-users" aria-hidden="true"></i> General group
              </p>
            </li>
          </ul>
        </div>
      </div> -->

      <!-- <div class="pagination" *ngIf="totalPostCount > 0">
        <ngb-pagination class="d-flex justify-content-end" [collectionSize]="collectionSize" [(page)]="currentPage"
          [maxSize]="2" [rotate]="true" size="sm" [boundaryLinks]="false" (pageChange)="getAllPost($event)">
        </ngb-pagination>
      </div> -->
    <!-- </div>
  </div> -->
  <!-- Image Modal -->
  <ng-template #postImage let-c="close" let-d="dismiss">
    <div class="modal-header">
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body"  class="post-img-popup-carousel">
      <owl-carousel-o  class="post-img-popup-carousel" [options]="customOptions" #owlElement [options]="customOptions" >
        <ng-container class="item post-img" *ngFor="let image of postImages;let i = index" >
           <div class="" id="postImg" >
            <img _ngcontent-c6="" src="{{image.full_url}}">
          </div> 
          <ng-template carouselSlide [id]="image.full_url">
            <img [src]="image.full_url" [alt]="image.alt" [title]="image.title">
          </ng-template>
        </ng-container>
    
      </owl-carousel-o>
    </div>
  </ng-template>
<!-- </div> -->



