import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
  BusinessListDetailsRes
} from 'src/app/shared/class/data.model';
import { environment } from '../../../../environments/environment';
import { Meta } from '@angular/platform-browser';
 
import { DeviceDetectorService } from 'ngx-device-detector';
import { CommonService } from 'src/app/shared/services/common.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { HomeService } from 'src/app/shared/services/home.service';
import { ReportBusinessModalComponent } from 'src/app/shared/component/report-business-modal/report-business-modal.component';
//import { AnyARecord } from 'dns';

@Component({
  selector: 'ryec-business-detail',
  templateUrl: './business-detail.component.html',
  styleUrls: ['./business-detail.component.css', "../market-place/market-place.component.css"]
})
export class BusinessDetailComponent implements OnInit {

  currentPage: number;
  selectedItem: any;
  selectedIndex: number;
  selectedServiceItem: any;
  selectedServiceIndex: number;
  selectedImageItem: any;
  selectedImageItemIndex: number;
  business_slug = '';
  business_name = '';
  currentRate = 0;
  businessDetail: any;
  productDetail : any;
  serviceDetail :any;
  collectionSize = 0;
  business_id: any;
  assetTypeId: number = 0;
  modalReference: any;
  activeId = 0;
  totalRatingCount = 0;
  perPageList = environment.BUSINESS_LIST_LIMIT;
  allRatings :any;
  business_category:any;
  start_5 = 0;
  start_4 = 0;
  start_3 = 0;
  start_2 = 0;
  start_1 = 0;
  trimLength = environment.DESCRIPTIO_TRIM_LENGTH;
  review = '';
  reviewValid = false;
  enquiryForm = {
    title: '',
    description: ''
  }
  validTitle = false;
  validDescription = false;
  validForm = false;
  repoUrl = window.location.href;
  user_id = 0;
  businessUserId: any;
  isAgent: any;
  showAgent = false;
  rating = 4.14;
  // businessClaimForm = {
  //   entity_id: 0,
  //   document: ''
  // }
  businessClaimForm: FormData = new FormData();
  isBusinessClaimValid: boolean = false;
  isFileValid: boolean = false;
  isBusinessAlreadyClaim = false;
  attachedFileName: string = "";
  userId: number = 0;
  isPostView: boolean = false;
  isMobile: boolean = false;
  isPageLoaded: boolean = false;
  isUserHasBusiness: boolean = false;
  businessDescription: string = "";

  businessStoresList: any;
  businessImages: any[] = [];
  schemaJson: any[] = [];
  businessImageUrl: string = "";
  assetTypeFieldsModel: any = {};
  appName = environment.APP_NAME;
  isUserRajput: boolean = false;

  constructor(
    private activeRoute: ActivatedRoute,
    private modalService: NgbModal,
    private hS: HomeService,
    private httpS: HttpService,
    private cS: CommonService,
   // private deviceService: Ng2DeviceService,
    private router: Router,
    private meta: Meta) {
    this.businessDetail = {
      status: 0,
      message: ''
    };
    this.selectedIndex = 1;
    this.selectedServiceIndex = 1;
    this.currentPage = 1;
    this.selectedImageItemIndex = 1;
    const userDetail = this.cS.getUserDetails();
    if (userDetail) {
      if (userDetail.isRajput) {
        this.isUserRajput = true;
      }
      this.user_id = userDetail.id;
      if (userDetail.business_id) {
        this.isUserHasBusiness = true;
      }
    }
    /*
    *get url slug
    */
    this.activeRoute.params.subscribe(data => {
      this.business_slug = data.slug;
      this.getBusinessDetailBySlug();
    });
    this.isAgent = localStorage.getItem('isAgent');
    if (this.isAgent === 'A') {
      this.showAgent = true;
    }

    localStorage.removeItem('business_name');
  }

  ngOnInit() {
    this.cS.scrollTop();

    this.businessClaimForm.set('entity_id', <any>0);
    this.businessClaimForm.set('document', '');
   // this.isMobile = this.deviceService.isMobile();
  }


  /**
  * Get Business Detail by business slug
  *@returns void
  */
  getBusinessDetailBySlug(): void {
    const postJson = { business_slug: this.business_slug };
    let categoryId: number = 0;

    this.hS.getBusinessDetailBySlug(postJson).subscribe((res: any) => {
      if (res.data) {
        this.meta.updateTag({ name: 'twitter:site', content: this.repoUrl });
        this.meta.updateTag({ name: 'twitter:title', content: res['data']['name'] });
        this.meta.updateTag({ name: 'twitter:description', content: res['data']['descriptions'] });

        this.meta.updateTag({ name: 'og:url', content: this.repoUrl });
        this.meta.updateTag({ name: 'og:type', content: "Business details" });
        this.meta.updateTag({ name: 'og:title', content: res['data']['name'] });
        this.meta.updateTag({ name: 'og:description', content: res['data']['descriptions'] });

        if (res.data.owners.length > 0) {
          this.meta.updateTag({ name: 'twitter:image', content: res['data']['owners']['0']['image_url'] });
          this.meta.updateTag({ name: 'twitter:card', content: res['data']['owners']['0']['image_url'] });
          this.meta.updateTag({ name: 'og:image', content: res['data']['owners']['0']['image_url'] });
        }

        this.businessDetail = res;
        this.business_id = res.data.id;
        this.businessDescription = res.data.descriptions;
        this.businessImages = res.data.business_images;
        this.assetTypeId = res.data.asset_type_id;
        this.business_name = res.data.name;
        this.businessUserId = res.data.user_id;
        this.totalRatingCount = res.data.rating.total;
        this.isPageLoaded = true;
        if (this.businessImages.length > 0) {
          this.businessImageUrl = this.businessImages[0].image_name;
        }
        if (res.data.parent_categories.length > 0) {
          categoryId = res.data.parent_categories[0].category_id;
        }
        /**
         * if schema is not empty then call generate fun and pass values
         * @Yuvraj 20/01/2022 
         */
        if (res.data.schema_json.length > 0) {
          this.generateEntitySchema(res.data.schema_json);
        }

        this.getSubAssetTypeFields(categoryId, res.data.asset_type_id);
        this.getAddedOnlineStores();
        this.start_5 = Math.round((res.data.rating['start_5_rating'] * 100) / this.totalRatingCount);
        this.start_4 = Math.round((res.data.rating['start_4_rating'] * 100) / this.totalRatingCount);
        this.start_3 = Math.round((res.data.rating['start_3_rating'] * 100) / this.totalRatingCount);
        this.start_2 = Math.round((res.data.rating['start_2_rating'] * 100) / this.totalRatingCount);
        this.start_1 = Math.round((res.data.rating['start_1_rating'] * 100) / this.totalRatingCount);
        this.getNextPageRatingList();
      }
    }, (err:any) => {
      console.log(err);
    });
  }

  /**
   * get schema json from the api and assign to schemaJson.
   * this will generate shema json script code in html file 
   * @Yuvraj 20/01/2022 06:00PM
   */
  generateEntitySchema(resSchemaJson: any) {
    this.schemaJson = resSchemaJson;
  }

  /**
  *open Image in modal view
  */
  openImageViwer(index: any, content: any) {
    this.activeId = index;
    this.modalService.open(content, { windowClass: 'image_gallery' }).result.then((result) => {
      console.log(`Closed with: ${result}`);
    }, (reason) => {
      console.log(reason);
    });
  }

  getSubAssetTypeFields(categoryId: number, assetTypeId: number) {
    const postJson = {
      category_id: categoryId,
      asset_type_id: assetTypeId
    };

    this.hS.getSubAssetTypeFields(postJson).subscribe((res: any) => {
      if (res.data) {
        this.assetTypeFieldsModel = res.data;
      }
    }, (err:any) => {
      console.log(err);
    });
  }

  /**
  *open Product detail in modal view
  */
  openProductDetail(product_id: any, content: any) {
    const postJson = { product_id: product_id };
    this.hS.getProductDetailByProductId(postJson).subscribe((res: any) => {
      this.productDetail = res.data;
      this.modalService.open(content, { windowClass: 'product_gallery' }).result.then((result) => {
        console.log(`Closed with: ${result}`);
      }, (reason) => {
        console.log(reason);
      });
    }, (err:any) => {
      console.log(err);
    });
  }

  /**
  *open Service detail in modal view
  */
  openServiceDetail(service_id: any, content: any) {
    const postJson = { service_id: service_id };
    this.hS.getServiceDetailByServiceId(postJson).subscribe((res: any) => {
      this.serviceDetail = res.data;
      this.modalService.open(content, { windowClass: 'service_gallery' }).result.then((result) => {
        console.log(`Closed with: ${result}`);
      }, (reason) => {
        console.log(reason);
      });
    }, (err:any) => {
      console.log(err);
    });
  }

  /**
  *open business list add review and rating modal
  */
  addReviewAndRating(content: any) {
    this.review = '';
    this.modalReference = this.modalService.open(content, { windowClass: 'rating_popup' });
    this.modalReference.result.then((result: any) => {
      console.log(`Closed with: ${result}`);
      this.getBusinessDetailBySlug();
    }, (reason: any) => {
      console.log(reason);
    });
  }

  /**
  *add reviews and rating for business list
  */
  submitRatingDetail() {
    if (this.review == '' && this.review.trim() == '') {
      this.reviewValid = true;
    } else {
      const userDetail = this.cS.getUserDetails();
      if (userDetail) {
        const postJson = {
          user_id: userDetail.id,
          business_id: this.business_id,
          rating: this.currentRate,
          comment: this.review
        }

        const apiUrl = environment.RYEC_API_URL + 'addBusinessRating';

        this.httpS.post(apiUrl, postJson).subscribe((res: any) => {
          if (res.status === 1) {
            this.cS.showSuccess(res.message);
          } else {
            this.cS.showError(res.message);
          }
          this.modalReference.close();
        },( err:any) => {
          if (err.error) {
            console.log(err.error);
          }
        });
      }
    }
  }

  checkAuth() {
    if (this.cS.getUserDetails()) {
      return true;
    }
    return false;
  }

  loadReportBusinessComponent() {
    const modalReference = this.modalService.open(ReportBusinessModalComponent, { windowClass: 'mobile_popup' });
    modalReference.componentInstance.assetTypeId = this.assetTypeId;
    modalReference.componentInstance.entityId = this.business_id;
    modalReference.result.then((result: any) => {
      console.log(`Closed with: ${result}`);
    }, (reason: any) => {
      console.log(reason);
    });
  }

  /**
  *open business list add enquiry modal
  */
  addEnquiryForBusiness(content: any) {
    this.enquiryForm.title = '';
    this.enquiryForm.description = ''
    this.modalReference = this.modalService.open(content, { windowClass: 'enquiry_popup' });
    this.modalReference.result.then((result: any) => {
      console.log(`Closed with: ${result}`);
    }, (reason: any) => {
      console.log(reason)
    });
  }

  /**
  *add reviews and rating for business list
  */
  submitEnquiryDetail() {
    const postJson = {
      business_id: this.business_id,
      // title: this.enquiryForm.title,
      message: this.enquiryForm.description
    };

    console.log(postJson)
    const apiUrl = environment.RYEC_API_URL + 'sendEnquiry';

    this.httpS.post(apiUrl, postJson).subscribe((res: any) => {
      console.log(res);
      if (res.status === 1) {
        this.cS.showSuccess(res.message);
      }
      else {
        this.cS.showError(res.message);
      }
      this.modalReference.close();
    }, (err:any) => {
      if (err.error) {
        console.log(err.error);
      }
    });
  }

  countCollectionSize() {
    if (this.totalRatingCount > this.perPageList) {
      this.collectionSize = Math.ceil(this.totalRatingCount / this.perPageList);
      this.collectionSize = this.collectionSize * 10;
    } else {
      this.collectionSize = this.perPageList;
    }
  }

  /**
  *get business rating list
  */
  getNextPageRatingList(event?: number): void {
    //console.log(event);
    const postJson = { business_id: this.business_id, page: this.currentPage };

    this.hS.getBusinessRating(postJson).subscribe((res: any) => {
      if (res.status === 1) {
        this.allRatings = res.data.reviews;
        this.countCollectionSize();
      }
    }, (err:any)=> {
      console.log(event);
      console.log(err);
    });
  }

  /**
  *open business list detail view
  */
  openBusinessDescription(content: any) {
    this.modalService.open(content, { windowClass: 'desc_popup' }).result.then((result) => {
      console.log(`Closed with: ${result}`);
    }, (reason) => {
      console.log(reason);
    });
  }

  /**
  *open business list categories list
  */
  openAllCategories(content: any) {
    this.modalService.open(content, { windowClass: 'allcategory_popup' }).result.then((result) => {
      console.log(`Closed with: ${result}`);
    }, (reason) => {
      console.log(reason);
    });
  }

  /**
  *open share popup
  */
  openShareOptions(content: any) {
    this.modalService.open(content, { windowClass: 'share_popup' }).result.then((result) => {
      console.log(`Closed with: ${result}`);
    }, (reason) => {
      console.log(reason);
    });
  }

  shareOnFacebook() {
    let currentFullUrl = window.location.href;
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${currentFullUrl}`, 'sharer');
  }

  shareOnTwitter() {
    let currentFullUrl = window.location.href;
    window.open(`https://twitter.com/intent/tweet?url=${currentFullUrl}`, 'sharer');
  }

  // // LinkedIn share won't work if your shareUrl is localhost:port/abc, it should be genuine deployed url
  shareOnLinkedIn() {
    let currentFullUrl = window.location.href;
    window.open(`https://www.linkedin.com/shareArticle?url=${currentFullUrl}`, 'sharer');
  }

  shareNow = async () => {
    const navigator = window.navigator as any;
    let urlLang: string = "";
    let lang = this.cS.getLang();
    if (lang) {
      if (lang == 'en') {
        urlLang = "en";
      }
      if (lang == 'hi') {
        urlLang = "hi";
      }
    }
    else {
      urlLang = "en";
    }

    if ('canShare' in navigator) {
      let currentFullUrl = window.location.href + '?lang=' + urlLang;
      let title = this.business_name;
      let text = new DOMParser().parseFromString(this.businessDescription, "text/html").documentElement.textContent;
      let imageUrl = this.businessImageUrl;
      const share = async function () {
        try {
          // const response = await fetch(imageUrl, {mode: 'no-cors'});
          const response = await fetch(imageUrl);
          const blob = await response.blob();
          const file = new File([blob], 'image.jpg', { type: blob.type });
          await navigator.share({
            url: currentFullUrl,
            title: title,
            text: text,
            files: [file],
          });
          console.log("shared successfully....");
        } catch (err:any) {
          console.log(err.name, err.message);
        }
      };
      share();
    }
  };

  changeEvent(event?: any) {
    console.log(event);
    if (this.review === '' && this.review.trim() === '') {
      this.reviewValid = true;
    }
    else {
      this.reviewValid = false;
    }
  }

  changeTitleEvent(event?: any) {
    console.log(event);
    // if (this.enquiryForm.title === '' && this.enquiryForm.title.trim() === '') {
    //   this.validTitle = true;
    // } else {
    //   this.validTitle = false;
    // }

    if (this.enquiryForm.description === '' && this.enquiryForm.description.trim() === '') {
      this.validDescription = true;
    } else {
      this.validDescription = false;
    }

    // if (this.enquiryForm.title === '' || this.enquiryForm.title.trim() === '' || this.enquiryForm.description === '' || this.enquiryForm.description.trim() === '') {
    if (this.enquiryForm.description === '' || this.enquiryForm.description.trim() === '') {
      this.validForm = false;
    } else {
      this.validForm = true;
    }
  }

  onBusinessClaim(content: any) {
    this.businessClaimForm.set('entity_id', <any>0);
    this.businessClaimForm.set('document', '');
    console.log('businessClaimForm', this.businessClaimForm);
    this.modalReference = this.modalService.open(content, { windowClass: 'enquiry_popup' });
    this.modalReference.result.then((result: any) => {
      console.log(`Closed with: ${result}`);
    }, (reason: any) => {
      console.log(reason)
    });
  }

  onFileChange(event: any) {
    const srcEle = event.srcElement;
    // console.log('srcEle', event)
    this.attachedFileName = srcEle.files[0].name;
    if (srcEle.files && srcEle.files[0]) {
      if (this.cS.checkValidDocSize(srcEle.files[0])) {
        this.businessClaimForm.append('entity_id', <any>this.business_id);
        this.businessClaimForm.append('document', srcEle.files[0]);
        this.isBusinessClaimValid = true;
        // this.readURL(srcEle.files[0]);
      } else {
        this.businessClaimForm.set('entity_id', <any>0);
        this.businessClaimForm.set('document', '');
        this.cS.showError("Please select file less than 2MB.");
        this.isBusinessClaimValid = false;
      }
    }
  }


  onBusinessClaimsubmit() {
    if (this.isBusinessClaimValid) {
      const apiUrl = environment.RYEC_API_URL + 'claimEntity';
      this.httpS.postUpload(apiUrl, this.businessClaimForm).subscribe((res: any) => {
        if (res.status === 1) {
          this.cS.showSuccess(res.message);
          this.isBusinessAlreadyClaim = true;
        }
        else {
          this.cS.showError(res.message);
          this.isBusinessAlreadyClaim = false;
        }
        this.modalReference.close();
        this.isBusinessClaimValid = false;
      }, (err:any) => {
        if (err.error) {
          console.log(err.error);
        }
      });
    }
    else {
      this.cS.showError("Please select document file");
    }
  }

  /**
  * add or update owner data
  * @param ownerId
  */
  viewOwnerData(ownerId?: number) {
    localStorage.setItem('business_name', this.business_name);
    const owner_id = btoa(<any>ownerId);
    this.router.navigate(['/home/business-owner'], { queryParams: { owner_id: owner_id }, queryParamsHandling: 'merge' });
  }

  /**
   * get online added stores
   * @Yuvraj 30/11/2021
   */
  getAddedOnlineStores() {
    const postJson = { business_id: this.business_id };
    this.hS.getBusinessOnlineStores(postJson).subscribe((res: any) => {
      if (res.status == 1) {
        this.businessStoresList = res.data;
      }
      else {
        this.businessStoresList = [];
      }
    });
  }

  /**
   * on click store redirect to store url
   * @Yuvraj 01/12/2021
   */
  onStoreClick(url: string) {
    window.open(url, '_blank');
  }

  onTabClick(event: any) {
    let tabName: string = "";
    tabName = event.tab.textLabel;
    if (tabName == 'Post') {
      this.userId = this.businessUserId;
      this.isPostView = true;
    }
    else {
      this.isPostView = false;
    }
  }
}
