import { Component, OnInit, ElementRef, ViewChild, HostListener } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

import {
  BusinessListDetailsRes
} from 'src/app/shared/class/data.model';
import { FormBuilder, FormControl, FormGroup, FormArray, Validators } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OnlineStoresModel } from '../../../shared/model/online-stores.model';
import { MessageService } from 'src/app/shared/services/message.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { HomeService } from 'src/app/shared/services/home.service';
import Swal from 'sweetalert2';
import { base64ToFile } from 'ngx-image-cropper';


@Component({
  selector: 'ryec-business-profile',
  templateUrl: './business-profile.component.html',
  styleUrls: ['./business-profile.component.css']
})
export class BusinessProfileComponent implements OnInit {
  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    if (this.eRef.nativeElement.contains(event.target)) {
      console.log(event.srcElement.className);

      if (event.srcElement.className === "fa fa-list-ul side_menu_icon" || event.srcElement.className === "ng-untouched ng-pristine ng-valid") {
        this.isTimeZoneShow = false;
      } else if (event.srcElement.className === 'container' || event.srcElement.className === 'ng-pristine ng-valid ng-touched' || event.srcElement.className === '' || event.srcElement.className === 'ng-star-inserted') {
        this.isTimeZoneShow = false;
      } else {
        if (event.srcElement.className === 'profile_menu_icon') {
          this.isTimeZoneShow = false;
        }
        if (event.srcElement.className === 'city-search ng-untouched ng-pristine ng-valid' || event.srcElement.className === 'city-search ng-touched ng-dirty ng-valid') {
          this.isTimeZoneShow = true;
        }
      }

      if (event.srcElement.className !== 'dropbtn') {
        this.isTimeZoneShow = false;
      }
      if (event.srcElement.className === 'city-search ng-untouched ng-pristine ng-valid' || event.srcElement.className === 'city-search ng-touched ng-dirty ng-valid') {
        this.isTimeZoneShow = true;
      }
    } else {
      this.isTimeZoneShow = false;
    }
  }
  businessDetail: any ;
  business_slug = '';
  business_id = 0;
  business_name: any;
  ind:any;
  addBusinessForm: any;
  activityEditFlag: number = -1;
  businessActivityArray: Array<{ id: 0, activity_title: string, operation?: string }> = [];
  businessImagesFormData: FormData = new FormData();
  base64BusinessImages: any=[];
  addProductFlag = false;
  addProductForm: FormGroup;
  productImagesFormData: FormData = new FormData();
  addServiceFlag = false;
  isAddNewStore = false;
  addServiceForm: FormGroup;
  serviceImagesFormData: FormData = new FormData();
  base64ProductImages: Array<{}> = [];
  base64ServiceLogo: any;
  business_address_auto: string = "";
  business_category: Array<{ id: number, category: string }> = [];
  selectedBusinessCategoryLists: Array<{ id: number, category: string }> = [];
  selectedName: Array<{}> = [];
  finalCategory: Array<{}> = [];
  mainCategory: any;
  subCategory: Array<{}> = [];
  mainCategorySelected = {};
  categoryMetaTag = [];
  metaTags: Array<string> = [];
  filteredMeatTags:any;
  categoryIds: any;
  category_name = '';
  deletedBusinessActivity: Array<{ id: 0, activity_title: string, operation?: string }> = [];
  @ViewChild('userProfile')
  userProfileRef!: ElementRef;
  @ViewChild('businessLogo')
  businessLogoRef!: ElementRef;
  showExtraFields = false;
  productImagesArr:any;
  product_id = 0;
  service_id = 0;
  serviceIndex = 0;
  timezoneArr: any;
  timezone = 'UTC +05:30 - Asia/Kolkata';
  addressDetail = [];
  countries :any;
  states = [];
  cities = [];
  isShow = false;
  isAgent: any;
  showAgent = false;
  onlineStoresList: any[] = [];
  businessStoresList: any[] = [];
  onlineStoreModel: any = new OnlineStoresModel();
  subCategoryLists: any[] = [];
  tempSelectedCategoryIds: any[] = [];
  selected: any[] = [];
  finalSelectedCategoryIds: any[] = [];
  appName = environment.APP_NAME;

  fields: any = {
    isRequired: true,
    business_working_hours:   {
      options: [
        {
          day: 'Monday',
          isOpen: true,
          startTime: "9:00",
          startMeridies: "AM",
          endTime: "6:00",
          endMeridies: "PM"
        },
        {
          day: 'Tuesday',
          isOpen: true,
          startTime: "9:00",
          startMeridies: "AM",
          endTime: "6:00",
          endMeridies: "PM"
        },
        {
          day: 'Wednesday',
          isOpen: true,
          startTime: "9:00",
          startMeridies: "AM",
          endTime: "6:00",
          endMeridies: "PM"
        },
        {
          day: 'Thursday',
          isOpen: true,
          startTime: "9:00",
          startMeridies: "AM",
          endTime: "6:00",
          endMeridies: "PM"
        },
        {
          day: 'Friday',
          isOpen: true,
          startTime: "9:00",
          startMeridies: "AM",
          endTime: "6:00",
          endMeridies: "PM"
        },
        {
          day: 'Saturday',
          isOpen: false,
          startTime: "9:00",
          startMeridies: "AM",
          endTime: "6:00",
          endMeridies: "PM"
        },
        {
          day: 'Sunday',
          isOpen: false,
          startTime: "9:00",
          startMeridies: "AM",
          endTime: "6:00",
          endMeridies: "PM"
        }
      ]
    }
  };
  @ViewChild('productImage')
  productImageRef!: ElementRef;
  @ViewChild('serviceImage')
  serviceImageRef!: ElementRef;
  myControl: FormControl = new FormControl();

  selectedTags: Array<{}> = [];
  timeZone = 'Asia/Kolkata';
  isTimeZoneShow = false;
  allTimezoneArr: any;
  businessProfilePic = '';
  business_logo = '';
  imageChangedEvent: any = '';
  croppedImage: any = '';
  modalReference: any;
  storeDisabled: any[] = [];
  businessCategory: any[] = [];
  locationsList: any[] = [];
  assetTypeFieldsModel: any = {};
  isLocationNotFound: boolean = false;

  valueChanged(newVal: string) {
    newVal = this.addBusinessForm.controls.tag.value;
    // console.log(this.addBusinessForm.controls.tag.value);
    if (this.metaTags.indexOf(newVal) == -1) {
      if (this.selectedTags.indexOf(newVal) == -1) {
        if (this.selectedTags.length < 51) {
          this.selectedTags.push(newVal);
          this.addBusinessForm.controls.tag.setValue(null);
        } else {
          this.cS.showError('you can maximum enter 50 meta tags');
        }
      }
      if (this.selectedTags.length < 51) {
        this.metaTags.splice(this.metaTags.indexOf(newVal), 1);
      }
    }
  }

  constructor(
    private activeRoute: ActivatedRoute,
    private hS: HomeService,
    private fb: FormBuilder,
    public router: Router,
    public cS: CommonService,
    public httpService: HttpService,
    private location: Location,
    private eRef: ElementRef,
    private mS: MessageService,
    private modalService: NgbModal) {

    const EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;

    this.addBusinessForm = this.fb.group({
      id: [this.business_id],
      user_id: [localStorage.getItem('member_id')],
      parent_category: [],
      name: [null, Validators.compose([Validators.required])],
      description: [],
      phone: [null],
      country_code: ['+91'],
      mobile: [null, Validators.compose([Validators.required,
      Validators.maxLength(13),
      Validators.minLength(6),
      Validators.pattern('^[0-9]+$')])],
      address: [null],
      latitude: [],
      longitude: [],
      email_id: [null, Validators.compose([Validators.pattern(EMAIL_REGEXP)])],
      establishment_year: [],
      website_url: [],
      facebook_url: [],
      twitter_url: [],
      linkedin_url: [],
      instagram_url: [],
      timezone: ['Asia/Kolkata'],
      tag: [],
      street_address: [null],
      locality: [null],
      country: [null],
      state: [null],
      city: [null],
      taluka: [null],
      district: [null],
      pincode: [null],
      location_id: [null],
      timezoneSearch: [null],

      metatags: [],
      category_id: [null],

      business_activities: [],
      business_working_hours: this.fb.group({
        options: this.fb.array([])
      }),
      working_hours: []
    });

    this.addProductForm = this.fb.group({
      name: [null, Validators.compose([Validators.required])],
      description: [null, Validators.compose([Validators.required])],
      cost: [null]
    });

    this.addServiceForm = this.fb.group({
      name: [null, Validators.compose([Validators.required])],
      description: [null, Validators.compose([Validators.required])],
      cost: [null]
    });

    this.businessDetail = {
      status: 0,
      message: ''
    };

    localStorage.removeItem('business_name');

    /*
    *get url slug
    */
    this.activeRoute.params.subscribe(data => {
      this.business_slug = data.slug;
      if (this.business_slug !== 'add-new') {
        this.isAgent = localStorage.getItem('isAgent');
        if (this.isAgent === 'A') {
          this.isShow = true;
          this.showExtraFields = true;
          this.getBusinessDetailBySlug(true);
        } else {
          const businessData = this.cS.getBusinessDetail();
          if (businessData) {
            this.business_name = businessData.business_name;
            if (businessData.business_slug == this.business_slug) {
              this.isShow = true;
              this.showExtraFields = true;
              this.getBusinessDetailBySlug(true);
            } else {
              this.router.navigateByUrl('/home');
            }
          }
        }

      } else {
        this.resetForm();
        if (localStorage.getItem('member_id') == 'undefined' || localStorage.getItem('member_id') == null || localStorage.getItem('member_id') == undefined || localStorage.getItem('member_id') == '') {
          this.router.navigateByUrl('/home');
        }
        this.patch();
      }
    });
    this.getTimezone();
  }
  changeStartMeridies(meridies: string, index: number, isStartOrEnd: string) {
    const meri = meridies === 'AM' ? 'PM' : 'AM';
    this.fields.business_working_hours.options[index][isStartOrEnd] = meri;
    // const control = this.addBusinessForm.controls.business_working_hours['controls']['options'];
    // control.value[index][isStartOrEnd] = meri;
  }

  placeChanged(place: any) {
    console.log(place);
    this.isShow = true;
    this.addBusinessForm.controls.latitude.setValue(place.geometry.location.lat());
    this.addBusinessForm.controls.longitude.setValue(place.geometry.location.lng());
    this.business_address_auto = place['formatted_address'];
  }

  addressChanged() {
    this.isShow = true;
  }

  checkboxChange(index: number) {
    this.fields.business_working_hours.options[index]['isOpen'] = !this.fields.business_working_hours.options[index]['isOpen'];
  }

  patch() {
    const control = <FormArray>this.addBusinessForm.controls.business_working_hours['controls']['options'];
    this.fields.business_working_hours.options.forEach((x:any )=> {
      control.push(this.patchValues(x.day, x.isOpen, x.startTime, x.endTime, x.startMeridies, x.endMeridies))
    });
  }

  patchValues(day: string, isOpen: boolean, startTime: string, endTime: string, startMeridies: string, endMeridies: string) {
    return this.fb.group({
      day: [day],
      isOpen: [isOpen],
      startTime: [startTime],
      endTime: [endTime],
      startMeridies: [startMeridies],
      endMeridies: [endMeridies]
    })
  }

  addActivity() {
    if (this.addBusinessForm.value['business_activities'] !== '') {
      if (this.activityEditFlag === -1) {
        this.businessActivityArray.push({ id: 0, activity_title: this.addBusinessForm.value['business_activities'], operation: "add" });
      } else {
        this.businessActivityArray[this.activityEditFlag]['activity_title'] = this.addBusinessForm.value['business_activities'];
        this.activityEditFlag = -1;
      }
      this.addBusinessForm.controls.business_activities.setValue('');
    }
  }

  editActivity(index: number) {
    this.addBusinessForm.controls.business_activities.setValue(this.businessActivityArray[index]['activity_title']);
    this.activityEditFlag = index;
  }

  deleteActivity(index: number) {
    this.businessActivityArray[index]['operation'] = 'delete';
    this.deletedBusinessActivity.push(this.businessActivityArray[index]);
    this.businessActivityArray.splice(index, 1);
  }

  ngOnInit() {
    this.cS.scrollTop();
    this.getAddressDetail();
    this.getOnlineStoresLists();
  }

  /**
  * Get Address Detail from entered details
  *@returns void
  */
  getAddressDetail() {
    this.hS.getAddressMaster().subscribe((res: any) => {
      this.countries = res.data.countries;
      this.cities = res.data.cities;
      this.states = res.data.states;
    }, err => {
      console.log(err);
    });
  }

  /**
  * Get Business Detail by business slug
  *@returns void
  */
  getBusinessDetailBySlug(isFirstLoad: boolean = false): void {

    this.business_category = [];

    const postJson = { business_slug: this.business_slug };
    let categoryId: number = 0;

    this.hS.getBusinessDetailBySlug(postJson).subscribe((res: any) => {
      if (res.data) {
        if (isFirstLoad) {
          if (res.data['hoursOperation'].length > 0) {
            for (let i = 0; i < 7; i++) {
              if (res.data['hoursOperation'][i]['open_close'] !== 'Closed') {
                const SD = res.data['hoursOperation'][i]['start_time'].split(' ');
                const ED = res.data['hoursOperation'][i]['end_time'].split(' ');

                this.fields.business_working_hours.options[i] = {
                  day: res.data['hoursOperation'][i]['name'],
                  isOpen: res.data['hoursOperation'][i]['open_close'] === 'Open' ? true : false,
                  startTime: SD[0],
                  startMeridies: SD[1].toUpperCase(),
                  endTime: ED[0],
                  endMeridies: ED[1].toUpperCase()
                };
              } else {
                this.fields.business_working_hours.options[i] = {
                  day: res.data['hoursOperation'][i]['name'],
                  isOpen: res.data['hoursOperation'][i]['open_close'] == "Open" ? true : false,
                  startTime: '9:00',
                  startMeridies: 'AM',
                  endTime: '6:00',
                  endMeridies: 'PM'
                };
              }
            }
          }
          this.patch();
        }

        if (res['data']['metatags'] !== '') {
          this.selectedTags = res['data']['metatags'].split(',');
        }

        this.businessDetail = res;
        this.business_id = res.data.id;
        this.business_name = res.data.name;
        this.businessCategory = res.data.category_hierarchy;
        if (res.data.parent_categories.length > 0) {
          categoryId = res.data.parent_categories[0].category_id;
        }
        this.getSubAssetTypeFields(categoryId, res.data.asset_type_id);
        // let category = '';
        // let id = 0;
        this.getAddedOnlineStores();
        this.getAllCategory();
        this.getSelectedChildCategory(this.businessCategory);
        // if (res.data.category_hierarchy.length > 0) {
        //   for (let x in res.data.category_hierarchy) {
        //     // for (let i in res.data.category_hierarchy[x]) {
        //     //     if (parseInt(i) != 0) {
        //     //         category += ' >> ' + res.data.category_hierarchy[x][i].name;
        //     //     } else {
        //     //         category = res.data.category_hierarchy[x][i].name;
        //     //     }
        //     //     if (parseInt(i) == (res.data.category_hierarchy[x].length - 1)) {
        //     //         id = res.data.category_hierarchy[x][i].id;
        //     //     }
        //     // }
        //     if (parseInt(x) != 0) {
        //       category += ' >> ' + res.data.category_hierarchy[x].name;
        //     } else {
        //       category = res.data.category_hierarchy[x].name;
        //     }
        //     if (parseInt(x) == (res.data.category_hierarchy[x].length - 1)) {
        //       id = res.data.category_hierarchy[x].id;
        //     }
        //     this.business_category.push(
        //       {
        //         category: category,
        //         id: id
        //       }
        //     );
        //   }
        // }
        // this.getCategoryMetatags();
        this.addBusinessForm.controls.id.setValue(res.data.id);
        this.addBusinessForm.controls.user_id.setValue(res.data.user_id);
        this.addBusinessForm.controls.parent_category.setValue(res.data.parent_category_id);
        this.addBusinessForm.controls.latitude.setValue(res.data.latitude);
        this.addBusinessForm.controls.longitude.setValue(res.data.longitude);
        this.addBusinessForm.controls.name.setValue(res.data.name);
        if (res.data.country_code) {
          this.addBusinessForm.controls.country_code.setValue(res.data.country_code);
        }
        this.addBusinessForm.controls.mobile.setValue(res.data.mobile);
        this.addBusinessForm.controls.phone.setValue(res.data.phone);
        this.addBusinessForm.controls.email_id.setValue(res.data.email);
        this.addBusinessForm.controls.address.setValue(res.data.full_address);
        this.addBusinessForm.controls.street_address.setValue(res.data.street_address);
        this.addBusinessForm.controls.locality.setValue(res.data.locality);
        // this.addBusinessForm.controls.country.setValue(res.data.country);
        // this.addBusinessForm.controls.state.setValue(res.data.state);
        // this.addBusinessForm.controls.city.setValue(res.data.city);
        this.addBusinessForm.controls.location_id.setValue(res.data.location_id);
        this.addBusinessForm.controls.district.setValue(res.data.district);
        this.addBusinessForm.controls.district.setValue(res.data.district);
        if(res.data.pincode){
          this.addBusinessForm.controls.pincode.setValue(res.data.pincode);
          this.locationSearch(res.data.pincode);
        }

        // this.addBusinessForm.controls.description.setValue(res.data.descriptions);
        this.addBusinessForm.controls.description.setValue(new DOMParser().parseFromString(res.data.descriptions, "text/html").documentElement.textContent);
        this.addBusinessForm.controls.establishment_year.setValue(res.data.year_of_establishment);
        this.addBusinessForm.controls.website_url.setValue(res.data.website);
        this.addBusinessForm.controls.facebook_url.setValue(res.data.social_profiles.facebook_url);
        this.addBusinessForm.controls.twitter_url.setValue(res.data.social_profiles.twitter_url);
        this.addBusinessForm.controls.linkedin_url.setValue(res.data.social_profiles.linkedin_url);
        this.addBusinessForm.controls.instagram_url.setValue(res.data.social_profiles.instagram_url);
        this.business_logo = res.data.business_logo;
        if (res.data.timezone !== '') {
          this.addBusinessForm.controls.timezone.setValue(res.data.timezone);
          this.timezoneArr.filter((item: any) => {
            const search = item['name'];
            if (search.toLowerCase().indexOf(res.data.timezone.toLowerCase()) > -1) {
              this.timezone = item['value'] + ' - ' + item['name'];
            }
          });
        }

        for (let i = 0; i < res.data.business_activities.length; i++) {
          res.data.business_activities[i]['operation'] = 'update';
        }
        this.businessActivityArray = res.data.business_activities;
        this.mS.setBusinessName(res.data.name);
        // localStorage.setItem('business_name', res.data.name);
        // this.cS.businessModel.business_name = res.data.name;
        // this.cS.setBusinessRegiFlag();
      }
    }, err => {
      console.log(err);
    });
  }

  getSubAssetTypeFields(categoryId: number, assetTypeId: number) {
    const postJson = {
      category_id: categoryId,
      asset_type_id: assetTypeId
    };

    this.hS.getSubAssetTypeFields(postJson).subscribe((res: any) => {
      if (res.data) {
        this.assetTypeFieldsModel = res.data;
      }
    }, err => {
      console.log(err);
    });
  }

  triggerUpload() {
    if (this.businessDetail.data) {
      if ((this.base64BusinessImages + this.businessDetail.data.business_images) == 9) {
        this.cS.showError('You can only upload 9 images.');
      } else {
        this.userProfileRef.nativeElement.click();
      }
    } else {
      if (this.base64BusinessImages === 9) {
        this.cS.showError('You can only upload 9 images.');
      } else {
        this.userProfileRef.nativeElement.click();
      }
    }
  }

  readURL(input: any) {
    const self = this;
    if (input.files && input.files[0]) {
      const reader = new FileReader();
      reader.onload = function (e:any) {
        self.base64BusinessImages.push(e['target']['result']);
      };

      reader.readAsDataURL(input.files[0]);
    }
  }

  uploadProfilePic(event: any) {
    const srcEle = event.srcElement;
    if (srcEle.files && srcEle.files[0]) {
      if (this.cS.checkValidImage(srcEle.files[0])) {
        this.businessImagesFormData.append('business_images[]', srcEle.files[0]);
        this.readURL(srcEle);
      } else {
        this.cS.showError('Please select file less than 5MB.');
      }
    }
  }

  uploadImage() {
    const apiUrl = environment.RYEC_API_URL + 'saveBusinessImages';
    this.businessImagesFormData.append('business_id', <any>this.business_id);
    this.httpService.postUpload(apiUrl, this.businessImagesFormData).subscribe((res: any) => {
      if (res['status'] === 1) {
        this.cS.showSuccess(res['message']);
      } else {
        this.cS.showError(res['message']);
      }

    });
  }

  deleteBusinessImage(index: any, id?: any) {
    if (id === undefined) {4
      this.base64BusinessImages.splice(index, 1);
    } else {
      const obj = {
        id: id,
        business_id: this.business_id
      };

      const apiUrl = environment.RYEC_API_URL + 'deleteBusinessImage';
      this.httpService.post(apiUrl, obj).subscribe((res: any) => {
        console.log(res);
        if (this.businessDetail.data) {
          this.businessDetail.data.business_images.splice(index, 1);
        }
      });
    }
  }

  changeCategory(category_id: any, flag?: any) {
    if (!flag) {
      this.business_category = [];
    }
    this.selectedName = [];
    // this.mainCategory.forEach(res => {
    //   if (category_id === res['category_id']) {
    //     console.log(res['category_id']);
    //     this.mainCategorySelected = res;
    //     console.log('mainCategorySelected', this.mainCategorySelected);
    //     this.getSubcategory(res);
    //   }
    // });

    // this.mainCategory.forEach((element: any) => {
    //   if (element.category_id) {
    //     if (element.category_id == category_id) {
    //       this.mainCategorySelected = element;
    //       this.getSubcategory(element);
    //     }
    //   }
    // });

    this.getSubCategoryLists(category_id)
  }

  saveBusinessCategory() {
    const postJson = {
      business_id: this.business_id,
      sub_category_ids: this.finalSelectedCategoryIds.toString()
    };

    const apiUrl = environment.RYEC_API_URL + 'saveBusinessCategories';
    this.httpService.post(apiUrl, postJson).subscribe((res: any) => {
      if (res.status === 1) {
        this.cS.showSuccess(res.message);
      }
      else {
        this.cS.showError(res.message);
      }
      this.tempSelectedCategoryIds = [];
      this.finalSelectedCategoryIds = [];
      this.selectedBusinessCategoryLists = [];
      this.modalReference.close();
      this.getBusinessDetailBySlug(false);
    }, err => {
      if (err.error) {
        console.log(err.error);
      }
    });

  }

  onCategoryCancel() {
    if (this.tempSelectedCategoryIds.length || this.finalSelectedCategoryIds.length) {
      this.tempSelectedCategoryIds = [];
      this.finalSelectedCategoryIds = [];
    }
    this.modalReference.close();
  }

  /**
   * get added business category and push them in tempSelectedCategoryIds
   * @Yuvraj 21/12/2021
   */
  getSelectedChildCategory(businessCategory: any) {
    if (businessCategory.length) {
      businessCategory.forEach((element: any) => {
        element.sub_category.forEach((element1: any) => {
          this.tempSelectedCategoryIds.push(element1.id);
          this.selectedBusinessCategoryLists.push({ id: element1.id, category: element1.name });
        });
      });

      this.getCategoryMetatags();
    }
  }

  /**
   * get categoryLists by parent category id
   * @Yuvraj 09/12/21
   */
  getSubCategoryLists(parentCategoryId: number): void {
    const postJson = {
      category_id: parentCategoryId,
      grandChilds: true
    };

    this.hS.getSubCategoryListByParentCategoryId(postJson).subscribe((res: any) => {
      if (res.status === 1) {
        this.subCategoryLists = res.data;
      }
      else {
        this.subCategoryLists = [];
      }
    }, err => {
      this.subCategoryLists = [];
      console.log(err);
    });
  }

  // when checkbox change, add/remove the item from the array
  onChange(checked: any, item: any) {
    if (checked) {
      this.tempSelectedCategoryIds.push(item.id);
      this.selectedBusinessCategoryLists.push({ id: item.id, category: item.name });
    } else {
      this.tempSelectedCategoryIds.splice(this.tempSelectedCategoryIds.indexOf(item.id), 1);
      this.selectedBusinessCategoryLists.splice(this.selectedBusinessCategoryLists.indexOf(item.id), 1);
    }

    if (this.tempSelectedCategoryIds.length) {
      this.finalSelectedCategoryIds = this.tempSelectedCategoryIds;
    }
  }

  // check if the item are selected
  checked(item: any) {
    if (this.tempSelectedCategoryIds.indexOf(item.id) != -1) {
      return true;
    }
    else {
      return false;
    }
  }

  removeCategory(index: number) {
    this.business_category.splice(index, 1);
    this.getCategoryMetatags();
  }


  chooseCategory(subCat: any) {
    this.selectedName.push(subCat['name']);

    let str = '';

    this.selectedName.forEach(res => {
      str += res + ' >> ';
    });

    this.selectedName = [];
    this.subCategory = [];
    str = str.substr(0, str.length - 3);
    if (this.business_category.length > 0) {
      for (let x in this.business_category) {
        if (this.business_category[x]['id'] !== subCat['category_id']) {
          this.business_category.push({ id: subCat['category_id'], category: str });
        }
      }
    } else {
      this.business_category.push({ id: subCat['category_id'], category: str });
    }
    // this.changeCategory(<any>this.category_name, true);
  }

  getSubcategory(obj: any) {
    const paramObj = {
      id: obj['category_id'],
      category_slug: obj['category_slug']
    };

    this.selectedName.push(obj['name']);

    const apiUrl = environment.RYEC_API_URL + 'getSubCategory';
    this.httpService.post(apiUrl, paramObj).subscribe((res: any) => {
      if (res['status'] === 1) {
        if (res['data']['sub_category'].length === 0) {
          this.cS.showError('No category found.');
        }

        this.subCategory = res['data']['sub_category'];
      }
    });
  }

  editBusinesscategory(content: any) {
    console.log(content);
    // swal({
    //   title: 'You can not change Business Category without approval.',
    //   text: 'Contact support at info@myrajasthanclub.com or call on 9166319568.',
    //   type: 'warning'
    // });
    this.modalReference = this.modalService.open(content, { windowClass: 'edit_category_popup' });
    this.modalReference.result.then((result: any) => {
      console.log(`Closed with: ${result}`);
    }, (reason: any) => {
      console.log(reason);
    });
  }

  /**
  * add or update owner data
  * @param ownerId
  */
  addExitOwnerData(ownerId?: number) {
    const business_id = btoa(<any>this.business_id);
    let owner_id = btoa(<any>0);
    if (ownerId !== undefined) {
      owner_id = btoa(<any>ownerId)
    }
    localStorage.setItem('business_name', this.business_name);
    this.router.navigate(['/user/add-owner'], { queryParams: { business_id: business_id, owner_id: owner_id }, queryParamsHandling: 'merge' })
  }

  /**
  * delete owner data
  * @param ownerId
  */
  deleteOwnerData(ownerId: number, index: number) {
    let name = 'this';
    if (this.businessDetail.data) {
      name = this.businessDetail.data.owners[index]['name'];
    }
 Swal.fire
    ({
      title: 'Do you really want to delete Owner?',
      text: name,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      showCancelButton: true,
      showCloseButton: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        const apiUrl = environment.RYEC_API_URL + 'deleteOwner';
        const postObj = {
          owner_id: ownerId
        };

        this.httpService.post(apiUrl, postObj).subscribe((res:any) => {
          if (res.status === 1) {
            this.cS.showSuccess(res.message);
            if (this.businessDetail.data) {
              this.businessDetail.data.owners.splice(index, 1);
            }
          }
        }, err => {
          console.log(err);
        });
      }
      }
  )}

  /**
  * add new product
  */
  addNewProduct() {
    this.addProductFlag = !this.addProductFlag;
    this.addProductForm.reset();
    this.base64ProductImages = [];
    this.productImagesArr = [];
  }

  hideProductSection() {
    this.addProductFlag = !this.addProductFlag;
    this.addProductForm.reset();
    this.base64ProductImages = [];
    this.productImagesArr = [];
  }

  saveProduct() {
    if (this.addProductForm.valid) {
      if (this.base64ProductImages.length != 0 || this.productImagesArr.length != 0) {
        const apiUrl = environment.RYEC_API_URL + 'saveProduct';
        this.productImagesFormData.append('id', <any>this.product_id);
        this.productImagesFormData.append('business_id', <any>this.business_id);
        this.productImagesFormData.append('name', this.addProductForm.controls.name.value);
        this.productImagesFormData.append('description', this.addProductForm.controls.description.value);
        this.productImagesFormData.append('cost', this.addProductForm.controls.cost.value);
        this.httpService.postUpload(apiUrl, this.productImagesFormData).subscribe((res: any) => {
          if (res['status'] === 1) {
            this.cS.showSuccess(res['message']);
            this.productImagesArr = [];
            this.base64ProductImages = [];
            if (this.product_id === 0) {
              if (this.businessDetail.data) {
                this.businessDetail.data.products.push({
                  id: res.data.id,
                  name: res.data.name,
                  image_url: res.data.product_images[0].image_thumbnail
                })
              }
            }
            this.productImagesFormData = new FormData();
            this.addProductForm.reset();
            this.addProductFlag = !this.addProductFlag;
            this.getBusinessDetailBySlug(false);
          } else {
            this.cS.showError(res['message']);
          }
        }, err => {
          console.log(err);
        });
      } else {
        this.cS.showError('Please select one product image')
      }
    }
  }

  triggerProductImage() {
    console.log(this.base64ProductImages.length);
    if ((this.base64ProductImages.length + this.productImagesArr.length) < 5) {
      this.productImageRef.nativeElement.click();
    } else {
      this.cS.showError('You can only upload 5 images in each product.');
    }
  }

  uploadProductPic(event: any) {
    const srcEle = event.srcElement;
    if (srcEle.files && srcEle.files[0]) {
      if (this.cS.checkValidImage(srcEle.files[0])) {
        this.productImagesFormData.append('product_images[]', srcEle.files[0]);
        this.readProductURL(srcEle);
      } else {
        this.cS.showError('Please select file less than 5MB.');
      }
    }
  }

  readProductURL(input: any) {
    const self = this;
    if (input.files && input.files[0]) {
      const reader = new FileReader();
      reader.onload = function (e:any) {
        self.base64ProductImages.push(e['target']['result']);
      };

      reader.readAsDataURL(input.files[0]);
    }
  }

  addNewService() {
    this.addServiceFlag = !this.addServiceFlag;
    this.addServiceForm.reset();
    this.base64ServiceLogo = '';
  }

  hideServiceSection() {
    this.addServiceFlag = !this.addServiceFlag;
    this.addServiceForm.reset();
    this.base64ServiceLogo = '';
  }

  saveService() {
    if (this.addServiceForm.valid) {
      if (this.base64ServiceLogo !== '') {
        const apiUrl = environment.RYEC_API_URL + 'saveService';
        this.serviceImagesFormData.append('id', <any>this.service_id);
        this.serviceImagesFormData.append('business_id', <any>this.business_id);
        this.serviceImagesFormData.append('name', this.addServiceForm.controls.name.value);
        this.serviceImagesFormData.append('description', this.addServiceForm.controls.description.value);
        this.serviceImagesFormData.append('cost', this.addServiceForm.controls.cost.value);
        this.httpService.postUpload(apiUrl, this.serviceImagesFormData).subscribe((res: any) => {
          if (res['status'] === 1) {
            this.cS.showSuccess(res['message']);
            if (this.service_id === 0) {
              if (this.businessDetail.data) {
                this.businessDetail.data.services.push({
                  id: res.data.id,
                  name: res.data.name,
                  image_url: res.data.thumb_logo
                });
              }
            } else {
              if (this.businessDetail.data) {
                this.businessDetail.data.services[this.serviceIndex].id = res.data.id;
                this.businessDetail.data.services[this.serviceIndex].name = res.data.name;
                this.businessDetail.data.services[this.serviceIndex].image_url = res.data.thumb_logo;
              }
            }
            this.serviceImagesFormData = new FormData();
            this.addServiceForm.reset();
            this.base64ServiceLogo = '';
            this.addServiceFlag = !this.addServiceFlag;
          } else {
            this.cS.showError(res['message']);
          }
        });
      } else {
        this.cS.showError('Please select service image')
      }
    }
  }

  triggerServiceImage() {
    this.serviceImageRef.nativeElement.click();
  }

  uploadServicePic(event: any) {
    const srcEle = event.srcElement;
    if (srcEle.files && srcEle.files[0]) {
      if (this.cS.checkValidImage(srcEle.files[0])) {
        this.serviceImagesFormData.append('logo', srcEle.files[0]);
        this.readServiceURL(srcEle);
      } else {
        this.cS.showError('Please select file less than 5MB.');
      }
    }
  }

  readServiceURL(input: any) {
    const self = this;
    if (input.files && input.files[0]) {
      const reader = new FileReader();
      reader.onload = function (e:any) {
        self.base64ServiceLogo = e['target']['result'];
      };

      reader.readAsDataURL(input.files[0]);
    }
  }

  removeService(service_id: number, index: number) {
    let name = 'this';
    if (this.businessDetail.data) {
      name = this.businessDetail.data.services[index]['name'];
    }

    Swal.fire({
      title: 'Are you sure?',
      text: 'To delete ' + name + ' service.',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      showCancelButton: true,
      showCloseButton: true,
      reverseButtons: true,

    }).then((result) => {
      if (result.value) {
        const apiUrl = environment.RYEC_API_URL + 'removeService';
        const postJson = { service_id: service_id };

        this.httpService.post(apiUrl, postJson).subscribe((res:any) => {
          if (res.status === 1) {
            this.cS.showSuccess(res.message);
            if (this.businessDetail.data) {
              this.businessDetail.data.services.splice(index, 1);
            }
          }
        }, err => {
          console.log(err);
        });
      }
      }
  )}

  removeProduct(product_id: number, index: number) {
    let name = 'this';
    if (this.businessDetail.data) {
      name = this.businessDetail.data.products[index]['name'];
    }

    Swal.fire({
      title: 'Are you sure?',
      text: 'To delete ' + name + ' product.',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      showCancelButton: true,
      showCloseButton: true,
      reverseButtons: true,

    }).then((result) => {
      if (result.value) {
        const apiUrl = environment.RYEC_API_URL + 'removeProduct';
        const postJson = { product_id: product_id };

        this.httpService.post(apiUrl, postJson).subscribe((res:any) => {
          if (res.status === 1) {
            this.cS.showSuccess(res.message);
            if (this.businessDetail.data) {
              this.businessDetail.data.products.splice(index, 1);
            }
            this.getBusinessDetailBySlug(false);
          }
        }, err => {
          console.log(err);
        });
      }
      }
  )}

  editProduct(product_id: number, index: number) {
    console.log(index);
    this.product_id = product_id;
    this.productImagesArr = [];
    this.addProductFlag = true;
    const postJson = { product_id: product_id };
    this.hS.getProductDetailByProductId(postJson).subscribe((res: any) => {
      if (res.status == 1) {
        this.addProductForm.controls.name.setValue(res.data.name);
        this.addProductForm.controls.description.setValue(res.data.descriptions);
        this.addProductForm.controls.cost.setValue(res.data.cost);
        for (const x in res.data.product_images) {
          console.log(res.data.product_images[x]);
          this.productImagesArr.push(res.data.product_images[x]);
        }
      }
    }, err => {
      console.log(err);
    });
  }

  editService(service_id: number, index: number) {
    this.serviceIndex = index;
    this.service_id = service_id;
    this.addServiceFlag = true;
    const postJson = { service_id: service_id };
    this.hS.getServiceDetailByServiceId(postJson).subscribe((res: any) => {
      if (res.status === 1) {
        this.addServiceForm.controls.name.setValue(res.data.name);
        this.addServiceForm.controls.description.setValue(res.data.descriptions);
        this.addServiceForm.controls.cost.setValue(res.data.cost);
        this.base64ServiceLogo = res.data.thumb_logo;
      }
    }, err => {
      console.log(err);
    });
  }

  getAllCategory() {
    this.hS.getMenuCategoryListing().subscribe(res => {
      this.mainCategory = <any>res.data;
    }, err => {
      console.log(err);
    });
  }


  getCategoryIds() {
    this.categoryIds = '';
    for (let x in this.business_category) {
      if (parseInt(x) !== 0) {
        this.categoryIds += ',' + this.business_category[x]['id'];
      } else {
        this.categoryIds = <any>this.business_category[x]['id'];
      }
    }
    this.addBusinessForm.controls.category_id.setValue(this.categoryIds);
  }

  getCategoryMetatags() {
    this.metaTags = [];
    // this.getCategoryIds();
    let categoryIds = this.tempSelectedCategoryIds.toString()
    const apiUrl = environment.RYEC_API_URL + 'getCategoryMetaTags';
    const postJson = { category_id: categoryIds };

    this.httpService.post(apiUrl, postJson).subscribe((res:any) => {
      if (res.status === 1) {
        for (let x in res.data) {
          if (this.selectedTags.indexOf(res.data[x]) === -1)
            this.metaTags.push(res.data[x]);
        }
      }
    }, err => {
      console.log(err);
    });
  }

  onSubmitEditBusiness() {

    if (this.addBusinessForm.valid) {
      for (let i = 0; i < this.deletedBusinessActivity.length; i++) {
        this.businessActivityArray.push(this.deletedBusinessActivity[i]);
      }

      this.addBusinessForm.controls.business_activities.setValue(this.businessActivityArray);
      // this.addBusinessForm.controls.category_id.setValue(this.categoryIds);
      this.addBusinessForm.removeControl('category_id');
      this.addBusinessForm.controls.metatags.setValue(this.selectedTags.toString());

      const hoursUpdated = this.addBusinessForm.controls.business_working_hours['controls']['options']['controls'];

      console.log(hoursUpdated[0].value);
      const filedObj = this.fields.business_working_hours.options;

      const hours = {
        'mon_open_close': hoursUpdated[0].value.isOpen === true ? 1 : 0,
        'mon_start_time': hoursUpdated[0].value.startTime,
        'mon_start_time_am_pm': filedObj[0].startMeridies,
        'mon_end_time': hoursUpdated[0].value.endTime,
        'mon_end_time_am_pm': filedObj[0].endMeridies,

        'tue_open_close': hoursUpdated[1].value.isOpen === true ? 1 : 0,
        'tue_start_time': hoursUpdated[1].value.startTime,
        'tue_start_time_am_pm': filedObj[1].startMeridies,
        'tue_end_time': hoursUpdated[1].value.endTime,
        'tue_end_time_am_pm': filedObj[1].endMeridies,

        'wed_open_close': hoursUpdated[2].value.isOpen === true ? 1 : 0,
        'wed_start_time': hoursUpdated[2].value.startTime,
        'wed_start_time_am_pm': filedObj[2].startMeridies,
        'wed_end_time': hoursUpdated[2].value.endTime,
        'wed_end_time_am_pm': filedObj[2].endMeridies,

        'thu_open_close': hoursUpdated[3].value.isOpen === true ? 1 : 0,
        'thu_start_time': hoursUpdated[3].value.startTime,
        'thu_start_time_am_pm': filedObj[3].startMeridies,
        'thu_end_time': hoursUpdated[3].value.endTime,
        'thu_end_time_am_pm': filedObj[3].endMeridies,

        'fri_open_close': hoursUpdated[4].value.isOpen === true ? 1 : 0,
        'fri_start_time': hoursUpdated[4].value.startTime,
        'fri_start_time_am_pm': filedObj[4].startMeridies,
        'fri_end_time': hoursUpdated[4].value.endTime,
        'fri_end_time_am_pm': filedObj[4].endMeridies,

        'sat_open_close': hoursUpdated[5].value.isOpen === true ? 1 : 0,
        'sat_start_time': hoursUpdated[5].value.startTime,
        'sat_start_time_am_pm': filedObj[5].startMeridies,
        'sat_end_time': hoursUpdated[5].value.endTime,
        'sat_end_time_am_pm': filedObj[5].endMeridies,

        'sun_open_close': hoursUpdated[6].value.isOpen === true ? 1 : 0,
        'sun_start_time': hoursUpdated[6].value.startTime,
        'sun_start_time_am_pm': filedObj[6].startMeridies,
        'sun_end_time': hoursUpdated[6].value.endTime,
        'sun_end_time_am_pm': filedObj[6].endMeridies,

        'timezone': this.addBusinessForm.controls.timezone.value
      };

      console.log(hours);


      //            this.addBusinessForm.controls.working_hours.setValue(hours);
      const apiUrl = environment.RYEC_API_URL + 'saveBusiness';

      //const splitVal = this.addBusinessForm.value.mobile.split('-');
      //this.addBusinessForm.value.mobile = splitVal[1];
      //this.addBusinessForm.value.country_code = splitVal[0];

      const paramObj = this.addBusinessForm.value;
      paramObj['working_hours'] = hours;

      if (this.business_address_auto !== '') {
        paramObj['address'] = this.business_address_auto;
      }

      this.httpService.post(apiUrl, paramObj).subscribe((res:any )=> {
        if (res.status === 1) {
          this.addBusinessForm.controls.business_activities.setValue('');
          this.cS.showSuccess(res.message);
          if (this.base64BusinessImages.length > 0 || this.businessProfilePic !== '') {
            this.uploadImage();
          }
          this.business_id = res.data.id;
          this.business_slug = res.data.business_slug;
          this.showExtraFields = true;
          localStorage.removeItem('member_id');
          //localStorage.removeItem('member_mobile');
          this.getBusinessDetailBySlug(false);
        } else {
          this.cS.showError(res.message);
        }
      }, err => {
        console.log(err);
      });
    }

  }

  cancelEditBusiness() {
    this.location.back();
  }

  getTimezone() {
    const apiUrl = environment.RYEC_API_URL + 'getTimezone';
    this.httpService.get(apiUrl).subscribe((res: any) => {
      if (res['status'] === 1) {
        this.timezoneArr = res.data;
        this.allTimezoneArr = this.timezoneArr;
      }
    });
  }

  changeTimeZone(event: any) {
    console.log(event.value);
    this.timezone = event.name;
    this.addBusinessForm.controls.timezone.setValue(event.name);
  }

  removeMetaTags(index: number) {
    if (this.metaTags.indexOf(<any>this.selectedTags[index]) === -1)
      this.metaTags.push(<any>this.selectedTags[index]);
    this.selectedTags.splice(index, 1);
  }

  resetForm() {
    this.addBusinessForm.reset();
    this.business_category = [];
    this.metaTags = [];
    this.base64BusinessImages = [];
    this.selectedTags = [];
    this.productImagesArr = [];
    this.addProductForm.reset();
    this.addServiceForm.reset();
    this.base64ProductImages = [];
    this.base64ServiceLogo = '';
    this.addBusinessForm.controls.user_id.setValue(localStorage.getItem('member_id'))
    //this.addBusinessForm.controls.mobile.setValue(localStorage.getItem('member_mobile'))
  }

  openHelpPopup(content: string) {
    console.log(content);
  }

  removeImage(image_id: any, index: any) {

    Swal.fire
    ({
      title: 'Are you sure?',
      text: 'To delete this image.',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      showCancelButton: true,
      showCloseButton: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        const apiUrl = environment.RYEC_API_URL + 'removeProductImage';
        const postJson = { product_image_id: image_id };

        this.httpService.post(apiUrl, postJson).subscribe((res:any) => {
          if (res.status === 1) {
            this.cS.showSuccess(res.message);
            if (this.productImagesArr) {
              this.productImagesArr.splice(index, 1);
            }
          }
        }, err => {
          console.log(err);
        });
    }
  }) }

  removeProductImage(index: number) {




    Swal.fire
    ({
      title: 'Are you sure?',
      text: 'To delete this image.',

      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      showCancelButton: true,
      showCloseButton: true,
      reverseButtons: true,

    }).then((result) => {
      if (result.value) {
        this.base64ProductImages.splice(index, 1);
      }
    }
  )}

  showTimeZoneDropDown() {
    if (this.isTimeZoneShow) {
      this.isTimeZoneShow = false;
    } else {
      this.isTimeZoneShow = true;
    }
  }

  filterTimeZone() {
    this.timezoneArr = [];

    if (this.addBusinessForm.controls.timezoneSearch.value !== '') {
      this.allTimezoneArr.filter((item: any) => {
        const search = item['value'] + ' ' + item['name'];
        if (search.toLowerCase().indexOf(this.addBusinessForm.controls.timezoneSearch.value.toLowerCase()) > -1) {
          this.timezoneArr.push(item);
        }
      });
    } else {
      this.timezoneArr = this.allTimezoneArr;
    }
  }

  selectTimeZone(timezone: any) {
    this.timezone = timezone.value + ' - ' + timezone.name;
    this.addBusinessForm.controls.timezone.setValue(timezone.name);
    this.isShow = false;
    this.addBusinessForm.controls.timezoneSearch.setValue('');
    this.timezoneArr = this.allTimezoneArr;
  }

  /**
   * Upload business logo
   */
  triggerLogoUpload() {
   this.businessLogoRef.nativeElement.click();
  }

  readLogoURL(input: any) {
    const self = this;
    if (input.files && input.files[0]) {
      const reader = new FileReader();
      reader.onload = function (e:any) {
        self.businessProfilePic = e['target']['result'];
      };
      reader.readAsDataURL(input.files[0]);
    }
  }

  uploadBusinessLogo(event: any, content: any) {
    const srcEle = event.srcElement;
    if (srcEle.files && srcEle.files[0]) {
      if (this.cS.checkValidImage(srcEle.files[0])) {
        this.imageChangedEvent = event;
        this.modalReference = this.modalService.open(content);
        this.modalReference.result.then((result: any) => {
          console.log(`Closed with: ${result}`);
        }, (reason: any) => {
          console.log(reason);
        });
      } else {
        this.cS.showError('Please select valid image.');
      }
    }
    // const srcEle = event.srcElement;
    // if (srcEle.files && srcEle.files[0]) {
    //     if (this.cS.checkValidImage(srcEle.files[0])) {
    //         this.businessImagesFormData.append('business_logo', srcEle.files[0]);
    //         this.readLogoURL(srcEle);
    //     } else {
    //         this.cS.showError('Please select file less than 5MB.');
    //     }
    // }
  }

  imageCropped(image: any) {
    this.croppedImage = image;
  }

  imageLoaded() {
    // show cropper
  }
  loadImageFailed() {
    // show message
  }

  sendCropImage() {
    //console.log(this.modalService);
    //console.log(this.croppedImage);
    const block = this.croppedImage.base64.split(';');
    const contentType = block[0].split(':')[1];
    const realData = block[1].split(',')[1];

    const blob = this.cS.b64toBlob(realData, contentType);
    this.modalReference.close();
    this.businessProfilePic = this.croppedImage.base64;
    this.businessImagesFormData.append('business_logo', blob);

  }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }


  /**
   * on click Add New Store open form for add new store
   * @Yuvraj 30/11/2021
   */
  addNewStore() {
    this.isAddNewStore = true;
    this.onlineStoreModel = JSON.parse(JSON.stringify(this.onlineStoreModel));
    if (this.onlineStoreModel.online_stores.length == 0) {
      this.addStoresRow();
    }
  }

  /**
   * get online store lists
   * @Yuvraj 30/11/2021
   */
  getOnlineStoresLists() {
    this.hS.getOnlineStore().subscribe((res: any) => {
      if (res.status == 1) {
        this.onlineStoresList = res.data;
        this.storeChange();
      }
      else {
        this.onlineStoresList = [];
      }
    });
  }


  /**
   * Add row on click add more
   * @Yuvraj
   * 30/11/2021
   */
  addStoresRow() {
    this.onlineStoreModel.online_stores.push({
      id: null,
      url: ''
    });
  }

  /**
   * delete particular row on click delete
   * @Yuvraj 30/11/2021
   */
  deleteStoreRow(index: number) {
    this.onlineStoreModel.online_stores.splice(index, 1);
    let tempData = JSON.parse(JSON.stringify(this.onlineStoreModel.online_stores));
    this.onlineStoreModel.online_stores = [];
    this.onlineStoreModel.online_stores = tempData;
    this.cS.showSuccess("Online Store deleted successfully");
    this.storeChange();
  }

  /**
   * on click cancel hide add store form
   * @Yuvraj 30/11/2021
   */
  onCancelStoreForm() {
    this.isAddNewStore = false;
    this.onlineStoreModel.online_stores = []; //empty
  }

  /**
   * on click save store
   * @Yuvraj 30/11/2021
   */
  saveStores(f1:any) {
    this.onlineStoreModel.business_id = this.business_id;
    const apiUrl = environment.RYEC_API_URL + 'saveBusinessOnlineStores';
    this.httpService.post(apiUrl, this.onlineStoreModel).subscribe((res: any) => {
      if (res.status === 1) {
        this.cS.showSuccess(res.message);
        this.isAddNewStore = false;
        this.onlineStoreModel.online_stores = []; //empty
        this.getAddedOnlineStores();
      } else {
        this.cS.showError(res.message);
      }
    });
  }

  /**
   * get online added stores
   * @Yuvraj 30/11/2021
   */
  getAddedOnlineStores() {
    const postJson = { business_id: this.business_id };
    this.hS.getBusinessOnlineStores(postJson).subscribe((res: any) => {
      if (res.status == 1) {
        this.businessStoresList = res.data;
      }
      else {
        this.businessStoresList = [];
      }
    });
  }

  /**
   * on click edit take data from getAddedOnlineStores and push them in model
   * @Yuvraj 01/12/2021
   */
  editStore() {
    if (this.businessStoresList.length) {
      this.onlineStoreModel.online_stores = []; //empty
      this.businessStoresList.forEach((element: any) => {
        this.onlineStoreModel.online_stores.push({
          id: element.id,
          url: element.url,
        })
        this.isAddNewStore = true;
        this.storeChange();
      });
    }
  }

  /**
   * on store selection restricate user to not selecte same store and disable selected store
   * @Yuvraj 07/12/2021
   */
  storeChange() {
    // this.storeDisabled = [];
    this.onlineStoresList.map((element) => {
      element.isSelected = false;
    });

    if (this.onlineStoreModel.online_stores.length) {
      this.onlineStoreModel.online_stores.forEach((item: any, index: number) => {
        console.log(item);
        this.onlineStoresList.map((element) => {
          if (element.id == this.onlineStoreModel.online_stores[index].id) {
            // console.log('yes match');
            // this.storeDisabled[index] = this.onlineStoreModel.online_stores[index].id;
            // console.log('storeDisabled', this.storeDisabled);
            // let storeList = this.onlineStoresList.find( (item: any) => item.id === this.onlineStoreModel.online_stores[index].id );
            // let storeList = _.findWhere(this.onlineStoresList, { id: this.onlineStoreModel.online_stores[index].id });
            // console.log(storeList);
            // this.onlineStoreModel.online_stores[index].id = storeList.id;
            element.isSelected = true;
          }
        });
      });
    }

  }

  locationSearch(event: any) {
    const val = event;
    if (val.length >= 6) {
      const postJson = { pincode: val };
      this.hS.getLocationByPinCode(postJson).subscribe((res: any) => {
        if (res.data) {
          if (res.data.length > 0) {
            this.locationsList = res.data;
            this.isLocationNotFound = false;
          } else {
            this.locationsList = [];
            this.isLocationNotFound = true;
          }
        }
      });
    }
  }
}
