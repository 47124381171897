<div class="modal-header">
    <!-- <h4 class="text-primary">{{ "ang_writeyourenquiry" | translate }}</h4> -->
    <h4 class="text-danger" *ngIf="removeUserName">Remove {{ removeUserName }} </h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')" >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  
  <div class="modal-body">
    <div id="container" class="container">
      <div class="row">
        <div class="col-md-12 my-2">
          <div class="info-form">
            <form name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" autocomplete="off" novalidate>
              <div class="form-group">
                <label for="reason">Reason <span>*</span></label>
                <textarea class="form-control" id="reason" name="reason" rows="3" placeholder="Please leave reason.." required [ngClass]="{ 'state-error': (f.submitted && reason.invalid) || (reason.touched && reason.invalid)}" [(ngModel)]="removeuserModel.reason" #reason="ngModel"></textarea>
                <div *ngIf="(f.submitted && reason.invalid) || (reason.touched && reason.invalid)" class="note">
                  <div *ngIf="reason.errors?.required">Reason is required</div>
                </div>
              </div>
              <div class="form-group">
                <p class="pull-right">
                  <button [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" [disabled]="isSubmit" type="submit">{{ "ang_submit" | translate | uppercase }}</button>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>