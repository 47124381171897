import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
  
import { PostJsonBusinessList } from 'src/app/shared/class/data.model';
 
import {
  debounceTime,
  map,
  distinctUntilChanged,
  filter
} from "rxjs/operators";
import { fromEvent } from 'rxjs/observable/fromEvent';
  
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { GroupJoinVerificationModalComponent } from 'src/app/shared/component/group-join-verification-modal/group-join-verification-modal.component';
import { HttpService } from 'src/app/shared/services/http.service';
import { HomeService } from 'src/app/shared/services/home.service';
import { CommonService } from 'src/app/shared/services/common.service';
declare var $: any;

@Component({
  selector: 'app-newslist',
  templateUrl: './newslist.component.html',
  styleUrls: ['./newslist.component.css']
})
export class NewslistComponent implements OnInit {
  sortingType: string = "popularity";
  groupType: string = "allGroup";
  center_lat = localStorage.getItem('latitude');
  center_long = localStorage.getItem('longitude');
  currentPage: number = 0;
  sort_slug: string = "";
  perPageList = environment.BUSINESS_LIST_LIMIT;
  endPage: number = environment.BUSINESS_LIST_LIMIT;
  newsList: any ={} ;
  myGroupsList: any = {};
  totalGroupsCount: number = 0;
  collectionSize: number = 0;
  first: number = 1;
  last: number = 0;
  maxSize: number = 5;
  noData: boolean = false;
  isAtoZShow: boolean = false;
  isZtoAShow: boolean = false;
  isSubmit: boolean = false;
  searchText: string = "";
  items: any[] = [];
  isCancelJoinRequest: boolean = false;
  isJoinRequest: boolean = true;
  appName = environment.APP_NAME;

  @ViewChild('homeSearchInput')
  homeSearchInput!: ElementRef;
  constructor(
    private hS: HomeService,
    private router: Router,
    private cS: CommonService,
    private httpS: HttpService,
    private modalService: NgbModal,
  ) {
    this.currentPage = 1;
  }

  ngOnInit() {
    if (this.center_lat === '' || this.center_lat === null) {
      this.hS.getNetworkLatLong();
    }

    this.currentPage = 1;
    this.checkSorting();
    this.checkRouterUrlForCallingAPI();
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.homeSearh();
  }

  checkSorting() {
    /*
      *Check which type of sorting happend
      */
    if (this.sortingType === 'relevance') {
      this.sort_slug = 'relevance';
    }
    else if (this.sortingType === 'popularity') {
      this.sort_slug = 'popular';
    }
    else if (this.sortingType === 'atoz') {
      this.sort_slug = 'AtoZ';
    }
    else if (this.sortingType === 'ztoa') {
      this.sort_slug = 'ZtoA';
    }
    else {
      this.router.navigateByUrl('/home');
    }
  }

  checkRouterUrlForCallingAPI() {
    const postJson: PostJsonBusinessList = {
      sortBy: this.sort_slug,
      page: this.currentPage,
      limit: this.perPageList
    };

    if (this.sort_slug === 'nearMe' || this.sort_slug === 'relevance') {
      postJson.radius = environment.LOCATION_RADIUS;
      postJson.latitude = <any>this.center_lat;
      postJson.longitude = <any>this.center_long;
    }

    if (this.sort_slug === 'relevance') {
      postJson.latitude = <any>this.center_lat;
      postJson.longitude = <any>this.center_long;
    }
    this.getGroupsList(postJson);
  }

  getGroupsList(postJson: any) {
    this.hS.getNewsList(postJson).subscribe((res: any) => {
      if (res.status == 1) {
        // this.convertToLowerCase(res.data);
        this.newsList = res.data.breakingNewsList;
        this.newsList = res.data.featuredNewsList;
        this.newsList = res.data.forYou;
        this.myGroupsList = {};
        this.totalGroupsCount = res.total;
        this.countCollectionSize();
      }
      else {
        this.newsList = [];
      }
    });
  }

  /**
  *Calculate pagination display on screen and count of total businesses
  */
  countCollectionSize() {
    if (this.totalGroupsCount > this.perPageList) {
      this.collectionSize = Math.ceil(this.totalGroupsCount / this.perPageList);
      this.collectionSize = this.collectionSize * 10;
      if (this.currentPage === 1) {
        this.first = 1;
        this.last = this.perPageList;
      } else {
        this.first = (this.currentPage - 1) * this.perPageList + 1;
        if (this.newsList.length < this.perPageList) {
          this.last = (this.currentPage - 1) * this.perPageList + this.newsList.length;
        } else {
          this.last = (this.currentPage - 1) * this.perPageList + this.perPageList;
        }
      }
    } else {
      this.last = this.totalGroupsCount;
      this.collectionSize = this.perPageList;
    }

    if (this.totalGroupsCount == 0 || this.totalGroupsCount == undefined) {
      this.noData = true;
    } else {
      this.noData = false;
    }
    this.cS.scrollTop();
    // this.showBusinessOnMap();
  }

  searchBusiness() {
    $(".auto_complete_listing").css("display", "none");
    if (this.searchText != "") {
      const postJson: PostJsonBusinessList = {
        sortBy: this.sort_slug,
        page: this.currentPage,
        limit: this.perPageList,
        searchText: this.searchText
      };

      if (this.sort_slug === 'nearMe' || this.sort_slug === 'relevance') {
        postJson.radius = environment.LOCATION_RADIUS;
        postJson.latitude = <any>this.center_lat;
        postJson.longitude = <any>this.center_long;
      }

      if (this.sort_slug === 'relevance') {
        postJson.latitude = <any>this.center_lat;
        postJson.longitude = <any>this.center_long;
      }
      this.getGroupsList(postJson);
    }
  }

  searchBusinessByCategory(name: string) {
    this.searchText = name;
    this.searchBusiness();
    $(".auto_complete_listing").css("display", "none");
  }

  /**
   * deley api calling and called once user finish typed in search text box
   * @Yuvraj 23/11/2021
   */
  homeSearh() {
    fromEvent(this.homeSearchInput.nativeElement, 'keyup').pipe(
      // get value
      map((event: any) => {
        return event.target.value;
      }),
      // if character length greater then 3
      // , filter(res => res.length >= 3)
      filter(res => {
        if (res && res.length >= 3) {
          return true;
        } else {
          $(".auto_complete_listing").css("display", "none");
          return false;
        }
      })

      // Time in milliseconds between key events
      , debounceTime(1000)

      // If previous query is diffent from current
      , distinctUntilChanged()

      // subscription for response
    ).subscribe((postJson: string) => {
      this.hS.getGroupsAutoCompleteList({ searchText: postJson }).subscribe((res: any) => {
        if (res.data) {
          if (res.data.length > 0) {
            $(".auto_complete_listing").css("display", "block");
            this.items = res.data;
          } else {
            $(".auto_complete_listing").css("display", "none");
          }
        }
      }, err => {
        console.log(err);
      });
    });
  }


  getNextPageBusinessList(event: number): void {
    console.log(event);
    // this.onGroupTypeChange();
    // this.businessMarkers = [];
    event=this.newsList;
     this.checkRouterUrlForCallingAPI();
  }

  /**
   * if sort type is change call api
   * @Yuvraj 18/01/2022
   */
  onSortTypeChange() {
    this.checkSorting();
    this.checkRouterUrlForCallingAPI();

    if (this.sortingType == 'atoz' || this.sortingType == 'ztoa') {
      let sortType: string = "";
      if (this.sortingType == 'atoz') {
        sortType = 'asc';
      }
      if (this.sortingType == 'ztoa') {
        sortType = 'desc';
      }
      this.filter(sortType);
    }
  }

  // onGroupTypeChange() {
  //   if (this.checkAuth()) {
  //     if (this.groupType == 'yourGroup') {
  //       this.getMyGroupsList();
  //     }
  //     else {
  //       this.checkSorting();
  //       this.checkRouterUrlForCallingAPI();
  //     }
  //   }
  //   else {
  //     this.router.navigate(['/login'], { queryParams: { returnUrl: '/groups' } });
  //   }
  // }

  // getMyGroupsList() {
  //   const postJson: PostJsonBusinessList = {
  //     // sortBy: this.sort_slug,
  //     page: this.currentPage,
  //     limit: this.perPageList
  //   };
  //   this.hS.getMyGroupsList(postJson).subscribe((res: any) => {
  //     if (res.status == 1) {
  //       this.myGroupsList = res.data;
  //       this.groupsList = [];
  //       // console.table(this.myGroupsList);
  //       this.totalGroupsCount = res.total;
  //       this.countCollectionSize();
  //     }
  //   });
  // }

  /**
  * filter by name
  */
  filter(filtername: string) {
    if (filtername == 'asc') {
      this.isAtoZShow = true; //if a to z is selected then disable atoz
      this.isZtoAShow = false; //if a to z is selected then inable ztoa
    } if (filtername == 'desc') {
      this.isZtoAShow = true; //if z to a is selected then disable ztoa
      this.isAtoZShow = false; //if z to a is selected then inable atoz
    }
  }

  onJoinGroup(item: any) {
    if (this.checkAuth()) {
      if (item.security_code !== '' && item.is_security_code !== 0) {
        this.loadGroupVerificationModalComponent(item);
      }
      else {
        let reqType: string = 'join';
        this.joinLeaveGroup(item, reqType);
      }
    }
    else {
      this.router.navigate(['/login'], { queryParams: { returnUrl: '/groups' } });
    }
  }

  onCancel(item: any) {
    if (this.checkAuth()) {
      let reqType: string = 'cancel';
      this.joinLeaveGroup(item, reqType);
    }
    else {
      this.router.navigate(['/login'], { queryParams: { returnUrl: '/groups' } });
    }
  }

  joinLeaveGroup(item: any, reqType: string) {
    let tempModel: any = {};
    tempModel.site_id = item.id;
    tempModel.type = reqType;
    this.isSubmit = true;
    const apiUrl = environment.RYEC_API_URL + 'joinOrLeaveSite';
    this.httpS.post(apiUrl, tempModel).subscribe((res: any) => {
      if (res.status === 1) {
        this.isSubmit = false;
        this.cS.showSuccess(res.message);
        this.checkRouterUrlForCallingAPI();
      }
      else {
        this.cS.showError(res.message);
        this.isSubmit = false;
      }
    });
  }

  loadGroupVerificationModalComponent(item: any) {
    const modalReference = this.modalService.open(GroupJoinVerificationModalComponent, { windowClass: 'mobile_popup' });
    modalReference.componentInstance.groupId = item.id; //group id
    modalReference.componentInstance.groupName = item.name; //group name
    modalReference.componentInstance.dynamicExtraFieldLabel = item.dynamic_field_label; //group dynamic field label
    modalReference.result.then((result: any) => {
      console.log(`Closed with: ${result}`);
    }, (reason: any) => {
      if (reason == 'close') {
        this.checkRouterUrlForCallingAPI();
      }
    });
  }


  checkAuth() {
    if (this.cS.getUserDetails()) {
      return true;
    }
    return false;
  }
}

