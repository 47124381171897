<footer *ngIf="isPrivate" class="footer_section" [ngStyle]="{'background':appName == 'MRC' ? 'var(--theme-mrc-based-primary-color)' : appName == 'BD' ? 'var(--theme-bd-based-primary-color)' : '#2C2B2B' }">
    <div class="container">
        <!--<div class="col-xs-12">
			<h2>{{'ang_contactus' | translate}}</h2>
		</div>-->
        <div class="row contact-detail">
            <div class="col-md-5 col-sm-4 col-xs-12">
                <div class="">
                    <a *ngIf="appName == 'MRC'" href="#" title="MyRajasthan Club"><img src="../../../assets/images/logonew.png" /></a>
                    <a *ngIf="appName == 'RYC'" href="#" title="Rajputs Club"><img src="../../../assets/images/white_raj_logo.png" /></a>
                    <a *ngIf="appName == 'BD'"  href="#" title="Bharat Darshan"><img width="150" src="../../../assets/images/bharat-darshan-logo.png" /></a>
                </div>
                <ul *ngIf="appName == 'MRC'">
                    <li style="color: var(--theme-mrc-based-text-primary-color);">
                        <span class="contact-icon" [ngStyle]="{'color': 'var(--theme-mrc-based-secondary-color)'}"><i class="fa fa-map-marker"></i></span>
                        <span> Udaipur, Rajsthan, India </span>
                    </li>
                    <li style="color: var(--theme-mrc-based-text-primary-color);">
                      <span class="contact-icon" [ngStyle]="{'color': 'var(--theme-mrc-based-secondary-color)'}"><i class="fa fa-map-marker"></i></span>
                      <span> Dallas, Texas, USA</span>
                    </li>
                    <li style="color: var(--theme-mrc-based-text-primary-color);">
                        <span class="contact-icon" [ngStyle]="{'color': 'var(--theme-mrc-based-secondary-color)'}">
                            <i class="fa fa-envelope"></i>
                        </span>
                        <a [ngStyle]="{'color': 'var(--theme-mrc-based-text-primary-color)'}" href="mailto:info@myrajasthanclub.com" title="Mail Us">info@myrajasthanclub.com</a>
                    </li>
                </ul>

                <ul *ngIf="appName == 'RYC'">
                    <li style="color: var(--theme-ryc-based-text-primary-color);">
                        <span class="contact-icon" [ngStyle]="{'color': 'var(--theme-ryc-based-secondary-color)'}"><i class="fa fa-map-marker"></i></span>
                        <span>
                            Dallas, Texas, USA
                        </span>
                    </li>
                    <li style="color: var(--theme-ryc-based-text-primary-color);">
                        <span class="contact-icon" [ngStyle]="{'color': 'var(--theme-ryc-based-secondary-color)'}">
                            <i class="fa fa-phone"></i>
                        </span>
                        <a [ngStyle]="{'color': 'var(--theme-ryc-based-text-primary-color)'}" href="tel:919680419568" title="Call Us">  +91 9680419568 </a>
                        <!-- <a [ngStyle]="{'color': 'var(--theme-ryc-based-text-primary-color)'}" href="tel:919099937890" title="Call Us"> +91 9099937890 </a> -->
                    </li>
                    <li style="color: var(--theme-ryc-based-text-primary-color);">
                        <span class="contact-icon" [ngStyle]="{'color': 'var(--theme-ryc-based-secondary-color)'}">
                            <i class="fa fa-envelope"></i></span>
                            <a [ngStyle]="{'color': 'var(--theme-ryc-based-text-primary-color)'}" href="mailto:info@rajputsclub.com" title="Mail Us">info@rajputsclub.com</a>
                    </li>
                </ul>
                <ul *ngIf="appName == 'BD'">
                    <li style="color: var(--theme-bd-based-text-primary-color);">
                        <span class="contact-icon" [ngStyle]="{'color': 'var(--theme-bd-based-text-primary-color)'}"><i class="fa fa-map-marker"></i></span>
                        <span>
                            Xsilica Software Solutions Pvt.Ltd, H.No.8-2-120/112/B/5&6,3rd Floor, BBR Forum, Road No.2, Banjara Hills, Hyderabad, 500034.
                        </span>
                    </li>
                    <li style="color: var(--theme-bd--text-primary-color);">
                        <span class="contact-icon" [ngStyle]="{'color': 'var(--theme-bd-based-text-primary-color)'}">
                            <i class="fa fa-phone"></i>
                        </span>
                        <a [ngStyle]="{'color': 'var(--theme-bd-based-text-primary-color)'}" href="tel:917852812997" title="Call Us">  +91 7852812997 </a>
                    </li>
                    <li style="color: var(--theme-bd-based-text-primary-color);">
                        <span class="contact-icon" [ngStyle]="{'color': 'var(--theme-bd-based-text-primary-color)'}">
                            <i class="fa fa-envelope"></i></span>
                            <a [ngStyle]="{'color': 'var(--theme-bd-based-text-primary-color)'}" href="mailto:info@bharatdarshan.org" title="Mail Us">
                                info@bharatdarshan.org</a>
                    </li>
                </ul>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12">
                <h3 [ngStyle]="{'color':appName == 'MRC' ?  'var(--theme-mrc-based-text-primary-color)' : appName == 'BD' ?  'var(--theme-bd-based-text-primary-color)':'var(--theme-ryc-based-text-primary-color)' }">
                    <!--<span class="contact-icon"><i class="fa fa-map-marker"></i></span>{{'ang_branchoffice' | translate}}-->{{'ang_information'
                    | translate}}
                </h3>
                <!--<p> {{'ang_branchofficeline1' | translate}}<br> {{'ang_branchofficeline2' | translate}} <br> {{'ang_branchofficeline3' |
                 translate}}<br> {{'ang_branchofficeline4' | translate}}</p>-->
                <ul class="foo-nav">
                    <li>
                        <a [ngStyle]="{'color':appName == 'MRC' ? 'var(--theme-mrc-based-text-primary-color)' : appName == 'BD' ?  'var(--theme-bd-based-text-primary-color)': 'var(--theme-ryc-based-text-primary-color)' }" href="javascript:void(0);" routerLink="/how-it-work" routerLinkActive="active"
                            title="{{'ang_howitswork' | translate}}">{{'ang_howitswork' | translate}}</a>
                    </li>
                    <li>
                        <a [ngStyle]="{'color':appName == 'MRC' ? 'var(--theme-mrc-based-text-primary-color)' : appName == 'BD' ?  'var(--theme-bd-based-text-primary-color)':'var(--theme-ryc-based-text-primary-color)' }" href="javascript:void(0);" routerLink="about" routerLinkActive="active"
                            title="{{'ang_aboutus' | translate}}">{{'ang_aboutus' | translate}}</a>
                    </li>
                    <li>
                        <a [ngStyle]="{'color':appName == 'MRC' ? 'var(--theme-mrc-based-text-primary-color)' :appName == 'BD' ?  'var(--theme-bd-based-text-primary-color)': 'var(--theme-ryc-based-text-primary-color)' }" href="javascript:void(0);" routerLink="contact" routerLinkActive="active"
                            title="{{'ang_contactus' | translate}}">{{'ang_contactus' | translate}}</a>
                    </li>
                    <li>
                        <a [ngStyle]="{'color':appName == 'MRC' ? 'var(--theme-mrc-based-text-primary-color)' :appName == 'BD' ?  'var(--theme-bd-based-text-primary-color)': 'var(--theme-ryc-based-text-primary-color)' }" href="javascript:void(0);"  routerLink="/MembershipPlan" routerLinkActive="active"
                            title="{{'ang_premiummembershipplan' | translate}}">{{'ang_premiummembershipplan' |translate}}</a>
                    </li>
                    <li *ngIf="appName == 'MRC'">
                        <a [ngStyle]="{'color':appName == 'MRC' ? 'var(--theme-mrc-based-text-primary-color)' : appName == 'BD' ?  'var(--theme-bd-based-text-primary-color)':'var(--theme-ryc-based-text-primary-color)' }"  routerLink="/privacy-policy" routerLinkActive="active" title="{{'ang_privacyandpolicy' | translate}}">
                            {{'ang_privacyandpolicy' | translate}}
                        </a>
                    </li>
                    <li *ngIf="appName == 'BD'">
                        <a style="color: var(--theme-bd-based-text-primary-color);"    href="https://app.bharatdarshan.org/privacy-policy" title="{{'ang_privacyandpolicy' | translate}}">
                            {{'ang_privacyandpolicy' | translate}}
                        </a>
                    </li>
                    <li *ngIf="appName == 'RYC'">
                        <a [ngStyle]="{'color':appName == 'RYC' ? 'var(--theme-mrc-based-text-primary-color)' : appName == 'BD' ?  'var(--theme-bd-based-text-primary-color)':'var(--theme-ryc-based-text-primary-color)' }"  href="https://app.rajputsclub.com/privacy-policy" title="{{'ang_privacyandpolicy' | translate}}">
                            {{'ang_privacyandpolicy' | translate}}
                        </a>
                    </li>
                    <li>
                        <a [ngStyle]="{'color':appName == 'MRC' ? 'var(--theme-mrc-based-text-primary-color)' : appName == 'BD' ?  'var(--theme-bd-based-text-primary-color)':'var(--theme-ryc-based-text-primary-color)' }" href="javascript:void(0);" routerLink="/terms-conditions" routerLinkActive="active"
                            title="{{'ang_termsandconditions' | translate}}">{{'ang_termsandconditions' |
                            translate}}</a>
                    </li>
                    <li>
                        <a [ngStyle]="{'color':appName == 'MRC' ? 'var(--theme-mrc-based-text-primary-color)' :appName == 'BD' ?  'var(--theme-bd-based-text-primary-color)': 'var(--theme-ryc-based-text-primary-color)' }" href="javascript:void(0);" routerLink="/refund-policy" routerLinkActive="active"
                            title="{{'ang_refundpolicy' | translate}}">{{'ang_refundpolicy' | translate}}</a>
                    </li>
                </ul>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-12">
                <h3 [ngStyle]="{'color':appName == 'MRC' ? 'var(--theme-mrc-based-text-primary-color)' : appName == 'BD' ?  'var(--theme-bd-based-text-primary-color)' :'var(--theme-ryc-based-text-primary-color)' }">{{'ang_oursocial' | translate}} </h3>
                <ul *ngIf="appName == 'MRC'">
                    <li>
                        <span class="contact-icon" [ngStyle]="{'color': 'var(--theme-mrc-based-secondary-color)'}">
                            <i class="fa fa-facebook"></i>
                        </span>
                        <a [ngStyle]="{'color': 'var(--theme-mrc-based-text-primary-color)'}" href="https://www.facebook.com/myrajasthanclub" target="_blank" title="{{'ang_facebook' | translate}}">
                            {{'ang_facebook' | translate}}
                        </a>
                    </li>
                    <li>
                        <span class="contact-icon" [ngStyle]="{'color': 'var(--theme-mrc-based-secondary-color)'}"><i class="fa fa-twitter"></i></span> <a [ngStyle]="{'color': 'var(--theme-mrc-based-text-primary-color)'}"
                            href="https://twitter.com/myrajasthanclub" target="_blank"
                            title="{{'ang_twitter' | translate}}">{{'ang_twitter' | translate}}</a>
                    </li>
                    <li>
                        <span class="contact-icon" [ngStyle]="{'color': 'var(--theme-mrc-based-secondary-color)'}"><i class="fa fa-instagram"></i></span> <a [ngStyle]="{'color': 'var(--theme-mrc-based-text-primary-color)'}"
                            href="https://www.instagram.com/myrajasthanclub/" target="_blank"
                            title="{{'ang_instagram' | translate}}">{{'ang_instagram' | translate}}</a>
                    </li>
                    <li>
                        <span class="contact-icon" [ngStyle]="{'color': 'var(--theme-mrc-based-secondary-color)'}"><i class="fa fa-youtube"></i></span> <a [ngStyle]="{'color': 'var(--theme-mrc-based-text-primary-color)'}"
                            href="https://www.youtube.com/channel/UCt6EYKoFKFa2dwwSaGw914A" target="_blank"
                            title="{{'ang_youtube' | translate}}">{{'ang_youtube' | translate}}</a>
                    </li>
                </ul>

                <ul *ngIf="appName == 'RYC'">
                    <li>
                        <span class="contact-icon" [ngStyle]="{'color': 'var(--theme-ryc-based-secondary-color)'}">
                            <i class="fa fa-linkedin"></i>
                        </span>
                        <a [ngStyle]="{'color': 'var(--theme-ryc-based-text-primary-color)'}" href="https://www.linkedin.com/company/ryuva-club/" target="_blank" title="{{'ang_linkedin' | translate}}">
                            {{'ang_linkedin' | translate}}
                        </a>
                    </li>
                    <li>
                        <span class="contact-icon" [ngStyle]="{'color': 'var(--theme-ryc-based-secondary-color)'}"><i class="fa fa-twitter"></i></span> <a
                        [ngStyle]="{'color': 'var(--theme-ryc-based-text-primary-color)'}" href="https://twitter.com/TheRajputsClub" target="_blank"
                            title="{{'ang_twitter' | translate}}">{{'ang_twitter' | translate}}</a>
                    </li>
                    <li>
                        <span class="contact-icon" [ngStyle]="{'color': 'var(--theme-ryc-based-secondary-color)'}"><i class="fa fa-instagram"></i></span> <a
                        [ngStyle]="{'color': 'var(--theme-ryc-based-text-primary-color)'}" href="https://www.instagram.com/rajputyuvaclub/" target="_blank"
                            title="{{'ang_instagram' | translate}}">{{'ang_instagram' | translate}}</a>
                    </li>
                    <li>
                        <span class="contact-icon" [ngStyle]="{'color': 'var(--theme-ryc-based-secondary-color)'}"><i class="fa fa-facebook"></i></span> <a
                        [ngStyle]="{'color': 'var(--theme-ryc-based-text-primary-color)'}" href="https://www.facebook.com/Ryuvaclub" target="_blank"
                            title="{{'ang_facebook' | translate}}">{{'ang_facebook' | translate}}</a>
                    </li>
                </ul>
                <ul *ngIf="appName == 'BD'">
                    <li>
                        <span class="contact-icon" [ngStyle]="{'color': 'var(--theme-bd-based-text-primary-color)'}">
                            <i class="fa fa-linkedin"></i>
                        </span>
                        <a [ngStyle]="{'color': 'var(--theme-bd-based-text-primary-color)'}" href="https://www.linkedin.com/company/bharatdarshan/" target="_blank" title="{{'ang_linkedin' | translate}}">
                            {{'ang_linkedin' | translate}}
                        </a>
                    </li>
                    <li>
                        <span class="contact-icon" [ngStyle]="{'color': 'var(--theme-bd-based-text-primary-color)'}"><i class="fa fa-twitter"></i></span> <a
                        [ngStyle]="{'color': 'var(--theme-bd-based-text-primary-color)'}" href="https://twitter.com/bharatdarshann" target="_blank"
                            title="{{'ang_twitter' | translate}}">{{'ang_twitter' | translate}}</a>
                    </li>
                    <li>
                        <span class="contact-icon" [ngStyle]="{'color': 'var(--theme-bd-based-text-primary-color)'}"><i class="fa fa-instagram"></i></span> <a
                        [ngStyle]="{'color': 'var(--theme-bd-based-text-primary-color)'}" href="https://www.instagram.com/bharatdarshan23/" target="_blank"
                            title="{{'ang_instagram' | translate}}">{{'ang_instagram' | translate}}</a>
                    </li>
                    <li>
                        <span class="contact-icon" [ngStyle]="{'color': 'var(--theme-bd-based-text-primary-color)'}"><i class="fa fa-facebook"></i></span> <a
                        [ngStyle]="{'color': 'var(--theme-bd-based-text-primary-color)'}" href="https://www.facebook.com/profile.php?id=100088132667210" target="_blank"
                            title="{{'ang_facebook' | translate}}">{{'ang_facebook' | translate}}</a>
                    </li>
                    <li>
                        <span class="contact-icon" [ngStyle]="{'color': 'var(--theme-bd-based-text-primary-color)'}"><i class="fa fa-youtube"></i></span> <a [ngStyle]="{'color': 'var(--theme-bd-based-text-primary-color)'}"
                            href="https://www.youtube.com/channel/UCkGrsJG65Xayj73WrT_5FWQ" target="_blank"
                            title="{{'ang_youtube' | translate}}">{{'ang_youtube' | translate}}</a>
                    </li>
                </ul>
            </div>
        </div>
    </div><!-- container End -->
</footer>
<div class="footer_copyright" [ngStyle]="{'background':appName == 'MRC' ? '#a85116' : '#1f1e1e' }">
    <div class="container">
        <div *ngIf="isPrivate && appName == 'MRC'" class="row">
            <div class="col-md-6 col-sm-6 col-xs-12 copytext">
              www.myrajasthanclub.com | Made In Rajasthan | © {{ year }} {{ "ang_allrightsreserved" | translate }}
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12">
                <ul class="list-inline">
                    <li><a href="https://play.google.com/store/apps/details?id=club.myrajasthan"
                            title="{{'ang_googleplaystore' | translate}}" target="_blank"><img
                                src="../../../assets/images/app-store.png"></a></li>
                    <li><a href="https://apps.apple.com/in/app/myrajasthan-club/id1545374835" title="{{'ang_appstore' | translate}}" target="_blank"><img
                                src="../../../assets/images/google-play.png"></a></li>
                </ul>
            </div>
        </div>
        <div *ngIf="isPrivate && appName == 'BD'" class="row">
            <div class="col-md-6 col-sm-6 col-xs-12 copytext">
              www.bharatdarshan.org | Made In India | © {{ year }} {{ "ang_allrightsreserved" | translate }}
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12">
                <ul class="list-inline">
                    <li><a href="https://play.google.com/store/apps/details?id=o"
                            title="{{'ang_googleplaystore' | translate}}" target="_blank"><img
                                src="../../../assets/images/app-store.png"></a></li>
                    <!-- <li><a href="https://apps.apple.com/in/app/myrajasthan-club/id1545374835" title="{{'ang_appstore' | translate}}" target="_blank"><img
                                src="../../../assets/images/google-play.png"></a></li> -->
                </ul>
            </div>
        </div>
        <div *ngIf="isPrivate && appName == 'RYC'" class="row">
            <div class="col-md-6 col-sm-6 col-xs-12 copytext">
              www.rajputsclub.com | © {{ year }} {{ "ang_allrightsreserved" | translate }}
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12">
                <ul class="list-inline">
                    <li>
                        <a href="https://play.google.com/store/apps/details?id=com.rajputsclub" title="{{'ang_googleplaystore' | translate}}" target="_blank">
                            <img src="../../../assets/images/app-store.png">
                        </a>
                    </li>
                    <li><a href="https://apps.apple.com/in/app/rajput-club/id1660935382" title="{{'ang_appstore' | translate}}" target="_blank">
                        <img src="../../../assets/images/google-play.png"></a>
                    </li>
                </ul>
            </div>
        </div>
        <!-- public website MRC -->
        <div *ngIf="!isPrivate && appName == 'MRC'" class="row">
            <div class="col-md-6 col-sm-6 col-xs-12 copytext">
                © Powered By
                <img class="footer-logo" src="../../../assets/images/logonew.png" /> |
                {{ "ang_allrightsreserved" | translate }}
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12 contact-detail">
                Do you want to create your public website on My Rajasthan Club Platform.<br />
                Contact Us on :
                <ul class="footer_contact">
                    <li>
                        <span class="contact-icon"><i class="fa fa-phone"></i></span>
                        <a href="tel:15126661209" title="Call Us"> +1 5126661209 , </a>
                        <a href="tel:9166319568" title="Call Us">+91 9166319568</a>
                    </li>
                    <li>
                        <span class="contact-icon"><i class="fa fa-envelope"></i></span> <a
                            href="mailto:info@myrajasthanclub.com" title="Mail Us">info@myrajasthanclub.com</a>
                    </li>
                    <li>
                        <a href="javascript:void(0);" routerLink="contact" routerLinkActive="active"
                            title="{{ 'ang_contactus' | translate }}">{{ "ang_contactus" | translate }}</a>
                    </li>
                </ul>
            </div>
        </div>
        <!-- public website RYC -->
        <div *ngIf="!isPrivate && appName == 'RYC'" class="row">
            <div class="col-md-6 col-sm-6 col-xs-12 copytext">
                © Powered By
                <img class="footer-logo" src="../../../assets/images/ryc-white-logo.png" /> |
                {{ "ang_allrightsreserved" | translate }}
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12 contact-detail">
                Do you want to create your public website on Rajputs Club Platform.<br />
                Contact Us on :
                <ul class="footer_contact">
                    <li style="color: #fff;">
                        <span class="contact-icon"><i class="fa fa-phone"></i></span>
                        <a style="color: #fff;" href="tel:9680419568" title="Call Us"> +91 9680419568</a>
                    </li>
                    <li style="color: #fff;">
                        <span class="contact-icon"><i class="fa fa-envelope"></i></span> <a
                            href="mailto:info@rajputsclub.com" style="color: #fff;" title="Mail Us">info@rajputsclub.com</a>
                    </li>
                    <li>
                        <a href="javascript:void(0);" routerLink="contact" routerLinkActive="active"
                        style="color: #fff;" title="{{ 'ang_contactus' | translate }}">
                        <span class="contact-icon">
                            <i class="fa fa-paper-plane" aria-hidden="true"></i>
                        </span>
                        {{ "ang_contactus" | translate }}
                    </a>
                    </li>
                    <li *ngIf="appName == 'RYC'">
                        <a href="https://www.myrajasthanclub.com/" target="_blank" style="color: #fff;" title="Explore Rajputs Club">
                            <span class="contact-icon">
                                <i class="fa fa-globe" aria-hidden="true"></i>
                            </span>
                            Explore Rajputs Club
                        </a>
                    </li>
                    <li *ngIf="appName == 'RYC'">
                        <a href="https://www.rajputsclub.com" target="_blank" style="color: #fff;" title="Explore Rajputs Club">
                            <span class="contact-icon">
                                <i class="fa fa-globe" aria-hidden="true"></i>
                            </span>
                            Explore Rajputs Club
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>

<a
      href="//www.dmca.com/Protection/Status.aspx?ID=d7be924a-0678-41cb-8e97-df8be61516be"
      title="DMCA.com Protection Status"
      class="dmca-badge"
    >
      <img
        src="https://images.dmca.com/Badges/dmca-badge-w250-5x1-07.png?ID=d7be924a-0678-41cb-8e97-df8be61516be"
        alt="DMCA.com Protection Status"
    /></a>
    <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js"></script>

<!-- footer_copyright End -->
