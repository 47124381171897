import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HomeService } from 'src/app/shared/services/home.service';


@Component({
  selector: 'ryec-template-detail',
  templateUrl: './template-detail.component.html',
  styleUrls: ['./template-detail.component.css']
})
export class TemplateDetailComponent implements OnInit {
  templateId: number = 0;
  templateModel: any = {};

  constructor(
    private activeRoute: ActivatedRoute,
    private homeService: HomeService,
  ) { }

  ngOnInit() {
    this.activeRoute.params.subscribe(data => {
      this.templateId = data.slug;
      if (this.templateId) { //if id is available call api
        this.getTemplateDetailsById(this.templateId);
      }
    });
  }


  /**
   * if get groupid from query params call api to get details of group
   * @Yuvraj 06/01/2022
   */
   getTemplateDetailsById(templateId: number) {
    const postJson = {
      template_id: templateId,
    };
    this.homeService.getTemplateDetailsById(postJson).subscribe((res: any) => {
      if (res.data) {
        this.templateModel = res.data[0];
      }
    });
  }

}
