<div class="modal-header">
  <!-- <h4 class="text-primary">{{ "ang_writeyourenquiry" | translate }}</h4> -->
  <h4 class="text-primary" *ngIf="groupName">{{ groupName }} </h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')" >
    <span aria-hidden="true">&times;</span>
  </button>
</div>


<div class="modal-body">
  <div id="container" class="container">
    <div class="row">
      <div class="col-md-12 my-2">
        <div class="info-form">
          <form name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" autocomplete="off" novalidate>
            <div class="form-group location-auto-search">
              <label for="searchText" class="control_label">Member Name <span class="required_feild">*</span></label>
              <input type="text" class="form-control" id="searchText" name="searchText" rows="3" placeholder="Search member.." required [ngClass]="{ 'state-error': (f.submitted && searchText.invalid) || (searchText.touched && searchText.invalid)}" [(ngModel)]="managementModel.searchText" #searchText="ngModel" (input)="searchMember($event)"/>
              <div class="member-list" style="display: none;">
                <perfect-scrollbar style="height: 210px;">
                  <ul>
                    <li class="search_input" *ngFor="let item of suggestMembersList; let i = index;" (click)="selectMemeber(item.id, item.name)">
                      <span class="profile_img" *ngIf="item.profile_url">
                        <img alt="profile" [src]="item.profile_url" />
                      </span>
                      <span class="profile_img" *ngIf="item.profile_url == ''">
                        <img alt="profile" src="assets/images/logo RYEC.png" />
                      </span>
                      {{ item.name }}
                    </li>
                  </ul>
                </perfect-scrollbar>
              </div>
              <div *ngIf="(f.submitted && searchText.invalid) || (searchText.touched && searchText.invalid)" class="note">
                <div *ngIf="searchText.errors?.required">Member name is required</div>
              </div>
            </div>
            <div class="form-group">
              <label for="groupDesignationId" class="control_label">Designation </label>
              <select [ngClass]="{ 'state-error': (f.submitted && groupDesignationId.invalid) || (groupDesignationId.touched && groupDesignationId.invalid)}" class="form-control" name="groupDesignationId" id="groupDesignationId" [(ngModel)]="managementModel.groupDesignationId" (change)="onChangeDesignation();" #groupDesignationId="ngModel" >
                <option [ngValue]="null">--Select--</option>
                <option value="-1">Custom Designation</option>
                <option *ngFor="let item of groupDesignationList; let i = index" [ngValue]="item.id" [disabled]="item.id == 10">{{ item.name }}</option>
              </select>
              <div *ngIf="(f.submitted && groupDesignationId.invalid) || (groupDesignationId.touched && groupDesignationId.invalid)" class="note">
                <div *ngIf="groupDesignationId.errors?.required">Designation is required</div>
              </div>
            </div>
            <div class="form-group" *ngIf="isCustomDesignation">
              <label class="control_label">Custom Designation <span class="required_feild">*</span></label>
              <input [ngClass]="{ 'state-error': (f.submitted && groupDesignationName.invalid) || (groupDesignationName.touched && groupDesignationName.invalid)}" type="text" class="form-control w-100" name="groupDesignationName"
              [(ngModel)]="managementModel.groupDesignationName" [required]="managementModel.groupDesignationId == -1" #groupDesignationName="ngModel" placeholder="Enter designation name">
              <div *ngIf="(f.submitted && groupDesignationName.invalid) || (groupDesignationName.touched && groupDesignationName.invalid)" class="note">
                <div *ngIf="groupDesignationName.errors?.required">Designation name is required</div>
              </div>
            </div>
            <div class="form-group">
              <label class="control_label">Role <span class="required_feild">*</span></label>
              <select [ngClass]="{ 'state-error': (f.submitted && groupMemberRoleId.invalid) || (groupMemberRoleId.touched && groupMemberRoleId.invalid)}" class="form-control" name="groupMemberRoleId" id="groupMemberRoleId" [(ngModel)]="managementModel.groupMemberRoleId" #groupMemberRoleId="ngModel" required>
                <option [ngValue]="null">--Select--</option>
                <option *ngFor="let item of groupRolesList; let i = index" [ngValue]="item.id" [disabled]="item.id == 10">{{ item.name }}</option>
              </select>
              <div *ngIf="(f.submitted && groupMemberRoleId.invalid) || (groupMemberRoleId.touched && groupMemberRoleId.invalid)" class="note">
                <div *ngIf="groupMemberRoleId.errors?.required">Role name is required</div>
              </div>
              </div>
            <div class="form-group">
              <p class="pull-right">
                <button [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" [disabled]="isSubmit" type="submit">{{ "ang_submit" | translate | uppercase }}</button>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>