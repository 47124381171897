<div class="modal-header">
    <h4 class="text-primary">{{ "ang_reportBusinessTitle" | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')" >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  <div class="modal-body">
    <div id="container" class="container">
      <div class="row" *ngIf="reasonsList?.length">
        <div class="">
          <h5 class="text-secondary">
            {{'ang_reportBusinessSubTitle' | translate }}
          </h5>
          <p class="text-secondary">
            {{'ang_reportBusinessSubTitleDesc' | translate }}
            <span>*</span>
          </p>
        </div>
        <div class="col-md-12 my-2">
          <div class="info-form">
            <form name="form" (submit)="submitReason()" autocomplete="off">
              <div class="form-group" *ngFor="let item of reasonsList; let i = index">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="{{item.id}}" name="reason{{i}}" (change)="onChange($event.target.checked, item)" [checked]="checked(item)">
                  <label class="form-check-label" for="gridCheck">
                    {{ item.reason }}
                  </label>
                </div>
              </div>
              <div class="form-group" *ngIf="selected?.length">
                <label for="comment">Comment <span>*</span></label>
                <textarea class="form-control" id="comment" name="comment" rows="3" placeholder="Please leave comment.." [(ngModel)]="reportReasonModel.comment" required></textarea>
              </div>
              <div class="form-group">
                <p class="pull-right">
                  <button [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" [disabled]="isSubmit" type="submit">{{ "ang_submit" | translate | uppercase }}</button>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  