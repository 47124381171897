import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GroupMemberDesignationRolesModalComponent } from 'src/app/shared/component/group-member-designation-roles-modal/group-member-designation-roles-modal.component';
import { GroupMemberRemoveConfirmationModalComponent } from 'src/app/shared/component/group-member-remove-confirmation-modal/group-member-remove-confirmation-modal.component';
import { GroupManagementModel } from 'src/app/shared/model/group-management.model';
import { GroupModel } from 'src/app/shared/model/group.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { HomeService } from 'src/app/shared/services/home.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { environment } from 'src/environments/environment';
  

 
  
// import {} from '../../../shared/component/city-auto-complete-search/city-auto-complete-search.component.css';
@Component({
  selector: 'ryec-group-edit',
  templateUrl: './group-edit.component.html',
  styleUrls: ['./group-edit.component.css', '../../shared/component/city-auto-complete-search/city-auto-complete-search.component.css']
})
export class GroupEditComponent implements OnInit {
  logImageShow: any = "assets/images/logo RYEC.png";
  groupId: number = 0;
  siteId: number = 0;
  groupModel = new GroupModel();
  groupModelData: FormData = new FormData();
  isSubmit: boolean = false;
  groupDetailsModel: any = {};
  postForm: FormGroup;
  categoryList: any = [];
  converImageShow: any[] = [];
  postConverImage: any[] = [];
  countryList: any[] = [];
  stateList: any[] = [];
  cityList: any[] = [];
  logoImagePost: any;
  showJoinSection: boolean = false;
  isJointRequest: boolean = false;
  isJoinSecurityCodeRequest: boolean = false;
  isPostModerateRequest: boolean = false;
  managementsLists: any[] = [];
  locationsList: any[] = [];
  isPrivate: boolean = false;
  isCoverImageDelete: boolean = false;
  userId: number = 0;
  groupCreatedByUserId: number = 0;
  isShowExtraField: boolean = false;
  managementModel = new GroupManagementModel();
  deleteImageIds: Array<any> = [];
  appName = environment.APP_NAME;
  constructor(
    private activeRoute: ActivatedRoute,
    private homeService: HomeService,
    private fb: FormBuilder,
    private cS: CommonService,
    private httpS: HttpService,
    private router: Router,
    private modalService: NgbModal,
  ) {
    const reg = '((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)';
    this.postForm = this.fb.group({
      id: [null, Validators.required],
      name: ["", Validators.required],
      asset_type_id: [null, Validators.required],
      description: ["", Validators.required],
      link: ["", Validators.pattern(reg)],
      facebook: ["", Validators.pattern(reg)],
      twitter: ["", Validators.pattern(reg)],
      linkedin: ["", Validators.pattern(reg)],
      instagram: ["", Validators.pattern(reg)],
      visibility: [false, Validators.required],
      is_enable_request: [false, Validators.required],
      is_security_code: [false, Validators.required],
      dynamic_field_label: [""],
      is_post_moderate: [false, Validators.required],
      contactPersonName: [""],
      contactNumber: ["", Validators.compose([Validators.maxLength(13), Validators.minLength(6), Validators.pattern('^[0-9]+$')])],
      locationId: [null],
      // contactCountryId: [null],
      // contactStateId: [null],
      // contactCityId: [null],
      contactAddress: [""],
      contactPinCode: [""],
    });

    const userDetail = this.cS.getUserDetails();
    if (userDetail) {
      this.userId = userDetail.id;
    }
  }

  ngOnInit() {
    this.activeRoute.params.subscribe(data => {
      this.groupId = data.slug;
      if (this.groupId) { //if id is available call api
        // this.getAddressDetail();
        this.getGroupDetailsById(this.groupId);
      }
    });
  }

  /**
   * get category lists
   * @Yuvraj 05/01/2022
   */
  getCategoryLists(): void {
    let postJson: any;
    postJson = { assetName: 'Group' };
    this.homeService.getSubAssetByAsset(postJson).subscribe((res: any) => {
      if (res.status === 1) {
        this.categoryList = res.data;
      }
      else {
        this.categoryList = [];
      }
    });
  }

  onCoverImageChange(event: any) {
    const srcEle = event.target;
    var filesAmount = event.target.files.length;
    if ((this.converImageShow.length + filesAmount) <= 20) {
      for (let i = 0; i < filesAmount; i++) {
        if (srcEle.files && srcEle.files[i]) {
          // this.converImageShow = [];
          if (this.cS.checkValidImage(srcEle.files[i])) {
            this.readCoverImageURL(srcEle.files[i]);
          } else {
            // this.converImageShow = ["assets/images/logo RYEC.png"];
            this.cS.showError("Please select file less than 5MB.");
          }
        }
      }
    }
    else {
      this.cS.showError("You can only upload 20 images.");
    }
  }

  readCoverImageURL(input: any) {
    const self = this;
    if (input) {
      const reader = new FileReader();
      reader.onload = function (event: Event) {
        if (event.target) {
          // self.converImageShow = reader.result;
          self.converImageShow.push({
            "id": 0,
            "url": reader.result

          });
          self.postConverImage.push(input);
        }
      };
      this.isCoverImageDelete = false;
      reader.readAsDataURL(input);
    }
  }

  removeCoverImage(id: number, index: number) {
    if (id !== undefined && id !== 0) {
      this.postConverImage.splice(index, 1);
      this.converImageShow.splice(index, 1);
      this.deleteImageIds.push(id);
    } else {
      this.converImageShow.splice(index, 1);
      this.postConverImage.splice(index, 1);
    }
    // console.log('id', id);
    // this.postConverImage.splice(index, 1);
    // this.converImageShow.splice(index, 1);
    // this.isCoverImageDelete = true;
  }

  removeLogoImage() {
    this.logoImagePost = "";
    this.logImageShow = "assets/images/logo RYEC.png";
  }

  onLogoImageChange(event: any) {
    const srcEle = event.target;
    if (srcEle.files && srcEle.files[0]) {
      if (this.cS.checkValidImage(srcEle.files[0])) {
        this.readLogoImageURL(srcEle.files[0]);
      } else {
        this.cS.showError("Please select file less than 5MB.");
      }
    }
  }

  readLogoImageURL(input: any) {
    const self = this;
    if (input) {
      const reader = new FileReader();
      reader.onload = function (event: Event) {
        if (event.target) {
          self.logImageShow = reader.result;
          self.logoImagePost = input;
        }
      };
      reader.readAsDataURL(input);
    }
  }

  onPrivacyChange(event: any) {
    if (event == true) {
      this.showJoinSection = true;
    }
    else {
      this.showJoinSection = false;
    }
  }

  joinRequest(event: any) {
    if (event == true) {
      this.isJointRequest = true;
    }
    else {
      this.isJointRequest = false;
    }
  }

  /**
   * set model value from response of api
   * @Yuvraj 12/01/2022
   */
  setFormData(data: any) {
    this.postForm.patchValue({
      id: data.id,
      name: data.name,
      asset_type_id: data.asset_type_id,
      description: data.description,
      link: data.link,
    });
    this.setLogoImg(data.logo);
    if (data.images.length) {
      // this.setCoverImg(data.images[0].url);
      this.setCoverImg(data.images);
    }
    this.setSocialLinkData(data.socials);
    this.setPrivacyData(data);
    this.setJoinRequestData(data);
    this.setContactData(data.contact);
    this.setSecurityCodeData(data);
    this.setPostModerateData(data);
    this.setExtraFieldData(data);
  }

  setSocialLinkData(socials: any | {}) {
    if (socials) {
      this.postForm.patchValue({
        facebook: !!socials.facebook ? socials.facebook : '',
        twitter: !!socials.twitter ? socials.twitter : '',
        linkedin: !!socials.linkedin ? socials.linkedin : '',
        instagram: !!socials.instagram ? socials.instagram : '',
      });
    }
  }

  setPrivacyData(data: any) {
    if (data.visibility == 'Public') {
      this.postForm.patchValue({
        visibility: false,
      });
    }

    else if (data.visibility == 'Private') {
      this.postForm.patchValue({
        visibility: true,
      });
      this.onPrivacyChange(true);
      this.isPrivate = true; //disable public type if group is private type
    }
  }

  setJoinRequestData(data: any) {
    this.postForm.patchValue({
      is_enable_request: data.is_enable_request,
    });
    this.joinRequest(data.is_enable_request);
  }

  setSecurityCodeData(data: any) {
    let isSecurityCodeEnable = (data.is_security_code == 1) ? true : false;
    this.postForm.patchValue({
      is_security_code: isSecurityCodeEnable,
    });
    this.joinSecurityCode(isSecurityCodeEnable);
  }

  setExtraFieldData(data: any) {
    this.postForm.patchValue({
      dynamic_field_label: data.dynamic_field_label,
    });
  }

  setPostModerateData(data: any) {
    let isPostModerateEnable = (data.is_post_moderate == 1) ? true : false;
    this.postForm.patchValue({
      is_post_moderate: isPostModerateEnable,
    });
    this.onGroupPostModerate(isPostModerateEnable);
  }

  setContactData(contact: any) {
    if (contact) {
      this.postForm.patchValue({
        contactPersonName: !!contact.name ? contact.name : '',
        contactNumber: !!contact.mobile_no ? contact.mobile_no : "",
        contactPinCode: !!contact.pincode ? contact.pincode : "",
        locationId: !!contact.location_id ? contact.location_id : "",
        contactAddress: !!contact.address ? contact.address : "",
      });

      if (contact.pincode) {
        this.locationSearch(contact.pincode)
      }
    }
  }
  /**
   * set logo image from response of api
   * @Yuvraj 12/01/2022
   */
  setLogoImg(url: any) {
    if (url) {
      this.logImageShow = url;
    }
    else {
      this.removeLogoImage();
    }
  }

  /**
   * set cover image from response of api
   * @Yuvraj 12/01/2022
   */
  setCoverImg(images: any) {
    if (images) {
      console.log('images', images);
      this.converImageShow = [];
      // this.postConverImage = images;
      this.converImageShow = images;
      // this.converImageShow.push(images);
    }
  }

  setSubmitModelValue() {
    this.groupModelData.append("id", this.postForm.controls.id.value);
    this.groupModelData.append("name", this.postForm.controls.name.value);
    this.groupModelData.append("asset_type_id", this.postForm.controls.asset_type_id.value);
    this.groupModelData.append("description", this.postForm.controls.description.value);
    this.groupModelData.append("link", this.postForm.controls.link.value);
    this.groupModelData.append("visibility", this.postForm.controls.visibility.value);
    this.groupModelData.append("is_enable_request", this.postForm.controls.is_enable_request.value);
    let isSecurityCodeEnable = (this.postForm.controls.is_security_code.value) ? '1' : '0';
    this.groupModelData.append("is_security_code", isSecurityCodeEnable);
    let isPostModerate = (this.postForm.controls.is_post_moderate.value) ? '1' : '0';
    this.groupModelData.append("dynamic_field_label", this.postForm.controls.dynamic_field_label.value);
    this.groupModelData.append("is_post_moderate", isPostModerate);
    this.groupModelData.append("contact_name", this.postForm.controls.contactPersonName.value);
    this.groupModelData.append("mobile_no", this.postForm.controls.contactNumber.value);
    this.groupModelData.append("pincode", this.postForm.controls.contactPinCode.value);
    this.groupModelData.append("location_id", this.postForm.controls.locationId.value);
    this.groupModelData.append("address", this.postForm.controls.contactAddress.value);
    // let deleteImages = (this.isCoverImageDelete) ? 'true' : 'false';
    // let deleteImages = (this.isCoverImageDelete) ? '1' : '0';
    // this.groupModelData.append("deleted_images", deleteImages);
    this.groupModelData.append("delete_image_ids", JSON.stringify(this.deleteImageIds));
    let social = JSON.stringify({
      facebook: this.postForm.value['facebook'],
      twitter: this.postForm.value['twitter'],
      linkedin: this.postForm.value['linkedin'],
      instagram: this.postForm.value['instagram']
    });
    this.groupModelData.append("socials", social);

    for (const x in Object.keys(this.postConverImage)) {
      this.groupModelData.append("images[" + x + "]", this.postConverImage[x]);
    }
    if (this.logoImagePost) {
      this.groupModelData.append("logo", this.logoImagePost);
    }
  }

  onSubmit() {
    if (this.postForm.valid) {
      this.setSubmitModelValue();
      this.isSubmit = true;
      const apiUrl = environment.RYEC_API_URL + 'createOrUpdateSite';
      this.httpS.postUpload(apiUrl, this.groupModelData).subscribe((res: any) => {
        if (res.status === 1) {
          this.cS.showSuccess(res.message);
          this.isSubmit = false;
          this.router.navigate(['/group/details/' + res.data.id]);
        }
        else {
          this.cS.showError(res.message);
          this.isSubmit = false;
        }
      });
    }
  }

  /**
   * if get groupid from query params call api to get details of group
   * @Yuvraj 06/01/2022
   */
  getGroupDetailsById(groupId: number) {
    const postJson = {
      id: groupId,
    };
    this.homeService.getGroupDetailsById(postJson).subscribe((res: any) => {
      if (res.data) {
        this.groupDetailsModel = res.data;
        this.groupCreatedByUserId = this.groupDetailsModel.created_by.id;
        this.getCategoryLists();
        this.setFormData(res.data);
        // this.getGroupRoleList(this.groupDetailsModel.id);
        // if (this.groupDetailsModel.id) {
        //   this.getGroupDesignationsListByGroupId(this.groupDetailsModel.id);
        // }
        this.getGroupMemberDetailsById(this.groupDetailsModel.id)
      }
    });
  }

  /**
   * get country state city
   */
  getAddressDetail() {
    this.homeService.getAddressMaster().subscribe(
      (res: any) => {
        this.countryList = res.data.countries;
        this.stateList = res.data.states;
        this.cityList = res.data.cities;
      });
  }


  locationSearch(event: any) {
    const val = event;
    if (val.length >= 6) {
      const postJson = { pincode: val };
      this.homeService.getLocationByPinCode(postJson).subscribe((res: any) => {
        if (res.data) {
          if (res.data.length > 0) {
            this.locationsList = res.data;
          } else {
            this.locationsList = [];
          }
        }
      });
    }
  }


  getGroupMemberDetailsById(siteId: number) {
    const postJson = {
      site_id: siteId,
      with_member: false
    };
    this.homeService.getGroupMemberById(postJson).subscribe((res: any) => {
      if (res.data) {
        this.managementsLists = res.data.allGroupMembers;
      }
    });
  }

  onEditMemberRole(item: any) {
    this.managementModel.groupMemberId = item.id;
    this.managementModel.searchText = item.name;
    this.managementModel.groupMemberRoleId = item.role_id;
    let isCustomDesignation: boolean = false;
    if (item.group_designation_id) {
      this.managementModel.groupDesignationId = item.group_designation_id;
    }
    if (item.custom_designation_name) {
      isCustomDesignation = true;
      this.managementModel.groupDesignationId = -1;
      this.managementModel.groupDesignationName = item.custom_designation_name;
    }
    this.loadGroupMemberDesignationAndRolesModalComponent(this.managementModel, isCustomDesignation);
  }

  joinSecurityCode(event: any) {
    if (event == true) {
      this.isJoinSecurityCodeRequest = true;
      this.isShowExtraField = true;
    }
    else {
      this.isJoinSecurityCodeRequest = false;
      this.isShowExtraField = false;
    }
  }

  loadGroupMemberRemoveConfirmationModalComponent(id: number, name: string) {
    if (this.userId === this.groupDetailsModel.created_by.id) {
      const modalReference = this.modalService.open(GroupMemberRemoveConfirmationModalComponent, { windowClass: 'mobile_popup' });
      modalReference.componentInstance.groupId = this.groupDetailsModel.id; //group id
      modalReference.componentInstance.removeUserId = id; //group user id
      modalReference.componentInstance.removeUserName = name; //group user name
      modalReference.result.then((result: any) => {
        console.log(`Closed with: ${result}`);
      }, (reason: any) => {
        if (reason == 'close') {
          this.getGroupMemberDetailsById(this.groupDetailsModel.id);
        }
      });
    }
    else {
      this.cS.showError("You are not authorized to access!");
    }
  }

  loadGroupMemberDesignationAndRolesModalComponent(managementModel?: any, isCustomDesignation?: boolean) {
    if (this.userId === this.groupDetailsModel.created_by.id) {
      const modalReference = this.modalService.open(GroupMemberDesignationRolesModalComponent, { windowClass: 'mobile_popup' });
      modalReference.componentInstance.groupId = this.groupDetailsModel.id; //group id
      modalReference.componentInstance.groupName = this.groupDetailsModel.name; //group name
      modalReference.componentInstance.parentModel = managementModel; //pass model in child if user click on edit button
      modalReference.componentInstance.isCustomDesignation = isCustomDesignation; //pass custom designation value if exisit
      modalReference.result.then((result: any) => {
        console.log(`Closed with: ${result}`);
      }, (reason: any) => {
        if (reason == 'close') {
          this.getGroupMemberDetailsById(this.groupDetailsModel.id);
        }
      });
    }
    else {
      this.cS.showError("You are not authorized to access!");
    }
  }

  onGroupPostModerate(event: any) {
    if (event == true) {
      this.isPostModerateRequest = true;
    }
    else {
      this.isPostModerateRequest = false;
    }
  }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

}
