import { Component, HostListener, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeviceDetectorService } from 'ngx-device-detector';
import { EventAttendieUsersComponent } from 'src/app/shared/component/event-attendie-users/event-attendie-users.component';
import { CommonService } from 'src/app/shared/services/common.service';
import { HomeService } from 'src/app/shared/services/home.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { environment } from 'src/environments/environment';
 
 

@Component({
  selector: 'ryec-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.css']
})
export class EventDetailsComponent implements OnInit {
  eventDetailsModel: any = {};
  userId: number = 0;
  eventId: number = 0;
  isMobile: boolean = false;
  isShow: boolean = false;
  topPosToStartShowing = 100;
  eventCreatedUserId: number = 0;
  groupCreatorId: number = 0;
  latitude = localStorage.getItem('latitude');
  longitude = localStorage.getItem('longitude');
  isSubmit: boolean = false;
  mapUrl: any;

  appName = environment.APP_NAME;

  constructor(
    public cS: CommonService,
    private modalService: NgbModal,
    private activeRoute: ActivatedRoute,
    private deviceService: DeviceDetectorService,
    private homeService: HomeService,
    private router: Router,
    private httpS: HttpService,
    public sanitizer: DomSanitizer,
  ) {
    const userDetail = this.cS.getUserDetails();
    if (userDetail) {
      this.userId = userDetail.id;
    }
  }

  ngOnInit() {
    this.activeRoute.params.subscribe(data => {
      this.eventId = data.slug;
      if (this.eventId) { //if id is available call api
        // this.getEventDetailsById(this.eventId);
      }
    });

    this.isMobile = this.deviceService.isMobile();
  }

  /**
   * if get event from query params call api to get details of event
   * @Yuvraj 27/04/2022
   */
  getEventDetailsById(eventId: number) {
    const postJson = { event_id: eventId };
    this.homeService.getEventDetailsById(postJson).subscribe((res: any) => {
      if (res.data) {
        this.eventDetailsModel = res.data;
        this.eventCreatedUserId = this.eventDetailsModel.user.id;
        this.groupCreatorId = this.eventDetailsModel.site.created_by;
        this.convertStartTime(this.eventDetailsModel.start_time);
        this.convertEndTime(this.eventDetailsModel.end_time);
        if (this.eventDetailsModel.latitude && this.eventDetailsModel.longitude) {
          this.mapUrl.mapURL = this.loadMap(this.eventDetailsModel.latitude, this.eventDetailsModel.longitude);
        }
      }
    });
  }

  loadMap(param1: any, param2: any) {  //call this function from html
    let url = this.sanitizer.bypassSecurityTrustResourceUrl(`https://maps.google.com/maps?q=${param1},${param2}&z=14&output=embed`) // notice carefully the backticks dont confuse it with quotes ('' or " ")
    return url
  }

  convertStartTime(startTime: any) {
    var timeString = startTime;
    var H = +timeString.substr(0, 2);
    var h = (H % 12) || 12;
    var ampm = H < 12 ? " AM" : " PM";
    this.eventDetailsModel.start_time = h + timeString.substr(2, 3) + ampm;
  }

  convertEndTime(endTime: any) {
    var timeString = endTime;
    var H = +timeString.substr(0, 2);
    var h = (H % 12) || 12;
    var ampm = H < 12 ? " AM" : " PM";
    this.eventDetailsModel.end_time = h + timeString.substr(2, 3) + ampm;
  }

  onEdit(id: number) {
    this.router.navigate(['/event/edit/' + id]);
  }

  onAttendEvent() {
    if (this.eventDetailsModel.event_type == 'free') {
      this.joinEvent();
    }
    else if (this.eventDetailsModel.event_type == 'paid') {
      // this.joinEvent();
      // integrate payment gatway
      this.cS.showError("Work is in progress");
    }
  }

  joinEvent() {
    let tempModel: any = {};
    tempModel.status = "going",
      tempModel.event_id = this.eventDetailsModel.id;
    tempModel.notes = "",
      tempModel.ip_address = "",
      tempModel.latitude = this.latitude;
    tempModel.longitude = this.longitude;
    tempModel.payment_transaction_id = 0,
      tempModel.amount = 0,
      tempModel.payment_status = "";

    this.isSubmit = true;
    const apiUrl = environment.RYEC_API_URL + 'joinEvent';
    this.httpS.post(apiUrl, tempModel).subscribe((res: any) => {
      if (res.status === 1) {
        this.cS.showSuccess(res.message);
        this.getEventDetailsById(this.eventId);
      }
      else {
        this.cS.showError(res.message);
        this.isSubmit = false;
      }
    });
  }

  loadEventAttendieUsersListModalComponent(item: any) {
    const modalReference = this.modalService.open(EventAttendieUsersComponent, { windowClass: 'mobile_popup' });
    modalReference.componentInstance.eventId = item.id; //tagged userslist
    modalReference.componentInstance.eventName = item.title; //tagged userslist
    modalReference.result.then((result: any) => {
      console.log(`Closed with: ${result}`);
    }, (reason: any) => {
      console.log('reason', reason);
    });
  }

  openShareOptions(content: string) {
    this.modalService.open(content, { windowClass: 'share_popup' }).result.then((result) => {
      console.log(`Closed with: ${result}`);
    }, (reason) => {
      console.log(reason);
    });
  }

  shareNow = async () => {
    // const navigator = window.navigator as any;
    // if ('canShare' in navigator) {
    //   // let currentFullUrl = window.location.href + '?lang=' + urlLang;
    //   let currentFullUrl = window.location.href;
    //   let title = this.jobsDetailsModel.title;
    //   let desc = this.jobsDetailsModel.description;
    //   let imageUrl = this.jobsDetailsModel.image_fullurl;
    //   const share = async function () {
    //     try {
    //       // const response = await fetch(imageUrl, {mode: 'no-cors'});
    //       const response = await fetch(imageUrl);
    //       const blob = await response.blob();
    //       const file = new File([blob], 'image.jpg', { type: blob.type });
    //       await navigator.share({
    //         url: currentFullUrl,
    //         title: title,
    //         text: desc,
    //         files: [file],
    //       });
    //       console.log("shared successfully....");
    //     } catch (err) {
    //       console.log(err.name, err.message);
    //     }
    //   };
    //   share();
    // }
  };

  shareOnFacebook() {
    let currentFullUrl = window.location.href;
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${currentFullUrl}`, 'sharer');
  }

  shareOnTwitter() {
    let currentFullUrl = window.location.href;
    window.open(`https://twitter.com/intent/tweet?url=${currentFullUrl}`, 'sharer');
  }

  // // LinkedIn share won't work if your shareUrl is localhost:port/abc, it should be genuine deployed url
  shareOnLinkedIn() {
    let currentFullUrl = window.location.href;
    window.open(`https://www.linkedin.com/shareArticle?url=${currentFullUrl}`, 'sharer');
  }

  // Back to top button
  @HostListener('window:scroll')
  checkScroll() {
    // window의 scroll top
    // Both window.pageYOffset and document.documentElement.scrollTop returns the same result in all the cases. window.pageYOffset is not supported below IE 9.
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
  }

  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  OpenCoverImageModal(content: string) {
    this.modalService.open(content, { windowClass: 'full-screen_img', centered: true }).result.then((result) => {
      console.log(`Closed with: ${result}`);
    }, (reason) => {
      console.log(reason);
    });
  }
  // Zoom image on click
  OpenImageModal(content: string) {
    this.modalService.open(content, { windowClass: 'full-screen_img', centered: true }).result.then((result) => {
      console.log(`Closed with: ${result}`);
    }, (reason) => {
      console.log(reason);
    });
  }
}
