import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
 
import { PostJsonBusinessList } from 'src/app/shared/class/data.model';

import { debounceTime, map, distinctUntilChanged, filter } from "rxjs/operators";
import { fromEvent } from 'rxjs/observable/fromEvent';
import swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/shared/services/common.service';
import { HomeService } from 'src/app/shared/services/home.service';
import { HttpService } from 'src/app/shared/services/http.service';
declare var $: any;

@Component({
  selector: 'ryec-group-jobs-listing',
  templateUrl: './group-jobs-listing.component.html',
  styleUrls: ['./group-jobs-listing.component.css']
})
export class GroupJobsListingComponent implements OnInit {
  groupId: number = 0;
  searchText: string = "";
  sort_slug: string = "";
  currentPage: number = 0;
  perPageList = environment.BUSINESS_LIST_LIMIT;
  endPage: number = environment.BUSINESS_LIST_LIMIT;
  center_lat = localStorage.getItem('latitude');
  center_long = localStorage.getItem('longitude');
  jobsList: any[] = [];
  items: any[] = [];
  totalJobsCount: number = 0;
  collectionSize: number = 0;
  first: number = 1;
  last: number = 0;
  maxSize: number = 5;
  noData: boolean = false;
  userId: number = 0;
  isUserHasBusiness: boolean = false;
  appName = environment.APP_NAME;
  jobCreatedById: number = 0;
  jobCreatedByName: string = "";

  @ViewChild('homeSearchInput')
  homeSearchInput!: ElementRef;
  constructor(
    private activeRoute: ActivatedRoute,
    private hS: HomeService,
    private cS: CommonService,
    private httpS: HttpService,
  ) {
    this.activeRoute.params.subscribe(data => {
      this.groupId = data.slug;
      // if (this.groupId) { //if id is available call api
      //   // this.getGroupJobsListingById(this.groupId);
      // }
    });
    this.currentPage = 1;
    const userDetail = this.cS.getUserDetails();
    if (userDetail) {
      this.userId = userDetail.id;
      if(userDetail.business_id){
        this.isUserHasBusiness = true;
      }
    }
  }

  ngOnInit() {
    this.checkRouterUrlForCallingAPI();
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.homeSearh();
  }

  checkRouterUrlForCallingAPI() {
    const postJson: PostJsonBusinessList = {
      site_id: this.groupId,
      page: this.currentPage,
      limit: this.perPageList
    };

    // if (this.sort_slug === 'nearMe' || this.sort_slug === 'relevance') {
    //   postJson.radius = environment.LOCATION_RADIUS;
    //   postJson.latitude = <any>this.center_lat;
    //   postJson.longitude = <any>this.center_long;
    // }

    // if (this.sort_slug === 'relevance') {
    //   postJson.latitude = <any>this.center_lat;
    //   postJson.longitude = <any>this.center_long;
    // }
    this.getJobsList(postJson);
  }

  searchBusiness() {
    $(".auto_complete_listing").css("display", "none");
    if (this.searchText != "") {
      const postJson: PostJsonBusinessList = {
        page: this.currentPage,
        limit: this.perPageList,
        title: this.searchText,
        site_id: this.groupId,
      };

      // if (this.sort_slug === 'nearMe' || this.sort_slug === 'relevance') {
      //   postJson.radius = environment.LOCATION_RADIUS;
      //   postJson.latitude = <any>this.center_lat;
      //   postJson.longitude = <any>this.center_long;
      // }

      // if (this.sort_slug === 'relevance') {
      //   postJson.latitude = <any>this.center_lat;
      //   postJson.longitude = <any>this.center_long;
      // }
      this.getJobsList(postJson);
    }
  }

  searchBusinessByCategory(name: string) {
    this.searchText = name;
    this.searchBusiness();
    $(".auto_complete_listing").css("display", "none");
  }

  getJobsList(postJson: any) {
    this.hS.getJobsList(postJson).subscribe((res: any) => {
      if (res.status == 1) {
        // this.convertToLowerCase(res.data);
        this.jobsList = res.data;
        this.totalJobsCount = res.total;
        this.jobCreatedById = res.user_id;
        this.countCollectionSize();
      }
      else {
        this.jobsList = [];
      }
    });
  }

  /**
  *Calculate pagination display on screen and count of total businesses
  */
  countCollectionSize() {
    if (this.totalJobsCount > this.perPageList) {
      this.collectionSize = Math.ceil(this.totalJobsCount / this.perPageList);
      this.collectionSize = this.collectionSize * 10;
      if (this.currentPage === 1) {
        this.first = 1;
        this.last = this.perPageList;
      } else {
        this.first = (this.currentPage - 1) * this.perPageList + 1;
        if (this.jobsList.length < this.perPageList) {
          this.last = (this.currentPage - 1) * this.perPageList + this.jobsList.length;
        } else {
          this.last = (this.currentPage - 1) * this.perPageList + this.perPageList;
        }
      }
    } else {
      this.last = this.totalJobsCount;
      this.collectionSize = this.perPageList;
    }

    if (this.totalJobsCount == 0 || this.totalJobsCount == undefined) {
      this.noData = true;
    } else {
      this.noData = false;
    }
    this.cS.scrollTop();
    // this.showBusinessOnMap();
  }

  getNextPageBusinessList(): void {
    // console.log(event);
    // this.businessMarkers = [];
    this.checkRouterUrlForCallingAPI();
  }

  /**
   * deley api calling and called once user finish typed in search text box
   * @Yuvraj 07/04/2021
   */
  homeSearh() {
    fromEvent(this.homeSearchInput.nativeElement, 'keyup').pipe(
      // get value
      map((event: any) => {
        return event.target.value;
      }),
      // if character length greater then 3
      // , filter(res => res.length >= 3)
      filter(res => {
        if (res && res.length >= 3) {
          return true;
        } else {
          $(".auto_complete_listing").css("display", "none");
          return false;
        }
      })

      // Time in milliseconds between key events
      , debounceTime(1000)

      // If previous query is diffent from current
      , distinctUntilChanged()

      // subscription for response
    ).subscribe((postJson: string) => {
      this.hS.getJobsAutoCompleteList({ searchText: postJson, site_id: this.groupId }).subscribe((res: any) => {
        if (res.data) {
          if (res.data.length > 0) {
            $(".auto_complete_listing").css("display", "block");
            this.items = res.data;
          } else {
            $(".auto_complete_listing").css("display", "none");
          }
        }
      });
    });
  }

  onJobDelete(id: number, name: string) {
    swal.fire({
      title: 'Are you sure you want to delete ' + name + ' ?',

      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      showCancelButton: true,
      showCloseButton: true,
      reverseButtons: true,
  
    }).then((result) => {
      if (result.value) {
        this.delteJob(id);
      }
    });
  }

  delteJob(id: number) {
    let tempModel: any = {};
    tempModel.job_vacancy_id = id;
    const apiUrl = environment.RYEC_API_URL + 'deleteJob';
    this.httpS.post(apiUrl, tempModel).subscribe((res: any) => {
      if (res.status === 1) {
        this.cS.showSuccess(res.message);
        this.checkRouterUrlForCallingAPI();
      }
      else {
        this.cS.showError(res.message);
      }
    });
  }

}
