import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeviceDetectorService } from 'ngx-device-detector';
 
import { BehaviorSubject } from 'rxjs';
 
import { environment } from 'src/environments/environment';
import { HomeService } from 'src/app/shared/services/home.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { CommentModalComponent } from 'src/app/shared/component/comment-modal/comment-modal.component';

@Component({
  selector: 'ryec-news-details',
  templateUrl: './news-details.component.html',
  styleUrls: ['./news-details.component.css']
})
export class NewsDetailsComponent implements OnInit {
  appName = environment.APP_NAME;
  newsDetailsModel: any;
  isShow: boolean = false;
  topPosToStartShowing: number = 100;
  businessImageUrl: string = "";
  isMobile: boolean = false;
  isPageLoaded: boolean = false;
  newsId: number = 0;
  seoPageUrl: string = environment.RYEC_SHARE_URL;
  readonly STORAGE_USER_KEY: string = "UN_AUTH_NEWS_READER_USER";
  returnRedirectUrl: string = "";
  userVisitCount = new BehaviorSubject(0);
  userVisitCount$ = this.userVisitCount.asObservable();
  relatedNewsList: any[] = [];
  commentList: any[] = [];
  collectionSize = 0;
  currentPage: number = 0;
  // perPageList = environment.BUSINESS_LIST_LIMIT;
  perPageList = 5;
  totalEventsCount: number = 0;
  first: number = 1;
  last: number = 0;
  noData: boolean = false;
  modalReference: any;
  newsSlug: string = "";


  constructor(
    private modalService: NgbModal,
    private deviceService: DeviceDetectorService,
    private activeRoute: ActivatedRoute,
    private homeService: HomeService,
    private datePipe: DatePipe,
    public cS: CommonService,
    public hS: HomeService,
  ) {
    this.currentPage = 1;
  }

  ngOnInit() {
    /**
     * get url slug
     */
    this.isMobile = this.deviceService.isMobile();
    this.activeRoute.params.subscribe(data => {
      this.newsSlug = data.slug;
      if (this.newsSlug) { //if id is available call api
        this.getNewsDetailsBySlug(this.newsSlug);
        this.returnRedirectUrl = "news/" + this.newsSlug;
      }
    });
  }

  /**
    *open share popup
    */
  openShareOptions(content: any) {
    this.modalService.open(content, { windowClass: 'share_popup' }).result.then((result) => {
      console.log(`Closed with: ${result}`);
    }, (reason) => {
      console.log(reason);
    });
  }


  shareNow = async () => {
    const navigator = window.navigator as any;
    if ('canShare' in navigator) {
      let currentFullUrl = window.location.href;
      let title = this.newsDetailsModel.name;
      let seoURL = this.seoPageUrl + '?reopenUrl=' + currentFullUrl + '&businessName=' + this.newsId;
      const share = async function () {
        try {
          await navigator.share({
            url: seoURL,
            // title: title,
            text: title,
            // files: [file],
          });
          console.log("shared successfully....");
        } catch (err:any) {
          console.log(err.name, err.message);
        }
      };
      share();
    }
  };

  shareOnFacebook() {
    let currentFullUrl = window.location.href;
    // let seoURL = this.seoPageUrl + '?reopenUrl=' + currentFullUrl + '&businessName=' + this.newsSlug;
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${currentFullUrl}`, 'sharer');
  }

  shareOnTwitter() {
    let currentFullUrl = window.location.href;
    // let seoURL = this.seoPageUrl + '?reopenUrl=' + currentFullUrl + '&businessName=' + this.newsSlug;
    window.open(`https://twitter.com/intent/tweet?url=${currentFullUrl}`, 'sharer');
  }

  // // LinkedIn share won't work if your shareUrl is localhost:port/abc, it should be genuine deployed url
  shareOnLinkedIn() {
    let currentFullUrl = window.location.href;
    // let seoURL = this.seoPageUrl + '?reopenUrl=' + currentFullUrl + '&businessName=' + this.newsSlug;
    window.open(`https://www.linkedin.com/shareArticle?url=${currentFullUrl}`, 'sharer');
  }


  // Back to top
  @HostListener('window:scroll')
  checkScroll() {

    // window의 scroll top
    // Both window.pageYOffset and document.documentElement.scrollTop returns the same result in all the cases. window.pageYOffset is not supported below IE 9.

    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    // console.log('[scroll]', scrollPosition);

    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
  }
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  ImageModal() {

  }

  getComments() {
    const postJson: any = {
      news_id: this.newsId,
      page: this.currentPage,
      limit: this.perPageList
    };
    this.getNewsCommentsListByNewsId(postJson);
  }

  getNewsDetailsBySlug(newsSlug: string) {
    const postJson = { slug: newsSlug, };
    this.homeService.getNewsDetailsBySlug(postJson).subscribe((res: any) => {
      if (res.data) {
        this.newsDetailsModel = res.data.newsDetails;
        this.newsId = this.newsDetailsModel.id;
        this.relatedNewsList = res.data.relatedNews;
        this.commentList = res.data.comments;
        this.checkUserDetails();
        if (this.newsDetailsModel.created_at) {
          let newCreatedDate = new Date(this.newsDetailsModel.created_at).toLocaleDateString("en-us");
          this.newsDetailsModel.created_at = this.datePipe.transform(newCreatedDate, 'EEEE, MMMM d, y');
        }
        this.getComments();
      }
    });
  }

  getNewsCommentsListByNewsId(postJson: any) {
    this.hS.getNewsCommentsListByNewsId(postJson).subscribe((res: any) => {
      if (res.status == 1) {
        // this.convertToLowerCase(res.data);
        this.commentList = res.data;
        this.totalEventsCount = res.total;
        this.countCollectionSize();
      }
      else {
        this.commentList = [];
      }
    });
  }

  /**
  *Calculate pagination display on screen and count of total businesses
  */
  countCollectionSize() {
    if (this.totalEventsCount > this.perPageList) {
      this.collectionSize = Math.ceil(this.totalEventsCount / this.perPageList);
      this.collectionSize = this.collectionSize * 10;
      if (this.currentPage === 1) {
        this.first = 1;
        this.last = this.perPageList;
      } else {
        this.first = (this.currentPage - 1) * this.perPageList + 1;
        if (this.commentList.length < this.perPageList) {
          this.last = (this.currentPage - 1) * this.perPageList + this.commentList.length;
        } else {
          this.last = (this.currentPage - 1) * this.perPageList + this.perPageList;
        }
      }
    } else {
      this.last = this.totalEventsCount;
      this.collectionSize = this.perPageList;
    }

    if (this.totalEventsCount == 0 || this.totalEventsCount == undefined) {
      this.noData = true;
    } else {
      this.noData = false;
    }
    this.cS.scrollTop();
    // this.showBusinessOnMap();
  }

  getNextPageBusinessList(): void {
    this.getComments();
  }

  checkAuth() {
    if (this.cS.getUserDetails()) {
      return true;
    }
    return false;
  }

  loadCommentComponent(newsId: number, replyCommentId: number) {
    if (this.checkAuth()) {
      const modalReference = this.modalService.open(CommentModalComponent, { windowClass: 'mobile_popup' }); //here
      modalReference.componentInstance.newsId = newsId; //news id
      modalReference.componentInstance.replyCommentId = replyCommentId; //reply comment id
      modalReference.result.then((result: any) => {
        console.log(`Closed with: ${result}`);
      }, (reason: any) => {
        if (reason == 'postDone') {
          this.getComments();
        }
      });
    }
    else{
      this.cS.onReturnRedirect(this.returnRedirectUrl);
    }
  }


  checkUserDetails() { //check user loged in or not
    if (!this.checkAuth()) { //if not loged in then set as fake user
      let visitCount: number = 0; //init value will be 0
      let sessionStorageData = this.get_sessionStorage_json(this.STORAGE_USER_KEY); //get data from session
      if (sessionStorageData) {
        visitCount = sessionStorageData.visitCount; //get value from data
        if (visitCount <= 9) { //check if 10 time visit or not if not continue count ++
          let session_data = this.sessionData(visitCount + 1);
          this.set_sessionStorage(this.STORAGE_USER_KEY, session_data);
          this.userVisitCount.next(visitCount + 1);
        }
        else if (visitCount >= 9) { //if visited more then conditional time then redirect to login
          // this.cS.navigateTo('/login');
          this.cS.onReturnRedirect(this.returnRedirectUrl);
        }
      }
      else { //if not found in session then set init value
        let session_data = this.sessionData(visitCount + 1);
        this.set_sessionStorage(this.STORAGE_USER_KEY, session_data);
      }
    }
    else { // if user loged in then remove session storage
      this.remove_sessionStorage(this.STORAGE_USER_KEY);
    }
  }

  // get json
  get_sessionStorage_json(key: any) {
    if (this.check_sessionStorage_Exists()) {
      if (window.sessionStorage.length > 0) {
        return JSON.parse(window.sessionStorage.getItem(key) || '{}');
      }
    }
    return false;
  }

  // set
  set_sessionStorage(key: any, value: any) {
    if (this.check_sessionStorage_Exists()) {
      if (value.constructor.name == "Array" || value.constructor.name == "Object") {
        this.set_sessionStorage_json(key, value);
      } else {
        window.sessionStorage.setItem(key, value);
      }
    }
  }

  // set json
  set_sessionStorage_json(key: any, value: any) {
    if (this.check_sessionStorage_Exists()) {
      window.sessionStorage.setItem(key, JSON.stringify(value));
    }
  }

  // check
  check_sessionStorage_Exists() {
    if (window.sessionStorage) {
      return true;
    }
    return false;
  }

  private sessionData(visitCount: any) {
    let session_data :any;
    session_data['visitCount'] = visitCount;
    return session_data;
  }

  // remove item
  remove_sessionStorage(key: any) {
    if (this.check_sessionStorage_Exists()) {
      if (window.sessionStorage.length > 0) {
        window.sessionStorage.removeItem(key);
      }
    }
    return false;
  }


  onWhatsAppBtn() {
    // let shareLink = this.
    let currentFullUrl = window.location.href;
    let seoURL = this.seoPageUrl + '?reopenUrl=' + currentFullUrl + '&businessName=' + this.newsId;
    if (this.isMobile) {
      window.open(`whatsapp://send?text=${seoURL}`);
    }
    else {
      window.open(`https://api.whatsapp.com/send?text=${seoURL}`, '_blank');
    }
  }

}
