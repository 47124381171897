import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/shared/services/common.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { environment } from '../../../../environments/environment';


@Component({
    selector: 'ryec-my-cart',
    templateUrl: './my-cart.component.html',
    styleUrls: ['./my-cart.component.css']
})
export class MyCartComponent implements OnInit, OnDestroy {

    selectedPlanDetail: any;
    paymentuplink:any;
    constructor(
        private router: Router,
        private httpS: HttpService,
        private cS: CommonService
    ) { }

    ngOnInit() {
        this.selectedPlanDetail = JSON.parse(<any>localStorage.getItem('selectedPlan'));
        if (this.selectedPlanDetail === null || this.selectedPlanDetail === undefined) {
            this.router.navigate(['/']);
        }
    }

    ngOnDestroy() {
        localStorage.removeItem('selectedPlan');
    }

    /**
     * Calling show interest api for memership plan
     */
    doPayment() {
        const postJson = {
            plan_id: this.selectedPlanDetail.id
        };

        //const apiUrl = environment.RYEC_API_URL + 'sendMembershipRequest';
        const apiUrl = environment.RYEC_API_URL + 'payg_payment';

        this.httpS.post(apiUrl, postJson).subscribe((res: any) => {
           if (res.status === 1) 
            {
                this.paymentuplink =res.payment_status_up_link

                window.open(res.payment_status_up_link,"_self");
                 //$window.location.href = '/index.html';
                //this.router.navigate([res.payment_status_up_link]); 
                //this.router.navigate(['/home/payment-successful']);
                //router.navigate(['/role']);
            }
             else {
                this.cS.showError(res.message);
            }
        }, err => {
            if (err.error) {
                console.log(err.error);
            }
        });

    }

}
