<div class="container">
	<div class="coming_text marketplace_text">
		<h4>Currently Event is only available on Android and iOS.</h4>
		<div class="row">
			<div class="col-md-12">
				<div class="stor_link" *ngIf="appName == 'MRC'">
					<a href="https://apps.apple.com/in/app/myrajasthan-club/id1545374835" target="_blank">
					<img src="assets/images/app_store_landing.png" alt="" class="img-responsive">
					</a>
					<a href="https://play.google.com/store/apps/details?id=club.myrajasthan" target="_blank">
					<img src="assets/images/play_store_landing.png" alt="" class="img-responsive">
					</a>
				</div>
				<div class="stor_link" *ngIf="appName == 'RYC'">
					<a href="https://apps.apple.com/in/app/rajput-club/id1660935382" target="_blank">
					<img src="assets/images/app_store_landing.png" alt="" class="img-responsive">
					</a>
					<a href="https://play.google.com/store/apps/details?id=com.rajputsclub" target="_blank">
					<img src="assets/images/play_store_landing.png" alt="" class="img-responsive">
					</a>
				</div>
			</div>
		</div>
	</div>
</div>


<!-- <div class="create-group_page">
  <div class="container">
      <div class="row">
          <div class="col-12">
              <div class="preview_section">
                  <div class="preview_desc">
                      <div class="mobile-actions">
                          <a href="javascript:void(0);" alt="Back"><i class="fa fa-long-arrow-left" aria-hidden="true"></i></a>
                          <a href="javascript:void(0);" alt="Share"><i class="fa fa-share" aria-hidden="true"></i></a>
                      </div>
                      <div class="preview-cover_photo" (click)="OpenCoverImageModal(ZoomImageCoverModal)">
                          <div *ngIf="eventDetailsModel.cover_image_url">
                            <div class="cover_photo">
                              <img alt="Cover" [src]="eventDetailsModel.cover_image_url">
                            </div>
                          </div>
                          <div class="cover_photo"  *ngIf="eventDetailsModel.image_fullurl == ''">
                              <img alt="Cover" src="assets/images/logo RYEC.png">
                          </div>
                      </div>
                      <div class="group_card">
                          <div class="preview_details">
                              <div class="w-100">
                                <div class="preview_logo"  (click)="OpenImageModal(ZoomImageModal)">
                                  <img [src]="eventDetailsModel.logo_image_url"/>
                                </div>
                                <div class="d-flex justify-content-between align-items-start sticky-header">
                                    <h2 class="group_name" *ngIf="eventDetailsModel.title">{{ eventDetailsModel.title }}</h2>
                                    <div class="d-flex btn-wrapper" >
                                      <button *ngIf="eventCreatedUserId !== userId && eventDetailsModel.isUserAttendees == false" type="button" (click)="onAttendEvent(eventDetailsModel)" [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'">
                                        Attend
                                      </button>
                                      <span>
                                        &nbsp;
                                      </span>
                                      <button *ngIf="!isMobile" type="button" (click)="openShareOptions(contentShare)" class=" share_btn" [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'">
                                          <i class="fa fa-share" aria-hidden="true"></i>
                                      </button>
                                      <button *ngIf="isMobile" type="button" (click)="shareNow()" class="share_btn" [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'">
                                          <i class="fa fa-share" aria-hidden="true"></i>
                                      </button>
                                      <div ngbDropdown *ngIf="eventCreatedUserId == userId">
                                        <button type="button" [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" id="dropdownConfig" ngbDropdownToggle>
                                            <i class="fa fa-bars" aria-hidden="true"></i>
                                        </button>
                                        <div ngbDropdownMenu aria-labelledby="dropdownConfig">
                                          <p *ngIf="eventCreatedUserId == userId">
                                            <button type="button" class="btn" (click)="onEdit(eventDetailsModel.id);" ngbDropdownItem [ngClass]="appName == 'RYC' ? 'actionBtnRYC' : 'actionBtnRYC'">
                                                Edit
                                            </button>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-between align-items-start ">
                                    <div class="group_desc">
                                      <p *ngIf="eventDetailsModel.event_nature">
                                        <span>
                                          <i class="fa fa-calendar-o" aria-hidden="true"></i>
                                          {{ eventDetailsModel.event_nature | titlecase }}
                                        </span>
                                      </p>
                                      <p *ngIf="eventDetailsModel.event_type">
                                        <span>
                                          <i class="fa fa-inr" aria-hidden="true"></i>
                                          {{ eventDetailsModel.event_type | titlecase }}
                                        </span>
                                      </p>
                                      <p>
                                        <span *ngIf="eventDetailsModel.external_link">
                                          <a [href]="eventDetailsModel.external_link" target="_blank">
                                            <i class="fa fa-globe" aria-hidden="true"></i>
                                            {{ eventDetailsModel.external_link }}
                                          </a>
                                        </span>
                                      </p>
                                    </div>                                       
                                </div>
                              </div>
                          </div>
                          <div class="group-action_tab">
                              <mat-tab-group mat-align-tabs="start">
                                  <mat-tab label="Details">
                                      <ng-template mat-tab-label>
                                        <div class="container">
                                          <span>About</span>
                                        </div>
                                      </ng-template>
                                      <div *ngIf="eventCreatedUserId == userId || groupCreatorId == userId">
                                        <div class="member_section request-pending_section p-3 group_card my-4">
                                          <ul>
                                            <li class="d-flex justify-content-between align-items-center">
                                              <span>
                                                Event Attendee ({{ eventDetailsModel.attending_count }})
                                              </span>
                                              <button [ngClass]="appName == 'RYC' ? 'ryec_btn' : 'mrc_btn'" type="button" (click)="loadEventAttendieUsersListModalComponent(eventDetailsModel)">Manage</button>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div>
                                        <div class="d-flex justify-content-between align-items-start flex-wrap m-2">
                                          <div class="about_section group_card my-4">
                                            <div class="d-flex justify-content-between align-items-center detail-title">
                                              <h3>{{ eventDetailsModel.title }}</h3>
                                              <button class="btn p-0" *ngIf="eventCreatedUserId == userId" type="button" (click)="onEdit(eventDetailsModel.id, eventDetailsModel.title);">
                                                <i class="fa fa-cog"></i>
                                              </button>
                                            </div>
                                            <ul class="about_list">
                                              <li *ngIf="eventDetailsModel.start_date">
                                                <h5>Event From</h5>
                                                <p class="description_box" *ngIf="eventDetailsModel.start_date">
                                                  <i class="fa fa-calendar-check-o" aria-hidden="true"></i>
                                                  {{ eventDetailsModel.start_date | date: "fullDate" }}
                                                </p>
                                                <p class="description_box" *ngIf="eventDetailsModel.start_time">
                                                  <i class="fa fa-clock-o" aria-hidden="true"></i>
                                                  {{ eventDetailsModel.start_time }}
                                                </p>
                                              </li>
                                              <li *ngIf="eventDetailsModel.end_date">
                                                <h5>Event To</h5>
                                                <p class="description_box" *ngIf="eventDetailsModel.end_date">
                                                  <i class="fa fa-calendar-check-o" aria-hidden="true"></i>
                                                  {{ eventDetailsModel.end_date | date: "fullDate" }}
                                                </p>
                                                <p class="description_box" *ngIf="eventDetailsModel.end_time">
                                                  <i class="fa fa-clock-o" aria-hidden="true"></i>
                                                  {{ eventDetailsModel.end_time }}
                                                </p>
                                              </li>
                                              <li *ngIf="eventDetailsModel.end_date">
                                                <h5>Event End</h5>
                                                <p class="description_box">
                                                  <i class="fa fa-calendar-check-o" aria-hidden="true"></i>
                                                  {{ eventDetailsModel.event_end_date | date: "fullDate" }}
                                                </p>
                                              </li>
                                              <li *ngIf="eventDetailsModel.event_repeat">
                                                <h5>Schedule</h5>
                                                <p class="description_box">{{ eventDetailsModel.event_repeat | titlecase }}</p>
                                              </li>
                                              <li *ngIf="eventDetailsModel.event_price !== '0.00'">
                                                <h5>Price</h5>
                                                <p class="description_box">{{ eventDetailsModel.event_price }}</p>
                                              </li>
                                              <li *ngIf="eventDetailsModel.event_category">
                                                <h5>Category</h5>
                                                <p class="description_box">{{ eventDetailsModel.event_category }}</p>
                                              </li>
                                              <li *ngIf="eventDetailsModel.event_location != ''">
                                                <h5>Location</h5>
                                                <p class="description_box">{{ eventDetailsModel.event_location }}</p>
                                              </li>
                                              <li *ngIf="eventDetailsModel.street_address != ''">
                                                <h5>Street Address</h5>
                                                <p class="description_box">{{ eventDetailsModel.street_address }}</p>
                                              </li>
                                              <li *ngIf="eventDetailsModel.description != ''">
                                                <h5>Desciption</h5>
                                                <p class="description_box">{{ eventDetailsModel.description }}</p>
                                              </li>
                                              <li *ngIf="eventDetailsModel.event_organizers?.length > 0">
                                                <h5>Organizer</h5>
                                                <section class="description_box">
                                                  <div class="table-responsive">
                                                    <table class="table table-striped">
                                                      <thead class="">
                                                        <tr>
                                                          <th>Name</th>
                                                          <th>Email</th>
                                                          <th>Mobile No</th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        <tr *ngFor="let item of eventDetailsModel.event_organizers; let i = index">
                                                          <td>{{ item.name }}</td>
                                                          <td>{{ item.email }}</td>
                                                          <td>{{ item.phone }}</td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                </section>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                  </mat-tab>
                              </mat-tab-group>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<ng-template #contentShare let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h5 class="modal-title">{{ "ang_share" | translate }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="social_profile">
      <ul>
        <li>
            <a href="javascript:void(0)" (click)="shareOnFacebook()">
              <img alt="facebook" src="../assets/images/facebook.png" />
            </a>
        </li>
        <li>
          <a href="javascript:void(0)" (click)="shareOnTwitter()">
            <img alt="twitter" src="../assets/images/twitter.png" />
          </a>
        </li>
        <li>
          <a href="javascript:void(0)" (click)="shareOnLinkedIn()">
            <img alt="linkedin" src="../assets/images/linkedin.png" />
          </a>
        </li>
      </ul>
    </div>
  </div>
</ng-template>
<ng-template #ZoomImageCoverModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <img *ngIf="eventDetailsModel.cover_image_url" [src]="eventDetailsModel.cover_image_url"/>
    <img *ngIf="!eventDetailsModel.cover_image_url" src="assets/images/logo RYEC.png" />
  </div>
</ng-template>


<ng-template #ZoomImageModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <img *ngIf="eventDetailsModel.logo_image_url" [src]="eventDetailsModel.logo_image_url"/>
    <img *ngIf="!eventDetailsModel.logo_image_url" src="assets/images/logo RYEC.png" />
  </div>
</ng-template> -->
