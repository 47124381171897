import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../environments/environment';
import { CommonService } from '../../services/common.service';
import { HttpService } from '../../services/http.service';



@Component({
  selector: 'ryec-group-join-verification-modal',
  templateUrl: './group-join-verification-modal.component.html',
  styleUrls: ['./group-join-verification-modal.component.css']
})
export class GroupJoinVerificationModalComponent implements OnInit {
  @Input() groupId: number = 0;
  @Input() groupName: string = "";
  @Input() dynamicExtraFieldLabel: string = "";
  verificationModel: any = {};
  isSubmit: boolean = false;
  appName = environment.APP_NAME;

  constructor(
    public activeModal: NgbActiveModal,
    private httpS: HttpService,
    private cS: CommonService,
  ) { }

  ngOnInit() {
    this.verificationModel.site_id = this.groupId;
    this.verificationModel.type = 'join';
    this.verificationModel.security_code = "";
    this.verificationModel.dynamic_field_value = "";
  }

  onSubmit() {
    this.isSubmit = true;
    const apiUrl = environment.RYEC_API_URL + 'joinOrLeaveSite';
    this.httpS.post(apiUrl, this.verificationModel).subscribe((res: any) => {
      if (res.status === 1) {
        this.isSubmit = false;
        this.cS.showSuccess(res.message);
        this.activeModal.dismiss('close');
      }
      else {
        this.isSubmit = false;
        this.cS.showError(res.message);
      }
      // this.activeModal.close();
    });
  }

}
