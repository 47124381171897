import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';

import { environment } from '../../../../environments/environment';
import { HomeService } from 'src/app/shared/services/home.service';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'ryec-market-place-detail',
  templateUrl: './market-place-detail.component.html',
  styleUrls: ['./market-place-detail.component.css']
})
export class MarketPlaceDetailComponent implements OnInit {

  modalReference: any;
  adsID: any;
  marketPlaceDetailData: any = {};
  userData: any;
  videoActiveId = 0;
  msgText: any = '';
  categoryArr: any = [];
  perPageList = environment.BUSINESS_LIST_LIMIT;
  totalIntrestCount = 0;
  collectionSize = 0;
  allIntrestData: any = [];
  currentPage: number = 1;
  maxSize = 5;
  appName = environment.APP_NAME;

  constructor(
    private modalService: NgbModal,
    private activeRoute: ActivatedRoute,
		private hS: HomeService,
    private cS: CommonService,
    private router: Router
  ) {}

  ngOnInit() {
    this.cS.scrollTop();
    if (localStorage.getItem('user') !== null) {
      this.userData = JSON.parse(<any>localStorage.getItem('user'));
    }

    this.activeRoute.params.subscribe(data => {
      this.adsID = window.atob(data.slug);
    });
    if (this.adsID) {
      this.getMarketPlaceDetailData(this.adsID);
    }
  }

  /**
   * For Open show interest popup model
   * @param contentInterest
   */
  openShowInterest(contentInterest: any) {
    this.modalReference = this.modalService.open(contentInterest, { windowClass: 'enquiry_popup' });
    this.modalReference.result.then((result: any) => {
      console.log(`Closed with: ${result}`);
    }, (reason: any) => {
      if (reason === 'submit') {
        if (this.msgText.trim()) {
          const postJson = {
            advertisement_id: this.marketPlaceDetailData.id,
            title: this.msgText.trim()
          };
          this.hS.getMarketPlaceDetail(postJson, 'sendAdvertisementEnquiry')
          .subscribe((res: any) => {
            console.log('getMarketPlaceDetailData', res);
            if (res['status'] === 1) {
              this.cS.showSuccess(res['message']);
              this.ngOnInit();
            } else {
              this.cS.showError(res['message']);
            }
          });
        } else {
          this.cS.showError('Please enter a valid message');
        }
      }
    });
  }

  /**
   * For Get all Market Place Detail Data
   * @param _adID
   */
  getMarketPlaceDetailData(_adID: any) {
    const postJson = { advertisement_id: _adID };

    this.hS.getMarketPlaceDetail(postJson, 'getAdsDetailById')
    .subscribe((res: any) => {
      console.log('getMarketPlaceDetailData', res);
      if (res['status'] === 1) {
        this.marketPlaceDetailData = res['data'];
        this.categoryArr = [];
        if (this.marketPlaceDetailData.hasOwnProperty('category_hierarchy')) {
          let categoryArr: any = this.marketPlaceDetailData.category_hierarchy;
          let myArr: any = [];
          if (categoryArr.length > 0) {
            for (const x in Object.keys(categoryArr)) {
              for (const y in Object.keys(categoryArr[x])) {
                const i = myArr.findIndex((z: any) => z.id === categoryArr[x][0].id);
                if (i === -1) {
                  myArr.push({
                    id: categoryArr[x][0].id,
                    name: categoryArr[x][0].name,
                    subCategoryArr: []
                  });
                  //console.log('myArr',myArr.length - 1);
                }
                console.log(y);
              }
              let j = myArr.length - 1;
              myArr[j].subCategoryArr.push(categoryArr[x][1]);
            }
          }
          console.log('myArr', myArr);
          this.categoryArr = myArr;
        }
        if (this.marketPlaceDetailData.hasOwnProperty('interest') && this.marketPlaceDetailData.interest.length > 0) {
          this.currentPage = 1;
          this.allIntrestData = [];
          this.getNextPageIntrestList();
        }
      } else {
        this.cS.showError(res['message']);
      }
    });
  }

  /**
    *open Video in modal view
  */
  openVideoViwer(index: any, content: any) {
    this.videoActiveId = index;
    this.modalService.open(content, { windowClass: 'video_gallery' }).result.then((result) => {
      console.log(`Closed with: ${result}`);
    }, (reason) => {
      console.log(reason);
    });
  }

  /**
   * For Get Date time diffrence
   * @param timestamp
   */
  dateDiff(timestamp: any) {
    let d = Math.abs(timestamp - new Date().getTime()) / 1000;
    let r :any;
    const s :any = {
        year: 31536000,
        month: 2592000,
        week: 604800,
        day: 86400,
        hour: 3600,
        minute: 60,
        second: 1
    };

    Object.keys(s).forEach(function(key) {
        r[key] = Math.floor(d / s[key]);
        d -= r[key] * s[key];
    });
    let diffStr: any = '';
    if (r['year'] > 0) {
      if (r['year'] === 1) {
        diffStr = r['year'] + ' year ago';
      } else {
        diffStr = r['year'] + ' years ago';
      }
    } else if (r['month'] > 0) {
      if (r['month'] === 1) {
        diffStr = r['month'] + ' month ago';
      } else {
        diffStr = r['month'] + ' months ago';
      }
    } else if (r['day'] > 0) {
      if (r['day'] === 1) {
        diffStr = 'Yesterday';
      } else {
        diffStr = r['day'] + ' days ago';
      }
    } else if (r['hour'] > 0) {
      diffStr = r['hour'] + ' hours ago';
    } else if (r['minute'] > 0) {
      diffStr = r['minute'] + ' minute ago';
    } else if (r['second'] > 0) {
      diffStr = r['second'] + ' second ago';
    }
    return diffStr;
  }

  /**
  *get business intrest list
  */
    getNextPageIntrestList(event?: number): void {
    console.log(event);
    const postJson = {
      advertisement_id: this.marketPlaceDetailData.id,
      page: this.currentPage
    };

    this.hS.getMarketPlaceDetail(postJson, 'getInterestResponses').subscribe((res: any) => {
        if (res.status === 1) {
            this.allIntrestData = res.data;
            console.log('allIntrestData',this.allIntrestData);
            this.totalIntrestCount = res.totalCount;
            this.countCollectionSize();
        }
    });
  }

  /**
   * For calculate pagination counts
   */
  countCollectionSize() {
    if (this.totalIntrestCount > this.perPageList) {
        this.collectionSize = Math.ceil(this.totalIntrestCount / this.perPageList);
        this.collectionSize = this.collectionSize * 10;
    } else {
        this.collectionSize = this.perPageList;
    }
  }

  /**
   * For delete responce by id
   * @param responceID
   */
  deleteResponses(content?: any, responceID?: any) {

    this.modalReference = this.modalService.open(content, { windowClass: 'dark-modal' });
    this.modalReference.result.then((result: any) => {
      console.log(`Closed with: ${result}`);
    }, (reason: any) => {
      if (reason === 'yes') {
        const postJson = {
          interest_id: responceID
        };

        this.hS.getMarketPlaceDetail(postJson, 'removeInterestedAdvertisement').subscribe((res: any) => {
          if (res.status === 1) {
            this.cS.showSuccess(res['message']);
            this.getNextPageIntrestList();
          } else {
            this.cS.showError(res['message']);
          }
        });
      }
    });
  }

  /**
   * Goto edit screen
   */
  editads() {
    localStorage.setItem('adDetailData', window.btoa(JSON.stringify(this.marketPlaceDetailData)));
    this.router.navigate(['home/edit-market-place']);
  }

  /**
   * Goto message detail screen
   * @param thread_id
   */
  gotomessageDetail(thread_id?: any) {
    localStorage.setItem('thread_id', thread_id);
    this.router.navigate(['messages']);
  }

}
