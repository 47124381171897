import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// For Multiple Projects handel and update project accordingly @Yuvraj 03/05/2022
if (window.location.href.indexOf('localhost') > 0) {
  environment.APP_NAME = "RYC"; // Bharat Darshan
  environment.APPLICATION_ID = "2"; //FOR Bharat Darshan
  environment.TITLE = "Rajputs Club";
  environment.gTagManagerId = 'UA-250415422-2' //Google analyticsCode For RYC
  environment.API_SECURITY_KEY ='basic MjpSQUpQVVRTQ0xVQjpSWUMtMjAyMjpSWUM6RU5ELVVTRVI=';
  environment.RYEC_SHARE_URL = 'https://admin.myrajasthanclub.com/api/share';
}
else if (window.location.href.indexOf('bharatdsnlnxportal') > 0) {
  environment.APP_NAME = "RYC";
  environment.APPLICATION_ID = "2"; //FOR RYC
  environment.TITLE = "Rajputs Club | Community of Rajput Society | Rajput Entrepreneur's Community";
  environment.gTagManagerId = 'G-8CQHF6TX6D'; //Google analyticsCode For RYC
  environment.RYEC_SHARE_URL = 'https://stagingadmin.rajputsclub.com/share';
  environment.API_SECURITY_KEY = 'basic MjpSQUpQVVRTQ0xVQjpSWUMtMjAyMjpSWUM6RU5ELVVTRVI=';
  if (environment.production) {
    environment.RYEC_SHARE_URL = 'https://admin.rajputsclub.com/share';
  }
}
else if (window.location.href.indexOf('rycstaging') > 0) {
  environment.APP_NAME = "RYC";
  environment.APPLICATION_ID = "2"; //FOR RYC
  environment.TITLE = "Rajputs Club | Community of Rajput Society | Rajput Entrepreneur's Community";
  environment.API_SECURITY_KEY ='basic MjpSQUpQVVRTQ0xVQjpSWUMtMjAyMjpSWUM6RU5ELVVTRVI=';
  environment.gTagManagerId = 'G-8CQHF6TX6D' //Google analyticsCode For RYC
  environment.RYEC_SHARE_URL = 'https://stagingadmin.rajputsclub.com/share';
}

else if (window.location.href.indexOf('bharatdarshan') > 0) {
  environment.APP_NAME = "BD"; // Bharat Darshan
  environment.APPLICATION_ID = "3"; //FOR Bharat Darshan
  environment.TITLE = "Bharat Darshan";
  environment.gTagManagerId = 'UA-250415422-2' //Google analyticsCode For BD
  environment.API_SECURITY_KEY ='basic MzpCSEFSQVREQVJTSEFOOkJELTIwMjI6QkQ6RU5ELVVTRVI=';
  environment.RYEC_SHARE_URL = 'https://stagingadmin.rajputsclub.com/share';
}
else {
  environment.APP_NAME = "MRC";
  environment.APPLICATION_ID = "1"; //FOR MRC
  environment.TITLE = 'MyRajashan.club'
  environment.API_SECURITY_KEY='basic MTpNWVJBSkFTVEhBTkNMVUI6TVJDLTIwMjI6TVJDOkVORC1VU0VS';
  environment.gTagManagerId = 'GTM-K4FCK9S' //Google analyticsCode For MRC
}

function bootstrap() {
     platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
   };


if (document.readyState === 'complete') {
  bootstrap();
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}

